import {ServiceClass} from '@/decorators';
import {WORK_PERMIT_TYPES} from '@/globals';
import {WorkPermitModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class WorkPermitApi extends BaseApi {
    public async getWorkPermits(accountId: string, filter: any = {}) {
        return this.GET_RequestAsync<Array<WorkPermitModel>>(
            `${this.ApiUrl}/accounts/${accountId}/work-permits?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public async getWorkPermitById(accountId: string, id: string) {
        return this.GET_RequestAsync<WorkPermitModel>(`${this.ApiUrl}/accounts/${accountId}/work-permits/${id}`);
    }

    // public async downloadFile(accountId: string, id: string) {
    //     return this.downloadRequest<any>(`${this.ApiUrl}/accounts/${accountId}/work-permits/${id}/download-file`);
    // }

    public async uploadWorkPermit(accountId: string, title: string, type: WORK_PERMIT_TYPES, file: File) {
        const formData = new FormData();
        formData.append('file', file);

        formData.append('type', type);
        formData.append('title', title);

        return this.POST_FileRequestAsync(`${this.ApiUrl}/accounts/${accountId}/work-permits`, formData);
    }

    public downloadWorkPermit(accountId: string, workPermitId: string) {
        return this.downnloadFileRequest(`${this.ApiUrl}/accounts/${accountId}/work-permits/${workPermitId}/download`);
    }
}
