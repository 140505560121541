export interface TagModel {
    id: string;
    name: string;
    type: string;
    code: string;
    locationId: string;
    active: boolean;
    created?: string;
    updated?: string;
}

export class CreateUpdateTagRequest implements Omit<TagModel, 'id' | 'active' | 'created' | 'updated'> {
    id?: string;
    name!: string;
    type!: string;
    code!: string;
    locationId!: string;

    constructor(data: CreateUpdateTagRequest) {
        Object.assign(this, data);
    }
}

export class TagsGroupByType {
    public qrCodeTags: TagModel[] = [];
    public barCodeTags: TagModel[] = [];
    public nfcTags: TagModel[] = [];
}
