export class AddJobNormalWorkPermit {
    id?: string | null = null;

    workPermitNo?: number | null = null;

    workOrderNumber: string | null = null;
    location?: string | null = null;
    issueDate?: Date | null = null;

    subcontractor?: string | null = null;
    areaOfWork?: string | null = null;
    startingDate?: string | null = null;
    startingTime?: string | null = null;
    endingDate?: string | null = null;
    endingTime?: string | null = null;

    // subContractorReceivingAuthoritySigned?: boolean | null = null;
    // subContractorReceivingAuthorityUserId?: string | null = null;
    // subContractorReceivingAuthoritySignDate?: string | null = null;
    // subContractorReceivingAuthoritySignTime?: string | null = null;

    // subContractorPerformingAuthoritySigned?: boolean | null = null;
    // subContractorPerformingAuthorityUserId?: string | null = null;
    // subContractorPerformingAuthoritySignDate?: string | null = null;
    // subContractorPerformingAuthoritySignTime?: string | null = null;

    description?: string | null = null;
    workEquipment?: string | null = null;

    jobId: string | null = null;
    permitCreaterUserId: string | null = null;

    constructor(data?: Partial<AddJobNormalWorkPermit>) {
        Object.assign(this, data);
    }
}
