var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "height-100vh overflow-auto", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "height-100x", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mb-lg-0 mb-sm-0 mb-10", attrs: { cols: "5" } },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column height-100x",
                  staticStyle: {
                    border: "3px black",
                    "background-color": "#738dca",
                    "border-radius": "45px",
                    padding: "0",
                    overflow: "hidden",
                  },
                },
                [
                  _c("div", { staticClass: "flex-shrink-1 px-7" }, [
                    _c("div", { staticClass: "mt-10 white--text" }, [
                      _c("h1", { staticClass: "px-0 py-3" }, [
                        _vm._v(_vm._s("Welcome to FOEx")),
                      ]),
                    ]),
                    _c("p", { staticClass: "white--text body-1" }, [
                      _vm._v(
                        " Interactively fashion orthogonal information via scalable customer service. Collaboratively integrate client-centric schemas without leveraged best practices. Enthusiastically scale clicks-and-mortar content and intuitive data.   Compentently productize enabled. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "flex-grow-1",
                      staticStyle: { position: "relative", height: "500px" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "text-left",
                        staticStyle: {
                          position: "absolute",
                          bottom: "0",
                          top: "0",
                          left: "0",
                          "z-index": "5",
                        },
                        attrs: {
                          src: require("../../../assets/Character.svg"),
                          height: "100%",
                          width: "100%",
                          position: "bottom left",
                          contain: "",
                        },
                      }),
                      _c("v-img", {
                        staticStyle: {
                          position: "absolute",
                          bottom: "0",
                          top: "0",
                          left: "0",
                          "z-index": "4",
                        },
                        attrs: {
                          src: require("../../../assets/Circle.svg"),
                          height: "100%",
                          width: "100%",
                          position: "bottom left",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-center align-sm-center height-100x py-10",
              },
              [_c("router-view", { key: _vm.$route.fullPath })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }