import {ServiceClass} from '@/decorators';
import i18n from '@/plugins/i18n';
import Swal from 'sweetalert2';

@ServiceClass()
export class AlertService {
    public show(type: 'success' | 'warning' | 'error' | 'info' | 'question', message: string, position?: 'top' | 'center' | 'bottom', timer?: number) {
        const isT = message.split('$t.');
        console.log(isT);
        if (isT.length > 1) {
            message = message.replace('$t.', '');
            message = i18n.t(message) as string;
        }
        return Swal.fire({
            icon: type,
            html: message,
            position: position ?? 'center',
            timer,
            confirmButtonColor: '#1B3054',
        });
    }
}
