import {ServiceClass} from '@/decorators';
import {SessionModel, UserSession, WorkPermitModel} from '@/sdk';
import {GlobalWorkPermitApi} from '@/sdk/api-services';

@ServiceClass()
export class GlobalWorkPermitsService {
    public globalWorkPermitApi: GlobalWorkPermitApi = new GlobalWorkPermitApi();

    public globalWorkPermits: Array<WorkPermitModel> = [];

    public selectedGlobalWorkPermit: WorkPermitModel | null = null;

    public session: UserSession = new UserSession();

    public sessionData: SessionModel | null = null;

    constructor() {
        this.session._session.subscribe(session => {
            this.sessionData = session;
        });
    }

    public async getGlobalWorkPermits() {
        this.globalWorkPermits = await this.globalWorkPermitApi.getWorkPermits({});

        return this.globalWorkPermits;
    }
}
