var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column px-3 pt-3 pb-4 height-100x",
      staticStyle: { "z-index": "1" },
    },
    [
      _c(
        "base-card",
        {
          staticClass: "my-2 flex-shrink-0",
          staticStyle: {
            "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          },
          attrs: { flat: "" },
        },
        [
          _c(
            "v-card-text",
            { staticStyle: { padding: "10px 15px" } },
            [
              _c("v-row", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "col-12 d-flex align-center" },
                  [
                    _c("base-text-field", {
                      staticClass: "mr-1",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "hide-details": "",
                        name: "Search",
                        label: "Search",
                      },
                    }),
                    _c(
                      "base-btn",
                      { staticClass: "mx-1", attrs: { depressed: "" } },
                      [_vm._v(" " + _vm._s(_vm.$t("Common.Search")) + " ")]
                    ),
                    _c(
                      "base-btn",
                      {
                        staticClass: "white--text",
                        on: {
                          click: function ($event) {
                            return _vm.addNewUserClick()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Common.AddNewUser")) + " ")]
                    ),
                    _c(
                      "base-btn",
                      {
                        staticClass: "white--text",
                        on: {
                          click: function ($event) {
                            return _vm.inviteRegularUser()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s("Invite Regular User") + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "base-card",
        { staticClass: "flex-grow-1", attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "white height-100x width-100x overflow-auto" },
            [
              _c("table", { staticClass: "sites-table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("UsersPage.TableHeaders.Number"))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("UsersPage.TableHeaders.UserID"))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("UsersPage.TableHeaders.FirstName"))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("UsersPage.TableHeaders.MiddleName"))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("UsersPage.TableHeaders.LastName"))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("UsersPage.TableHeaders.LoginID"))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("UsersPage.TableHeaders.AccountRole"))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("UsersPage.TableHeaders.Actions"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.userSetupSrv.accountUsersList,
                    function (user, index) {
                      return _c("tr", { key: `${user.id}` }, [
                        _c("td", { staticClass: "width-30px" }, [
                          _c("div", [_vm._v(" " + _vm._s(index + 1) + " ")]),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "width-300px overflow-hidden",
                              staticStyle: { "white-space": "nowrap" },
                            },
                            [_vm._v(_vm._s(user.id))]
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "width-150px" }, [
                            _vm._v(_vm._s(user.firstName)),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "width-150px" }, [
                            _vm._v(_vm._s(user.middleName)),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "width-150px" }, [
                            _vm._v(_vm._s(user.lastName)),
                          ]),
                        ]),
                        _c("td"),
                        _c("td", [
                          _c("div", { staticClass: "width-100px" }, [
                            _vm._v(
                              _vm._s(
                                user.roles && user.roles.length
                                  ? user.roles[0].name
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "width-200px text-center",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "white--text mr-1 mb-1",
                                    attrs: { color: "blue", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editClick(user)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Common.Edit")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "white--text mr-1 mb-1",
                                    attrs: { color: "cyan", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.assignSiteClick(user)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s("Assign Sites") + " ")]
                                ),
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "white--text mr-1 mb-1",
                                    attrs: {
                                      color: "error",
                                      small: "",
                                      disabled: _vm.Session.userId === user.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteClick(user)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("Common.Delete")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("SaveUserDialogComponent", {
        ref: "saveUserDialogRef",
        attrs: {
          accountId: _vm.accountIdC,
          name: `save-user-dialog-${_vm.accountIdC}`,
        },
      }),
      _c("InviteRegularUserDialogComponent", {
        ref: "inviteRegularUserDialogRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }