var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "d-flex height-100x flex-column", attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "flex-shrink-0" },
        [
          _c("v-icon", [_vm._v("mdi-file-document")]),
          _c("span", [_vm._v("Report Listing")]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "flex-grow-1 height-100x overflow-auto" },
        [
          _c("table", { staticClass: "foex-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("#")]),
                _c("th", [_vm._v("Date Generated")]),
                _c("th", [_vm._v("Report Type")]),
                _c("th", [_vm._v("Generated By")]),
                _c("th", [_vm._v("File Name")]),
                _c("th", [_vm._v("Actions")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.reports, function (report, i) {
                return _c("tr", { key: report.id }, [
                  _c("td", [_vm._v(_vm._s(i + 1))]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.$helpers.formatDate(report.generatedOn))),
                  ]),
                  _c("td", [_vm._v(_vm._s(report.reportType))]),
                  _c("td"),
                  _c("td", [_vm._v(_vm._s(report.fileName))]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadReport(report)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-download")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "red" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteReport(report)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }