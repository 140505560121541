export enum INSPECTION_QUESTION_OPTION_TYPES {
    INSPECTION_RESULT = 'inspection_result',
    INSPECTION_SEVERITY = 'inspection_severity',
}

export class InspectionQuestionFieldOptionsModel {
    public id?: string | null = null;
    public inspectionFormQuestionId?: string | null = null;
    public option: string | null = null;
    public type: INSPECTION_QUESTION_OPTION_TYPES | null = null;
    public sortOrder?: number | null = null;
    public ratingValue: number = 0;

    public constructor(init?: Partial<InspectionQuestionFieldOptionsModel>) {
        Object.assign(this, init);
    }
}
