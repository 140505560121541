import {ServiceClass} from '@/decorators';
import {WORK_PERMIT_TYPES} from '@/globals';
import {WorkPermitModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class GlobalWorkPermitApi extends BaseApi {
    public async getWorkPermits(filter: any = {}) {
        return this.GET_RequestAsync<Array<WorkPermitModel>>(`${this.ApiUrl}/global-work-permits?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public async getWorkPermitById(id: string) {
        return this.GET_RequestAsync<WorkPermitModel>(`${this.ApiUrl}/global-work-permits/${id}`);
    }

    public async uploadWorkPermit(title: string, type: WORK_PERMIT_TYPES, file: File) {
        const formData = new FormData();
        formData.append('file', file);

        formData.append('type', type);
        formData.append('title', title);

        return this.POST_FileRequestAsync(`${this.ApiUrl}/global-work-permits`, formData);
    }

    public downloadWorkPermit(workPermitId: string) {
        return this.downnloadFileRequest(`${this.ApiUrl}/global-work-permits/${workPermitId}/download`);
    }

    public async cloneToAccount(workPermitId: string, accountId: string) {
        return this.POST_RequestAsync<WorkPermitModel>(`${this.ApiUrl}/global-work-permits/${workPermitId}/clone-to-account/${accountId}`, {});
    }
}
