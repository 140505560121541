var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-3", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "foex-tabs",
                  attrs: { height: "35px", "hide-slider": "" },
                  on: { change: _vm.onTabChange },
                  model: {
                    value: _vm.fieldsDbTab,
                    callback: function ($$v) {
                      _vm.fieldsDbTab = $$v
                    },
                    expression: "fieldsDbTab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "foex-tab",
                      attrs: { "active-class": "foex-tab--active" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/icons/area.png"),
                          height: "20",
                          contain: "",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Common.Area")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "foex-tab",
                      attrs: { "active-class": "foex-tab--active" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/icons/equipment.png"),
                          height: "25",
                          contain: "",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Common.Equipment")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex ml-auto" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-2",
                      attrs: { color: "admin-primary", outlined: "" },
                      on: { click: _vm.importCsv },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("FieldsDbPage.ImportCsv")) + " "
                      ),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("mdi-import"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "admin-primary ", outlined: "" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("FieldsDbPage.ExportCsv")) + " "
                      ),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("mdi-export"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "height-100x",
          model: {
            value: _vm.fieldsDbTab,
            callback: function ($$v) {
              _vm.fieldsDbTab = $$v
            },
            expression: "fieldsDbTab",
          },
        },
        [
          _c(
            "v-tab-item",
            { staticClass: "height-100x" },
            [
              _c(
                "v-row",
                { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(_vm.$t("FieldsDbPage.AreaTab.Title"))),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c("AreaFieldsDbComponent", {
                on: {
                  onFieldClick: _vm.onFieldClick,
                  addFieldOption: _vm.addFieldOption,
                  toggleRequire: _vm.toggleRequire,
                  editOption: _vm.editOption,
                  deleteOption: _vm.deleteOption,
                },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "height-100x" },
            [
              _c(
                "v-row",
                { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(_vm.$t("FieldsDbPage.EquipmentTab.Title"))),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c("EquipmentFieldsDbComponent", {
                on: {
                  onFieldClick: _vm.onFieldClick,
                  addFieldOption: _vm.addFieldOption,
                  toggleRequire: _vm.toggleRequire,
                  editOption: _vm.editOption,
                  deleteOption: _vm.deleteOption,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("SaveFieldOptionDialogComponent", {
        ref: "addFieldOptionDialogRef",
        attrs: { currentTab: _vm.fieldsDbTab === 0 ? "area" : "equipment" },
      }),
      _c("ImportFieldsDbCsvDialogComponent", { ref: "importFieldsDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }