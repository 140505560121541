var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.siteLocationsSrv.selectedLcoation
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column overflow-hidden height-100x",
          class: { "height-100x": !_vm.diagramViewStyle },
        },
        [
          _c("div", { staticClass: "grey lighten-3 mb-4 flex-shrink-0" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center results-header" },
              [
                _c(
                  "div",
                  { staticClass: "px-5" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "results-header-h1",
                        staticStyle: { width: "500px" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex",
                            attrs: { lg: "12", cols: "6" },
                          },
                          [
                            _c("v-img", {
                              staticClass: "area-search header-img-title",
                              attrs: {
                                height: "20",
                                width: "20",
                                contain: "",
                                src: require("@/assets/icons/distribution-white.png"),
                              },
                            }),
                            _c("h2", { staticClass: "areaTitle" }, [
                              _vm._v("Distribution Panel Details"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      _vm._l(_vm.locationPath, function (path, index) {
                        return _c(
                          "div",
                          { key: path.id, staticClass: "d-flex" },
                          [
                            path.type === "site" && _vm.diagramViewStyle
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: "/images/plant.png",
                                  },
                                })
                              : _vm._e(),
                            path.type === "site" && !_vm.diagramViewStyle
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/plant-light.png"),
                                  },
                                })
                              : _vm.diagramViewStyle &&
                                path.type === "location" &&
                                index === _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "20",
                                    width: "22",
                                    contain: "",
                                    src: "/images/dp.png",
                                  },
                                })
                              : !_vm.diagramViewStyle &&
                                path.type === "location" &&
                                index === _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/distribution-white.png"),
                                  },
                                })
                              : _vm.diagramViewStyle &&
                                path.type === "location" &&
                                index < _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: "/images/area.png",
                                  },
                                })
                              : !_vm.diagramViewStyle &&
                                path.type === "location" &&
                                index < _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/area-light.png"),
                                  },
                                })
                              : _vm._e(),
                            path.type === "site"
                              ? _c("h4", { staticClass: "header-title-text" }, [
                                  _vm._v(_vm._s(path.title)),
                                ])
                              : _c(
                                  "span",
                                  {
                                    staticClass: "cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "goToLocation",
                                          path.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "header-title-text" },
                                      [_vm._v(_vm._s(path.title))]
                                    ),
                                  ]
                                ),
                            index !== _vm.locationPath.length - 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "header-title-text next-arrow",
                                    staticStyle: {
                                      color: "white",
                                      padding: "0px 5px",
                                    },
                                  },
                                  [_vm._v(" > ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
                !_vm.readOnlyFlag && !_vm.diagramViewStyle
                  ? _c(
                      "div",
                      { staticClass: "px-3" },
                      [
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              border: "1px solid white !important",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("saveDistributionPanel")
                              },
                            },
                          },
                          [_vm._v("Save")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.diagramViewStyle
                  ? _c(
                      "div",
                      { staticClass: "px-3" },
                      [
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              padding: "0 !important",
                              border: "none",
                              height: "auto",
                              "min-width": "auto",
                              "background-color": "white !important",
                              "box-shadow": "none",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("fullPageFormView", true)
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                height: "16",
                                width: "16",
                                contain: "",
                                src: "/images/enlarge.png",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              padding: "0 !important",
                              border: "none",
                              height: "auto",
                              "min-width": "auto",
                              "background-color": "white !important",
                              "box-shadow": "none",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("goToLocation", null)
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                height: "26",
                                width: "26",
                                contain: "",
                                src: "/images/minus.png",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              key:
                _vm.siteLocationsSrv.selectedLcoation.distributionPanel.id || 0,
              staticClass:
                "flex-grow-1 flex-1 overflow-auto d-flex detailed-forms info-tabs-header",
            },
            [
              _c(
                "div",
                { staticClass: "col col-12" },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "my-tabs min-height-100",
                      staticStyle: { "background-color": "white" },
                      attrs: { vertical: _vm.diagramViewStyle ? false : true },
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [_vm._v("General Information")]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [_vm._v("Parent Area Information")]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [_vm._v("Wiring Information")]
                      ),
                      !_vm.readOnlyFlag && _vm.diagramViewStyle
                        ? _c(
                            "div",
                            {
                              staticClass: "px-3",
                              staticStyle: { "margin-left": "auto" },
                            },
                            [
                              _c(
                                "base-btn",
                                {
                                  staticStyle: {
                                    border: "1px solid white !important",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("saveEquipmentDetail")
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab-item",
                        {
                          key: `${
                            _vm.siteLocationsSrv.selectedLcoation
                              .distributionPanel.id || 0
                          }_gei`,
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              readonly: "",
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation.title,
                                              name: "projectName",
                                              label: "Name",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation,
                                                  "title",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              readonly: _vm.readOnlyFlag,
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .distributionPanel.id,
                                              name: "distributionID",
                                              disabled: "",
                                              label: "Distribution Panel ID",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .distributionPanel,
                                                  "id",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              readonly: "",
                                              value: _vm.locationPath
                                                .map((loc) => loc.title)
                                                .join(" > "),
                                              name: "locationName",
                                              label: "Location",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          key: `${
                            _vm.siteLocationsSrv.selectedLcoation
                              .distributionPanel.id || 0
                          }_pai`,
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  !_vm.siteLocationsSrv.selectedLcoation
                                    .locationId
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-h4 text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "This Distribution Panel does not have any parent area."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  name: "areaID",
                                                  readonly: "",
                                                  label: "Area ID",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation,
                                                      "locationId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  name: "title",
                                                  readonly: "",
                                                  label: "Title",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .parentLocation.locationDetail
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      name: "lowAmbientTemp",
                                                      readonly: "",
                                                      label:
                                                        "Low Ambient Temperature",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .lowAmbientTemp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "lowAmbientTemp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.lowAmbientTemp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .parentLocation.locationDetail
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      name: "highAmbientTemp",
                                                      readonly: "",
                                                      label:
                                                        "High Ambient Temperature",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .highAmbientTemp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "highAmbientTemp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.highAmbientTemp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .parentLocation.locationDetail
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaIpx
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label:
                                                        "IP (X) Requirement",
                                                      "return-object": false,
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail.ipx,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "ipx",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.ipx",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .parentLocation.locationDetail
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaIpy
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label:
                                                        "IP (Y) Requirement",
                                                      "return-object": false,
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail.ipy,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "ipy",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.ipy",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation &&
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation.locationDetail
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasArea
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas Area",
                                                  "return-object": false,
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail.gasZone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "gasZone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasZone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation &&
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation.locationDetail
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasGroup
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas Group",
                                                  "return-object": false,
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail.gasGroup,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "gasGroup",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasGroup",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasTemperatureClass
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas temp. Class",
                                                  "return-object": false,
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail
                                                      .gasTempClass,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "gasTempClass",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasTempClass",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation &&
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation.locationDetail
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaDustArea
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Dust Area",
                                                  "return-object": false,
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail.dustZone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "dustZone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustZone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation &&
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation.locationDetail
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaDustGroup
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Dust Group",
                                                  "return-object": false,
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail.dustgroup,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "dustgroup",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustgroup",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustCloudIgnitionTemp",
                                                  label:
                                                    "Dust Cloud Ignition Temperature (oC)",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail
                                                      .dustCloudIgnitionTemp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "dustCloudIgnitionTemp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustCloudIgnitionTemp",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation &&
                                  _vm.siteLocationsSrv.selectedLcoation
                                    .parentLocation.locationDetail
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustLayerDepth",
                                                  label: "Dust Layer Depth ",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail
                                                      .dustLayerDepth,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "dustLayerDepth",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustLayerDepth",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustLayerIgnitionTemp",
                                                  label:
                                                    "Dust Layer Ignition Temperature (oC)",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .parentLocation
                                                      .locationDetail
                                                      .dustLayerIgnitionTemp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .parentLocation
                                                        .locationDetail,
                                                      "dustLayerIgnitionTemp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustLayerIgnitionTemp",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          key: `${
                            _vm.siteLocationsSrv.selectedLcoation
                              .distributionPanel.id || 0
                          }_pbi`,
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "rounded-card" },
                                            [
                                              _c("v-card-title", [
                                                _vm._v(" Power Board "),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    height: "364px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c("v-spacer", {
                                                            staticStyle: {
                                                              padding: "5px",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "base-autocomplete",
                                                            {
                                                              attrs: {
                                                                readonly:
                                                                  _vm.readOnlyFlag,
                                                                dense: "",
                                                                outlined: "",
                                                                items:
                                                                  _vm.powerBoardType,
                                                                value:
                                                                  _vm
                                                                    .siteLocationsSrv
                                                                    .selectedLcoation
                                                                    .distributionPanel
                                                                    .powerBoardType,
                                                                "item-text":
                                                                  "value",
                                                                "item-value":
                                                                  "id",
                                                                label: "Type",
                                                                "return-object": false,
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .distributionPanel,
                                                                      "powerBoardType",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "base-text-field",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#fdf7e7",
                                                              },
                                                              attrs: {
                                                                readonly:
                                                                  _vm.readOnlyFlag,
                                                                value:
                                                                  _vm
                                                                    .siteLocationsSrv
                                                                    .selectedLcoation
                                                                    .distributionPanel
                                                                    .powerBoardVoltage,
                                                                name: "powerBoardVoltage",
                                                                label:
                                                                  "Voltage",
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .distributionPanel,
                                                                      "powerBoardVoltage",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "base-text-field",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#fdf7e7",
                                                              },
                                                              attrs: {
                                                                readonly:
                                                                  _vm.readOnlyFlag,
                                                                value:
                                                                  _vm
                                                                    .siteLocationsSrv
                                                                    .selectedLcoation
                                                                    .distributionPanel
                                                                    .powerBoardFrequency,
                                                                name: "powerBoardFrequency",
                                                                label:
                                                                  "Frequency",
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .distributionPanel,
                                                                      "powerBoardFrequency",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "rounded-card" },
                                            [
                                              _c("v-card-title", [
                                                _vm._v("Switch Board"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticStyle: {
                                                    overflow: "scroll",
                                                    height: "300px",
                                                    "overflow-x": "hidden",
                                                  },
                                                },
                                                [
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .switchBoards?.length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switches",
                                                        },
                                                        _vm._l(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .switchBoards,
                                                          function (
                                                            board,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "v-row",
                                                              {
                                                                key: board.id,
                                                                staticClass:
                                                                  "switch-b",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                    staticStyle:
                                                                      {
                                                                        "padding-bottom":
                                                                          "0",
                                                                        "padding-top":
                                                                          "8px",
                                                                      },
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "text-h6",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Output " +
                                                                            _vm._s(
                                                                              index +
                                                                                1
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                          value:
                                                                            board.id,
                                                                          name: "powerBoardID",
                                                                          label:
                                                                            "Output ID",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "id",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          value:
                                                                            board.outpulPhase,
                                                                          name: "powerBoardVoltage",
                                                                          label:
                                                                            "Phase",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "outpulPhase",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          value:
                                                                            board.outputVoltage,
                                                                          name: "powerBoardVoltage",
                                                                          label:
                                                                            "Voltage",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "outputVoltage",
                                                                                $event
                                                                              )
                                                                            },
                                                                          keypress:
                                                                            _vm.validateNumber,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          value:
                                                                            board.outputFrequency,
                                                                          name: "powerBoardFrequency",
                                                                          label:
                                                                            "Frequency",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "outputFrequency",
                                                                                $event
                                                                              )
                                                                            },
                                                                          keypress:
                                                                            _vm.validateNumber,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                      "data-id":
                                                                        index,
                                                                    },
                                                                    on: {
                                                                      dragover:
                                                                        _vm.allowDrop,
                                                                      drop: _vm.drop,
                                                                      dragenter:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.onDragEnter(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Connect With"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          items:
                                                                            _vm.getEquipmentsList,
                                                                          value:
                                                                            board.connectWith,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Select Equip / DP",
                                                                          "return-object": false,
                                                                          multiple: false,
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "connectWith",
                                                                                $event
                                                                              )
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeConnectWithFields(
                                                                                board,
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          loading:
                                                                            _vm.loadingConnectLists,
                                                                          items:
                                                                            _vm.watchFinalList(
                                                                              index
                                                                            ),
                                                                          value:
                                                                            board.connectWithMid,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Select Outputs / Entries",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "connectWithMid",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          items:
                                                                            _vm.selectConnectDirection,
                                                                          value:
                                                                            board.connectDirection,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Direction",
                                                                          clearable:
                                                                            "",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                board,
                                                                                "connectDirection",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.readOnlyFlag
                                                                      ? _c(
                                                                          "v-row",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                padding:
                                                                                  "15px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "25px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                    icon: "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateBoard(
                                                                                        board
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "green--text cursor-pointer",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-check"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "25px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                    icon: "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteBoard(
                                                                                        board
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        height:
                                                                                          "16",
                                                                                        width:
                                                                                          "25",
                                                                                        contain:
                                                                                          "",
                                                                                        src: require("@/assets/icons/delete-red.png"),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.showNewBoard
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fdf7e7",
                                                                  },
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .outpulPhase,
                                                                    name: "powerBoardVoltage",
                                                                    label:
                                                                      "Phase",
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "outpulPhase",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fdf7e7",
                                                                  },
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .outputVoltage,
                                                                    name: "powerBoardVoltage",
                                                                    label:
                                                                      "Voltage",
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "outputVoltage",
                                                                          $event
                                                                        )
                                                                      },
                                                                    keypress:
                                                                      _vm.validateNumber,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fdf7e7",
                                                                  },
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .outputFrequency,
                                                                    name: "powerBoardFrequency",
                                                                    label:
                                                                      "Frequency",
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "outputFrequency",
                                                                          $event
                                                                        )
                                                                      },
                                                                    keypress:
                                                                      _vm.validateNumber,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                              on: {
                                                                dragover:
                                                                  _vm.allowDrop,
                                                                drop: _vm.dropNew,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Connect With"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    items:
                                                                      _vm.getEquipmentsList,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .connectWith,
                                                                    "item-text":
                                                                      "title",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      "Select Equip / DP",
                                                                    "return-object": false,
                                                                    multiple: false,
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "connectWith",
                                                                          $event
                                                                        )
                                                                      },
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeConnectWithFieldsNew(
                                                                          _vm
                                                                            .newBoard
                                                                            .connectWith
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    loading:
                                                                      _vm.loadingConnectLists,
                                                                    items:
                                                                      _vm.finalListNew,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .connectWithMid,
                                                                    "item-text":
                                                                      "title",
                                                                    "item-value":
                                                                      "id",
                                                                    clearable:
                                                                      "",
                                                                    label:
                                                                      "Select Outputs / Entries",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "connectWithMid",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    items:
                                                                      _vm.selectConnectDirection,
                                                                    value:
                                                                      _vm
                                                                        .newBoard
                                                                        .connectDirection,
                                                                    "item-text":
                                                                      "value",
                                                                    "item-value":
                                                                      "key",
                                                                    label:
                                                                      "Direction",
                                                                    clearable:
                                                                      "",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newBoard,
                                                                          "connectDirection",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _vm.showNewBoard
                                                    ? _c(
                                                        "base-btn",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.saveSwitchBoard,
                                                          },
                                                        },
                                                        [_vm._v("Save")]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.showNewBoard &&
                                                  !_vm.readOnlyFlag
                                                    ? _c(
                                                        "base-btn",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.addSwitchBoard,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Add Switch Board "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("v-file-input", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              name: "electProcessDrawings",
                                              label:
                                                "Electrical and Process Drawings",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("v-file-input", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              name: "equipmentPhoto",
                                              label: "Photo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }