var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-6",
      attrs: {
        width: 248,
        app: "",
        "mobile-breakpoint": "991",
        "mini-variant-width": "60",
        "mini-variant": _vm.CoreSrv.UserDrawer.Mini,
      },
      on: {
        "update:miniVariant": function ($event) {
          return _vm.$set(_vm.CoreSrv.UserDrawer, "Mini", $event)
        },
        "update:mini-variant": function ($event) {
          return _vm.$set(_vm.CoreSrv.UserDrawer, "Mini", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c("div", { staticClass: "pa-2 d-flex justify-center" }, [
                _c("div", [
                  _c(
                    "span",
                    {
                      staticClass:
                        "top-shadow d-block text-center caption font-weight-medium grey--text text--lighten-2 no-select",
                    },
                    [_vm._v(" v " + _vm._s(_vm.AppVersion) + " ")]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.CoreSrv.UserDrawer.Mode,
        callback: function ($$v) {
          _vm.$set(_vm.CoreSrv.UserDrawer, "Mode", $$v)
        },
        expression: "CoreSrv.UserDrawer.Mode",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex px-1 drawer-logo",
          class: [
            { "px-2 pt-3": !_vm.CoreSrv.UserDrawer.Mini },
            { "px-1": _vm.CoreSrv.UserDrawer.Mini },
          ],
        },
        [
          _c(
            "v-avatar",
            { attrs: { tile: "", width: "100%", height: "50" } },
            [
              !_vm.CoreSrv.UserDrawer.Mini
                ? _c("v-img", {
                    staticClass: "transparent cursor-pointer",
                    attrs: {
                      height: "50",
                      contain: "",
                      src: require("@/assets/logo.svg"),
                      alt: "MHParks.com Logo",
                      title: "MHParks.com Logo",
                    },
                    on: {
                      click: function ($event) {
                        _vm.window.location.href = "/"
                      },
                    },
                  })
                : _c("v-img", {
                    staticClass: "transparent cursor-pointer",
                    attrs: {
                      height: "35",
                      contain: "",
                      src: require("@/assets/logo-mini.svg"),
                      alt: "MHParks.com Logo",
                      title: "MHParks.com Logo",
                    },
                    on: {
                      click: function ($event) {
                        _vm.window.location.href = "/"
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-list",
            {
              class: {
                "pt-2  pr-0 pl-0": !_vm.CoreSrv.UserDrawer.Mini,
              },
              attrs: { nav: "", dense: "" },
            },
            [
              _vm._l(_vm.DatabaseLinks, function (menu) {
                return _c(
                  "v-list-group",
                  {
                    key: menu.Title,
                    attrs: { "prepend-icon": menu.Icon, "no-action": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(menu.Title)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: menu.Active,
                      callback: function ($$v) {
                        _vm.$set(menu, "Active", $$v)
                      },
                      expression: "menu.Active",
                    },
                  },
                  _vm._l(menu.Links, function (link) {
                    return _c(
                      "v-list-item",
                      {
                        key: link.Name,
                        staticClass:
                          "d-flex align-end rounded-0 pl-5 border-transparent",
                        class: {
                          "py-1": !_vm.CoreSrv.UserDrawer.Mini,
                        },
                        attrs: {
                          to: { name: link.Name, params: link.data },
                          inactive: link.inactive,
                          "active-class": "admin-primary--text border",
                          exact: "",
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          {
                            class: {
                              "mr-3": !_vm.CoreSrv.UserDrawer.Mini,
                              "mr-1": _vm.CoreSrv.UserDrawer.Mini,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "25" } }, [
                              _vm._v(_vm._s(link.Icon)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass:
                                  "subtitle-2 font-weight-medium ml-0",
                              },
                              [_vm._v(" " + _vm._s(link.Title) + " ")]
                            ),
                          ],
                          1
                        ),
                        link.Chip
                          ? _c(
                              "v-chip-group",
                              { attrs: { mandatory: "", color: "white" } },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass:
                                      "height-25 px-3 mt-1 mr-0 caption",
                                    attrs: { label: "", color: link.Color },
                                  },
                                  [_vm._v(" " + _vm._s(link.Chip) + " ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              !_vm.CoreSrv.UserDrawer.Mini
                ? _c("v-divider", { staticClass: "black mx-6" })
                : _vm._e(),
              _vm._l(_vm.AccountLinks, function (menu) {
                return _c(
                  "v-list-group",
                  {
                    key: menu.Title,
                    attrs: { "prepend-icon": menu.Icon, "no-action": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(menu.Title)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: menu.Active,
                      callback: function ($$v) {
                        _vm.$set(menu, "Active", $$v)
                      },
                      expression: "menu.Active",
                    },
                  },
                  _vm._l(menu.Links, function (link) {
                    return _c(
                      "v-list-item",
                      {
                        key: link.Name,
                        staticClass:
                          "d-flex align-end rounded-0 pl-5 border-transparent",
                        class: {
                          "py-1": !_vm.CoreSrv.UserDrawer.Mini,
                        },
                        attrs: {
                          to: { name: link.Name, params: link.data },
                          inactive: link.inactive,
                          "active-class": "admin-primary--text border",
                          exact: "",
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          {
                            class: {
                              "mr-3": !_vm.CoreSrv.UserDrawer.Mini,
                              "mr-1": _vm.CoreSrv.UserDrawer.Mini,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "25" } }, [
                              _vm._v(_vm._s(link.Icon)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass:
                                  "subtitle-2 font-weight-medium ml-0",
                              },
                              [_vm._v(" " + _vm._s(link.Title) + " ")]
                            ),
                          ],
                          1
                        ),
                        link.Chip
                          ? _c(
                              "v-chip-group",
                              { attrs: { mandatory: "", color: "white" } },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass:
                                      "height-25 px-3 mt-1 mr-0 caption",
                                    attrs: { label: "", color: link.Color },
                                  },
                                  [_vm._v(" " + _vm._s(link.Chip) + " ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }