import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject} from '@/globals';
import {SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import SiteSetupButtonBarComponent from '@/views/client/site-setup/site-setup-button-bar/site-setup-button-bar.component';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        // ClientDrawerComponent,
        // LoginSignUpComponent
        SiteSetupButtonBarComponent,
        // AccountOrganizationSelectionComponent,
    },
})
export default class SitesListComponent extends VueWrapper {
    public name: string = 'add-site-dialog';

    public stepper: number = 1;

    public sitesApi: SitesApi = new SitesApi();

    public newSite: SiteModel = new SiteModel();

    public sites: Array<SiteModel> = [];

    public search: string = '';

    public mouseDrag: boolean = false;

    public paymentMethods: Array<string> = ['Credit Card', 'Bank transfer/ACH', 'Paypal'];
    public roles: Array<string> = ['System Admin', 'Account Admin', 'Safety Manager', 'Safety Inspector'];
    public responseAgencyContacts: Array<string> = ['Fire/Explosion', 'Security', 'IT/Communication'];

    private searchSubject = new Subject<string>();

    public loadAllSitesLoading: boolean = false;

    public activeTab: string = 'site';

    public goToAddPlantPage() {
        console.log('go to add plant page clicked');
        this.$router.push({name: 'Site Setup'});
    }

    public mounted() {
        this.loadAllSites();
        this.AddSubscription$ = this.searchSubject.pipe(debounceTime(500)).subscribe(() => {
            this.loadAllSites();
        });
        let thElm: any = null;
        let startOffset: any = 0;
        Array.prototype.forEach.call(document.querySelectorAll('table th'), function (th) {
            th.style.position = 'relative';

            const grip: any = document.createElement('div');
            grip.innerHTML = '&nbsp;';
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.position = 'absolute';
            grip.style.cursor = 'col-resize';
            grip.addEventListener('mousedown', function (e: any) {
                thElm = th;
                startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
        });
        document.addEventListener('mousemove', e => {
            if (thElm) {
                console.log(startOffset + e.pageX + 'px');
                thElm.style.width = startOffset + e.pageX + 'px';
                //disable text selection of th
                this.mouseDrag = true;
            }
        });
        document.addEventListener('mouseup', () => {
            thElm = undefined;
            this.mouseDrag = false;
        });
    }

    public loadAllSites() {
        this.loadAllSitesLoading = true;
        // this.LoaderSrv.showFullScreenLoader('$t.Common.Sites.Loading');

        let filter: AnyObject = {
            // include: [
            //     {
            //         relation: 'organization',
            //     },
            // ],
            order: ['siteName'],
        };
        if (this.search) {
            filter = {
                ...filter,
                where: {
                    or: [
                        {siteName: {like: `%${this.search}%`}},
                        {siteCode: {like: `%${this.search}%`}},
                        {role: {like: `%${this.search}%`}},
                        {accountStatus: {like: `%${this.search}%`}},
                        {userRole: {like: `%${this.search}%`}},
                        {licenseType: {like: `%${this.search}%`}},
                        // {accountStartDate: {like: `%${this.search}%`}},
                        // {accountEndDate: {like: `%${this.search}%`}},
                    ],
                },
            };
        }

        this.sitesApi
            .getAccountSites(this.UserSessionSrv.accountId!, filter)
            .then(res => {
                this.sites = res ?? [];
            })
            .catch(err => {
                //
            })
            .finally(() => {
                this.loadAllSitesLoading = false;
                // this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public debouncedLoadAllSites() {
        this.searchSubject.next(this.search);
    }

    public clearSearch() {
        if (this.search) {
            this.search = '';
            // this.loadAllSites();
        }
    }

    public addNewSiteClick() {
        this.newSite = new SiteModel();
        this.CoreSrv.OpenModal(this.name);
    }

    public editSite(site: SiteModel) {
        this.activeTab = 'site';
        this.newSite = new SiteModel({
            ...site,
            jobOrderStartDate: this.$helpers.formatDate(new Date(site.jobOrderStartDate!).toISOString(), 'yyyy-MM-dd'),
            jobOrderEndDate: this.$helpers.formatDate(new Date(site.jobOrderEndDate!).toISOString(), 'yyyy-MM-dd'),
            // accountStartDate: this.$helpers.formatDate(new Date(site.accountStartDate!).toISOString(), 'yyyy-MM-dd'),
            // accountEndDate: this.$helpers.formatDate(new Date(site.accountEndDate!).toISOString(), 'yyyy-MM-dd'),
            // licenseStartDate: this.$helpers.formatDate(new Date(site.licenseStartDate!).toISOString(), 'yyyy-MM-dd'),
            // licenseEndDate: this.$helpers.formatDate(new Date(site.licenseEndDate!).toISOString(), 'yyyy-MM-dd'),
        });
        this.CoreSrv.OpenModal(this.name);
    }

    public saveNewSite() {
        // alert('asdasd');
        // console.log(this.newSite.accountType);
        // let validForm = true;
        // for (const [key, value] of Object.entries(this.newSite)) {
        //     console.log(`${key}: ${value}`);
        //     if (!['created', 'updated', 'id'].includes(key)) {
        //         if (!value) {
        //             validForm = false;
        //             break;
        //         }
        //     }
        // }
        // console.log('aaaaa', validForm);
        if (this.newSite.id) {
            this.sitesApi
                .UpdateSite(
                    this.newSite.id,
                    new SiteModel({
                        ...this.newSite,
                        jobOrderStartDate: new Date(this.newSite.jobOrderStartDate!),
                        jobOrderEndDate: new Date(this.newSite.jobOrderEndDate!),
                        // accountStartDate: new Date(this.newSite.accountStartDate!),
                        // accountEndDate: new Date(this.newSite.accountEndDate!),
                        // licenseEndDate: new Date(this.newSite.licenseEndDate!),
                        // licenseStartDate: new Date(this.newSite.licenseStartDate!),
                    })
                )
                .subscribe(
                    res => {
                        this.AlertSrv.show('success', '$t.SiteListPage.AddSiteDialog.SaveSuccessMessage');
                        this.newSite = new SiteModel();
                        this.CoreSrv.CloseModal(this.name);
                        this.loadAllSites();
                    },
                    err => {
                        this.AlertSrv.show('error', '$t.SiteListPage.AddSiteDialog.SaveErrMessage');
                    }
                );
        } else {
            this.sitesApi
                .Create(
                    new SiteModel({
                        ...this.newSite,
                        jobOrderStartDate: new Date(this.newSite.jobOrderStartDate!),
                        jobOrderEndDate: new Date(this.newSite.jobOrderEndDate!),
                        accountId: this.UserSessionSrv.accountId!,
                        // accountStartDate: new Date(this.newSite.accountStartDate!),
                        // accountEndDate: new Date(this.newSite.accountEndDate!),
                        // licenseEndDate: new Date(this.newSite.licenseEndDate!),
                        // licenseStartDate: new Date(this.newSite.licenseStartDate!),
                    })
                )
                .subscribe(
                    res => {
                        if (res.Data?.id) {
                            this.AlertSrv.show('success', '$t.SiteListPage.AddSiteDialog.SaveSuccessMessage');
                            this.newSite = new SiteModel();
                            this.CoreSrv.CloseModal(this.name);
                            this.loadAllSites();
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', '$t.SiteListPage.AddSiteDialog.SaveErrMessage');
                    }
                );
        }
    }

    public delteSite(site: SiteModel) {
        this.ConfirmSrv.open('$t.SiteListPage.DeleteSite', '$t.SiteListPage.DeleteSiteMessage').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.sitesApi
                    .deleteSite(site.id!)
                    .subscribe(
                        res => {
                            if (res.Status) {
                                this.AlertSrv.show('success', '$t.SiteListPage.DeleteSiteSuccessMessage');
                                this.loadAllSites();
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', '$t.SiteListPage.DeleteSiteErrMessage');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public clearForm() {
        this.ConfirmSrv.open('$t.Common.CancelDataEntry', '$t.Common.LostUnsavedChanged', {CancelText: '$t.Common.No'}).then(yes => {
            if (yes) {
                //close modal
                this.CoreSrv.CloseModal(this.name);
            }
            // this.sitesApi.deleteSite(id).subscribe(
            //     res => {
            //         if (res.Status) {
            //             this.AlertSrv.show('success', 'Site has been deleted successfully!');
            //             this.loadAllSites();
            //         }
            //     },
            //     err => {
            //         this.AlertSrv.show('error', 'Unable to delete site. Please try again later.');
            //     }
            // );
        });
    }

    // public onAssignOrganization(organization: OrganizationModel) {
    //     this.ConfirmSrv.open('$t.SiteListPage.AssignOrganization', '$t.SiteListPage.AssignOrganizationMessage').then(yes => {
    //         if (yes) {
    //             this.sitesApi.assignOrganization(this.newSite.id!, organization.id).subscribe(
    //                 res => {
    //                     this.AlertSrv.show('success', '$t.SiteListPage.AssignOrganizationSuccessMessage');
    //                     const site = this.sites.find(x => x.id === this.newSite.id);
    //                     if (site) {
    //                         this.newSite = new SiteModel({
    //                             ...site,
    //                             organization: organization,
    //                             jobOrderStartDate: this.newSite.jobOrderStartDate,
    //                             jobOrderEndDate: this.newSite.jobOrderEndDate,
    //                         });
    //                     }
    //                     this.loadAllSites();
    //                 },
    //                 err => {
    //                     this.AlertSrv.show('error', '$t.SiteListPage.AssignOrganizationErrMessage');
    //                 }
    //             );
    //         }
    //     });
    // }
}
