var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        ref: "baseDialogRef",
        attrs: { persistent: "" },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.close.apply(null, arguments)
          },
        },
        model: {
          value: _vm.Show,
          callback: function ($$v) {
            _vm.Show = $$v
          },
          expression: "Show",
        },
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "base-card",
        { staticClass: "py-0 dialog-card" },
        [
          _vm.$slots.header
            ? _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between pt-6 pb-2 new-modal-header",
                },
                [
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "text-h5 font-weight-bold primary--text" },
                      [_vm._t("header")],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    { on: { click: _vm.close } },
                    [
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: true,
                              expression: "true",
                            },
                          ],
                          staticClass: "cursor-pointer",
                          attrs: { color: "white" },
                        },
                        [_vm._v(" mdi-close-circle-outline ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("v-divider", { staticClass: "mb-2" }),
          _c(
            "div",
            {
              staticClass: "pl-2",
              class: {
                "pr-2": !_vm.scrollable,
                "pr-8": _vm.scrollable,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "px-4 py-3",
                  class: {
                    "overflow-auto": _vm.scrollable,
                    "overflow-hidden": !_vm.scrollable,
                  },
                  staticStyle: { "max-height": "calc(100vh - 260px)" },
                },
                [_vm._t("default")],
                2
              ),
            ]
          ),
          _c("v-divider", { staticClass: "mt-2" }),
          _c(
            "div",
            { staticClass: "d-flex justify-end pa-2" },
            [_vm._t("dialogFooter")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }