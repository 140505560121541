var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap" },
    _vm._l(_vm.items, function (item, index) {
      return _c("base-checkbox", {
        key: index,
        staticClass: "mr-3",
        attrs: {
          label: item.Value,
          "input-value": _vm.values.find((value) => value.Id === item.Id),
          disabled: _vm.$attrs.disabled,
          indeterminate: _vm.$attrs.indeterminate,
          "hide-details": !!_vm.$attrs["hide-details"],
        },
        on: {
          change: function ($event) {
            return _vm.updateValues($event, item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }