var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "py-0 px-0 height-100vh main-layout-container",
      staticStyle: { position: "relative" },
      attrs: { fluid: "" },
    },
    [
      _c("client-app-bar-component"),
      _c(
        "v-main",
        {
          staticClass: "height-100x overflow-hidden",
          attrs: { id: "main-content-section" },
        },
        [
          _c("div", { staticClass: "d-flex flex-column height-100x" }, [
            _c(
              "div",
              {
                staticClass: "white flex-grow-1 overflow-hidden pb-2",
                attrs: { id: "temp-content" },
              },
              [_c("job-setup-main-component")],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }