var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "height-100x mb-2" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center basic-box-header px-4" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-shrink-0 mr-5" },
                  [
                    _c("v-img", {
                      staticClass: "area-search header-img-title",
                      attrs: {
                        height: "20",
                        width: "20",
                        contain: "",
                        src: require("@/assets/icons/area-light.png"),
                      },
                    }),
                    _c("h2", { staticClass: "areaTitle" }, [
                      _vm._v(_vm._s("Sites List")),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-grow-1" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("base-text-field", {
                          staticClass: "mr-3 search-site-input",
                          attrs: {
                            "prepend-inner-icon": "mdi-magnify",
                            clearable: "",
                            "single-line": "",
                            "hide-details": "",
                            name: "Search",
                            "background-color": "white",
                            label: _vm.$t("SiteListPage.SearchLabel"),
                            value: _vm.search,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.search = $event
                            },
                            input: _vm.debouncedLoadAllSites,
                            "click:clear": _vm.clearSearch,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "div",
                      { staticClass: "flex-shrink-0 ml-auto" },
                      [
                        _c(
                          "base-btn",
                          {
                            attrs: { color: "white" },
                            on: {
                              click: function ($event) {
                                return _vm.addNewSiteClick()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("SiteListPage.AddNewSiteBtn")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column px-3 pt-3 pb-4 height-100x",
          staticStyle: { "z-index": "1" },
        },
        [
          _vm.loadAllSitesLoading
            ? _c("v-progress-linear", {
                staticClass: "mb-2",
                attrs: { indeterminate: "" },
              })
            : _vm._e(),
          _c("div", { staticClass: "table-parent-scroll flex-grow-1" }, [
            _c("table", { staticClass: "sites-table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("SiteListPage.TableHeader.Number"))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("SiteListPage.TableHeader.SiteName"))),
                  ]),
                  _c("th", [_vm._v("Physical Address")]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("SiteListPage.TableHeader.Actions"))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.sites, function (site, index) {
                  return _c("tr", { key: `${site.id}` }, [
                    _c("td", {}, [_vm._v(_vm._s(index + 1))]),
                    _c("td", {}, [_vm._v(_vm._s(site.siteName))]),
                    _c("td", {}, [_vm._v(_vm._s(site.siteAddress))]),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "white-space": "nowrap",
                          width: "430px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticStyle: {
                                  padding: "14px 8px",
                                  "margin-left": "10px",
                                  "font-size": "15px",
                                  "border-radius": "8px",
                                },
                                attrs: {
                                  color: "#4B86B8",
                                  dark: "",
                                  "x-small": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSite(site)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("SiteListPage.TableActions.Edit")
                                    ) +
                                    " "
                                ),
                                _c("v-img", {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    src: require("../../../assets/icons/edit-light-lg.png"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text mr-1 mb-1",
                                staticStyle: {
                                  padding: "14px 8px",
                                  "margin-left": "10px",
                                  "font-size": "15px",
                                  "border-radius": "8px",
                                },
                                attrs: {
                                  color: "#E3754F",
                                  to: {
                                    name: "site-setup",
                                    params: { siteId: site.id },
                                  },
                                  "x-small": "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("SiteListPage.TableActions.Site")
                                    ) +
                                    " "
                                ),
                                _c("v-img", {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    src: require("../../../assets/icons/site-lg.png"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text mr-1 mb-1",
                                staticStyle: {
                                  padding: "14px 8px",
                                  "margin-left": "10px",
                                  "font-size": "15px",
                                  "border-radius": "8px",
                                },
                                attrs: {
                                  color: "#E3754F",
                                  to: {
                                    name: "Team Main",
                                    params: { siteId: site.id },
                                  },
                                  "x-small": "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("SiteListPage.TableActions.Team")
                                    ) +
                                    " "
                                ),
                                _c("v-img", {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    src: require("../../../assets/icons/user-lg.png"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text mr-1 mb-1",
                                staticStyle: {
                                  padding: "14px 8px",
                                  "margin-left": "10px",
                                  "font-size": "15px",
                                  "border-radius": "8px",
                                },
                                attrs: {
                                  color: "#E3754F",
                                  to: {
                                    name: "Job Main",
                                    params: { siteId: site.id },
                                  },
                                  "x-small": "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("SiteListPage.TableActions.Jobs")
                                    ) +
                                    " "
                                ),
                                _c("v-img", {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    src: require("../../../assets/icons/jobs-lg.png"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticStyle: {
                                  padding: "14px 8px",
                                  "margin-left": "10px",
                                  "font-size": "15px",
                                  "border-radius": "8px",
                                },
                                attrs: {
                                  color: "#7CC05B",
                                  dark: "",
                                  "x-small": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.delteSite(site)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("SiteListPage.TableActions.Delete")
                                    ) +
                                    " "
                                ),
                                _c("v-img", {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    src: require("../../../assets/icons/delete-lg-light.png"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "save-wrapper-component",
        {
          staticStyle: {
            "background-color": "white !important",
            color: "#000 !important",
          },
          attrs: {
            "max-width": "1000px",
            name: _vm.name,
            title: _vm.newSite.id
              ? _vm.$t("SiteListPage.AddSiteDialog.EditTitle")
              : _vm.$t("SiteListPage.AddSiteDialog.AddTitle"),
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("base-btn", { on: { click: _vm.saveNewSite } }, [
                    _vm._v(
                      _vm._s(_vm.$t("SiteListPage.AddSiteDialog.SaveBtn"))
                    ),
                  ]),
                  _c(
                    "base-btn",
                    {
                      staticClass: "mx-1 white--text",
                      attrs: { color: "grey lighten-1", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.clearForm()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("SiteListPage.AddSiteDialog.CancelBtn")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticStyle: { "min-height": "300px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-shrink-0 overflow-auto min-width-250",
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "my-tabs height-100x",
                              attrs: { vertical: "" },
                              model: {
                                value: _vm.activeTab,
                                callback: function ($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  attrs: {
                                    "tab-value": "site",
                                    "active-class": "side-tab-active",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "SiteListPage.AddSiteDialog.SiteTab"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-tab",
                                {
                                  attrs: {
                                    "tab-value": "emergency",
                                    "active-class": "side-tab-active",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "SiteListPage.AddSiteDialog.EmergencyContactTab"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "height-100x d-flex flex-column flex-grow-1 px-3 overflow-hidden",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "overflow-auto" },
                            [
                              _c(
                                "v-tabs-items",
                                {
                                  staticClass: "flex-grow-1",
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    { attrs: { value: "site" } },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          disabled: true,
                                                          value: _vm.newSite.id,
                                                          name: "siteCode",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.SiteID"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "id",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .siteName,
                                                          name: "siteName",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.SiteName"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "siteName",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .siteAddress,
                                                          name: "siteAddress",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.SitePhysicalAddress"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "siteAddress",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { attrs: { value: "emergency" } },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .emergencyContactName,
                                                          name: "organizationAddress",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.FoexEmergencyContactName"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "emergencyContactName",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .emergencyContactEmail,
                                                          name: "organizationWebsite",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.FoexEmergencyContactEmail"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "emergencyContactEmail",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .emergencyContactTelephone,
                                                          name: "contractualContactName",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.FoexEmergencyContactPhoneOffice"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "emergencyContactTelephone",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .emergencyContactMobile,
                                                          name: "contractualContactEmail",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.FoexEmergencyContactPhoneMobile"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "emergencyContactMobile",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .orgEmergencyContactName,
                                                          name: "organizationAddress",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.OrgEmergencyContactName"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "orgEmergencyContactName",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .orgEmergencyContactEmail,
                                                          name: "organizationWebsite",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.OrgEmergencyContactEmail"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "orgEmergencyContactEmail",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .orgEmergencyContactTelephone,
                                                          name: "contractualContactName",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.OrgEmergencyContactPhoneOffice"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "orgEmergencyContactTelephone",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.newSite
                                                              .orgEmergencyContactMobile,
                                                          name: "contractualContactEmail",
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.OrgEmergencyContactPhoneMobile"
                                                          ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "orgEmergencyContactMobile",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "d-flex",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("base-combobox", {
                                                        staticClass:
                                                          "flex-grow-1",
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "SiteListPage.AddSiteDialog.LocalEmergencyResponseAgencyContacts"
                                                          ),
                                                          name: "role",
                                                          items:
                                                            _vm.responseAgencyContacts,
                                                          "item-text": "value",
                                                          "item-value": "key",
                                                          clearable: "",
                                                          multiple: false,
                                                          "return-object": false,
                                                          value:
                                                            _vm.newSite
                                                              .localEmergencyResponse,
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.newSite,
                                                                "localEmergencyResponse",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }