import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {EquipmentSections, KeyValuePair} from '@/globals';
import {AdminDataFieldService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';
import {Container, Draggable, DropResult} from 'vue-smooth-dnd';

@Component({
    components: {
        Container,
        Draggable,
    },
})
export default class EquipmentFieldsDbComponent extends VueWrapper {
    public equipmentSections: Array<KeyValuePair> = [
        {
            key: EquipmentSections.ParentAreaInformation,
            value: this.$t('FieldsDbPage.EquipmentTab.Sections.ParentAreaInformation').toString(),
            active: false,
        },
        {
            key: EquipmentSections.GeneralInformation,
            value: this.$t('FieldsDbPage.EquipmentTab.Sections.GeneralInformation').toString(),
            active: true,
        },
        {
            key: EquipmentSections.EquipmentClassification,
            value: this.$t('FieldsDbPage.EquipmentTab.Sections.EquipmentClassification').toString(),
            active: false,
        },
        {
            key: EquipmentSections.WiringCableEntry,
            value: this.$t('FieldsDbPage.EquipmentTab.Sections.WiringCableEntry').toString(),
            active: false,
        },
    ];

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public get getFieldOptions() {
        return this.adminDataFieldsSrv.selectedField ? this.adminDataFieldsSrv.selectedFieldOptions[this.adminDataFieldsSrv.selectedField.id!] : [];
    }

    public get getDependentOptions() {
        return this.adminDataFieldsSrv.selectedFieldOptions[this.adminDataFieldsSrv.selectedField!.id!].filter(
            x => x.dependentOnFieldOptionId === this.adminDataFieldsSrv.dependentFieldOption
        );
    }

    public getEquipmentFields(key: string) {
        return this.adminDataFieldsSrv.equipmentFields.filter(x => x.section === key && ['select', 'multi-select'].includes(x.type!));
    }

    public getChildPayload(index: number) {
        return this.getFieldOptions[index];
    }

    public getChildPayloadDependent(index: number) {
        return this.getDependentOptions[index];
    }

    sortingLoader: boolean = false;

    onDrop(payload: DropResult) {
        this.sortingLoader = true;
        this.adminDataFieldsSrv.sortOnDrop(payload).finally(() => {
            this.sortingLoader = false;
        });
    }

    onDropDependent(payload: DropResult) {
        this.sortingLoader = true;
        this.adminDataFieldsSrv.sortOnDrop(payload).finally(() => {
            this.sortingLoader = false;
        });
    }
}
