var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "base-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", { staticClass: "d-flex justify-space-between" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("AccountSetupPage.Heading")))]),
            _c(
              "div",
              [
                _c("base-btn", { on: { click: _vm.createAccountClick } }, [
                  _vm._v(_vm._s(_vm.$t("AccountSetupPage.CreateAccount"))),
                ]),
                _c("base-btn", { on: { click: _vm.inviteClick } }, [
                  _vm._v(_vm._s("Invite Client Admin")),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("base-card", { attrs: { outlined: "" } }, [
        _c("table", { staticClass: "sites-table" }, [
          _c("thead", [
            _c("tr", [
              _c("th"),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("AccountSetupPage.TableHeaders.No"))),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("AccountSetupPage.TableHeaders.AccountID"))
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("AccountSetupPage.TableHeaders.AccountTitle"))
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("AccountSetupPage.TableHeaders.AccountType"))
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("AccountSetupPage.TableHeaders.AccountStatus"))
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("AccountSetupPage.TableHeaders.StartDate"))
                ),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("AccountSetupPage.TableHeaders.EndDate"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("AccountSetupPage.TableHeaders.Actions"))),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.accountSetupSrv.accounts, function (account, ind) {
                return [
                  _c(
                    "tr",
                    {
                      key: `${account.id}`,
                      class: {
                        "grey lighten-2":
                          _vm.selectedAccount &&
                          _vm.selectedAccount.id === account.id,
                      },
                    },
                    [
                      _c(
                        "td",
                        { staticClass: "width-30px" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.accountId === account.id
                                                      ? "success"
                                                      : "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markSelectedClick(
                                                      account
                                                    )
                                                  },
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-check-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "AccountSetupPage.Tooltip.MarkSelected"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "width-30px" }, [
                        _c("div", [_vm._v(_vm._s(ind + 1))]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-200px" }, [
                          _vm._v(_vm._s(account.id)),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-100px" }, [
                          _vm._v(_vm._s(account.accountTitle) + " "),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-100px" }, [
                          _vm._v(_vm._s(account.accountType) + " "),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-100px" }, [
                          _vm._v(_vm._s(account.accountStatus) + " "),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-100px" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                account.accountStartDate,
                                "yyyy-MM-dd"
                              )
                            ) + " "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "width-100px" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                account.accountEndDate,
                                "yyyy-MM-dd"
                              )
                            ) + " "
                          ),
                        ]),
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "width-200px text-center",
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "base-btn",
                                {
                                  staticClass: "white--text mr-1 mb-1",
                                  attrs: { color: "blue", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editClick(account)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Common.Edit")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "base-btn",
                                {
                                  staticClass: "white--text mr-1 mb-1",
                                  attrs: {
                                    color: "cyan",
                                    small: "",
                                    to: {
                                      name: "account-users",
                                      params: { accountId: account.id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Common.Users")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "base-btn",
                                {
                                  staticClass: "white--text mr-1 mb-1",
                                  attrs: { color: "error", small: "" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Common.Delete")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("SaveAccountDialogComponent", { ref: "saveAccountDialogRef" }),
      _c("InviteClientAdminDialogComponent", {
        ref: "inviteClientAdminDialogRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }