var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "450px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.Title")) +
                        " "
                    ),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [_c("div")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.TableHeaders.Name"))
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewGasDbRecord.TableHeaders.Value")
                  )
                ),
              ]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.CasNo"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.casNo))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.Name"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.name))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.OtherName"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.otherName))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.Formula"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.formula))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.Formula"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.formula1))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MolarMass"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.molarMass))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.VaporPressure"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.vaporPressure))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.LiquidDensity"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.liquidDensity))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.RelativeDensity"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.relativeDensity))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MeltingPoint"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.meltingPoint))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.BoilingPoint"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.boilingPoint))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.FlashPoint"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.flashPoint))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.LFLVol"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.LFLVol))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.UFLVol"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.UFLVol))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.LFLGm3"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.LFL))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.UFLGm3"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.UFL))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.AIT"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.AIT))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MostIncMixture"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.mostIncMixture))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MESG"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.MESG))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.g100g0"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.g100g0))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MICRatio"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.MICRatio))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.TClass"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.tClass))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.EquipmentGroup"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.equipmentGroup))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewGasDbRecord.MethodOfClass"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.methodOfClass))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }