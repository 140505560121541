var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-observer", {
    ref: "baseForm",
    attrs: { slim: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ invalid, reset, validate, errors }) {
            return [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      invalid ? undefined : _vm.$emit("submit")
                    },
                  },
                },
                [
                  _vm._t("default", null, {
                    invalid: invalid,
                    reset: reset,
                    validate: validate,
                    errors: errors,
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }