var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    _vm._g(
      _vm._b(
        {
          attrs: { "offset-y": "", transition: "slide-x-transition" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "base-btn",
                    _vm._g({ attrs: { icon: !!_vm.$attrs.icon } }, on),
                    [
                      !_vm.$attrs.icon
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.$attrs["btn-text"]) + " "),
                          ])
                        : _c("v-icon", [_vm._v(_vm._s(_vm.$attrs.icon))]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        "v-menu",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }