var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "1280px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._v(" Preview Document "), _vm._t("header")]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [_c("div")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.document
        ? _c("div", [
            _vm.document.fileType.toLowerCase() === "png" ||
            _vm.document.fileType.toLowerCase() === "jpg" ||
            _vm.document.fileType.toLowerCase() === "jpeg"
              ? _c(
                  "div",
                  [
                    _c("v-img", {
                      attrs: {
                        src: `${_vm.ApiUrl}/documentations/${_vm.document.id}/get-file/${_vm.document.title}`,
                      },
                    }),
                  ],
                  1
                )
              : _vm.document.fileType.toLowerCase() === "pdf"
              ? _c("div", [
                  _c("iframe", {
                    staticClass: "pdf-iframe",
                    attrs: {
                      width: "100%",
                      src: `${_vm.ApiUrl}/documentations/${_vm.document.id}/get-file/${_vm.document.title}`,
                    },
                  }),
                ])
              : _c("div", [
                  _c("h4", [
                    _vm._v("No Preview available for this file type."),
                  ]),
                ]),
          ])
        : _vm._e(),
      _vm.uploadedFile && _vm.uploadedFile.mimeType
        ? _c("div", [
            _vm.uploadedFile.mimeType.includes("image/")
              ? _c(
                  "div",
                  [
                    _c("v-img", {
                      attrs: {
                        src: `${_vm.ApiUrl}/uploaded-file/${_vm.uploadedFile.principalId}/get-file/${_vm.uploadedFile.fileName}`,
                      },
                    }),
                  ],
                  1
                )
              : _vm.uploadedFile.mimeType.toLowerCase() === "application/pdf"
              ? _c("div", [
                  _c("iframe", {
                    staticClass: "pdf-iframe",
                    attrs: {
                      width: "100%",
                      src: `${_vm.ApiUrl}/uploaded-file/${_vm.uploadedFile.principalId}/get-file/${_vm.uploadedFile.fileName}`,
                    },
                  }),
                ])
              : _c("div", [
                  _c("h4", [
                    _vm._v("No Preview available for this file type."),
                  ]),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }