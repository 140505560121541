var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      attrs: { "max-width": "500px", name: _vm.name, title: "Add New Folder" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ invalid }) {
            return [
              _c(
                "base-btn",
                { attrs: { disabled: invalid }, on: { click: _vm.saveFolder } },
                [_vm._v("Save")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("base-text-field", {
                    attrs: {
                      rules: "required",
                      value: _vm.locationDocumentationSrv.folder.title,
                      name: "title",
                      label: "Folder Name",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.locationDocumentationSrv.folder,
                          "title",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }