var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("base-select", {
        staticClass: "mr-2 width-140px flex-shrink-0",
        attrs: {
          items: _vm.filteredCountries,
          "item-text": "name",
          "item-value": "dialCode",
          label: "Country Code",
          outlined: "",
          dense: "",
          "hide-details": "",
          value: _vm.countryCode,
        },
        on: {
          change: _vm.onCountryChange,
          "update:value": function ($event) {
            _vm.countryCode = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-item",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "search-container" },
                  [
                    _c("base-text-field", {
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (data) {
              return [
                _c("div", { staticClass: "d-flex overflow-hidden" }, [
                  _c("img", {
                    staticClass: "flag-icon",
                    attrs: { src: data.item.icon, alt: "" },
                  }),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(data.item.dialCode) +
                        ") " +
                        _vm._s(data.item.name)
                    ),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "selection",
            fn: function (data) {
              return [
                _c("span", { staticClass: "d-flex overflow-hidden" }, [
                  _c("img", {
                    staticClass: "flag-icon",
                    attrs: { src: data.item.icon, alt: "" },
                  }),
                  _c("span", [_vm._v(_vm._s(data.item.dialCode))]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("base-text-field", {
        staticClass: "flex-grow-1",
        attrs: {
          name: "num",
          label: _vm.$attrs.label ?? "Phone Number",
          disabled: !_vm.countryCode,
          mask: _vm.selectedMask,
        },
        on: { input: _vm.emitPhoneNumber },
        model: {
          value: _vm.numberTyped,
          callback: function ($$v) {
            _vm.numberTyped = $$v
          },
          expression: "numberTyped",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }