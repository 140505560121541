import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair} from '@/globals';
import {DocumentationModel, LocationModel, SiteModel, SwitchBoardModel} from '@/sdk';
import {DocumentationApi, LocationApi, SitesApi} from '@/sdk/api-services';
import {WiringCableModel} from '@/sdk/models/location/wiring-cables.model';
import TagManagementComponent from '@/views/client/site-setup/site-setup-location-form/tag-management/tag-management.component';
import PreviewDocumentDialogComponent from '@/views/shared/preview-document-dialog/preview-document-dialog.component';
import {Component, Prop} from 'vue-property-decorator';
import AreaRulesSummaryDialogComponent from '../area-rules-summary-dialog/area-rules-summary-dialog.component';
import DocumentationListItemComponent from '../documentation-list-item/documentation-list-item.component';
import InspectionHistoryComponent from '../inspection-history/inspection-history.component';
import {AdminDataFieldService, SiteLocationService} from '../services';
import WiringCableEntry from '../wiring-cable/wiring-cable-entry-form.component';

@Component({
    name: 'EquipmentDetailsFormComponent',
    components: {
        DocumentationListItemComponent,
        WiringCableEntry,
        InspectionHistoryComponent,
        PreviewDocumentDialogComponent,
        AreaRulesSummaryDialogComponent,
        TagManagementComponent,
    },
})
export default class EquipmentDetailsFormComponent extends VueWrapper {
    @Prop({
        required: true,
    })
    locationPath!: Array<any>;

    @Prop({
        required: false,
        default: false,
    })
    readOnlyFlag!: Boolean | false;

    @Prop({
        required: false,
    })
    diagramViewStyle!: boolean;

    @Prop({
        required: false,
    })
    diagramFullPageConfig!: boolean;

    public $refs!: {
        previewDialog: PreviewDocumentDialogComponent;
        areaRulesSummaryDialogRef: AreaRulesSummaryDialogComponent;
    };
    public classification: string | null = null;
    public equipmentTypeSelected: string | null = null;

    public gasDustGroup: string | null = null;

    public inspectionTab: number | null = null;

    public showAddNew: Boolean | false = false;

    // public isSelectedIntrinsic: boolean | false = false;
    // public isSelectedMotor: boolean | false = false;
    // public isSelectedExPInf: boolean | false = false;
    // public isSelectedExECurrentTrans: boolean | false = false;
    // public isSelectedExEGenJunct: boolean | false = false;

    public tabObject = {
        isSelectedIntrinsic: false,
        isSelectedMotor: false,
        isSelectedExPInf: false,
        isSelectedExECurrentTrans: false,
        isSelectedExEGenJunct: false,
    };

    public wiringCableArray: Array<any> = [];

    public wiringCAbleTabs: any | null = null;

    public newWiringCableObj: WiringCableModel = new WiringCableModel();

    public newWiringCableObj1: WiringCableModel = new WiringCableModel();

    public newSwitchBoardObj: WiringCableModel = new SwitchBoardModel();

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public siteLocations!: Array<LocationModel>;

    public eplDYIList: Array<KeyValuePair> = [
        {
            key: 'Mine',
            value: 'Mine',
        },
        {
            key: 'Gas',
            value: 'Gas',
        },
        {
            key: 'Dust',
            value: 'Dust',
        },
    ];

    public selectYesNo: Array<KeyValuePair> = [
        {
            key: 'yes',
            value: 'Yes',
        },
        {
            key: 'no',
            value: 'No',
        },
    ];

    public selectKhzMhz: Array<KeyValuePair> = [
        {
            key: 'khz',
            value: 'KHz',
        },
        {
            key: 'mhz',
            value: 'MHz',
        },
    ];

    public selectConnectDirection: Array<KeyValuePair> = [
        {
            key: 'IN',
            value: 'IN',
        },
        {
            key: 'OUT',
            value: 'OUT',
        },
        {
            key: 'N/A',
            value: 'N/A',
        },
    ];

    public selectTorqueType: Array<KeyValuePair> = [
        {
            key: 'variableTorque',
            value: 'Variable Torque',
        },
        {
            key: 'constantTorque',
            value: 'Constant Torque',
        },
        {
            key: 'constantPower',
            value: 'Constant Power',
        },
        {
            key: 'operationalTorqueLimit',
            value: 'Operational Torque Limit',
        },
    ];

    public fieldEquipAppar: Array<KeyValuePair> = [
        {
            key: 'fieldEquipment',
            value: 'Field Equipment',
        },
        {
            key: 'AssociatedApparatus',
            value: 'Associated apparatus',
        },
        {
            key: 'SimpleApparatus',
            value: 'Simple Apparatus',
        },
    ];

    public divisionRiskLevelArr: Array<KeyValuePair> = [
        {
            key: 'IntrinsicSafety',
            value: 'Intrinsic safety',
        },
        {
            key: 'Instruments',
            value: 'Instruments',
        },
        {
            key: 'LVPowerequipment',
            value: 'LV Power equipment',
        },
        {
            key: 'HVPowerequipment',
            value: 'HV Power equipment',
        },
    ];

    public powerTypeArr: Array<KeyValuePair> = [
        {
            key: 'FixedPower',
            value: 'Fixed Power',
        },
        {
            key: 'MultiPower',
            value: 'Multi Power',
        },
        {
            key: 'VariablePower',
            value: 'Variable Power',
        },
    ];

    public powerAcDc: Array<KeyValuePair> = [
        {
            key: 'AC',
            value: 'AC',
        },
        {
            key: 'DC',
            value: 'DC',
        },
    ];

    public rowRadioSingleToComnine: any | null = null;
    public selectedMiscCertification: string | null = null;

    // public changeOperatorFlag: boolean | false = false;

    // public onChangeOperatedConvertor() {
    //     this.changeOperatorFlag = this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.mOperatedConvertor === 'yes';
    //     console.log('this.changeOperatorFlag', this.changeOperatorFlag);
    // }

    public validateNumber(evt: any) {
        evt = evt ? evt : window.event;
        const expect = evt.target.value.toString() + evt.key.toString();

        if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    public inspFreq: Array<String> = ['3 years', '2 years', '1 year', '6 months', '3 months'];
    showSearchResults: boolean | false = false;

    public allEquipmentDistributionLocations: Array<any> = [];
    public allEquipmentCables: Array<WiringCableModel> = [];
    public allDistributionSwitchBoards: any[] = [];

    // public get equipmentTypes(): Array<any> {
    //     if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.equipmentClassification) {
    //         return this.equipmentClassificationDYDList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.equipmentClassification as any] as Array<any>;
    //     }
    //     return [];
    // }

    public get getEquipmentsList(): Array<any> {
        const siteLocations = this.siteLocationsSrv.siteLocations;
        if (siteLocations) {
            // console.log('this.siteLocationsSrv.selectedLcoation', siteLocations);
            this.allEquipmentDistributionLocations = Object.assign(
                [],
                siteLocations.filter(site => site.type === 'equipment' || site.type === 'distribution')
            );
            // console.log('allEquipmentDistributionLocations', this.allEquipmentDistributionLocations);

            return this.allEquipmentDistributionLocations;
        }
        return [];
    }

    // public makeArrayProtection() {
    //     const arr = this.siteLocationsSrv.selectedLcoation?.equipmentDetail!.protectionTechnique || [];
    //     let arr1: string | any[] = [];
    //     for (let i = 0; i < arr.length; i++) {
    //         const element = arr[i];
    //         this.protectionTechDYDList[element];
    //         arr1 = arr1.concat(this.protectionTechDYDList[element]);
    //     }
    //     // console.log('arr1', arr1);
    //     return arr1;
    // }

    public finalList: Array<any> = [];
    public finalListNew: Array<any> = [];
    public loadingConnectLists: Boolean | false = false;

    // public getEntriesFinal() {
    //     console.log('this.newWiringCableObj', this.newWiringCableObj);
    //     if (!this.newWiringCableObj.connectWith) {
    //         this.finalList = [];
    //     }
    // }
    // public get getEntriesFinal(): Array<any> {
    //     console.log('this.loadingConnectLists', this.loadingConnectLists);
    //     if (this.finalList[this.tab]) {
    //         // console.log('this.finalList[this.tab]', this.finalList[this.tab]);
    //         return this.finalList[this.tab] as Array<any>;
    //     }
    //     return [];
    // }

    public onRadioClick(v: any) {
        console.log(this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.rowRadioSingleToComnine, v);
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.rowRadioSingleToComnine === v) {
            this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.rowRadioSingleToComnine = null;
        } else {
            this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.rowRadioSingleToComnine = v;
        }
        // this.rowRadioSingleToComnine = event;
    }

    public makeConnectWithFields(entry: any, index: any = 0) {
        // this.LoaderSrv.showFullScreenLoader();
        this.loadingConnectLists = true;
        // this.finalList[index] = [];
        const tempList: Array<any> = [];
        // console.log('entry', entry);
        // console.log('index', index);
        // console.log('finalList', this.finalList);
        if (entry && entry.connectWith) {
            new LocationApi()
                .getLocationById(entry.connectWith, {
                    include: [
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log('res.Data', res.Data);
                            if (res.Data.type === 'equipment') {
                                this.allEquipmentCables = res.Data.wiringCables! || [];
                                for (let j = 0; j < this.allEquipmentCables.length; j++) {
                                    const element2 = this.allEquipmentCables[j];
                                    tempList.push({id: element2.id, title: `Wire ${j + 1} (${element2.id})`});
                                    // this.finalList.push({id: element2.id, title: `Cable Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
                                    // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
                                }
                            } else if (res.Data.type === 'distribution') {
                                this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
                                for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
                                    const element3 = this.allDistributionSwitchBoards[j];
                                    tempList.push({
                                        id: element3.id,
                                        title: `Output ${j + 1} (${element3.id})`,
                                    });
                                    // console.log(`Switch Board ${j + 1} -> ${element3.outpulPhase} - (${element3.id})`);
                                }
                            }
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'ID not found or deleted for Connecting Select Equip / DP .');
                        console.error('warning', 'ID not found.');
                    }
                )
                .add(() => {
                    // this.LoaderSrv.hideFullScreenLoader();
                    // console.log('this.finalList[index]', this.finalList[index]);
                    // console.log('this.finalList', this.finalList);
                    // console.log('index', index);
                    // console.log('tempList', tempList);
                    this.finalList[index] = tempList;
                    this.loadingConnectLists = false;
                });
        }
    }

    public makeConnectWithFieldsNew(entry: any) {
        // this.LoaderSrv.showFullScreenLoader();
        this.loadingConnectLists = true;
        this.finalListNew = [];
        if (entry) {
            new LocationApi()
                .getLocationById(entry, {
                    include: [
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log('res.Data', res.Data);
                            if (res.Data.type === 'equipment') {
                                this.allEquipmentCables = res.Data.wiringCables! || [];
                                for (let j = 0; j < this.allEquipmentCables.length; j++) {
                                    const element2 = this.allEquipmentCables[j];
                                    this.finalListNew.push({id: element2.id, title: `Wire ${j + 1} (${element2.id})`});
                                    // this.finalList.push({id: element2.id, title: `Cable Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
                                    // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
                                }
                            } else if (res.Data.type === 'distribution') {
                                this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
                                for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
                                    const element3 = this.allDistributionSwitchBoards[j];
                                    this.finalListNew.push({
                                        id: element3.id,
                                        title: `Output ${j + 1} (${element3.id})`,
                                    });
                                    // console.log(`Switch Board ${j + 1} -> ${element3.outpulPhase} - (${element3.id})`);
                                }
                            }
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'ID not found or deleted for Connecting Select Equip / DP .');
                        console.error('warning', 'ID not found.');
                    }
                )
                .add(() => {
                    // this.LoaderSrv.hideFullScreenLoader();
                    this.loadingConnectLists = false;
                });
        }
    }

    // Use for Multi select Connect Fields for above single select
    // public makeConnectWithFields(entries: any) {
    //     // console.log('siteLocationsSrv', this.siteLocationsSrv);
    //     for (let i = 0; i < entries.length; i++) {
    //         const element = entries[i];
    //         this.LoaderSrv.showFullScreenLoader();
    //         new LocationApi()
    //             .getLocationById(element, {
    //                 include: [
    //                     {
    //                         relation: 'switchBoards',
    //                     },
    //                     {
    //                         relation: 'wiringCables',
    //                     },
    //                 ],
    //             })
    //             .subscribe(
    //                 res => {
    //                     if (res.Data) {
    //                         // console.log('res.Data', res.Data);
    //                         if (res.Data.type === 'equipment') {
    //                             this.allEquipmentCables = res.Data.wiringCables! || [];
    //                             for (let j = 0; j < this.allEquipmentCables.length; j++) {
    //                                 const element2 = this.allEquipmentCables[j];
    //                                 this.finalList.push({id: element2.id, title: `Entry ${j + 1} -> (${element2.id})`});
    //                                 // this.finalList.push({id: element2.id, title: `Cable Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
    //                                 // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
    //                             }
    //                         } else if (res.Data.type === 'distribution') {
    //                             this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
    //                             for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
    //                                 const element3 = this.allDistributionSwitchBoards[j];
    //                                 this.finalList.push({
    //                                     id: element3.id,
    //                                     title: `Output ${j + 1} -> ( ${element3.id} )`,
    //                                 });
    //                                 // console.log(`Switch Board ${j + 1} -> ${element3.outpulPhase} - (${element3.id})`);
    //                             }
    //                         }
    //                     }
    //                 },
    //                 err => {
    //                     this.AlertSrv.show('warning', 'ID not found.');
    //                     console.error('warning', 'ID not found.');
    //                 }
    //             )
    //             .add(() => {
    //                 this.LoaderSrv.hideFullScreenLoader();
    //             });
    //     }
    // }

    // public get allLocationEquipments(): Array<any> {
    //     if (this.siteLocationsSrv.siteLocations) {
    //         console.log('this.siteLocationsSrv.selectedLcoation', this.siteLocationsSrv.siteLocations);
    //         // return this.siteLocationsSrv.selectedLcoation.equipmentDetail || [];
    //     }
    //     return [];
    // }

    // Change to protectionTechniquesSubDYD
    public get protectionTechniquesDYD(): Array<any> {
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.protectionTechnique?.length) {
            return this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.protectionTechnique.flatMap(pt => {
                const tmp = (this.$formOptions.protectionTechniquesDYDList[pt] as Array<KeyValuePair>) ?? [];
                return tmp.map(x => `${pt} - ${x.value}`);
            });
        }
        return [];
    }

    // public get eplSubDYD(): Array<any> {
    //     if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.eplGasAndDust) {
    //         // console.log(this.eplDYDList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.eplGasAndDust as any] as Array<any>);
    //         return this.eplDYDList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.eplGasAndDust as any] as Array<any>;
    //     }
    //     return [];
    // }

    documents: Array<DocumentationModel> = [];

    public addDocument: boolean = false;
    public addDocument1: boolean = false;
    public documentTypes: Array<string> = ['Certification', 'Drawing', 'Instruction manual', 'Catalog', 'Document - etc', 'Document - Photo'];
    public selectedType: string | null = null;

    public file: File | null = null;

    public documentationApi: DocumentationApi = new DocumentationApi();

    public accept: string =
        `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/png,image/jpeg,application/pdf,` +
        `application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`;

    public newSeq: number = 0;

    public async saveNewCable() {
        if (!this.newWiringCableObj1.id) {
            // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj1);
            // console.log('this.newWiringCableObj.connectWith', this.newSeq);
            this.newWiringCableObj1.locationId = this.siteLocationsSrv.selectedLcoation?.id;
            let seqFlag = true;
            if (!this.newSeq) {
                // console.log('this.newWiringCableObj.sequence', this.newWiringCableObj1.sequence);
                this.newWiringCableObj1.sequence = this.siteLocationsSrv.selectedLcoation!.wiringCables!.length + 1;
                seqFlag = false;
            } else {
                this.newWiringCableObj1.sequence = this.newSeq;
                // console.log('this.newWiringCableObj.sequence2', this.newWiringCableObj1.sequence);
            }
            this.finalList[this.newWiringCableObj1.sequence - 1] = this.finalListNew;
            this.LoaderSrv.showFullScreenLoader();
            await this.updateSyncLocation(this.newWiringCableObj1);
            await this.syncToDiagramCanvas(this.newWiringCableObj1);
            new LocationApi()
                .CreateLocationWiringCable(this.newWiringCableObj1)
                .subscribe(
                    res => {
                        this.tab = 1;
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', '$t.Common.WiringEntry.SaveWiringEntrySuccess');
                        // this.showNewBoard = false;
                        // this.$forceUpdate();
                        if (seqFlag) {
                            this.siteLocationsSrv.selectedLcoation?.wiringCables?.splice((this.newWiringCableObj1?.sequence ?? 1) - 1, 0, res.Data!);
                            // console.log('res.Data22222', this.siteLocationsSrv.selectedLcoation?.wiringCables);
                            this.saveAllWires();
                            // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                        } else {
                            this.siteLocationsSrv.selectedLcoation?.wiringCables?.push(res.Data!);
                            // this.saveAllWires();
                        }
                        this.setActive(res.Data?.id);
                        this.newWiringCableObj1 = new WiringCableModel();
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', '$t.Common.WiringEntry.SaveWiringEntryError');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                    // this.$forceUpdate();
                });
        }
    }

    public async syncToDiagramCanvas(board: any) {
        console.log('syncToDiagramCanvas => board =>', board);
        this.siteLocationsSrv.getSiteLocations();
        const siteLocations = this.siteLocationsSrv.siteLocations;
        const site = this.siteLocationsSrv.site;
        if (board.connectWith) {
            const connectedLocation = siteLocations.find(location => location.id === board.connectWith);
            console.log('syncToDiagramCanvas => connectedLocation =>', connectedLocation);
            const defaultSavedModelContent = {
                class: 'GraphLinksModel',
                linkFromPortIdProperty: 'fromPort',
                linkToPortIdProperty: 'toPort',
                nodeDataArray: [
                    {
                        key: -1,
                        category: 'Conditional',
                        loc: '700 150',
                        text: 'Start',
                        equipment: 'equip.png',
                        dp: 'dp.png',
                        earthWire: 'earthWire.png',
                        arrow: 'arrow.png',
                        hand: 'hand.png',
                        undo: 'undo.png',
                        redo: 'redo.png',
                        zoomIn: 'zoomIn.png',
                        zoomOut: 'zoomOut.png',
                    },
                    {
                        key: -99,
                        category: 'ConditionalPower',
                        text: 'Power Source',
                        loc: '100 150',
                        icon: 'power.png',
                        color: 'moccasin',
                        move: true,
                    },
                ],
                linkDataArray: [],
            };
            const dataToCanvasJsonString = site && site.drawingCanvas1 ? site!.drawingCanvas1! : JSON.stringify(defaultSavedModelContent);
            const siteCanvasJson = JSON.parse(dataToCanvasJsonString);

            // const checkIfAlreadyExist = siteCanvasJson.linkDataArray.find((link:any) => (link.from === board.locationId && link.to === ))
            if (
                (connectedLocation && !connectedLocation?.wiringCables) ||
                (connectedLocation && connectedLocation?.wiringCables && connectedLocation?.wiringCables.length === 0)
            ) {
                const linkFound = siteCanvasJson.linkDataArray.find((link: any) => link.from === board.locationId && link.to === connectedLocation.id);
                if (!linkFound) {
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);
                    const newConnectionLink = {
                        from: board.locationId,
                        to: connectedLocation.id,
                        fromPort: 'R',
                        toPort: 'L',
                    };
                    siteCanvasJson.linkDataArray.push(newConnectionLink);
                    console.log('syncToDiagramCanvas => newConnectionLink =>', newConnectionLink);
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);

                    new SitesApi().UpdateSite(site!.id!, new SiteModel({...site, drawingCanvas1: JSON.stringify(siteCanvasJson)})).subscribe(
                        res => {
                            console.log('Site has been saved successfully!', res);
                            console.log('Site has been saved successfully!', res.Data);
                            this.siteLocationsSrv.site!.drawingCanvas11 = JSON.stringify(siteCanvasJson);
                            // this.AlertSrv.show('success', 'Site has been saved successfully!');
                        },
                        err => {
                            // this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                        }
                    );
                    // window.location.reload();
                }
            }
        }
    }

    public async saveAllWires() {
        console.log('this.siteLocationsSrv.selectedLcoation?.wiringCables', this.siteLocationsSrv.selectedLcoation);
        this.siteLocationsSrv.selectedLcoation?.wiringCables?.forEach((w, i) => {
            w.sequence = i + 1;
        });

        await new LocationApi().updateAllWiringCables(this.siteLocationsSrv.selectedLcoation?.wiringCables).subscribe(res => {
            console.log('updateAllWiringCables', res.Data);
        });
        this.siteLocationsSrv.selectedLcoation!.wiringCables?.sort((a, b) => a.sequence! - b.sequence!);
    }

    public getLocationToUpdateSync(entryID: any) {
        const siteLocations = this.siteLocationsSrv.siteLocations;
        if (siteLocations) {
            // console.log('this.siteLocationsSrv.selectedLcoation', siteLocations);
            // console.log('entryID', entryID);
            // console.log(
            //     'siteLocations.find',
            //     siteLocations.find(site => site.type === 'equipment' && site.id === entryID)
            // );
            return siteLocations.find(site => site.id === entryID);
        }
        return null;
    }

    public async updateSyncLocation(cable: any) {
        if (cable.connectWith) {
            const connectedSite = this.getLocationToUpdateSync(cable.connectWith);
            console.log('connectedSite', connectedSite);
            if (connectedSite && connectedSite.id) {
                await new LocationApi()
                    .getLocationById(connectedSite.id, {
                        include: [
                            {
                                relation: 'wiringCables',
                                order: ['sequence'],
                            },
                            {
                                relation: 'switchBoards',
                            },
                        ],
                    })
                    .subscribe(
                        res => {
                            if (res.Data) {
                                // this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);
                                console.log('res.Data', res.Data);
                                console.log('res.Data', this.siteLocationsSrv.selectedLcoation);

                                const responseData = res.Data;
                                if (responseData && responseData.wiringCables) {
                                    const wiringCableFound = responseData.wiringCables.find(
                                        cableFnd => cableFnd.connectWith === this.siteLocationsSrv!.selectedLcoation!.id
                                    );
                                    // const newObjToUpdate = {};
                                    console.log('wiringCableFound', wiringCableFound);

                                    if (wiringCableFound) {
                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                wiringCableFound.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                wiringCableFound.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                wiringCableFound.connectDirection = 'N/A';
                                            }
                                        } else {
                                            wiringCableFound.connectDirection = 'N/A';
                                        }
                                        wiringCableFound.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;
                                        console.log('wiringCableFound', wiringCableFound);
                                        new LocationApi()
                                            .UpdateWiringCable(wiringCableFound)
                                            .subscribe(
                                                res => {
                                                    console.log('res.Data 2', res.Data);
                                                    // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                    // this.setActive(cable.id);
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.AutoUpdateWiringEntryError');
                                                }
                                            )
                                            .add(() => {
                                                this.LoaderSrv.hideFullScreenLoader();
                                            });
                                        // const newObjToUpdate = {
                                        //     connectWith: wiringCableFound.connectWith,
                                        //     connectDirection: cable.connectDirection === 'IN' ? 'OUT' : 'IN',
                                        // };
                                    } else {
                                        console.log('NOT FOUND, CREATE NEW');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newWiringCableObj.locationId = responseData.id;
                                        this.newWiringCableObj.sequence = responseData.wiringCables!.length + 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newWiringCableObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newWiringCableObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newWiringCableObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newWiringCableObj.connectDirection = 'N/A';
                                        }
                                        this.newWiringCableObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationWiringCable newWiringCableObj', this.newWiringCableObj);

                                        new LocationApi()
                                            .CreateLocationWiringCable(this.newWiringCableObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.SaveWiringEntryError');
                                                }
                                            )
                                            .add(() => {
                                                this.newWiringCableObj = new WiringCableModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                } else if (responseData && responseData.switchBoards) {
                                    const switchBoardsFound = responseData.switchBoards.find(
                                        cableFnd => cableFnd.connectWith === this.siteLocationsSrv!.selectedLcoation!.id
                                    );
                                    // const newObjToUpdate = {};
                                    console.log('wiringCableFound', switchBoardsFound);

                                    if (switchBoardsFound) {
                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                switchBoardsFound.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                switchBoardsFound.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                switchBoardsFound.connectDirection = 'N/A';
                                            }
                                        } else {
                                            switchBoardsFound.connectDirection = 'N/A';
                                        }
                                        switchBoardsFound.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;
                                        console.log('switchBoardsFound', switchBoardsFound);
                                        new LocationApi()
                                            .UpdateSwitchBoard(switchBoardsFound)
                                            .subscribe(
                                                res => {
                                                    console.log('res.Data 2', res.Data);
                                                    // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                    // this.setActive(cable.id);
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.AutoUpdateSwitchBoardError');
                                                }
                                            )
                                            .add(() => {
                                                this.LoaderSrv.hideFullScreenLoader();
                                            });
                                        // const newObjToUpdate = {
                                        //     connectWith: wiringCableFound.connectWith,
                                        //     connectDirection: cable.connectDirection === 'IN' ? 'OUT' : 'IN',
                                        // };
                                    } else {
                                        console.log('NOT FOUND, CREATE NEW newSwitchBoardObj');
                                        // console.log('this.newSwitchBoardObj.connectWith', this.newSwitchBoardObj);
                                        // console.log('this.newSwitchBoardObj.connectWith', this.newSeq);
                                        this.newSwitchBoardObj.locationId = responseData.id;
                                        // this.newSwitchBoardObj.sequence = responseData.wiringCables!.length + 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newSwitchBoardObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newSwitchBoardObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newSwitchBoardObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newSwitchBoardObj.connectDirection = 'N/A';
                                        }
                                        this.newSwitchBoardObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationSwitchBoard newSwitchBoardObj', this.newSwitchBoardObj);

                                        new LocationApi()
                                            .CreateLocationSwitchBoard(this.newSwitchBoardObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationSwitchBoard resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.SaveWiringEntryError');
                                                }
                                            )
                                            .add(() => {
                                                this.newSwitchBoardObj = new SwitchBoardModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                } else {
                                    if (connectedSite.type === 'equipment') {
                                        console.log('NOT FOUND, CREATE NEW equipment Wiring Core');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newWiringCableObj.locationId = responseData.id;
                                        this.newWiringCableObj.sequence = 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newWiringCableObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newWiringCableObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newWiringCableObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newWiringCableObj.connectDirection = 'N/A';
                                        }
                                        this.newWiringCableObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationWiringCable newWiringCableObj', this.newWiringCableObj);

                                        new LocationApi()
                                            .CreateLocationWiringCable(this.newWiringCableObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.SaveWiringEntryError');
                                                }
                                            )
                                            .add(() => {
                                                this.newWiringCableObj = new WiringCableModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    } else if (connectedSite.type === 'distribution') {
                                        console.log('NOT FOUND, CREATE NEW distribution Switch Board');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newSwitchBoardObj.locationId = responseData.id;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newSwitchBoardObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newSwitchBoardObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newSwitchBoardObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newSwitchBoardObj.connectDirection = 'N/A';
                                        }
                                        this.newSwitchBoardObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationSwitchBoard newSwitchBoardObj', this.newSwitchBoardObj);

                                        new LocationApi()
                                            .CreateLocationSwitchBoard(this.newSwitchBoardObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationSwitchBoard resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', '$t.Common.WiringEntry.SaveWiringEntryError');
                                                }
                                            )
                                            .add(() => {
                                                this.newSwitchBoardObj = new SwitchBoardModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                }
                                //
                            } else {
                                // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                                this.siteLocationsSrv.selectedLcoation = null;
                            }
                        },
                        err => {
                            this.AlertSrv.show('warning', 'Unable to load data.');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    public async updateCable(cable: any) {
        console.log('updateCable Board', cable);

        if (cable.id) {
            this.LoaderSrv.showFullScreenLoader();
            await this.updateSyncLocation(cable);
            new LocationApi()
                .UpdateWiringCable(cable)
                .subscribe(
                    res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', '$t.Common.WiringEntry.UpdateWiringEntrySuccess');
                        this.setActive(cable.id);
                        // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', '$t.Common.WiringEntry.UpdateWiringEntryError');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public deleteCable(cable: any) {
        console.log('Delete Wire', cable);
        this.ConfirmSrv.open('Delete Wire', `Are you sure you want to delete this Wire ${cable.sequence}?`).then(y => {
            if (y) {
                if (cable.id) {
                    this.LoaderSrv.showFullScreenLoader();
                    new LocationApi()
                        .deleteWiringCables(cable.id)
                        .subscribe(
                            res => {
                                // console.log('res.Data', res.Data);
                                this.AlertSrv.show('success', '$t.Common.WiringEntry.DeleteWiringEntrySuccess');
                                this.siteLocationsSrv.selectedLcoation?.wiringCables?.splice(cable.sequence - 1, 1);
                                this.saveAllWires();
                                // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            },
                            err => {
                                console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                this.AlertSrv.show('error', '$t.Common.WiringEntry.DeleteWiringEntryError');
                            }
                        )
                        .add(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            }
        });
    }

    public tab = 0;

    public prev() {
        if (this.tab === 0) return;
        this.tab = this.tab - 1;
    }
    public next() {
        this.tab = this.tab + 1;
    }
    public setActive(id: any) {
        console.log('id', this.tab);
        console.log('id', this.siteLocationsSrv.selectedLcoation!.wiringCables);

        if (this.siteLocationsSrv.selectedLcoation) {
            this.$emit('checkEquipmentRules', this.siteLocationsSrv.selectedLcoation.id!);
        }

        this.showAddNew = false;
        if (id) {
            this.tab = this.siteLocationsSrv.selectedLcoation!.wiringCables!.findIndex(d => d.id === id);
        } else {
            this.tab = 1;
        }
    }
    public setNew() {
        this.tab = this.siteLocationsSrv.selectedLcoation!.wiringCables!.length;
        this.showAddNew = true;
        // console.log('id', this.tab);
    }

    public locationItemClick(location: LocationModel, fromSearch?: boolean) {
        this.locationPath = [];
        let locationId = location.id;
        let foundLocation: LocationModel | undefined = location;

        let locationId1 = location.id;
        let foundLocation1: LocationModel | undefined | null = null;

        this.showSearchResults = false;

        while (locationId1 != null) {
            if (locationId1) {
                foundLocation1 = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId1);
            }
            if (foundLocation1) {
                foundLocation1.expanded = true;
            }
            locationId1 = foundLocation1?.locationId;
        }

        while (locationId != null) {
            if (foundLocation) {
                this.locationPath.push({id: foundLocation.id!, title: foundLocation.title!, type: 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.siteLocationsSrv.site) {
            this.locationPath.push({id: this.siteLocationsSrv.site.id!, title: this.siteLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPath = this.locationPath.reverse();

        if (location.type === 'equipment' || location.type === 'distribution') {
            this.siteLocationsSrv.getSiteLocation(location.id!);
        } else {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .getLocationById(location.id!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'documentations',
                        },
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);
                            this.sortWireArray();

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.approvalType ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.approvalType === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.approvalType = [];
                            // }

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.environmentalCondition ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.environmentalCondition === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.environmentalCondition = [];
                            // }
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            this.siteLocationsSrv.selectedLcoation = null;
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'Unable to load data.');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public filesSelected(event: any) {
        console.log('file', event);
        if (event) {
            this.file = event;
            console.log('file', this.file);
        }
    }

    public getDocuments() {
        this.LoaderSrv.showFullScreenLoader();
        this.siteLocationsSrv.selectedLcoation!.wiringCables?.sort((a, b) => a.sequence! - b.sequence!);
        this.documentationApi
            .GetDocuments(this.siteLocationsSrv.selectedLcoation?.id || '')
            .subscribe(
                res => {
                    this.documents = res.Data ?? [];
                    this.siteLocationsSrv.selectedLcoation?.wiringCables?.forEach((el, index) => {
                        if (el.connectWith) {
                            this.makeConnectWithFields(el, index);
                        }
                    });
                },
                err => {
                    //
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public upload(cableID: string) {
        this.LoaderSrv.showFullScreenLoader();
        this.documentationApi
            .AddDocumentCable(this.siteLocationsSrv.selectedLcoation?.id || '', cableID, this.file!, this.selectedType!)
            .subscribe(
                res => {
                    this.getDocuments();
                    this.AlertSrv.show('success', 'Document has been uploaded successfully!');
                    this.reset();
                },
                error => {
                    this.AlertSrv.show('error', error.message || 'Unable to upload document. Please try again later.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public uploadEquip() {
        this.LoaderSrv.showFullScreenLoader();
        this.documentationApi
            .AddDocumentCable(
                this.siteLocationsSrv.selectedLcoation?.id || '',
                this.siteLocationsSrv.selectedLcoation?.id || '',
                this.file!,
                this.selectedType!
            )
            .subscribe(
                res => {
                    this.getDocuments();
                    this.AlertSrv.show('success', 'Document has been uploaded successfully!');
                    this.reset();
                },
                error => {
                    this.AlertSrv.show('error', error.message || 'Unable to upload document. Please try again later.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public reset() {
        this.file = null;
        this.selectedType = null;
        this.addDocument = false;
        this.addDocument1 = false;
    }

    public changeCheckboxTabs() {
        // this.isSelectedIntrinsic = false;
        // this.isSelectedMotor = false;
        // this.isSelectedExPInf = false;
        // this.isSelectedExECurrentTrans = false;
        // this.isSelectedExEGenJunct = false;
    }

    public view(document: DocumentationModel) {
        this.$refs.previewDialog.open(document);
    }

    public deleteDoc(documentation: DocumentationModel) {
        this.ConfirmSrv.open('Delete Location', `Are you sure you want to delete this ${documentation.type}?`).then(y => {
            if (y) {
                this.LoaderSrv.showFullScreenLoader();
                new DocumentationApi()
                    .delete(documentation.id!)
                    .subscribe(
                        res => {
                            this.AlertSrv.show('success', `${documentation.type} has been deleted successfully!`);
                            this.getDocuments();
                        },
                        err => {
                            this.AlertSrv.show('error', `Unable to delete this ${documentation.type}. Please try again later!`);
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public summaryClick() {
        this.$refs.areaRulesSummaryDialogRef?.open();
    }

    public allowDrop(ev: any) {
        ev.preventDefault();
    }

    public async drop(event: any) {
        const locationId = event.dataTransfer.getData('id');

        const locationSite = this.siteLocationsSrv.siteLocations.find(d => d.id === locationId);
        if (locationSite?.type === 'equipment' || locationSite?.type === 'distribution') {
            this.siteLocationsSrv.selectedLcoation!.wiringCables![this.tab].connectWith = locationId;
            this.makeConnectWithFields(this.siteLocationsSrv.selectedLcoation!.wiringCables![this.tab], this.tab);
        }
    }
    public async dropNew(event: any) {
        const locationId = event.dataTransfer.getData('id');

        const locationSite = this.siteLocationsSrv.siteLocations.find(d => d.id === locationId);
        if (locationSite?.type === 'equipment' || locationSite?.type === 'distribution') {
            this.newWiringCableObj1.connectWith = locationId;
            this.makeConnectWithFieldsNew(this.newWiringCableObj1);
        }
    }
    public wireCableArr: Array<any> = [];

    public sortWireArray() {
        this.wireCableArr = this.siteLocationsSrv.selectedLcoation?.wiringCables || [];
        this.wireCableArr.sort((a, b) => a.sequence - b.sequence);
        console.log('wireCableArr', this.wireCableArr);
    }
    public mounted() {
        this.adminDataFieldsSrv.loadEquipmentFieldOptions();
        this.getDocuments();
        this.sortWireArray();
    }
}
