export class SignJobNormalWorkPermitViewwModel {
    id?: string | null = null;
    date?: string | null = null;
    time?: string | null = null;
    isSigned?: boolean | null = null;
    uniqueKey: string | null = null;
    jobNormalWorkPermitId?: string | null = null;
    signedByUserId: string | null = null;

    linkedToSignId?: string | null = null;

    constructor(data?: Partial<SignJobNormalWorkPermitViewwModel>) {
        Object.assign(this, data);
    }
}
