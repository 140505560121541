import {DataFieldModel} from './data-field.model';

export class DataFieldStatusModel {
    id?: string | null = null;
    created: string | null = null;
    locationId?: string | null = null;
    subId?: string | null = null;
    status?: string | null = null;
    errorCode?: string | null = null;
    message?: string | null = null;
    ruleKey?: string | null = null;
    dataFieldId?: string | null = null;

    dataField?: DataFieldModel | null = null;

    constructor(data?: Partial<DataFieldStatusModel>) {
        Object.assign(this, data);
    }
}
