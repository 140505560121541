var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: _vm.direction == "top",
        bottom: _vm.direction == "bottom",
        left: _vm.direction == "left",
        right: _vm.direction == "righ",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_vm._t("default", null, { on: on })]
            },
          },
        ],
        null,
        true
      ),
    },
    [_c("span", [_vm._v(_vm._s(_vm.msg))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }