var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column height-100x" },
    [
      _c("div", { staticClass: "flex-shrink-0 px-1" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-grow-1" },
            [
              _c("v-text-field", {
                staticClass: "mr-2",
                attrs: {
                  height: "20px",
                  dense: "",
                  outlined: "",
                  clearable: "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend-inner",
                    fn: function () {
                      return [
                        _c("v-img", {
                          attrs: {
                            height: "16",
                            width: "30",
                            contain: "",
                            src: require("@/assets/icons/Search-light.png"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-shrink-0" },
            [
              _c(
                "base-btn",
                {
                  staticStyle: { width: "22%" },
                  attrs: { color: "primary" },
                  on: { click: _vm.searchClick },
                },
                [
                  _c("v-img", {
                    attrs: {
                      height: "16",
                      contain: "",
                      src: require("@/assets/icons/search.png"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSearchResults,
                expression: "showSearchResults",
              },
            ],
            staticClass: "overflow-auto pt-0 pb-2 table-results",
            staticStyle: { "margin-top": "15px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "flex-shrink-0 px-3 font-weight-bold",
                staticStyle: { padding: "10px 5px !important" },
              },
              [
                _c("span", { staticStyle: { "font-size": "24px" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("SiteComponents.SiteSetupSearch.Title"))
                  ),
                ]),
                _c(
                  "base-btn",
                  {
                    staticStyle: { float: "right" },
                    attrs: { color: "primary" },
                    on: { click: _vm.hideResult },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "icon",
                        attrs: { size: "20", color: "white" },
                      },
                      [_vm._v("mdi-window-close")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "base-card",
              {
                staticClass: "overflow-hidden flex-1 d-flex flex-column",
                staticStyle: { border: "none" },
                attrs: { outlined: "" },
              },
              [
                _c(
                  "v-card-text",
                  {
                    staticClass:
                      "flex-grow-1 white height-100x width-100x overflow-hidden pa-2 pt-0",
                    staticStyle: { padding: "10px 5px !important" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "height-100x overflow-auto" },
                      [
                        _c(
                          "v-simple-table",
                          { staticClass: "location-table" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.LocationType"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.Title"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.FlammeableSubstances"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.ExplosionConsequences"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.IpxIpy"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.AmbientTemperatureRange"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.InspectionFrequency"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.ApprovalType"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.GasZone"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.GasGroup"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.TemperatureClass"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.DustZone"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.DustGroup"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.DustIgnitionTempType"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.GasDustGroup"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.DustIgnitionTempValue"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SiteComponents.SiteSetupSearch.TableHeaders.EnvironmentalCondition"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.searchedLocations,
                                function (location, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: `${index}-${location.locationId}`,
                                      staticClass: "search-row cursor-pointer",
                                      class: {},
                                      on: {
                                        click: function ($event) {
                                          return _vm.locationItemClickFn(
                                            location
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(location.type)),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "tdcc",
                                          attrs: { width: "400px" },
                                        },
                                        [_vm._v(_vm._s(location.title))]
                                      ),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationFlammableSubstances
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationExplosionConsequences
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationIpx) +
                                            " -" +
                                            _vm._s(location.locationIpy)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationLowAmbientTemp
                                          ) +
                                            " - " +
                                            _vm._s(
                                              location.locationHighAmbientTemp
                                            )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationInspectionFreq
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationApprovalType)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationGasZone)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationGasGroup)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationTempClass)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationDustZone)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationDustgroup)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationDustIgnitionTempType
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(location.locationGasGroup)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationDustIngnitionTempVal
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            location.locationEnvironmentalCondition
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "base-card",
              {
                staticClass:
                  "height-100x overflow-hidden flex-1 d-flex flex-column",
                staticStyle: { border: "none" },
                attrs: { outlined: "" },
              },
              [
                _c(
                  "v-card-text",
                  {
                    staticClass:
                      "flex-grow-1 white width-100x overflow-hidden pa-2 pt-0",
                    staticStyle: { padding: "10px 5px !important" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "height-100x overflow-auto" },
                      [
                        _c(
                          "v-simple-table",
                          { staticClass: "location-table" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Location Type"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Title"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Project Name"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Tag"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Electrical Circuit Connection"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Electrical And Process Drawing"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Equipment Classification"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("IP(x)-IP(y)"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Equipment Type"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Manufacturer"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Supplier"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Model No"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Serial No"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Zone"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Flammable Substances"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Explosion Consequences"),
                                ]),
                                _c("th", { staticClass: "tdcc" }, [
                                  _vm._v("Gas Group"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.searchedEquipments,
                                function (equipment, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: `${index}-${equipment.locationId}`,
                                      staticClass: "search-row cursor-pointer",
                                      class: {},
                                      on: {
                                        click: function ($event) {
                                          return _vm.locationItemClickFn(
                                            equipment
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(equipment.type)),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(equipment.title)),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentProjectName)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(equipment.equipmentTag)),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentElectricalCircuitConnection
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentElectricalAndProcessDrawing
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentEquipmentClassification
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentIpx) +
                                            "-" +
                                            _vm._s(equipment.equipmentIpy)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(equipment.equipmentType)),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentManufacturer
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentSupplier)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentModelNo)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentSerialNo)
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(_vm._s(equipment.equipmentZone)),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentFlammableSubstances
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(
                                            equipment.equipmentExplosionConsequences
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "tdcc" }, [
                                        _vm._v(
                                          _vm._s(equipment.equipmentGasGroupf)
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }