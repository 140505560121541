import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {
    AreaSearchViewModel,
    DataFieldStatusModel,
    DistributionPanelModel,
    EquipmentSearchViewModel,
    LocationDetailModel,
    LocationModel,
    SwitchBoardModel,
} from '../models';
import {CoresGroup} from '../models/location/cores-group.model';
import {EquipmentDetailModel} from '../models/location/equipment-detail.model';
import {WiringCableModel} from '../models/location/wiring-cables.model';
import {BaseApi} from './base.api';

@ServiceClass()
export class LocationApi extends BaseApi {
    public deleteLocation(id: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/locations/${id}`);
    }

    public UpdateLocation(id: string, data: LocationModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        delete data.type;

        // if (!data.locationId) delete data.locationId;
        delete data.locations;

        return this.PATCH_Request<AnyObject>(`${this.ApiUrl}/locations/${id}`, {title: data.title, locationId: data.locationId});
    }

    public UpdateSequence(id: string, sequence: number) {
        return this.PATCH_Request<AnyObject>(`${this.ApiUrl}/locations/${id}`, {sequence});
    }

    public getLocationById(id: string, filter: AnyObject = {}) {
        return this.GET_Request<LocationModel>(`${this.ApiUrl}/locations/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public CreateLocationEquipmentDetail(locationId: string, data: EquipmentDetailModel) {
        delete data.id;
        delete data.created;
        delete data.updated;

        if (!data.certificationScheme) data.certificationScheme = [];
        if (!data.protectionTechnique) data.protectionTechnique = [];
        if (!data.protectionTechniqueSub) data.protectionTechniqueSub = [];
        if (!data.wiringProtectionTechnique) data.wiringProtectionTechnique = [];
        if (!data.ipy) data.ipy = [];

        return this.POST_Request<EquipmentDetailModel>(`${this.ApiUrl}/locations/${locationId}/equipment-detail`, data);
    }

    public UpdateLocationEquipmentDetail(locationId: string, data: EquipmentDetailModel) {
        // delete data.id;
        delete data.created;
        delete data.updated;

        if (!data.certificationScheme) data.certificationScheme = [];
        if (!data.protectionTechnique) data.protectionTechnique = [];
        if (!data.protectionTechniqueSub) data.protectionTechniqueSub = [];
        if (!data.wiringProtectionTechnique) data.wiringProtectionTechnique = [];
        if (!data.ipy) data.ipy = [];

        return this.PATCH_Request<EquipmentDetailModel>(`${this.ApiUrl}/locations/${locationId}/equipment-detail`, data);
    }

    public searchLocationDetails(siteId: string, search: string) {
        return this.GET_Request<{locationDetails: Array<AreaSearchViewModel>; equipmentDetails: Array<EquipmentSearchViewModel>}>(
            `${this.ApiUrl}/locations/search-location-details?siteId=${siteId}&search=${search}`
        );
    }

    public equipmentDetail(locationId: string, filter: AnyObject = {}) {
        return this.GET_Request<EquipmentDetailModel>(`${this.ApiUrl}/locations/${locationId}/equipment-detail?filter=${JSON.stringify(filter)}`);
    }

    public CreateLocationDistributionPanel(locationId: string, data: DistributionPanelModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        delete data.switchBoards;

        console.log('CreateLocationDistributionPanel', data);

        return this.POST_Request<DistributionPanelModel>(`${this.ApiUrl}/locations/${locationId}/distribution-panel`, data);
    }

    public UpdateLocationDistributionPanel(locationId: string, data: DistributionPanelModel) {
        delete data.created;
        delete data.updated;
        delete data.switchBoards;

        return this.PATCH_Request<DistributionPanelModel>(`${this.ApiUrl}/locations/${locationId}/distribution-panel`, data);
    }

    public distributionPanel(locationId: string, filter: AnyObject = {}) {
        return this.GET_Request<DistributionPanelModel>(`${this.ApiUrl}/locations/${locationId}/distribution-panel?filter=${JSON.stringify(filter)}`);
    }

    public CreateLocationSwitchBoard(data: SwitchBoardModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        delete data.finalMidArr;

        console.log('CreateLocationSwitchBoard', data);

        return this.POST_Request<SwitchBoardModel>(`${this.ApiUrl}/switch-boards`, data);
    }

    public UpdateSwitchBoard(data: SwitchBoardModel) {
        delete data.created;
        delete data.updated;
        delete data.finalMidArr;

        console.log('UpdateLocationSwitchBoard', data);

        return this.PATCH_Request<SwitchBoardModel>(`${this.ApiUrl}/switch-boards/${data.id}`, data);
    }

    public switchBoards(locationId: string, filter: AnyObject = {}) {
        console.log('GET switchBoards', filter);
        return this.GET_Request<DistributionPanelModel>(`${this.ApiUrl}/locations/${locationId}/switch-boards?filter=${JSON.stringify(filter)}`);
    }

    public deleteSwitchBoards(boardId: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/switch-boards/${boardId}`);
    }

    public CreateLocationWiringCable(data: WiringCableModel) {
        delete data.id;
        delete data.created;
        delete data.updated;

        console.log('CreateLocationWiringCable', data);

        return this.POST_Request<WiringCableModel>(`${this.ApiUrl}/wiring-cables`, data);
    }

    public UpdateWiringCable(data: WiringCableModel) {
        delete data.created;
        delete data.updated;

        console.log('UpdateWiringCable', data);

        return this.PATCH_Request<WiringCableModel>(`${this.ApiUrl}/wiring-cables/${data.id}`, data);
    }

    public wiringCables(locationId: string, filter: AnyObject = {}) {
        console.log('GET switchBoards', filter);
        return this.GET_Request<WiringCableModel>(`${this.ApiUrl}/locations/${locationId}/wiring-cables?filter=${JSON.stringify(filter)}`);
    }

    public updateAllWiringCables(wiringCables: any) {
        console.log('wiringCables', wiringCables);
        return this.PATCH_Request<any>(`${this.ApiUrl}/wiring-cables`, {wiringCables: wiringCables});
    }

    public deleteWiringCables(boardId: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/wiring-cables/${boardId}`);
    }

    public async deleteCoresGroups(coresGroupId: string) {
        return await this.DELETE_RequestAsync<any>(`${this.ApiUrl}/cores-groups/${coresGroupId}`);
    }

    public async coresGroups(locationId: string, filter: AnyObject = {}) {
        console.log('GET coreGroups', filter);
        return await this.GET_RequestAsync<CoresGroup>(`${this.ApiUrl}/locations/${locationId}/cores-groups?filter=${JSON.stringify(filter)}`);
    }

    public async CreateLocationCoresGroups(data: CoresGroup) {
        delete data.id;
        delete data.created;

        console.log('CreateLocationWiringCable', data);
        return await this.POST_RequestAsync<CoresGroup>(`${this.ApiUrl}/locations/${data.locationId}/cores-groups`, data);
    }

    public async UpdateCoresGroups(data: CoresGroup) {
        delete data.created;

        console.log('UpdateCoresGroups', data);
        return await this.PATCH_RequestAsync<CoresGroup>(`${this.ApiUrl}/cores-groups/${data.id}`, data);
    }

    public CreateLocationLocationDetail(locationId: string, data: LocationDetailModel) {
        delete data.id;
        delete data.created;
        delete data.updated;

        if (!data.approvalType) data.approvalType = [];
        if (!data.flammableSubstances) data.flammableSubstances = [];
        if (!data.flammableDust) data.flammableDust = [];
        if (!data.environmentalCondition) data.environmentalCondition = [];

        return this.POST_Request<LocationDetailModel>(`${this.ApiUrl}/locations/${locationId}/location-detail`, data);
    }

    public GetLocationFormStatus(locationId: string) {
        const filter = {
            include: [
                {
                    relation: 'dataField',
                },
            ],
        };
        return this.GET_Request<Array<DataFieldStatusModel>>(
            `${this.ApiUrl}/locations/${locationId}/location-detail/get-rules-status?filter=${JSON.stringify(filter)}`
        );
    }

    public CheckLocationDetailRules(locationId: string) {
        return this.POST_Request<LocationDetailModel>(`${this.ApiUrl}/locations/${locationId}/location-detail/check-rules`, {});
    }

    public CheckEquipmentDetailRules(locationId: string) {
        return this.POST_Request<any>(`${this.ApiUrl}/locations/${locationId}/equipment-detail/check-rules`, {});
    }

    public UpdateLocationLocationDetail(locationId: string, data: LocationDetailModel) {
        // delete data.id;
        delete data.created;
        delete data.updated;

        if (!data.approvalType) data.approvalType = [];
        if (!data.flammableSubstances) data.flammableSubstances = [];
        if (!data.flammableDust) data.flammableDust = [];
        if (!data.environmentalCondition) data.environmentalCondition = [];

        return this.PATCH_Request<LocationDetailModel>(`${this.ApiUrl}/locations/${locationId}/location-detail`, data);
    }
}
