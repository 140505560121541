var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "lighten-3 d-flex flex-column height-100x overflow-auto" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between px-2 py-1 align-center" },
        [
          _c("div", [_vm._v("Inspection Area Structure")]),
          _c(
            "div",
            [
              _c(
                "base-btn",
                { attrs: { small: "", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "browse-section px-2" }, [
        _c(
          "div",
          {
            staticClass: "d-flex align-center mb-1 site-box",
            class: {
              "drag-hilight":
                _vm.jobLocationsSrv.enterItemSiteId === _vm.site.id,
            },
            attrs: { id: _vm.site.id },
            on: {
              dragover: _vm.allowDrop,
              drop: _vm.onItemDrop,
              dragenter: _vm.onDragEnter,
            },
          },
          [
            _c(
              "div",
              {},
              [
                _c(
                  "base-btn",
                  { attrs: { small: "", icon: "" } },
                  [
                    _c("v-img", {
                      attrs: {
                        height: "16",
                        width: "30",
                        contain: "",
                        src: require("@/assets/icons/plant-light-lg.png"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex mr-5" }, [
              _c("span", { staticClass: "body-2" }, [
                _vm._v(_vm._s(_vm.site.siteName)),
              ]),
            ]),
            _vm._m(0),
          ]
        ),
        _c(
          "div",
          [
            _c("location-list-item-component", {
              attrs: {
                draggable: false,
                parentType: "site",
                parentId: _vm.site.id,
                locations: _vm.siteLocations,
                readonly: true,
                level: 1,
              },
              on: {
                locationItemClick: function ($event) {
                  return _vm.$emit("locationItemClick", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "item-controls" }, [_c("span"), _c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }