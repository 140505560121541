var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "py-0 px-0 height-100vh main-layout-container",
      staticStyle: { position: "relative" },
      attrs: { fluid: "" },
    },
    [
      _c("client-app-bar-component"),
      _c(
        "v-main",
        { staticClass: "overflow-auto", attrs: { id: "main-content-section" } },
        [
          _c(
            "div",
            {
              staticClass: "height-100x overflow-hidden",
              attrs: { id: "temp-content" },
            },
            [_c("router-view", { key: _vm.$route.fullPath })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }