import { render, staticRenderFns } from "./account-info-dialog.component.vue?vue&type=template&id=f36c4566&scoped=true"
import script from "./account-info-dialog.component.ts?vue&type=script&lang=ts&external"
export * from "./account-info-dialog.component.ts?vue&type=script&lang=ts&external"
import style0 from "./account-info-dialog.component.vue?vue&type=style&index=0&id=f36c4566&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36c4566",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/foex-3r/foex-3r-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f36c4566')) {
      api.createRecord('f36c4566', component.options)
    } else {
      api.reload('f36c4566', component.options)
    }
    module.hot.accept("./account-info-dialog.component.vue?vue&type=template&id=f36c4566&scoped=true", function () {
      api.rerender('f36c4566', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shared/account-info/account-info-dialog/account-info-dialog.component.vue"
export default component.exports