var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-form", {
    ref: "baseForm",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "base-dialog",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      "content-class": "foex-dialog",
                      name: _vm.name,
                      "max-width": "950px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _vm._v(" Non Conformity Record Files "),
                              _vm._t("header"),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "dialogFooter",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "base-btn",
                                    {
                                      attrs: { outlined: "", color: "primary" },
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v(" Close ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "base-dialog",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              ),
              [
                _c(
                  "div",
                  [
                    !_vm.isReadonly
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("base-text-field", {
                                  staticStyle: { color: "#fdf7e7" },
                                  attrs: {
                                    label: "Title",
                                    value: _vm.title,
                                    rules: "required",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.title = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.file
                              ? _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("div", { staticClass: "mr-3" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.file.name) + " "
                                        ),
                                      ]),
                                      _c(
                                        "base-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            outlined: "",
                                            small: "",
                                            color: "red",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.file = null
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-close")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.file
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("base-file-picker", {
                                      attrs: {
                                        maxSize: 50 * 1024 * 1024,
                                        width: "100%",
                                        height: "50px",
                                        accept: "image/*,application/pdf",
                                      },
                                      on: {
                                        filesSelected: _vm.onFilesSelected,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: "admin-primary",
                                      disabled: invalid,
                                    },
                                    on: { click: _vm.saveFile },
                                  },
                                  [_vm._v(" Upload ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [_c("v-divider")],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("div", [
                            _c("table", { staticClass: "foex-table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Title")]),
                                  _c("th", [_vm._v("File Name")]),
                                  _c("th", [_vm._v("Actions")]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.filesList, function (uploadedFile) {
                                  return _c("tr", { key: uploadedFile.id }, [
                                    _c("td", [
                                      _vm._v(_vm._s(uploadedFile.title)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(uploadedFile.fileName)),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        !_vm.isReadonly
                                          ? _c(
                                              "base-btn",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  outlined: "",
                                                  color: "red",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteFile(
                                                      uploadedFile
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isReadonly
                                          ? _c(
                                              "base-btn",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  outlined: "",
                                                  color: "admin-primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadFile(
                                                      uploadedFile
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-download"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "base-btn",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              icon: "",
                                              small: "",
                                              outlined: "",
                                              color: "admin-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.previewFile(
                                                  uploadedFile
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-eye")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("PreviewDocumentDialogComponent", {
                  ref: "previewDocumentDialogRef",
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }