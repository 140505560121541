var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-form", {
    ref: "baseForm",
    on: {
      submit: function ($event) {
        return _vm.$emit("submit", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (form) {
            return [
              _c(
                "base-dialog",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { name: _vm.name },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _vm._v(" " + _vm._s(_vm.title) + " "),
                                _vm._t("header"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "dialogFooter",
                            fn: function () {
                              return [_vm._t("footer", null, null, form)]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "base-dialog",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [_vm._t("default", null, null, form)],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }