import {AnyObject} from '@/globals';

export class IndividualProtectionEquipmentViewModel {
    halmet: boolean | null = null;
    hearProtectors: boolean | null = null;
    gasMask: boolean | null = null;
    dielectricGloves: boolean | null = null;
    safetyGloves: boolean | null = null;
    weldersHelmet: boolean | null = null;
    emergencyRespirator: boolean | null = null;
    safetyShoes: boolean | null = null;
    rubberSafetyBoots: boolean | null = null;
    safetyGlasses: boolean | null = null;
    weldersApron: boolean | null = null;
    protectiveGoggles: boolean | null = null;
    antyDustOveralls: boolean | null = null;
    weldersBreeches: boolean | null = null;
    h2sMask: boolean | null = null;
    wWorkClothes: boolean | null = null;
    safetyBelts: boolean | null = null;
    dielectricBoots: boolean | null = null;
    safetyHarness: boolean | null = null;
    doubleSafetyHarness: boolean | null = null;
    dustMask: boolean | null = null;

    constructor(data?: Partial<IndividualProtectionEquipmentViewModel> | null) {
        Object.assign(this, data);
    }
}

export class JobNormalWpToBeFilledByContractorViewModel {
    id?: string | null = null;
    individualProtectionEquipment: IndividualProtectionEquipmentViewModel | null = null;
    commonProtectionEquipment?: string | null = null;
    otherSafetyMeasures?: string | null = null;
    electricalPTWRequired?: boolean | null = null;
    electricalPTWNumber?: string | null = null;
    confinedSpacePTWRequired?: boolean;
    confinedSpacePTWNumber?: string | null = null;
    electricalOrConfinedPTWRequired?: boolean | null = null;

    constructor(data?: Partial<JobNormalWpToBeFilledByContractorViewModel>) {
        Object.assign(this, data);
        if (!this.individualProtectionEquipment) {
            this.individualProtectionEquipment = new IndividualProtectionEquipmentViewModel();
        }
    }
}
