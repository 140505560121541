export class RoleModel {
    public id?: string | null = null;
    public name: string | null = null;
    public slug: string | null = null;
    public type: string | null = null;
    public description: string | null = null;

    constructor(data?: Partial<RoleModel>) {
        Object.assign(this, data);
    }
}
