import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {AnyObject} from '@/globals';
import {SdkConfig} from '../sdk.config';

@ServiceClass()
export class TranslationsApi extends BaseApi {
    public async GetLangMessages(lang: string) {
        return this.GET_RequestAsync<AnyObject>(`${SdkConfig.ApiBaseUrl}/assets/locales/${lang}.json`);
    }
}
