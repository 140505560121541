var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { staticClass: "d-flex" },
        [
          _c("v-card-text", { staticClass: "d-flex justify-center" }, [
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-3", staticStyle: { width: "90px" } },
              [
                _c("v-img", {
                  attrs: { src: require("../../../assets/button1.svg") },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("test"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }