export interface EquipmentSearchViewModel {
    locationId: string;
    siteId: string;
    title: string;
    type: string;
    equipmentACDC: string | null;
    equipmentCertificationScheme: string | null;
    equipmentDivisionForRiskLevel: string | null;
    equipmentDustGroupf: string | null;
    equipmentEnvironmentalCondition: string | null;
    equipmentEPLDust: string | null;
    equipmentEPLGas: string | null;
    equipmentEquipmentClassification: string | null;
    equipmentType: string | null;
    equipmentFieldEquipmentAssociatedApparatus: string | null;
    equipmentGasDustGroup: string | null;
    equipmentGasGroupf: string | null;
    equipmentGasTemperatureClass: string | null;
    equipmentInspectionFrequency: string | null;
    equipmentIpx: string | null;
    equipmentIpy: string | null;
    equipmentPowerTypef: string | null;
    equipmentProtectionTechnique: string | null;
    equipmentProtectionTechniqueSub: string | null;
    equipmentTemperatureClass: string | null;
    equipmentZone: string | null;
    equipmentClient: string | null;
    equipmentProjectName: string | null;
    equipmentTag: string | null;
    equipmentElectricalCircuitConnection: string | null;
    equipmentElectricalAndProcessDrawing: string | null;
    equipmentPhoto: string | null;
    equipmentManufacturer: string | null;
    equipmentSupplier: string | null;
    equipmentModelNo: string | null;
    equipmentSerialNo: string | null;
    equipmentFlammableSubstances: string | null;
    equipmentExplosionConsequences: string | null;
    equipmentGasArea: string | null;
    equipmentDustIgnitionTempRequired: string | null;
    equipmentExMarketing: string | null;
    equipmentIecex: string | null;
    equipmentXur: string | null;
    equipmentCertificationNumber: string | null;
    equipmentEplGasAndDust: string | null;
    equipmentEplGasAndDustSub: string | null;
    equipmentClassificationGasDustGroup: string | null;
    equipmentClassificationTemperatureClass: string | null;
    equipmentMaxSurfaceTemp: string | null;
    equipmentMaxSurfaceTempWithDustLayerMM: string | null;
    equipmentMaxSurfaceTempWithDustCloud: string | null;
    equipmentAmbientTemp: string | null;
    equipmentEquipmentAmbientTemp: string | null;
    equipmentApprovalType: string | null;
    equipmentWiringProtectionTechnique: string | null;
    equipmentPowerCableType: string | null;
    equipmentPowerCableModel: string | null;
    equipmentPowerCableManufacturer: string | null;
    equipmentPowerCableOther: string | null;
    equipmentPowerCableElecFrom: string | null;
    equipmentSignalCableType: string | null;
    equipmentSignalCableModel: string | null;
    equipmentSignalCableManufacturer: string | null;
    equipmentSignalCableOther: string | null;
    equipmentSignalCableFrom: string | null;
    equipmentSignalCableTo: string | null;
    equipmentFieldManufacturer: string | null;
    equipmentCableManufacturer: string | null;
    equipmentApparatusManufacturer: string | null;
    equipmentFieldModelNo: string | null;
    equipmentCableModelNo: string | null;
    equipmentApparatusModelNo: string | null;
    equipmentFieldSerialNo: string | null;
    equipmentCableSerialNo: string | null;
    equipmentApparatusSerialNo: string | null;
    equipmentFieldCertNo: string | null;
    equipmentCableCertNo: string | null;
    equipmentApparatusCertNo: string | null;
    equipmentFieldUiV: string | null;
    equipmentApparatusUiV: string | null;
    equipmentFieldLiMa: string | null;
    equipmentApparatusLiMa: string | null;
    equipmentFieldPiW: string | null;
    equipmentApparatusPiW: string | null;
    equipmentFieldCiuF: string | null;
    equipmentCableCMuF: string | null;
    equipmentCableLengthM1: string | null;
    equipmentCableCCuF: string | null;
    equipmentApparatusCiuF: string | null;
    equipmentFieldLiMh: string | null;
    equipmentCableLmMh: string | null;
    equipmentCableLengthM2: string | null;
    equipmentCableCLMh: string | null;
    equipmentApparatusLiMh: string | null;
    equipmentCableLrcMhOHM: string | null;
    equipmentCableLRoMhOHM: string | null;
    equipmentContinuityOHM: string | null;
    equipmentScreenOHM: string | null;
    equipmentContinuityOHM2: string | null;
    equipmentMultiCoreScreenOHM: string | null;
    equipmentScreenOHM2: string | null;
    equipmentOperatedConvertor: string | null;
    equipmentSpeedRangeLimitLow: string | null;
    equipmentSpeedRangeLimitHigh: string | null;
    equipmentFrequencyRangeLimitLow: string | null;
    equipmentFrequencyRangeLimitHigh: string | null;
    equipmentSwitchingFrequency: string | null;
    equipmentSwitchingFrequencyUnit: string | null;
    equipmentTypeOfTorque: string | null;
    equipmentSpecificConverter: string | null;
    equipmentTypeOfConverter: string | null;
    equipmentExEbMotor: string | null;
    equipmentIaIn: string | null;
    equipmentTeS: string | null;
    equipmentPminPurgeFlow: string | null;
    equipmentPminPurgeDurartion: string | null;
    equipmentPtypeOfProtectiveGas: string | null;
    equipmentPminOverpressure: string | null;
    equipmentPmaxOverpressure: string | null;
    equipmentPminSupplyPressure: string | null;
    equipmentPmaxSupplyPressure: string | null;
    equipmentPmaxLeakageRate: string | null;
    equipmentPrangeOfTemprature: string | null;
    equipmentPpointsOfPressure: string | null;
    equipmentPinternalSourceOfRelease: string | null;
    equipmentPmaxInletPressure: string | null;
    equipmentPmaxFlowRate: string | null;
    equipmentEshortCircuitCurrent: string | null;
    equipmentEGmaxRatedPower: string | null;
    equipmentEGarrangedMethod: string | null;
    equipmentEGterminalSize: string | null;
    equipmentEGpermissibleConductorNumber: string | null;
    equipmentEGpermissibleConductorSize: string | null;
    equipmentEGmaxCurrent: string | null;
    equipmentWarningMarkingInformation: string | null;
    equipmentComponentInformationf: string | null;
    equipmentRowRadioSingleToComnine: string | null;
    equipmentPowerACDC: string | null;
    equipmentPhasesf: string | null;
    equipmentConverterNamef: string | null;
    equipmentVoltageVf: string | null;
    equipmentFrequencyHzf: string | null;
    equipmentMinVoltageVf: string | null;
    equipmentMinFrequencyHzf: string | null;
    equipmentMaxVoltageVf: string | null;
    equipmentMaxFrequencyHzf: string | null;
    equipmentSuffixf: string | null;
    equipmentMaxSurfaceTempf: string | null;
    equipmentTestDustLayerf: string | null;
    equipmentMaxSurfaceTempWithDustLayerCf: string | null;
    equipmentSpecialConditionsUsef: string | null;
    equipmentLowAmbientTemperature: string | null;
    equipmentHighAmbientTemperature: string | null;
    equipmentLowServiceTemperature: string | null;
    equipmentHighServiceTemperature: string | null;
    equipmentDustLayerIgnition: string | null;
    equipmentDustLayerDepth: string | null;
    equipmentDustTemperatureClass: string | null;
    equipmentGasAreaf: string | null;
    equipmentIPYrequirement: string | null;
    equipmentIPXrequirement: string | null;
    equipmentFieldEquipApparatus: string | null;
    equipmentInstalledDate: string | null;
    equipmentDivisionRiskLevel: string | null;
    equipmentNote: string | null;
    equipmentCreated: string | null;
    equipmentUpdated: string | null;
}
