import {ServiceClass} from '@/decorators';
import {InspepctionFormModel} from '@/sdk';
import {InspectionFormsApi} from '@/sdk/api-services';
import {JobApi} from '@/sdk/api-services/job.api';
import {nameof} from 'ts-simple-nameof';

@ServiceClass()
export class InspectionFormsService {
    public inspectionFormsData: Array<InspepctionFormModel> = [];

    public selectedObj: InspepctionFormModel | null = null;

    public inspectionFormsApi: InspectionFormsApi = new InspectionFormsApi();
    public jobsApi: JobApi = new JobApi();

    public async loadInspectionForms() {
        return this.inspectionFormsApi
            .getInspectionFormsAsync({
                order: `${nameof<InspepctionFormModel>(x => x.title)} ASC`,
            })
            .then(x => {
                this.inspectionFormsData = x ?? [];

                return x;
            });
    }

    public getById(id: string) {
        return this.inspectionFormsData.find(x => x.id === id) ?? null;
    }

    public async loadInspectionFormById(id: string) {
        return this.inspectionFormsApi.getInspectionFormByIdAsync(id).then(x => {
            this.selectedObj = x ?? null;

            return x;
        });
    }

    public async cloneInspectionForm(data: {inspectionFormId: string; title: string}) {
        return this.inspectionFormsApi.cloneInspectionForm(data);
    }

    public async inspectionFormSettings(id: string, data: {protectionTechniquesIds: Array<string>; title: string}) {
        return this.inspectionFormsApi.inspectionFormSettings(id, data);
    }
}
