var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column flex-srink-0",
      staticStyle: { "min-width": "max-content" },
    },
    [
      _vm._l(_vm.getDcoumentations, function (location) {
        return [
          _c(
            "div",
            {
              key: `${location.type}${location.id}`,
              staticClass: "d-flex align-center mb-1 ba-1 list-item-box",
              staticStyle: { flex: "0 0 auto" },
              attrs: { id: `${location.id}` },
            },
            [
              _vm._l(_vm.level - 1, function (l) {
                return _c("div", {
                  key: `${location.id}${l}`,
                  staticClass: "d-inline-block",
                  staticStyle: { width: "25px", height: "1px" },
                })
              }),
              _c(
                "div",
                { staticStyle: { "min-width": "30px" } },
                [
                  _vm.hasSubLocations(location.id)
                    ? _c(
                        "base-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              location.expanded = !location.expanded
                            },
                          },
                        },
                        [
                          location.expanded
                            ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                            : _c("v-icon", [_vm._v("mdi-chevron-right")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex mr-5" },
                [
                  _c("v-img", {
                    staticClass: "mr-3",
                    attrs: {
                      height: "18",
                      width: "18",
                      src: _vm.locationIcon(location),
                    },
                  }),
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(_vm._s(location.title)),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "item-controls d-flex" }, [
                location.type === "folder"
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-x": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "base-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { small: "", icon: "" } },
                                            "base-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              height: "16",
                                              width: "30",
                                              contain: "",
                                              src: require("@/assets/icons/add.png"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "", nav: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addFolderClick(location)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Add Sub Area"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addFileClick(location)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Add File(s)"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "span",
                  [
                    _c(
                      "base-btn",
                      {
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteLocation(location)
                          },
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "red--text cursor-pointer" },
                          [_vm._v("mdi-delete")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          location.expanded && _vm.hasSubLocations(location.id)
            ? [
                _c("DocumentationListItemComponent", {
                  key: `child-${location.type}${location.id}`,
                  attrs: {
                    parentType: location.type,
                    parentId: location.id,
                    documentations: _vm.documentations,
                    level: _vm.level + 1,
                  },
                }),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }