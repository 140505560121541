var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column height-100x overflow-hidden" },
    [
      _c("div", { staticClass: "lighten-3 mb-4 flex-shrink-0" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center basic-box-header" },
          [
            _c(
              "div",
              { staticClass: "px-5" },
              [
                _c(
                  "v-row",
                  { staticStyle: { width: "500px" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex", attrs: { cols: "6" } },
                      [
                        _c("v-img", {
                          staticClass: "area-search header-img-title",
                          attrs: {
                            height: "20",
                            width: "20",
                            contain: "",
                            src: require("@/assets/icons/area-light.png"),
                          },
                        }),
                        _c("h2", { staticClass: "areaTitle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("SiteComponents.AreaDetails.Title"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  _vm._l(_vm.locationPath, function (path, index) {
                    return _c(
                      "div",
                      { key: path.id, staticClass: "d-flex" },
                      [
                        path.type === "site"
                          ? _c("v-img", {
                              staticClass: "area-search header-img-title",
                              attrs: {
                                height: "18",
                                width: "18",
                                contain: "",
                                src: require("@/assets/icons/plant-light.png"),
                              },
                            })
                          : path.type === "location"
                          ? _c("v-img", {
                              staticClass: "area-search header-img-title",
                              attrs: {
                                height: "18",
                                width: "18",
                                contain: "",
                                src: require("@/assets/icons/area-light.png"),
                              },
                            })
                          : _vm._e(),
                        path.type === "site"
                          ? _c("h4", { staticClass: "header-title-text" }, [
                              _vm._v(_vm._s(path.title)),
                            ])
                          : _c(
                              "span",
                              {
                                staticClass: "cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("goToLocation", path.id)
                                  },
                                },
                              },
                              [
                                _c("h4", { staticClass: "header-title-text" }, [
                                  _vm._v(_vm._s(path.title)),
                                ]),
                              ]
                            ),
                        index !== _vm.locationPath.length - 1
                          ? _c(
                              "span",
                              {
                                staticClass: "header-title-text",
                                staticStyle: {
                                  color: "white",
                                  padding: "0px 5px",
                                },
                              },
                              [_vm._v(" > ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c("v-spacer"),
            _c(
              "div",
              { staticClass: "px-3" },
              [
                !_vm.readOnlyFlag
                  ? _c(
                      "base-btn",
                      {
                        staticStyle: { border: "1px solid white !important" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("saveLocationDetail")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Common.Save")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.siteLocationsSrv.locationDetailDataFieldStatuses.length > 0
          ? _c(
              "div",
              { staticClass: "warning-box" },
              [
                _c("span", [
                  _vm._v(
                    "There are total " +
                      _vm._s(
                        _vm.siteLocationsSrv.locationDetailDataFieldStatuses
                          .length
                      ) +
                      " warnings."
                  ),
                ]),
                _c(
                  "base-btn",
                  {
                    attrs: {
                      color: "#e17e2c",
                      outlined: "",
                      small: "",
                      disabled: _vm.readOnlyFlag,
                    },
                    on: { click: _vm.summaryClick },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Common.Details")) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.siteLocationsSrv.selectedLcoation?.locationDetail
        ? _c(
            "div",
            {
              staticClass:
                "flex-grow-1 flex-1 overflow-auto d-flex info-tabs-header",
            },
            [
              _c(
                "div",
                { staticClass: "col col-12" },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "my-tabs min-height-100",
                      attrs: { vertical: "" },
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "SiteComponents.AreaDetails.Tabs.GeneralInformation"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "SiteComponents.AreaDetails.Tabs.TagManagement"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "SiteComponents.AreaDetails.Tabs.AreaClassification"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "SiteComponents.AreaDetails.Tabs.Documentations"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("SiteComponents.AreaDetails.Tabs.Note")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            staticStyle: { color: "#fdf7e7" },
                                            attrs: {
                                              name: "areaName",
                                              disabled: _vm.readOnlyFlag,
                                              label: _vm.$t(
                                                "Fields.AreaFields.AreaTitle.Label"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaFlammableSubstance
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              multiple: true,
                                              filter:
                                                _vm.flammableSubstanceFilter,
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .flammableSubstances,
                                              label: _vm.$t(
                                                "Fields.AreaFields.FlammableGas.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            on: {
                                              "update:value": [
                                                function ($event) {
                                                  return _vm.$set(
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail,
                                                    "flammableSubstances",
                                                    $event
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.onSelected(
                                                    $event,
                                                    "areaFlammableSubstance"
                                                  )
                                                },
                                              ],
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.item
                                                                      .value
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.item
                                                                      .extras
                                                                      ?.formula
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              266306150
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "table",
                                          { staticClass: "width-100" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.Title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.RelativeDensity"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.LEL"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.UEL"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.AIT"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.GasGroup"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.GasDbTableHeader.TClass"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.flameableGasInfo,
                                                function (item) {
                                                  return _c(
                                                    "tr",
                                                    { key: `${item.id}` },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.relativeDensity
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.LFLVol)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.UFLVol)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.AIT)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.equipmentGroup
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.tClass)
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaFlammableDust
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              multiple: true,
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.flammableDust,
                                              label: _vm.$t(
                                                "Fields.AreaFields.FlammableDust.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            on: {
                                              "update:value": [
                                                function ($event) {
                                                  return _vm.$set(
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail,
                                                    "flammableDust",
                                                    $event
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.onSelected(
                                                    $event,
                                                    "areaFlammableDust"
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "table",
                                          { staticClass: "width-100" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.DustDbTableHeader.Title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.DustDbTableHeader.DustGroup"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.DustDbTableHeader.MinIgnitionTempOfDustCloud"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.DustDbTableHeader.MinIgnitionDepthOfDustLayer"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "SiteComponents.AreaDetails.DustDbTableHeader.MinIgnitionTempOfDustLayer"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.flameableDustInfo,
                                                function (item) {
                                                  return _c(
                                                    "tr",
                                                    { key: `${item.id}` },
                                                    [
                                                      _c("td", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "150px",
                                                              overflow:
                                                                "'hidden'",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.dustGroup)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.minimumIgnitionTempDustCloud
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.minimumIgnitionDepthDustLayer
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.minimumIgnitionTempDustLayer
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaExplosionConsequence
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.ExplosionConsequences.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .explosionConsequences,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "explosionConsequences",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.explosionConsequences",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              multiple: true,
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaApprovalType
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.ApprovalType.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.approvalType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "approvalType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.approvalType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaInspectionFrequency
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.AreaInspectionFrequency.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .inspectionFreq,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "inspectionFreq",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.inspectionFreq",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              multiple: true,
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaEnvironmentalCondition
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.EnvironmentalCondition.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .environmentalCondition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "environmentalCondition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.environmentalCondition",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaEnvironmentalRisk
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.EnvironmentalRisk.Label"
                                              ),
                                              "return-object": false,
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .environmentalRisk,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "environmentalRisk",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.environmentalRisk",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _vm.siteLocationsSrv.selectedLcoation.id
                            ? _c("TagManagementComponent", {
                                key: _vm.siteLocationsSrv.selectedLcoation.id,
                                attrs: {
                                  siteLocationId:
                                    _vm.siteLocationsSrv.selectedLcoation.id,
                                  readOnlyFlag: _vm.readOnlyFlag,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail
                                                          .lowAmbientTemp,
                                                      name: "areaLowAmbientTemp",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.LowAmbientTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "areaLowAmbientTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "areaLowAmbientTemperature"
                                                        ),
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .locationDetail,
                                                          "lowAmbientTemp",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail
                                                          .highAmbientTemp,
                                                      name: "areaHighAmbientTemp",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.HighAmbientTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "areaHighAmbientTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "areaHighAmbientTemperature"
                                                        ),
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .locationDetail,
                                                          "highAmbientTemp",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaIpx
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.Ipx.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail.ipx,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .locationDetail,
                                                          "ipx",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.locationDetail.ipx",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaIpy
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.Ipy.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail.ipy,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .locationDetail,
                                                          "ipy",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.locationDetail.ipy",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList.areaGasArea
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.GasArea.Label"
                                              ),
                                              "return-object": false,
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasArea"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasArea"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.gasZone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "gasZone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.gasZone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaGasGroup
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.GasGroup.Label"
                                              ),
                                              "return-object": false,
                                              "hide-details": false,
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasGroup"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasGroup"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.gasGroup,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "gasGroup",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.gasGroup",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaGasTemperatureClass
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.GasTempClass.Label"
                                              ),
                                              "return-object": false,
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasTemperatureClass"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaGasTemperatureClass"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.gasTempClass,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "gasTempClass",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.gasTempClass",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaDustArea
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.DustArea.Label"
                                              ),
                                              "return-object": false,
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustArea"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustArea"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.dustZone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "dustZone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.dustZone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.adminDataFieldsSrv
                                                  .areaFieldOptions[
                                                  _vm.areaFieldsList
                                                    .areaDustGroup
                                                ],
                                              "item-text": "value",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "Fields.AreaFields.DustGroup.Label"
                                              ),
                                              "return-object": false,
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustGroup"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustGroup"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.dustgroup,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "dustgroup",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.dustgroup",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              name: "dustCloudIgnitionTemp",
                                              label:
                                                "Dust Cloud Ignition Temperature (oC)",
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustCloudIgnitionTemperature"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustCloudIgnitionTemperature"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .dustCloudIgnitionTemp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "dustCloudIgnitionTemp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.dustCloudIgnitionTemp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              name: "dustLayerDepth",
                                              label: _vm.$t(
                                                "Fields.AreaFields.DustLayerDepth.Label"
                                              ),
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustLayerDepth"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustLayerDepth"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .dustLayerDepth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "dustLayerDepth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.dustLayerDepth",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              name: "dustLayerIgnitionTemp",
                                              label: _vm.$t(
                                                "Fields.AreaFields.DustLayerIgnitionTemperature.Label"
                                              ),
                                              "error-count":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustLayerIgnitionTemperature"
                                                ).length ?? 1,
                                              "error-messages":
                                                _vm.siteLocationsSrv.checkFieldError(
                                                  "areaDustLayerIgnitionTemperature"
                                                ),
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            model: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail
                                                  .dustLayerIgnitionTemp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "dustLayerIgnitionTemp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "siteLocationsSrv.selectedLcoation.locationDetail.dustLayerIgnitionTemp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _vm.siteLocationsSrv.selectedLcoation.id
                            ? _c("AreaDocumentationComponent", {
                                key: _vm.siteLocationsSrv.selectedLcoation.id,
                                attrs: {
                                  siteLocationId:
                                    _vm.siteLocationsSrv.selectedLcoation.id,
                                  documents:
                                    _vm.siteLocationsSrv.selectedLcoation
                                      .documentations ?? [],
                                  disabled: _vm.readOnlyFlag,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("base-textarea", {
                                            attrs: {
                                              value:
                                                _vm.siteLocationsSrv
                                                  .selectedLcoation
                                                  .locationDetail.note,
                                              label: _vm.$t(
                                                "Fields.AreaFields.Note.Label"
                                              ),
                                              "auto-grow": "",
                                              disabled: _vm.readOnlyFlag,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .locationDetail,
                                                  "note",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("AreaRulesSummaryDialogComponent", {
        ref: "areaRulesSummaryDialogRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }