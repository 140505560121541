import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {ShowableRoute} from '@/globals';
import {Component} from 'vue-property-decorator';

@Component
export default class AdminDrawerComponent extends VueWrapper {
    public DatabaseLinks: Array<{Title: string; Icon: string; Active: boolean; Links: Array<ShowableRoute>}> = [];
    public AccountLinks: Array<{Title: string; Icon: string; Active: boolean; Links: Array<ShowableRoute>}> = [];
    public expandedGroup: string | null = 'Accounts'; // Default expanded group
    public plans = false;
    public mini = '';

    // public certificationSchemes: Array<KeyValuePair> = [
    //     {
    //         key: 'IECEx',
    //         value: 'IECEx',
    //     },
    //     {
    //         key: 'ATEX',
    //         value: 'ATEX',
    //     },
    //     {
    //         key: 'KCs',
    //         value: 'KCs',
    //     },
    //     {
    //         key: 'North America',
    //         value: 'North America',
    //     },
    // ];

    // public selectedScheme: string | null = 'IECEx';

    public window = window;

    public selectedMenu = '';

    // public userSrv: UsersService = new UsersService();
    // public user: UsersModel = new UsersModel();

    public created() {
        // this.userSrv.User$.subscribe(user => {
        //     this.user = user;
        // });
        this.DatabaseLinks = [
            {
                Title: this.$t('AdminDrawer.Database').toString(),
                Icon: 'mdi-database',
                Active: false,
                Links: [
                    {
                        Title: this.$t('AdminDrawer.FieldsDB').toString(),
                        Name: 'fields-db',
                        Icon: 'mdi-database-settings',
                        Chip: '',
                        Color: '',
                    },
                    {
                        Title: this.$t('AdminDrawer.GasDB').toString(),
                        Name: 'gas-db',
                        Icon: 'mdi-database-settings',
                        Chip: '',
                        Color: '',
                    },
                    {
                        Title: this.$t('AdminDrawer.DustDB').toString(),
                        Name: 'dust-db',
                        Icon: 'mdi-database-settings',
                        Chip: '',
                        Color: '',
                    },
                    {
                        Title: this.$t('AdminDrawer.WorkPermitsDB').toString(),
                        Name: 'global-work-permits',
                        Icon: 'mdi-account-group-outline',
                        Chip: '',
                        Color: '',
                        inactive: false,
                    },
                    {
                        Title: this.$t('AdminDrawer.InspectionFormsDB').toString(),
                        Name: 'inspection-forms-db',
                        Icon: 'mdi-account-settings',
                        Chip: '',
                        Color: '',
                        inactive: false,
                    },
                ],
            },
        ];

        this.AccountLinks = [
            {
                Title: this.$t('AdminDrawer.Accounts').toString(),
                Icon: 'mdi-account',
                Active: false,
                Links: [
                    {
                        Title: this.$t('AdminDrawer.AccountsList').toString(),
                        Name: 'account-list',
                        Icon: 'mdi-account-settings',
                        Chip: '',
                        Color: '',
                        inactive: false,
                    },
                    {
                        Title: this.$t('AdminDrawer.UsersByAccount').toString(),
                        Name: 'account-users',
                        Icon: 'mdi-account-group-outline',
                        Chip: '',
                        Color: '',
                        inactive: false,
                        data: {id: this.UserSessionSrv.Session?.selectedAccountId},
                    },
                    {
                        Title: this.$t('AdminDrawer.WorkPermitsByAccount').toString(),
                        Name: 'account-work-permits',
                        Icon: 'mdi-account-group-outline',
                        Chip: '',
                        Color: '',
                        inactive: false,
                        data: {id: this.UserSessionSrv.Session?.selectedAccountId},
                    },
                ],
            },
        ];
    }

    public mounted() {
        this.AddSubscription$ = this.UserSessionSrv._session.subscribe(session => {
            if (session) {
                this.AccountLinks[0].Links.splice(1, 1, {
                    Title: this.$t('AdminDrawer.UsersByAccount').toString(),
                    Name: 'account-users',
                    Icon: 'mdi-account-group-outline',
                    Chip: '',
                    Color: '',
                    inactive: false,
                    data: {accountId: this.UserSessionSrv.Session?.selectedAccountId},
                });

                this.AccountLinks[0].Links.splice(2, 1, {
                    Title: this.$t('AdminDrawer.WorkPermitsByAccount').toString(),
                    Name: 'account-work-permits',
                    Icon: 'mdi-account-group-outline',
                    Chip: '',
                    Color: '',
                    inactive: false,
                    data: {accountId: this.UserSessionSrv.Session?.selectedAccountId},
                });

                if (
                    this.$route.params.accountId !== this.UserSessionSrv.Session?.selectedAccountId &&
                    [this.AccountLinks[0].Links[1].Name, this.AccountLinks[0].Links[2].Name].includes(this.$route.name ?? '')
                ) {
                    this.$router.push({
                        name: this.$route.name ?? '',
                        params: {accountId: this.UserSessionSrv.Session?.selectedAccountId ?? ''},
                    });
                }
            }
        });

        if (this.$route.matched.find(x => x.name === 'account-setup')) {
            this.DatabaseLinks[0].Active = false;
            this.AccountLinks[0].Active = true;
        } else if (this.$route.matched.find(x => x.name === 'database-setup')) {
            this.AccountLinks[0].Active = false;
            this.DatabaseLinks[0].Active = true;
        }
    }

    public toggleGroup(group: string) {
        this.expandedGroup = this.expandedGroup === group ? null : group;
    }
}
