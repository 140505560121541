import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import ImportGasDbDialogComponent from './import-gas-db-dialog/import-gas-db-dialog.component';
import {GasDbService} from '@/views/shared/services';
import PreviewGasDbRecordDialogComponent from './preview-gas-db-record-dialog/preview-gas-db-record-dialog.component';
import {GasDbModel} from '@/sdk';

@Component({
    components: {
        ImportGasDbDialogComponent,
        PreviewGasDbRecordDialogComponent,
    },
})
export default class GasDbComponent extends VueWrapper {
    public $refs!: {
        importGasDbDialog: ImportGasDbDialogComponent;
        previewGasObjDialog: PreviewGasDbRecordDialogComponent;
    };

    public gasDbSrv: GasDbService = new GasDbService();

    private interval: any = null;

    public mounted() {
        this.LoaderSrv.showFullScreenLoader('$t.Common.GasDb.Loading');
        this.gasDbSrv.loadGasDb().finally(() => {
            this.LoaderSrv.hideFullScreenLoader();
        });

        this.interval = setInterval(() => {
            this.gasDbSrv.loadGasDb().finally(() => {
                // this.LoaderSrv.hideFullScreenLoader();
            });
        }, 10000);
    }

    public beforeDestroy() {
        clearInterval(this.interval);
    }

    public preview(obj: GasDbModel) {
        this.gasDbSrv.selectedObj = obj;
        this.$refs.previewGasObjDialog.open();
    }

    public importGasDb() {
        this.$refs.importGasDbDialog.open();
    }
}
