import {ServiceClass} from '@/decorators';
import {CreateUpdateTagRequest, TagModel, TagsGroupByType} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class TagApi extends BaseApi {
    public Create(data: CreateUpdateTagRequest) {
        return this.POST_RequestAsync<TagModel>(`${this.ApiUrl}/tags`, data);
    }

    public UpdateTag(id: string, data: CreateUpdateTagRequest) {
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/tags/${id}`, data);
    }

    public getTags() {
        return this.GET_RequestAsync<TagModel[]>(`${this.ApiUrl}/tags`);
    }

    public getTagsByLocation(locationId: string) {
        return this.GET_RequestAsync<TagsGroupByType>(`${this.ApiUrl}/tags/location/${locationId}`);
    }

    public activateTag(tagId: string, locationId: string, type: string) {
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/tags/activate`, {tagId, locationId, type});
    }

    public generateQRCode(code: string, size: number = 300) {
        return this.GET_RequestAsync<string>(`${this.ApiUrl}/tags/qr-code/${code}?size=${size}`);
    }

    public generateBarcode(code: string, size: number = 100) {
        return this.GET_RequestAsync<string>(`${this.ApiUrl}/tags/barcode/${code}?size=${size}`);
    }
}
