import {ServiceClass} from '@/decorators';
import {ReportModel} from '../models';
import {PaginationResponseModel, PaginationViewModel} from '../models/common';
import {BaseApi} from './base.api';

@ServiceClass()
export class ReportsApi extends BaseApi {
    public getReports(siteId: string, filter?: PaginationViewModel<ReportModel>) {
        return this.GET_RequestAsync<PaginationResponseModel<ReportModel>>(`${this.ApiUrl}/reports/${siteId}?filter=${JSON.stringify(filter)}`);
    }

    public deleteReport(reportId: string) {
        return this.DELETE_RequestAsync(`${this.ApiUrl}/reports/${reportId}`);
    }

    public generateAreaReport(siteId: string) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/area-report/${siteId}`);
    }

    public generateInspectionReportExd(siteId: string, isSave: boolean = false) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/inspection-report/${siteId}/ex-d?isSave=${isSave}`);
    }

    public generateInspectionReportExi(siteId: string, isSave: boolean = false) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/inspection-report/${siteId}/ex-i?isSave=${isSave}`);
    }

    public generateInspectionReportExp(siteId: string, isSave: boolean = false) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/inspection-report/${siteId}/ex-p?isSave=${isSave}`);
    }

    public generateEquipmentReport(siteId: string, isSave: boolean = false) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/equipment-report/${siteId}?isSave=${isSave}`);
    }

    public async downloadReport(reportId: string) {
        return this.downloadRequest<Blob>(`${this.ApiUrl}/reports/${reportId}/download`);
    }

    public downloadEquipmentReport(locationId: string) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/equipment-report/${locationId}`);
    }

    public generateJobReport(siteId: string) {
        return this.downloadRequest<any>(`${this.ApiUrl}/reports/job-report/${siteId}`);
    }
}
