var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "450px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._v(" Import Dust DB "), _vm._t("header")]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "base-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              disabled: !_vm.file,
                              color: "admin-primary",
                            },
                            on: { click: _vm.save },
                          },
                          [_vm._v(" Upload & Import ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        [
          !_vm.file
            ? _c("base-file-picker", {
                attrs: {
                  width: "100%",
                  height: "100px",
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
                on: { filesSelected: _vm.filesSelected },
              })
            : _c("div", [
                _c("h3", [_vm._v("Selected File:")]),
                _vm._v(" " + _vm._s(_vm.file.name) + " "),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }