export class SiteModel {
    public id?: string | null = null;
    public siteCode?: string | null = null;
    public siteName: string | null = null;
    public siteNameSlug?: string | null = null;
    public siteAddress?: string | null = null;
    public jobOrderCode?: string | null = null;
    public jobOrderName?: string | null = null;
    public jobOrderStatus?: string | null = null;
    public jobOrderStartDate?: Date | string | null = null;
    public jobOrderEndDate?: Date | string | null = null;
    public accountId: string | null = null;
    public organizationId?: string | null = null;
    public emergencyContactName?: string | null = null;
    public emergencyContactEmail?: string | null = null;
    public emergencyContactTelephone?: string | null = null;
    public emergencyContactMobile?: string | null = null;
    public localEmergencyResponse?: string | null = null;
    public drawingCanvas1?: string | null = null;
    public created?: string | null = null;
    public updated?: string | null = null;

    // public organization?: OrganizationModel | null = null;

    // areas: Area[];

    [key: string]: any;

    constructor(data?: Partial<SiteModel>) {
        Object.assign(this, data);
    }
}
