var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "1050px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Dialogs.ImportGasDb.Title")) + " "
                    ),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "base-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              disabled: !_vm.parsedCsv.length,
                              color: "admin-primary",
                            },
                            on: { click: _vm.save },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Common.Import")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        [
          !_vm.file
            ? _c("base-file-picker", {
                attrs: {
                  width: "100%",
                  height: "100px",
                  accept: "text/csv",
                  text: _vm.$t("Fields.Common.BrowseFiles.Label"),
                },
                on: { filesSelected: _vm.filesSelected },
              })
            : _c("div", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("Dialogs.ImportGasDb.SelectedFile"))),
                ]),
                _vm._v(" " + _vm._s(_vm.file.name) + " "),
                _c(
                  "div",
                  _vm._l(_vm.parsedCsv, function (item) {
                    return _c(
                      "table",
                      { key: item.id, staticClass: "foex-table mb-2" },
                      [
                        _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: item.fieldOptions.length } },
                            [_vm._v(_vm._s(item.title))]
                          ),
                        ]),
                        _c(
                          "tr",
                          _vm._l(item.fieldOptions, function (opt, index) {
                            return _c("td", { key: "opt-" + index }, [
                              _vm._v(_vm._s(opt.value)),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }