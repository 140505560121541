var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "scroll", attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-5" },
        [
          _c("v-row", [_c("h3", [_vm._v("INFORMATION DETAILS")])]),
          _c(
            "v-row",
            { staticStyle: { height: "20%", "margin-bottom": "10px" } },
            [
              _c("h3", { staticStyle: { "background-color": "#e4e7eb" } }, [
                _vm._v("POINT 2"),
              ]),
            ]
          ),
          _c("v-row", {
            staticStyle: {
              border: "2px solid",
              height: "100%",
              "background-color": "#dee2e6",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Client")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Project Name/Number")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Plant")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Area")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Sub-Area")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Point")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Equipment Name")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Tag Information")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Type")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Electrical Circuit Connection")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Electrical and Process Drawings")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Photo")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Equipment Classification")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Equipment Type")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Manufacturer")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Supplier")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Model No.")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Serial No.")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Equipment IP Class")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Gas Zone")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Gas Group")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Temp Class Requirement")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Environmental Condition")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-1", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Ex Marking")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Certification Number")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Certification Scheme")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Protection Technique")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("EPL")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Gas/Dust Group")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Temperature Class")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Maximum Surface Temperature")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Maximum Surface Temperature w/Dust Cloud")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.items,
                              label: "Choose an Option",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [
                            _vm._v(
                              "Maximum Surface Temperature w/Dust Layer Depth"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Equipment Ambient Temperature")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "start" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "v-btn",
                            { attrs: { width: "200px", color: "green" } },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "start" } },
                        [
                          _c(
                            "v-btn",
                            { attrs: { width: "200px", color: "red" } },
                            [_vm._v("Delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c("v-btn", { attrs: { width: "200px", color: "red" } }, [
                    _vm._v("Warning Information"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }