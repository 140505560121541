var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "lighten-3 d-flex flex-column height-100x overflow-hidden" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex px-2 py-1 align-center basic-box-header overflow-hidden flex-shrink-0",
        },
        [
          _c("div", { staticClass: "mr-2 text-h6" }, [
            _vm._v(" " + _vm._s(_vm.site.siteName) + " "),
            _c("br"),
            _vm._v(
              " (" +
                _vm._s(_vm.getUserLoginId) +
                ", " +
                _vm._s(_vm.getUserRole) +
                ") "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "browse-section pa-2 flex-grow-1 overflow-auto" },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-center mb-1 site-box cursor-pointer",
              class: {
                "drag-hilight":
                  _vm.siteLocationsSrv.enterItemSiteId === _vm.site.id,
                "grey lighten-2": !_vm.siteLocationsSrv.selectedLcoation,
              },
              attrs: { id: `${_vm.site.id}`, type: "site" },
              on: {
                dragover: _vm.allowDrop,
                drop: _vm.onItemDrop,
                dragenter: _vm.onDragEnter,
                click: function ($event) {
                  return _vm.$emit("siteClick", { id: _vm.site.id })
                },
              },
            },
            [
              _c(
                "div",
                {},
                [
                  _c(
                    "base-btn",
                    { attrs: { small: "", icon: "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "16",
                          width: "30",
                          contain: "",
                          src: require("@/assets/icons/plant-light-lg.png"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex" }, [
                _c("span", { staticClass: "body-2" }, [
                  _vm._v(_vm._s(_vm.site.siteName)),
                ]),
              ]),
              _c("div", { staticClass: "item-controls" }, [
                _c(
                  "span",
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-x": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "base-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { small: "", icon: "" } },
                                      "base-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "16",
                                        width: "30",
                                        contain: "",
                                        src: require("@/assets/icons/add.png"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "", nav: "" } },
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.addItemClick },
                              },
                              [_c("v-list-item-title", [_vm._v("Add Area")])],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.addEquipment },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v("Add Equipment"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.addDistribution },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v("Add Distribution Pabel"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("span"),
              ]),
            ]
          ),
          _c(
            "div",
            [
              _c("LocationListItemComponent", {
                attrs: {
                  parentType: "site",
                  parentId: _vm.site.id,
                  locations: _vm.filteredSiteLocations,
                  level: 1,
                },
                on: {
                  locationItemClick: function ($event) {
                    return _vm.$emit("locationItemClick", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex px-3 pt-1 pb-2 overflow-hidden flex-shrink-0" },
        [
          _c(
            "div",
            { staticClass: "d-flex width-100" },
            [
              _c("base-text-field", {
                staticClass: "width-100",
                attrs: {
                  clearable: "",
                  "single-line": "",
                  "hide-details": "",
                  value: _vm.search,
                  "background-color": "white",
                  placeholder: "Search",
                  "prepend-inner-icon": "mdi-magnify",
                },
                on: {
                  "update:value": function ($event) {
                    _vm.search = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }