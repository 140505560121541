import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {WorkPermitModel} from '@/sdk';
import {GlobalWorkPermitApi, WorkPermitApi} from '@/sdk/api-services';
import {Component, Prop} from 'vue-property-decorator';

interface DownloadWorkpermitDialogContext {
    type: string;
}

@Component
export default class DownloadWorkpermitDialogComponent extends VueWrapper {
    readonly name: string = 'DownloadWorkpermitDialogComponent';

    @Prop({
        type: String,
        required: true,
    })
    accountId!: string;

    tabs = {
        workPermitDb: 'work-permits-db',
        accountWorkPermits: 'account-work-permits',
    };

    context: DownloadWorkpermitDialogContext | null = null;

    public workPermitTemplates: WorkPermitModel[] = [];
    accountWorkPermitTemplates: WorkPermitModel[] = [];

    globalWorkPermitsApi: GlobalWorkPermitApi = new GlobalWorkPermitApi();
    workPermitsApi: WorkPermitApi = new WorkPermitApi();

    selectedTab: string = this.tabs.workPermitDb;

    open(context: DownloadWorkpermitDialogContext) {
        this.context = {...context};
        this.CoreSrv.OpenModal(this.name);

        this.getGlobalWorkPermits();
    }

    close() {
        this.CoreSrv.CloseModal(this.name);
    }

    onTabChange(tab: string) {
        this.selectedTab = tab;
        if (tab === this.tabs.accountWorkPermits) {
            this.getAccountWorkPermits();
        } else {
            this.getGlobalWorkPermits();
        }
    }

    get cardText() {
        return this.selectedTab === this.tabs.workPermitDb ? 'Global Work Permits' : 'Account Work Permits';
    }

    getGlobalWorkPermits() {
        this.LoaderSrv.showFullScreenLoader();
        this.globalWorkPermitsApi
            .getWorkPermits({
                ...(this.context?.type ? {where: {type: this.context.type}} : {}),
            })
            .then(res => {
                this.workPermitTemplates = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to load work permits.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getAccountWorkPermits() {
        this.workPermitsApi
            .getWorkPermits(this.accountId, {...(this.context?.type ? {where: {type: this.context.type}} : {})})
            .then(res => {
                this.accountWorkPermitTemplates = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to load work permits.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    downloadWorkPermit(wokrPermit: WorkPermitModel) {
        if (!wokrPermit.id || !wokrPermit.filename) return;

        this.LoaderSrv.showFullScreenLoader();
        this.workPermitsApi
            .downloadWorkPermit(this.accountId, wokrPermit.id!)
            .then(res => {
                //
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download work permit.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    downloadGlobalWorkPermit(workPermit: WorkPermitModel) {
        if (!workPermit.id || !workPermit.filename) return;

        this.LoaderSrv.showFullScreenLoader();
        this.globalWorkPermitsApi
            .downloadWorkPermit(workPermit.id!)
            .then(res => {
                //
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download work permit.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    async cloneToAccount(workPermit: WorkPermitModel) {
        if (!workPermit.id) return;

        const yes = await this.ConfirmSrv.open('Clone Work Permit to Account', 'Are you sure you want to clone this work permit to your account?');

        if (!yes) return;

        this.LoaderSrv.showFullScreenLoader();
        this.globalWorkPermitsApi
            .cloneToAccount(workPermit.id, this.accountId)
            .then(() => {
                this.AlertSrv.show('success', 'Work permit cloned successfully.');
                this.selectedTab = this.tabs.accountWorkPermits;
                this.getAccountWorkPermits();
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to clone work permit.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
