var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { name: _vm.name, width: "700px", persistent: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Dialogs.ProjectSelection.Title")) +
                        " "
                    ),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "base-btn",
                          {
                            attrs: { disabled: !_vm.isSelected },
                            on: { click: _vm.projectSelected },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Common.Ok")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "mb-1 d-flex justify-end" },
        [
          !_vm.readonly
            ? _c(
                "base-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs.addSiteProjectDialogRef.open(true)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-plus")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("Dialogs.ProjectSelection.AddProjectBtn")) +
                      " "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("table", { staticClass: "foex-table projects-table text-center" }, [
        _c("thead", [
          _c("tr", [
            _c("th"),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("Dialogs.ProjectSelection.TableHeaders.Number"))
              ),
            ]),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("Dialogs.ProjectSelection.TableHeaders.ID"))
              ),
            ]),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("Dialogs.ProjectSelection.TableHeaders.Title"))
              ),
            ]),
            _c("th", [
              _vm._v(
                _vm._s(
                  _vm.$t("Dialogs.ProjectSelection.TableHeaders.StartDate")
                )
              ),
            ]),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("Dialogs.ProjectSelection.TableHeaders.EndDate"))
              ),
            ]),
            !_vm.readonly
              ? _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Dialogs.ProjectSelection.TableHeaders.Actions")
                    )
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.siteProjectsSrv.projectsList, function (project, index) {
            return _c("tr", { key: project.id }, [
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.checkboxClick(project)
                        },
                      },
                      model: {
                        value: _vm.selectedProjects,
                        callback: function ($$v) {
                          _vm.selectedProjects = $$v
                        },
                        expression: "selectedProjects",
                      },
                    },
                    [
                      [
                        _c(
                          "div",
                          [
                            _c("v-radio", {
                              key: project.id,
                              attrs: { color: "#1b3054", value: project.id },
                            }),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("td", [_vm._v(_vm._s(index + 1))]),
              _c("td", [_vm._v(" " + _vm._s(project.id) + " ")]),
              _c("td", [_vm._v(_vm._s(project.title))]),
              _c("td", [_vm._v(_vm._s(project.startDate))]),
              _c("td", [_vm._v(_vm._s(project.endDate))]),
              !_vm.readonly
                ? _c(
                    "td",
                    [
                      _c(
                        "base-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.editProject(project)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _c("AddSiteProjectDialogComponent", {
        ref: "addSiteProjectDialogRef",
        attrs: { siteId: _vm.siteId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }