import VueI18n from 'vue-i18n';
import Vue from 'vue';
import {TranslationsApi} from '@/sdk/api-services';

Vue.use(VueI18n);

console.log('i18n inilizaing');

export default new VueI18n({
    locale: localStorage.getItem('locale') ?? 'en',
    messages: {
        en: require('@/locales/en.json'),
        ko: require('@/locales/ko.json'),
    },
});
