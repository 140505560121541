import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component} from 'vue-property-decorator';
import {SiteTeamMembersService} from '../../../shared/services';
import {JobService} from '../../../shared/services/job.service';

@Component
export default class JobLinkJobManagerComponent extends VueWrapper {
    public jobsSrv: JobService = new JobService();
    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();

    public jobsApi: JobApi = new JobApi();

    public isJobManagerLinked(managerId: string) {
        return !!this.jobsSrv.jobJobManagers.find(x => x.id === managerId);
    }

    public unlinkJobManager(jobId: string, managerId: string) {
        this.ConfirmSrv.open(
            this.$t('SiteComponents.JobManagers.UnlinkConfirmTitle') as string,
            this.$t('SiteComponents.JobManagers.UnlinkConfirmMessage') as string
        ).then(yes => {
            if (yes) {
                this.jobsApi.unlinkJobJobManager(jobId, managerId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', this.$t('SiteComponents.JobManagers.UnlinkSuccess') as string);
                            this.jobsSrv.getJobJobManagers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || (this.$t('SiteComponents.JobManagers.UnlinkError') as string));
                    }
                );
            }
        });
    }

    public linkJobManager(jobId: string, managerId: string) {
        this.ConfirmSrv.open(
            this.$t('SiteComponents.JobManagers.LinkConfirmTitle') as string,
            this.$t('SiteComponents.JobManagers.LinkConfirmMessage') as string
        ).then(yes => {
            if (yes) {
                this.jobsApi.linkJobJobManager(jobId, managerId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', this.$t('SiteComponents.JobManagers.LinkSuccess') as string);
                            this.jobsSrv.getJobJobManagers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || (this.$t('SiteComponents.JobManagers.LinkError') as string));
                    }
                );
            }
        });
    }
}
