import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobModel, LocationModel, ProjectModel, SiteModel, UserSession} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {SiteLocationService} from '@/views/shared/services/site-location.service';
import {Component} from 'vue-property-decorator';
import SiteSetupAreaBrowserComponent from '../../client/site-setup/site-setup-area-browser/site-setup-area-browser.component';
// import SiteSetupEquipmentFormComponent from '../site-setup-equipment-form/site-setup-equipment-form.component';
import {LocationApi} from '@/sdk/api-services/location.api';

import {JobApi} from '@/sdk/api-services/job.api';
import {
    AdminDataFieldService,
    JobLocationService,
    JobsInspectionFormsService,
    SiteProjectsService,
    SiteTeamMembersService,
    WorkPermitsService,
} from '@/views/shared/services';
import {JobService} from '@/views/shared/services/job.service';
import JobSetupAreaBrowserComponent from '../job-setup-area-browser/job-setup-area-browser.component';

import SiteSetupLocationFormComponent from '@/views/client/site-setup/site-setup-location-form/site-setup-location-details-form.component';
import SiteSetupSearchComponent from '@/views/client/site-setup/site-setup-search/site-setup-search.component';
import DistributionPanelFormComponent from '@/views/shared/distribution-panel-form/distribution-panel-form.component';
import EquipmentDetailsFormComponent from '@/views/shared/equipment-details-form/equipment-details-form.component';
import ProjectSelectionDialogComponent from '@/views/shared/project-selection-dialog/project-selection-dialog.component';
import JobDetailsComponent from '../job-details/job-details.component';

@Component({
    name: 'JobSetupMainComponent',
    components: {
        SiteSetupAreaBrowserComponent,
        JobSetupAreaBrowserComponent,
        ProjectSelectionDialogComponent,

        // SiteSetupSearchComponent,
        // SiteSetupLocationFormComponent,
        // SaveLocationDialogComponent,
        // SiteSetupEquipmentFormComponent,

        // HotWorkComponent,
        // WorkPermitInput,
        // ConfinedSpaceWorkPermitComponent,
        // ElectricalWorkPermitComponent,
        // GassFreePermitComponent,

        SiteSetupLocationFormComponent,
        EquipmentDetailsFormComponent,
        DistributionPanelFormComponent,
        JobDetailsComponent,
        SiteSetupSearchComponent,
    },
})
export default class JobSetupMainComponent extends VueWrapper {
    public $refs!: {
        areaBrowserElem: HTMLDivElement;
        jobAreaBrowserElem: HTMLDivElement;
        jobListElem: HTMLDivElement;
        jobListParentElem: HTMLDivElement;
        projectSelectionDialogRef: ProjectSelectionDialogComponent;
        jobDetailsComponentRef: JobDetailsComponent;
    };

    public siteToEdit: SiteModel = new SiteModel();

    public siteId: string | null = null;

    public jobLocationsSrv: JobLocationService = new JobLocationService();
    public siteLocationsSrv: SiteLocationService = new SiteLocationService();
    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();

    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();
    public jobInspectionFormsSrv: JobsInspectionFormsService = new JobsInspectionFormsService();
    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();

    public adminDataFieldSrv: AdminDataFieldService = new AdminDataFieldService();

    public jobService: JobService = new JobService();

    public locationPath: Array<{id: string; title: string; type: string}> = [];

    public inspectionTab: number | null = null;

    public selectedInspectors: any = [];

    // public calendarType: any = 'month';
    // public calendarTypes: any = ['month', 'week', 'day', '4day'];
    // public mode: any = 'stack';
    // public modes: any = ['stack', 'column'];
    // public weekday: any = [0, 1, 2, 3, 4, 5, 6];
    // public weekdays: any = [
    //     {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
    //     {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
    //     {text: 'Mon - Fri', value: [1, 2, 3, 4, 5]},
    //     {text: 'Mon, Wed, Fri', value: [1, 3, 5]},
    // ];
    // public value: any = '';
    // public events: any = [];
    // public colors: any = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];
    // public names: any = ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'];

    //INPUTS
    // titleInput: String | null = null;
    // typeInput: String | null = null;
    // areaInput: String | null = null;
    // subAreaInput: String | null = null;
    // flammableInput: String | null = null;
    // explosionInput: String | null = null;
    // ipxInput: String | null = null;
    // ipyInput: String | null = null;
    // ambTempInput: String | null = null;
    // inspFrequencyInput: String | null = null;
    // approvalTypeInput: String | null = null;
    // gasZoneInput: String | null = null;
    // gasGroupInput: String | null = null;
    // tempClassInput: String | null = null;
    // dustZoneInput: String | null = null;
    // dustGroupInput: String | null = null;
    // dustIgnitionSelectionInput: String | null = null;
    // dustIgnitionInput: String | null = null;
    // envCondInput: String | null = null;

    /////////////////////////////////////////////
    // public classification: string | null = null;
    // public equipmentTypeSelected: string | null = null;

    // public eplDYDList: AnyObject = {
    //     Mine: [
    //         {
    //             key: 'Ma',
    //             value: 'Ma',
    //         },
    //         {
    //             key: 'Mb',
    //             value: 'Mb',
    //         },
    //     ],
    //     Gas: [
    //         {
    //             key: 'Ga',
    //             value: 'Ga',
    //         },
    //         {
    //             key: 'Gb',
    //             value: 'Gb',
    //         },
    //         {
    //             key: 'Gc',
    //             value: 'Gc',
    //         },
    //     ],
    //     Dust: [
    //         {
    //             key: 'Da',
    //             value: 'Da',
    //         },
    //         {
    //             key: 'Db',
    //             value: 'Db',
    //         },
    //         {
    //             key: 'Dc',
    //             value: 'Dc',
    //         },
    //     ],
    // };

    public startX: number | null = null;
    public startY: number | null = null;
    public startWidth: number | null = null;
    // public startHeight: number | null = null;

    public onBrowserMouseDown(e: any) {
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startWidth = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.width ?? '0', 10);
        // this.startHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.height ?? '0', 10);

        document.documentElement.addEventListener('mousemove', this.doDrag, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag, false);
    }

    doDrag(e: any) {
        this.$refs.areaBrowserElem.style.width = `${this.startWidth + e.clientX - (this.startX ?? 0)}px`;
        // this.$refs.areaBrowserElem.style.height = `${this.startHeight + e.clientY - (this.startY ?? 0)}`;
    }

    stopDrag(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
    }

    public startX1: number | null = null;
    public startY1: number | null = null;
    // public startWidth1: number | null = null;
    public jobListElemHeight: number | null = null;

    public parentHeight: number | null = null;
    public currentHeight: number = 0;

    public onListMouseDown(e: any) {
        this.startX1 = e.clientX;
        this.startY1 = e.clientY;
        // this.startWidth1 = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.width ?? '0', 10);
        this.jobListElemHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.height ?? '0', 10);

        // this.parentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListParentElem)?.height ?? '0', 10);

        this.currentHeight = this.jobListElemHeight + e.clientY - (this.startY1 ?? 0);

        document.documentElement.addEventListener('mousemove', this.doDrag1, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag1, false);
    }

    doDrag1(e: any) {
        // this.$refs.jobListElem.style.width = `${this.startWidth1 + e.clientX - (this.startX1 ?? 0)}px`;
        this.currentHeight = this.jobListElemHeight + e.clientY - (this.startY1 ?? 0);
        this.$refs.jobListElem.style.height = `${this.currentHeight}px`;
    }

    stopDrag1(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag1, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag1, false);
    }

    public inilizeHeights() {
        this.parentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListParentElem)?.height ?? '0', 10);
        if (this.parentHeight > 65) {
            this.parentHeight = this.parentHeight - 64;
        }

        this.jobListElemHeight = this.parentHeight / 2 - 8;
        this.currentHeight = this.jobListElemHeight;
    }

    public startX2: number | null = null;
    public startY2: number | null = null;
    public startHeight2: number | null = null;

    public leftParentHeight: number | null = null;
    public leftCurrentHeight: number | null = null;

    public onLeftListMouseDown(e: any) {
        this.startX2 = e.clientX;
        this.startY2 = e.clientY;
        // this.startWidth1 = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.width ?? '0', 10);
        this.startHeight2 = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobAreaBrowserElem)?.height ?? '0', 10);

        this.leftParentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.height ?? '0', 10);

        this.leftCurrentHeight = this.startHeight2 + e.clientY - (this.startY2 ?? 0);

        document.documentElement.addEventListener('mousemove', this.doDrag2, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag2, false);
    }

    doDrag2(e: any) {
        // this.$refs.jobListElem.style.width = `${this.startWidth1 + e.clientX - (this.startX1 ?? 0)}px`;
        this.leftCurrentHeight = this.startHeight2 + e.clientY - (this.startY2 ?? 0);
        this.$refs.jobAreaBrowserElem.style.height = `${this.leftCurrentHeight}px`;
    }

    stopDrag2(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag2, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag2, false);
    }

    public get equipmentTypes(): Array<any> {
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.equipmentClassification) {
            return this.$formOptions.equipmentTypesList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.equipmentClassification as any] as Array<any>;
        }
        return [];
    }

    // Change to protectionTechniquesSubDYD
    // public get protectionTechniquesDYD(): Array<any> {
    //     if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.protectionTechnique) {
    //         return this.$formOptions.protectionTechniquesDYDList[
    //             this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.protectionTechnique as any
    //         ] as Array<any>;
    //     }
    //     return [];
    // }

    // public get eplSubDYD(): Array<any> {
    //     if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.epl) {
    //         console.log(this.eplDYDList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.epl as any] as Array<any>);
    //         return this.eplDYDList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.epl as any] as Array<any>;
    //     }
    //     return [];
    // }
    ////////////////////////////////////////////

    public mouseDrag: boolean = false;

    public userSession: UserSession = new UserSession();

    public showSearchResults: boolean | false = false;

    public created() {
        this.siteId = this.$route.params['siteId'];
        this.showSearchResults = false;
    }

    public showSearchResultsEvent(state: boolean) {
        this.showSearchResults = state;
    }

    public async mounted() {
        this.siteProjectsSrv.reset();
        this.jobService.reset();

        this.inilizeHeights();

        // this.inspectorService.getInspectors(this.siteId);
        this.siteTeamMembersSrv.getSiteWorkers(this.siteId);
        this.siteTeamMembersSrv.getJobManagers(this.siteId);
        // this.siteTeamMembersSrv.getSafetyManagers(this.siteId);
        // this.siteTeamMembersSrv.getSafetyOfficers(this.siteId);
        // this.siteTeamMembersSrv.getOperationManagers(this.siteId);

        this.getSiteToEdit(this.siteId!);

        this.jobLocationsSrv.getSiteLocations(this.siteId!);
        this.jobLocationsSrv.selectedLcoation = null;

        //call jobs
        this.getJobsDB();

        // try {
        //     if (this.userSession.Session?.accountId) {
        //         // await this.workPermitsSrv.getWorkPermits(this.userSession.Session?.accountId);
        //     }
        // } catch (err) {
        //     console.log(err);
        // }

        let thElm: any = null;
        let startOffset: any = 0;
        Array.prototype.forEach.call(document.querySelectorAll('table th'), function (th) {
            th.style.position = 'relative';

            const grip: any = document.createElement('div');
            grip.innerHTML = '&nbsp;';
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.position = 'absolute';
            grip.style.cursor = 'col-resize';
            grip.addEventListener('mousedown', function (e: any) {
                thElm = th;
                startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
        });
        document.addEventListener('mousemove', e => {
            if (thElm) {
                console.log(startOffset + e.pageX + 'px');
                thElm.style.width = startOffset + e.pageX + 'px';
                //disable text selection of th
                this.mouseDrag = true;
            }
        });
        document.addEventListener('mouseup', () => {
            thElm = undefined;
            this.mouseDrag = false;
        });
    }

    onSelectProjectClick() {
        this.$refs.projectSelectionDialogRef.open();
    }

    public getJobsDB() {
        if (!this.siteProjectsSrv.selectedProject) return;

        this.jobService.getProjectJobs(this.siteProjectsSrv.selectedProject.id!, {
            order: ['sequence'],
            where: {
                siteId: this.siteId,
                projectId: this.siteProjectsSrv.selectedProject.id,
            },
            include: [
                {
                    relation: 'location',
                    scope: {
                        include: [
                            {
                                relation: 'equipmentDetail',
                            },
                            {
                                relation: 'distributionPanel',
                            },
                            {
                                relation: 'switchBoards',
                            },
                            {
                                relation: 'wiringCables',
                                order: ['sequence'],
                            },
                            {
                                relation: 'documentations',
                            },
                        ],
                    },
                },
            ],
        });
    }

    public async drop(event: any) {
        if (!this.siteProjectsSrv.selectedProject) {
            this.AlertSrv.show('warning', '$t.Common.JobSetup.SelectProjectWarning');
            return;
        }
        console.log(event, event.dataTransfer.getData('id'));
        const locationId = event.dataTransfer.getData('id');

        const location = this.jobLocationsSrv.siteLocations.find(x => x.id === locationId);
        if (location?.type === 'equipment' || location?.type === 'distribution') {
            await this.jobService.addNewJobToList(location);
        } else if (location?.type === 'location') {
            await this.recursiveAddLocations(location);
        }
        await this.saveAllJobs();
        this.getJobsDB();
    }

    public async recursiveAddLocations(location: LocationModel) {
        const childLocations = this.jobLocationsSrv.siteLocations.filter(x => x.locationId === location.id);

        for (let i = 0; i < childLocations.length; i++) {
            const loc = childLocations[i];
            console.log(loc);

            if (loc.type === 'equipment' || location?.type === 'distribution') {
                await this.jobService.addNewJobToList(loc);
            } else if (loc?.type === 'location') {
                await this.recursiveAddLocations(loc);
            }
        }
    }

    public async up(index: number) {
        if (index > 0) {
            const old = this.jobService.jobs[index - 1];

            this.jobService.jobs.splice(index - 1, 1);
            this.jobService.jobs.splice(index, 0, old);

            await this.saveAllJobs();
            this.getJobsDB();
        }
    }
    public async down(index: number) {
        if (index < this.jobService.jobs.length - 1) {
            const old = this.jobService.jobs[index];

            this.jobService.jobs.splice(index, 1);
            this.jobService.jobs.splice(index + 1, 0, old);

            await this.saveAllJobs();
            this.getJobsDB();
        }
    }

    public projectSelected(project: ProjectModel) {
        console.log('selected project', project);
        this.getJobsDB();
    }

    public jobClick(job: JobModel) {
        this.siteLocationsSrv.selectedLcoation = null;
        this.jobService.selectedJob = job;

        // this.jobService.getJobSafetyOfficers(job.id!);
        // this.jobService.getJobSafetyManagers(job.id!);
        // this.jobService.getJobOperationManagers(job.id!);
        this.jobService.getJobWorkers(job.id!);
        this.jobService.getJobJobManagers(job.id!);
        this.jobService.getJobNonConformityRecords(job.id!);

        this.adminDataFieldSrv.loadEquipmentFieldOptions();

        // new JobNormalWorkPermitService().getUserJobNormalWorkPermists(job.id!, this.userSession.userId!);
        // this.jobService.getJobAssignedInspectionForms(job.id!);

        this.getFillableInspectionForms();

        // const found = this.jobLocationsSrv.siteLocations.find(x => x.id === job.locationId);

        // if (found) {
        //     this.locationItemClick(found);
        // }

        this.siteLocationsSrv.selectedLcoation = null;

        this.$refs.jobDetailsComponentRef?.onNewJobClick();
    }

    public async getFillableInspectionForms() {
        if (this.jobService.selectedJob) {
            this.LoaderSrv.showFullScreenLoader();
            try {
                await this.jobInspectionFormsSrv.fillableJobsInspectionForms(this.jobService.selectedJob.id!);

                // const promises = [];
                // for (const insf of this.jobInspectionFormsSrv.jobInspectionForms) {
                //     promises.push(this.jobInspectionFormsSrv.loadInspectionFormQuestions(insf.id!));
                // }
                // await Promise.all(promises);
            } catch (error) {
                this.AlertSrv.show('error', '$t.Common.JobSetup.LoadInspectionFormsError');
            } finally {
                this.LoaderSrv.hideFullScreenLoader();
            }
        }
    }

    public allowDrop(ev: any) {
        ev.preventDefault();
    }
    public onDragStart(event: any) {
        event.dataTransfer.setData('id', event.target.id);
    }

    public onDragEnter(event: any) {
        event.preventDefault();
        console.log('drag ener', event.pageX, event.pageY);
    }
    public onDragLeave(event: any) {
        //
    }

    public onMouseEnter(event: any) {
        // console.log(event.pageX, event.pageY);
    }

    public getSiteToEdit(id: string) {
        this.LoaderSrv.showFullScreenLoader();
        new SitesApi()
            .getSiteById(id)
            .subscribe(
                res => {
                    this.siteToEdit = res.Data ?? new SiteModel();
                    this.jobLocationsSrv.site = this.siteToEdit;
                },
                err => {
                    this.AlertSrv.show('error', '$t.Common.JobSetup.LoadSiteError');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public jobEdit(index: number) {
        if ((this.jobService.jobs[index] as any).edit) {
            this.saveAllJobs();
        }
        (this.jobService.jobs[index] as any).edit = !(this.jobService.jobs[index] as any).edit;
        // alert(index);
    }
    public async deleteJob(jobId: string) {
        this.ConfirmSrv.open('$t.Common.JobSetup.DeleteJobConfirmTitle', '$t.Common.JobSetup.DeleteJobConfirmMessage').then(async yes => {
            if (yes) {
                const ind = this.jobService.jobs.findIndex(x => x.id === jobId);
                if (ind !== -1) {
                    this.jobService.jobs.splice(ind, 1);
                    await this.saveAllJobs();
                }
                new JobApi().deleteJob(jobId).subscribe(
                    res => {
                        this.AlertSrv.show('success', '$t.Common.JobSetup.DeleteJobSuccess');
                        this.getJobsDB();
                    },
                    err => {
                        this.AlertSrv.show('error', '$t.Common.JobSetup.DeleteJobError');
                    }
                );
            }
        });
        // alert(index);
    }
    public async saveAllJobs() {
        console.log(this.jobService.jobs);
        this.jobService.jobs.forEach((j, i) => {
            j.sequence = i + 1;
        });
        await this.jobService.updateJobs(this.jobService.jobs);
    }
    // getEvents({start, end}: any) {
    //     const events = [];

    //     const min = new Date(`${start.date}T00:00:00`);
    //     const max = new Date(`${end.date}T23:59:59`);
    //     const days = (max.getTime() - min.getTime()) / 86400000;
    //     const eventCount = this.rnd(days, days + 20);

    //     for (let i = 0; i < eventCount; i++) {
    //         const allDay = this.rnd(0, 3) === 0;
    //         const firstTimestamp = this.rnd(min.getTime(), max.getTime());
    //         const first = new Date(firstTimestamp - (firstTimestamp % 900000));
    //         const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
    //         const second = new Date(first.getTime() + secondTimestamp);

    //         events.push({
    //             name: this.names[this.rnd(0, this.names.length - 1)],
    //             start: first,
    //             end: second,
    //             color: this.colors[this.rnd(0, this.colors.length - 1)],
    //             timed: !allDay,
    //         });
    //     }

    //     this.events = events;
    // }
    // getEventColor(event: any) {
    //     return event.color;
    // }
    rnd(a: any, b: any) {
        return Math.floor((b - a + 1) * Math.random()) + a;
    }

    public goToLocation(locationId: string) {
        const found = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
        found && this.locationItemClick(found);
    }

    public locationItemClick(location: LocationModel, fromSearch?: boolean) {
        this.jobService.selectedJob = null;
        this.locationPath = [];
        let locationId = location.id;
        let foundLocation: LocationModel | undefined = location;

        let locationId1 = location.id;
        let foundLocation1: LocationModel | undefined | null = null;

        this.showSearchResults = false;

        while (locationId1 != null) {
            if (locationId1) {
                foundLocation1 = this.jobLocationsSrv.siteLocations.find(x => x.id === locationId1);
            }
            if (foundLocation1) {
                foundLocation1.expanded = true;
            }
            locationId1 = foundLocation1?.locationId;
        }

        while (locationId != null) {
            if (foundLocation) {
                this.locationPath.push({id: foundLocation.id!, title: foundLocation.title!, type: 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.jobLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.jobLocationsSrv.site) {
            this.locationPath.push({id: this.jobLocationsSrv.site.id!, title: this.jobLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPath = this.locationPath.reverse();

        if (location.type === 'equipment' || location.type === 'distribution') {
            this.siteLocationsSrv.getSiteLocation(location.id!);
        } else {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .getLocationById(location.id!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'documentations',
                        },
                        {
                            relation: 'switchBoards',
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            this.siteLocationsSrv.selectedLcoation = null;
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', '$t.Common.JobSetup.LoadDataError');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }
    public workPermitForms = ['input', 'hot-work', 'confined-space', 'electric-work', 'gass-free'];
    public permitFormsIndex = 0;
    public permitFormsValue = 'input';
    public navigateWorkPermitForms(btn: string) {
        if (this.permitFormsIndex < 5 && this.permitFormsIndex > -1) {
            if (btn === 'next') {
                this.permitFormsIndex++;
            } else {
                this.permitFormsIndex--;
            }
            this.permitFormsValue = this.workPermitForms[this.permitFormsIndex];
        }
    }

    public saveEquipmentDetail() {
        //
    }

    public saveLocationDetail() {
        if (this.siteLocationsSrv.selectedLcoation?.locationDetail) {
            if (!this.siteLocationsSrv.selectedLcoation.locationDetail.id) {
                this.siteLocationsSrv.selectedLcoation.locationDetail.locationId = this.siteLocationsSrv.selectedLcoation.id!;

                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .CreateLocationLocationDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.locationDetail)
                    .subscribe(
                        res => {
                            if (res.Data) {
                                this.AlertSrv.show('success', '$t.Common.JobSetup.SaveLocationDetailsSuccess');
                                this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', '$t.Common.JobSetup.SaveLocationDetailsError');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocationLocationDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.locationDetail)
                    .subscribe(
                        res => {
                            if (res.Data) {
                                this.AlertSrv.show('success', '$t.Common.JobSetup.SaveEquipmentDetailsSuccess');

                                this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', '$t.Common.JobSetup.SaveEquipmentDetailsError');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }
}
