var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mb-2", attrs: { "no-gutters": "" } },
    [
      _c("v-col", { staticClass: "py-2 px-4", attrs: { cols: "4" } }, [
        _c("h4", { staticClass: "text-h6 my-2" }, [
          _vm._v(_vm._s(_vm.$t("FieldsDbPage.EquipmentTab.FieldsList"))),
        ]),
        _c(
          "div",
          { staticClass: "px-3" },
          [
            _c(
              "v-list",
              _vm._l(_vm.equipmentSections, function (section) {
                return _c(
                  "v-list-group",
                  {
                    key: section.key,
                    staticClass: "mb-1 grey lighten-4",
                    attrs: { "no-action": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(section.value)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: section.active,
                      callback: function ($$v) {
                        _vm.$set(section, "active", $$v)
                      },
                      expression: "section.active",
                    },
                  },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass: "pl-3",
                        attrs: { dense: "", color: "transparent" },
                      },
                      [
                        _c(
                          "v-list-item-group",
                          {
                            attrs: { mandatory: "" },
                            model: {
                              value: _vm.adminDataFieldsSrv.selected1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.adminDataFieldsSrv,
                                  "selected1",
                                  $$v
                                )
                              },
                              expression: "adminDataFieldsSrv.selected1",
                            },
                          },
                          _vm._l(
                            _vm.getEquipmentFields(section.key),
                            function (field) {
                              return _c(
                                "v-list-item",
                                {
                                  key: field.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("onFieldClick", field)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(" " + _vm._s(field.title) + " "),
                                        field.required
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "error--text font-weight-bold",
                                              },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.adminDataFieldsSrv.fieldType(
                                              field
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-action", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        field.required
                                          ? _c(
                                              "base-btn",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  "x-small": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.$emit(
                                                      "toggleRequire",
                                                      field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "FieldsDbPage.MarkNotRequired"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !field.required
                                          ? _c(
                                              "base-btn",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  "x-small": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.$emit(
                                                      "toggleRequire",
                                                      field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "FieldsDbPage.MarkRequired"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("v-icon", [
                                          _vm._v("mdi-chevron-right"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("v-col", { staticClass: "py-2 px-4", attrs: { cols: "8" } }, [
        _c("div", { staticClass: "d-flex justify-space-between" }, [
          _c("h4", { staticClass: "text-h6 my-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("FieldsDbPage.EquipmentTab.FieldOptions")) +
                " "
            ),
          ]),
          _c(
            "span",
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    color: "admin-primary ",
                    disabled: _vm.adminDataFieldsSrv.selectedField === null,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("addFieldOption", "equipment")
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("FieldsDbPage.AddOption")) + " "),
                  _c("v-icon", { staticClass: "ml-1" }, [
                    _vm._v("mdi-plus-circle-outline"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.adminDataFieldsSrv.selectedField &&
        _vm.adminDataFieldsSrv.selectedField.dependOnFieldId
          ? _c(
              "div",
              [
                _c("base-autocomplete", {
                  staticClass: "flex-grow-1",
                  attrs: {
                    name: "dependentField",
                    items:
                      _vm.adminDataFieldsSrv.selectedFieldOptions[
                        _vm.adminDataFieldsSrv.selectedField.dependOnFieldId
                      ],
                    value: _vm.adminDataFieldsSrv.dependentFieldOption,
                    "item-text": "value",
                    "item-value": "id",
                    label: "Dependent Field",
                    clearable: "",
                    "return-object": false,
                  },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(
                        _vm.adminDataFieldsSrv,
                        "dependentFieldOption",
                        $event
                      )
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c(
              "div",
              [
                _c("v-progress-linear", {
                  attrs: { indeterminate: "", active: _vm.sortingLoader },
                }),
              ],
              1
            ),
            _vm.adminDataFieldsSrv.selectedField?.id &&
            !_vm.adminDataFieldsSrv.selectedField.dependOnFieldId
              ? _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "Container",
                      {
                        attrs: {
                          "drag-handle-selector": ".drag-handler",
                          "get-child-payload": _vm.getChildPayload,
                        },
                        on: { drop: _vm.onDrop },
                      },
                      _vm._l(_vm.getFieldOptions, function (opt) {
                        return _c(
                          "Draggable",
                          { key: opt.id },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { link: "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "drag-handler" },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-drag-horizontal-variant"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(opt.value) + " "),
                                    ]),
                                    opt.regressPriority != null
                                      ? _c("v-list-item-subtitle", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "FieldsDbPage.RegressPriority"
                                              )
                                            ) +
                                              " " +
                                              _vm._s(opt.regressPriority)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-list-item-action", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "editOption",
                                                "area",
                                                opt
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "blue lighten-1",
                                              },
                                            },
                                            [_vm._v("mdi-pencil-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "deleteOption",
                                                "area",
                                                opt.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "red lighten-1" },
                                            },
                                            [_vm._v("mdi-delete-empty")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.adminDataFieldsSrv.selectedField?.id &&
            _vm.adminDataFieldsSrv.selectedField.dependOnFieldId &&
            _vm.adminDataFieldsSrv.dependentFieldOption
              ? _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "Container",
                      {
                        attrs: {
                          "drag-handle-selector": ".drag-handler",
                          "get-child-payload": _vm.getChildPayloadDependent,
                        },
                        on: { drop: _vm.onDropDependent },
                      },
                      _vm._l(_vm.getDependentOptions, function (opt) {
                        return _c(
                          "Draggable",
                          { key: opt.id },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { link: "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "drag-handler" },
                                  [_c("v-icon", [_vm._v("mdi-drag")])],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(opt.value) + " "),
                                    ]),
                                    opt.regressPriority != null
                                      ? _c("v-list-item-subtitle", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FieldsDbPage.RegressPriority"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(opt.regressPriority) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-list-item-action", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "editOption",
                                                "area",
                                                opt
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "blue lighten-1",
                                              },
                                            },
                                            [_vm._v("mdi-pencil-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "deleteOption",
                                                "area",
                                                opt.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "red lighten-1" },
                                            },
                                            [_vm._v("mdi-delete-empty")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("v-divider"),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }