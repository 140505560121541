import {ServiceClass} from '@/decorators';
import {InspectionFormQuestionModel, InspectionQuestionFieldOptionsModel, InspepctionFormModel} from '@/sdk';
import {InspectionFormQuestionsApi} from '@/sdk/api-services';
import {JobApi} from '@/sdk/api-services/job.api';
import {nameof} from 'ts-simple-nameof';

@ServiceClass()
export class JobsInspectionFormsService {
    public jobInspectionForms: Array<InspepctionFormModel> = [];

    public inspectionFormQuestions: {[key: string]: Array<InspectionFormQuestionModel>} = {};
    public inspectionFormQuestionsAgainsJob: {[key: string]: Array<InspectionFormQuestionModel>} = {};

    public jobsApi: JobApi = new JobApi();
    public inspectionFormQuestionsApi: InspectionFormQuestionsApi = new InspectionFormQuestionsApi();

    public async fillableJobsInspectionForms(jobId: string) {
        return this.jobsApi.fillableInspectionForms(jobId).then(x => {
            this.jobInspectionForms = x ?? [];

            return x;
        });
    }

    public findInspectionFormById(id: string) {
        return this.jobInspectionForms.find(x => x.id === id) ?? null;
    }

    public async loadInspectionFormQuestions(inspectionFormId: string) {
        return this.inspectionFormQuestionsApi
            .getInspectionFormQuestionsAsync(inspectionFormId, {
                include: [
                    {
                        relation: 'inspectionQuestionFieldOptions',
                        scope: {
                            order: `${nameof<InspectionQuestionFieldOptionsModel>(x => x.sortOrder)} ASC`,
                        },
                    },
                ],
            })
            .then(x => {
                this.inspectionFormQuestions[inspectionFormId] = x ?? [];

                return x;
            });
    }

    public loadInspectionFormQuestionsAgainsJob(inspectionFormId: string, jobId: string, userId: string) {
        return this.inspectionFormQuestionsApi
            .getInspectionFormQuestionsAgainsJobAsync(inspectionFormId, jobId, {
                include: [
                    {
                        relation: 'inspectionQuestionFieldOptions',
                        scope: {
                            order: `${nameof<InspectionQuestionFieldOptionsModel>(x => x.sortOrder)} ASC`,
                        },
                    },
                    {
                        relation: 'inspectionFormQuestionAnswers',
                        scope: {
                            where: {
                                jobId,
                                userId,
                            },
                        },
                    },
                ],
            })
            .then(x => {
                for (const q of x) {
                    if (!q.inspectionFormQuestionAnswers) {
                        q.inspectionFormQuestionAnswers = [{answer: null, severity: null, comment: null}];
                    }
                }
                this.inspectionFormQuestionsAgainsJob = {...this.inspectionFormQuestionsAgainsJob, [inspectionFormId]: x ?? []};

                return x;
            });
    }
}
