export class AccountViewModel {
    public id?: string | null = null;

    accountTitle?: string | null = null;

    public accountType?: string | null = null;
    public accountStatus?: string | null = null;
    public accountStartDate?: string | null = null;
    public accountEndDate?: string | null = null;
    public licenseType?: string | null = null;
    public licenseStartDate?: string | null = null;
    public licenseEndDate?: string | null = null;
    public licenseStatus?: string | null = null;

    public paymentMethod?: string | null = null;
    public paymentStatus?: string | null = null;

    public contractualContactName?: string | null = null;
    public contractualContactEmail?: string | null = null;
    public contractualContactPhone?: string | null = null;
    public contractualContactMobilePhone?: string | null = null;

    public created?: string | null = null;
    public updated?: string | null = null;

    constructor(data?: Partial<AccountViewModel>) {
        Object.assign(this, data);
    }
}
