import VueWrapper from '@/components/core/Vue/vue.wrapper';
import SaveWrapperComponent from '@/components/shared/save-wrapper/save-wrapper.component';
import {ACCOUNT_STATUS, KeyValuePair, PaymentMethodsList} from '@/globals';
import {SessionModel, SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {AccountService, RolesService, UserSetupService} from '@/views/shared/services';
import SetPasswordDialogComponent from '@/views/user-setup/set-password-dialog/set-password-dialog.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    components: {
        SetPasswordDialogComponent,
        // AccountOrganizationsComponent,
    },
})
export default class AccountInfoDialogComponent extends VueWrapper {
    public $refs!: {
        setPasswordRef: SetPasswordDialogComponent;
        saveWraperRef: SaveWrapperComponent;
    };

    @Prop({
        type: String,
        default: () => {
            return 'account-info-dialog';
        },
    })
    public readonly name!: string;

    @Prop({
        type: String,
        default: () => {
            return 'save';
        },
    })
    public readonly type!: string;

    public rolesSrv: RolesService = new RolesService();
    public sitesApi: SitesApi = new SitesApi();

    public accountSrv: AccountService = new AccountService();

    public paymentMethods: Array<KeyValuePair> = PaymentMethodsList;

    public userSetupSrv: UserSetupService = new UserSetupService();
    public sites: Array<SiteModel> = [];

    public session: SessionModel | null = new SessionModel();

    public activeTab: string = 'accountInfo';

    public mounted() {
        this.UserSessionSrv._session.subscribe(session => {
            this.session = session;
        });
    }

    public get accountStatuses() {
        return Object.keys(ACCOUNT_STATUS);
    }

    public onPassword(password: string) {
        this.userSetupSrv.userToEdit.password = password;
    }

    public get isProfile() {
        return this.type === 'profile';
    }

    public loadUserAccount() {
        if (this.session) {
            this.LoaderSrv.showFullScreenLoader('$t.Dialogs.AccountInfo.LoadingUserData');
            this.accountSrv
                .loadUserAccount(this.session.userId!)
                .then(x => {})
                .catch(err => {
                    console.log(err);
                    this.AlertSrv.show('error', '$t.Dialogs.AccountInfo.LoadingUserDataError');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public async save() {
        if (await this.accountSrv.save()) {
            this.loadUserAccount();
            this.close();
        }
    }

    public reset() {
        this.accountSrv.reset();
        this.$refs.saveWraperRef.reset();
    }

    public open() {
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }

        this.reset();

        this.loadUserAccount();
        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        this.CoreSrv.CloseModal(this.name);
    }

    public clearForm() {
        this.reset();
        this.close();
    }
}
