var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { staticClass: "height-100x", style: _vm.cssProps },
    [
      _c("confirm-component"),
      _c("router-view"),
      _c(
        "v-fade-transition",
        [
          _c(
            "v-overlay",
            {
              attrs: {
                value: _vm.LoaderSrv.FullScreenLoader,
                "z-index": "300",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("v-progress-linear", {
                    attrs: { indeterminate: "", color: "white", rounded: "" },
                  }),
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(_vm.LoaderSrv.FullScreenLoaderMessage),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }