var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.siteLocationsSrv.selectedLcoation
    ? _c(
        "div",
        { staticClass: "d-flex flex-column overflow-hidden height-100x" },
        [
          _c("div", { staticClass: "grey lighten-3 mb-4 flex-shrink-0" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center results-header" },
              [
                _c(
                  "div",
                  { staticClass: "px-5" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "results-header-h1",
                        staticStyle: { width: "500px" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex",
                            attrs: { lg: "12", cols: "6" },
                          },
                          [
                            _c("v-img", {
                              staticClass: "area-search header-img-title",
                              attrs: {
                                height: "20",
                                width: "20",
                                contain: "",
                                src: require("@/assets/icons/equipment-light.png"),
                              },
                            }),
                            _c("h2", { staticClass: "areaTitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "SiteComponents.EquipmentDetails.Title"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      _vm._l(_vm.locationPath, function (path, index) {
                        return _c(
                          "div",
                          { key: path.id, staticClass: "d-flex" },
                          [
                            path.type === "site" && _vm.diagramViewStyle
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: "/images/plant.png",
                                  },
                                })
                              : _vm._e(),
                            path.type === "site" && !_vm.diagramViewStyle
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/plant-light.png"),
                                  },
                                })
                              : _vm.diagramViewStyle &&
                                path.type === "location" &&
                                index === _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "20",
                                    width: "22",
                                    contain: "",
                                    src: "/images/equip.png",
                                  },
                                })
                              : !_vm.diagramViewStyle &&
                                path.type === "location" &&
                                index === _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/equipment-light.png"),
                                  },
                                })
                              : _vm.diagramViewStyle &&
                                path.type === "location" &&
                                index < _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: "/images/area.png",
                                  },
                                })
                              : !_vm.diagramViewStyle &&
                                path.type === "location" &&
                                index < _vm.locationPath.length - 1
                              ? _c("v-img", {
                                  staticClass: "area-search header-img-title",
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("@/assets/icons/area-light.png"),
                                  },
                                })
                              : _vm._e(),
                            path.type === "site"
                              ? _c("h4", { staticClass: "header-title-text" }, [
                                  _vm._v(_vm._s(path.title)),
                                ])
                              : _c(
                                  "span",
                                  {
                                    staticClass: "cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "goToLocation",
                                          path.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "header-title-text" },
                                      [_vm._v(_vm._s(path.title))]
                                    ),
                                  ]
                                ),
                            index !== _vm.locationPath.length - 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "header-title-text next-arrow",
                                    staticStyle: {
                                      color: "white",
                                      padding: "0px 5px",
                                    },
                                  },
                                  [_vm._v(" > ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
                !_vm.readOnlyFlag
                  ? _c(
                      "div",
                      { staticClass: "px-3" },
                      [
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              border: "1px solid white !important",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("saveEquipmentDetail")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Common.Save")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.diagramViewStyle
                  ? _c(
                      "div",
                      { staticClass: "px-3" },
                      [
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              padding: "0 !important",
                              border: "none",
                              height: "auto",
                              "min-width": "auto",
                              "background-color": "white !important",
                              "box-shadow": "none",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("fullPageFormView", true)
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                height: "16",
                                width: "16",
                                contain: "",
                                src: "/images/enlarge.png",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "base-btn",
                          {
                            staticStyle: {
                              padding: "0 !important",
                              border: "none",
                              height: "auto",
                              "min-width": "auto",
                              "background-color": "white !important",
                              "box-shadow": "none",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("goToLocation", null)
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                height: "36",
                                width: "36",
                                contain: "",
                                src: "/images/minus.png",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.siteLocationsSrv.locationDetailDataFieldStatuses.length > 0
              ? _c(
                  "div",
                  { staticClass: "warning-box" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SiteComponents.EquipmentDetails.WarningCountMsg",
                            {
                              count:
                                _vm.siteLocationsSrv
                                  .locationDetailDataFieldStatuses.length,
                            }
                          )
                        ) + " "
                      ),
                    ]),
                    _c(
                      "base-btn",
                      {
                        attrs: { color: "#e17e2c", outlined: "", small: "" },
                        on: { click: _vm.summaryClick },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Common.Details")) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.siteLocationsSrv.selectedLcoation.equipmentDetail &&
          _vm.siteLocationsSrv.selectedLcoation.equipmentDetail.id
            ? _c(
                "div",
                {
                  key: "siteLocationsSrv.selectedLcoation.equipmentDetail.id || 0",
                  staticClass:
                    "flex-grow-1 flex-1 overflow-auto d-flex detailed-forms info-tabs-header",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col col-12" },
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "my-tabs min-height-100",
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            vertical: _vm.diagramViewStyle ? false : true,
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("General Information")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Parent Area Information")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Tag Management")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Equipment Certification")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Digital Twin")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Wiring Entry")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Inspection History")]
                          ),
                          !_vm.diagramViewStyle
                            ? _c(
                                "v-tab",
                                {
                                  attrs: { "active-class": "side-tab-active" },
                                },
                                [_vm._v("Documentation")]
                              )
                            : _vm._e(),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Note")]
                          ),
                          !_vm.readOnlyFlag && _vm.diagramViewStyle
                            ? _c(
                                "div",
                                {
                                  staticClass: "px-3",
                                  staticStyle: { "margin-left": "auto" },
                                },
                                [
                                  _c(
                                    "base-btn",
                                    {
                                      staticStyle: {
                                        border: "1px solid white !important",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "saveEquipmentDetail"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab-item",
                            [
                              _vm.siteLocationsSrv.selectedLcoation
                                .equipmentDetail
                                ? _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .title,
                                                      name: "areaName",
                                                      disabled: "",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.EquipmentName.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation,
                                                          "title",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      readonly: "",
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value: _vm.locationPath
                                                        .map((loc) => loc.title)
                                                        .join(" > "),
                                                      name: "locationName",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Location.Label"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "Classification",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentEquipmentClassification
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .equipmentClassification,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.EquipmentClassification.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "equipmentClassification",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "equipmentFieldEquipmentAssociatedApparatus",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentFieldEquipmentAssociatedApparatus
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .fieldEquipmentAssociatedApparatus,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.FieldEquipmentAssociatedApparatus.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "fieldEquipmentAssociatedApparatus",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .manufacturer,
                                                      name: "equipManufacturer",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Manufacturer.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "manufacturer",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .supplier,
                                                      name: "equipSupplier",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Supplier.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "supplier",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .modelNo,
                                                      name: "equipModelNo",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ModelNo.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "modelNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .serialNo,
                                                      name: "equipSerialNo",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.SerialNo.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "serialNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "divisionForRiskLevel",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentDivisionForRiskLevel
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .divisionForRiskLevel,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.DivisionForRiskLevel.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "divisionForRiskLevel",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-date-picker", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.InstalledDate.Label"
                                                      ),
                                                      name: "installedDate",
                                                      format: true,
                                                      formatStr: "yyyy-MM-dd",
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .installedDate,
                                                      max: _vm.$helpers.formatDate(
                                                        new Date().toISOString()
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "installedDate",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "text-h5" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SiteComponents.EquipmentDetails.ElectricalPowerInfo"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "powerType",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentPowerType
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .powerType,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.PowerType.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "powerType",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "powerType",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentACDC
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail.ACDC,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ACDC.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "ACDC",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .phasesf,
                                                      name: "phasesf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Phases.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "phasesf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .converterNamef,
                                                      name: "converterName",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ConverterName.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "converterNamef",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .voltageVf,
                                                      name: "voltageVf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.VoltageV.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "voltageVf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .frequencyHzf,
                                                      name: "frequencyHzf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.FrequencyHz.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "frequencyHzf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .minVoltageVf,
                                                      name: "minVoltageVf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MinVoltageV.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "minVoltageVf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .minFrequencyHzf,
                                                      name: "minFrequencyHzf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MinFrequencyHz.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "minFrequencyHzf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .maxVoltageVf,
                                                      name: "maxVoltageVf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MaxVoltageV.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "maxVoltageVf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .maxFrequencyHzf,
                                                      name: "maxFrequencyHzf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MaxFrequencyHz.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "maxFrequencyHzf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      !_vm.siteLocationsSrv.selectedLcoation
                                        .locationId
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-h4 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SiteComponents.EquipmentDetails.NoParentAreaInfo"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.siteLocationsSrv.selectedLcoation
                                            .parentLocation
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      name: "areaID",
                                                      disabled: "",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.AreaID.Label"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation,
                                                          "locationId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.locationId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      name: "title",
                                                      disabled: "",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.AreaTitle.Label"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.siteLocationsSrv
                                                .selectedLcoation
                                                .parentLocation &&
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.parentLocation
                                                .locationDetail
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        staticStyle: {
                                                          color: "#fdf7e7",
                                                        },
                                                        attrs: {
                                                          name: "lowAmbientTemp",
                                                          disabled: "",
                                                          label: _vm.$t(
                                                            "Fields.AreaFields.LowAmbientTemperature.Label"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .parentLocation
                                                              .locationDetail
                                                              .lowAmbientTemp,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .parentLocation
                                                                .locationDetail,
                                                              "lowAmbientTemp",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.lowAmbientTemp",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.siteLocationsSrv
                                                .selectedLcoation
                                                .parentLocation &&
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.parentLocation
                                                .locationDetail
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        staticStyle: {
                                                          color: "#fdf7e7",
                                                        },
                                                        attrs: {
                                                          name: "highAmbientTemp",
                                                          disabled: "",
                                                          label: _vm.$t(
                                                            "Fields.AreaFields.HighAmbientTemperature.Label"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .parentLocation
                                                              .locationDetail
                                                              .highAmbientTemp,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .parentLocation
                                                                .locationDetail,
                                                              "highAmbientTemp",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.highAmbientTemp",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.siteLocationsSrv
                                                .selectedLcoation
                                                .parentLocation &&
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.parentLocation
                                                .locationDetail
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        attrs: {
                                                          dense: "",
                                                          outlined: "",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .areaFieldOptions[
                                                              _vm.areaFieldsList
                                                                .areaIpx
                                                            ],
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label: _vm.$t(
                                                            "Fields.AreaFields.Ipx.Label"
                                                          ),
                                                          "return-object": false,
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .parentLocation
                                                              .locationDetail
                                                              .ipx,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .parentLocation
                                                                .locationDetail,
                                                              "ipx",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.ipx",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.siteLocationsSrv
                                                .selectedLcoation
                                                .parentLocation &&
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.parentLocation
                                                .locationDetail
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        attrs: {
                                                          dense: "",
                                                          outlined: "",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .areaFieldOptions[
                                                              _vm.areaFieldsList
                                                                .areaIpy
                                                            ],
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label: _vm.$t(
                                                            "Fields.AreaFields.Ipy.Label"
                                                          ),
                                                          "return-object": false,
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .parentLocation
                                                              .locationDetail
                                                              .ipy,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .parentLocation
                                                                .locationDetail,
                                                              "ipy",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.ipy",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation &&
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation.locationDetail
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaGasArea
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.GasArea.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .gasZone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "gasZone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasZone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation &&
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation.locationDetail
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaGasGroup
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.GasGroup.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .gasGroup,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "gasGroup",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasGroup",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaGasTemperatureClass
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.GasTempClass.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .gasTempClass,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "gasTempClass",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.gasTempClass",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation &&
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation.locationDetail
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaDustArea
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.DustArea.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .dustZone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "dustZone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustZone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation &&
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation.locationDetail
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .areaFieldOptions[
                                                          _vm.areaFieldsList
                                                            .areaDustGroup
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.DustGroup.Label"
                                                      ),
                                                      "return-object": false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .dustgroup,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "dustgroup",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustgroup",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      name: "dustCloudIgnitionTemp",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.DustCloudIgnitionTemperature.Label"
                                                      ),
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .dustCloudIgnitionTemp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "dustCloudIgnitionTemp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustCloudIgnitionTemp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation &&
                                      _vm.siteLocationsSrv.selectedLcoation
                                        .parentLocation.locationDetail
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      name: "dustLayerDepth",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.DustLayerDepth.Label"
                                                      ),
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .dustLayerDepth,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "dustLayerDepth",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustLayerDepth",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      name: "dustLayerIgnitionTemp",
                                                      label: _vm.$t(
                                                        "Fields.AreaFields.DustLayerIgnitionTemperature.Label"
                                                      ),
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .parentLocation
                                                          .locationDetail
                                                          .dustLayerIgnitionTemp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .parentLocation
                                                            .locationDetail,
                                                          "dustLayerIgnitionTemp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "siteLocationsSrv.selectedLcoation.parentLocation.locationDetail.dustLayerIgnitionTemp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _vm.siteLocationsSrv.selectedLcoation.id
                                ? _c("TagManagementComponent", {
                                    key: _vm.siteLocationsSrv.selectedLcoation
                                      .id,
                                    attrs: {
                                      siteLocationId:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .id,
                                      readOnlyFlag: _vm.readOnlyFlag,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _vm.siteLocationsSrv.selectedLcoation
                                .equipmentDetail
                                ? _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "Certification Scheme",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentCertificationScheme
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .certificationScheme,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.CertificationScheme.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: true,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentCertificationScheme"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentCertificationScheme"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "certificationScheme",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .certificationNumber,
                                                      name: "certificationNumber",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.CertificationNumber.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "certificationNumber",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .suffixf,
                                                      name: "suffixf",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Suffix.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "suffixf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .lowAmbientTemperature,
                                                      name: "equipmentLowAmbientTemp",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.LowAmbientTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentLowAmbientTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentLowAmbientTemperature"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "lowAmbientTemperature",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .highAmbientTemperature,
                                                      name: "equipmentHighAmbientTemp",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.HighAmbientTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentHighAmbientTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentHighAmbientTemperature"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "highAmbientTemperature",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .lowServiceTemperature,
                                                      name: "equipmentLowServiceTemp",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.LowServiceTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentLowServiceTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentLowServiceTemperature"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "lowServiceTemperature",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .highServiceTemperature,
                                                      name: "equipmentHighServiceTemp",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.HighServiceTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentHighServiceTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentHighServiceTemperature"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "highServiceTemperature",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: {
                                                value:
                                                  _vm.siteLocationsSrv
                                                    .selectedLcoation
                                                    .equipmentDetail
                                                    .rowRadioSingleToComnine,
                                                row: "",
                                                disabled: _vm.readOnlyFlag,
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "Fields.EquipmentFields.SingleOrCombined.Label"
                                                  ),
                                                  value: "single-or-combined",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onRadioClick(
                                                      "single-or-combined"
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "Fields.EquipmentFields.MultipleTypesOfProtection.Label"
                                                  ),
                                                  value:
                                                    "multiple-types-of-protection",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onRadioClick(
                                                      "multiple-types-of-protection"
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "Fields.EquipmentFields.TwoIndependentGBTypes.Label"
                                                  ),
                                                  value:
                                                    "two-independent-gb-types",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onRadioClick(
                                                      "two-independent-gb-types"
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "Fields.EquipmentFields.BoundryWall.Label"
                                                  ),
                                                  value: "boundry-wall",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onRadioClick(
                                                      "boundry-wall"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "Protection Technique",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentProtectionTechnique
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .protectionTechnique,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ProtectionTechnique.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: true,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentProtectionTechnique"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentProtectionTechnique"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "protectionTechnique",
                                                          $event
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.siteLocationsSrv.selectedLcoation.equipmentDetail.protectionTechniqueSub =
                                                          []
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      name: "Protection Technique EPL",
                                                      items:
                                                        _vm.adminDataFieldsSrv.getDependentItemsOf(
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentProtectionTechniqueEpl,
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentProtectionTechnique
                                                        ),
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .protectionTechniqueSub,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ProtectionTechniqueEPL.Label"
                                                      ),
                                                      clearable: "",
                                                      disabled:
                                                        !_vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .protectionTechnique ||
                                                        _vm.readOnlyFlag,
                                                      multiple: true,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentProtectionTechniqueEpl"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentProtectionTechniqueEpl"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "protectionTechniqueSub",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentInspectionFrequency
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .inspectionFrequency,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.EquipmentInspectionFrequency.Label"
                                                      ),
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "inspectionFrequency",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "EPL (Gas)",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentEplGas
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.EplGas.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .ePLGas,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentEplGas"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentEplGas"
                                                        ),
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "ePLGas",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "Gas group",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentGasGroup
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.GasGroup.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .gasGroupf,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentGasGroup"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentGasGroup"
                                                        ),
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "gasGroupf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "EPL (Dust)",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentEplDust
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.EplDust.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .ePLDust,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentEplDust"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentEplDust"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "ePLDust",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "Dust Group",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentDustGroup
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      rules: "required",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.DustGroup.Label"
                                                      ),
                                                      clearable: "",
                                                      multiple: false,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .dustGroupf,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentDustGroup"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentDustGroup"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "dustGroupf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      name: "equipmentGasTemperatureClass",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentGasTemperatureClass
                                                        ],
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .gasTemperatureClass,
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.GasTempClass.Label"
                                                      ),
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentGasTemperatureClass"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentGasTemperatureClass"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "gasTemperatureClass",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      dense: "",
                                                      outlined: "",
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .maxSurfaceTempf,
                                                      name: "maximumSurfaceTemperature",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MaxSurfaceTemperature.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentMaxSurfaceTemperature"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentMaxSurfaceTemperature"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "maxSurfaceTempf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentIpx
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail.ipx,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Ipx.Label"
                                                      ),
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentIpx"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentIpx"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "ipx",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      dense: "",
                                                      outlined: "",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentIpy
                                                        ],
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail.ipy,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.Ipy.Label"
                                                      ),
                                                      multiple: true,
                                                      limit: 2,
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentIpy"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentIpy"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "ipy",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .testDustLayerf,
                                                      name: "maximumSurfaceTemperature",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.TestingDustLayerDepth.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentTestingDustLayerDepth"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentTestingDustLayerDepth"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "testDustLayerf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .maxSurfaceTempWithDustLayerCf,
                                                      name: "maximumSurfaceTemperature",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.MaxSurfaceTemperatureWithDustLayer.Label"
                                                      ),
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentMaxSurfaceTempWithDustLayer"
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentMaxSurfaceTempWithDustLayer"
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "maxSurfaceTempWithDustLayerCf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .specialConditionsUsef,
                                                      name: "specialconditionsuse",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.SpecialConditionForUse.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "specialConditionsUsef",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .exComponentInformationf,
                                                      name: "exComponentInformation",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ExComponentInformation.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "exComponentInformationf",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .warningMarkingInformation,
                                                      name: "warningMarkingInformation",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.WarningMarkingInformation.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "warningMarkingInformation",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.readOnlyFlag,
                                                      value:
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .equipmentDetail
                                                          .exMarketing,
                                                      name: "equipExMarking",
                                                      label: _vm.$t(
                                                        "Fields.EquipmentFields.ExMarking.Label"
                                                      ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .equipmentDetail,
                                                          "exMarketing",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "eqp-classification",
                                              attrs: { rounded: "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h6",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Miscellaneous Certification"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-sheet",
                                                        {
                                                          staticClass:
                                                            "eqp-classification-inner mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "v-tabs",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.selectedMiscCertification,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedMiscCertification =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectedMiscCertification",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    "active-class":
                                                                      "tab-cable-active",
                                                                    "tab-value":
                                                                      "IntrinsicSafety",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `Fields.EquipmentFields.IntrinsicSafety.Label`
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    "active-class":
                                                                      "tab-cable-active",
                                                                    "tab-value":
                                                                      "MotorRotatingElectricMachine",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `Fields.EquipmentFields.MotorRotatingElectricMachine.Label`
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    "active-class":
                                                                      "tab-cable-active",
                                                                    "tab-value":
                                                                      "ExPInformation",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `Fields.EquipmentFields.ExPInformation.Label`
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    "active-class":
                                                                      "tab-cable-active",
                                                                    "tab-value":
                                                                      "ExeCurrentTransformer",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `Fields.EquipmentFields.ExeCurrentTransformer.Label`
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    "active-class":
                                                                      "tab-cable-active",
                                                                    "tab-value":
                                                                      "ExeGeneralPurposeJunctionBox",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `Fields.EquipmentFields.ExeGeneralPurposeJunctionBox.Label`
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tabs-items",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.selectedMiscCertification,
                                                    callback: function ($$v) {
                                                      _vm.selectedMiscCertification =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedMiscCertification",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ExeGeneralPurposeJunctionBox",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "background-color":
                                                                            "#1c2f53",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "SiteComponents.EquipmentDetails.GeneralPurposeJunctionBox"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGmaxRatedPower,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MaxRatedPower.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGmaxRatedPower",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGarrangedMethod,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.DefinedArrangedMethod.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGarrangedMethod",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGterminalSize,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.TerminalSize.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGterminalSize",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGpermissibleConductorNumber,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.PermissibleNumberConductor.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGpermissibleConductorNumber",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGpermissibleConductorSize,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.PermissibleSizeConductor.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGpermissibleConductorSize",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEGmaxCurrent,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MaxCurrent.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEGmaxCurrent",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "ExeCurrentTransformer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "background-color":
                                                                            "#1c2f53",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "SiteComponents.EquipmentDetails.ExeCurrentTransformer"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exEshortCircuitCurrent,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ShortCircuitCurrent.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exEshortCircuitCurrent",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value: "ExPInformation",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "background-color":
                                                                            "#1c2f53",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "SiteComponents.EquipmentDetails.ProtectionTypeExP"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPminPurgeFlow,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MinPurgeFlow.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPminPurgeFlow",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPminPurgeDurartion,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MinPurgeDuration.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPminPurgeDurartion",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPtypeOfProtectiveGas,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.TypeOfProtectiveGas.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPtypeOfProtectiveGas",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPminOverpressure,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MinOverpressure.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPminOverpressure",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPmaxOverpressure,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MaxOverpressure.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPmaxOverpressure",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPminSupplyPressure,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MinSupplyPressure.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPminSupplyPressure",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPmaxSupplyPressure,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MaxSupplyPressure.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPmaxSupplyPressure",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPmaxLeakageRate,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.MaxLeakageRate.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPmaxLeakageRate",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPrangeOfTemprature,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.RangeOfTemperatureForProtectiveGas.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPrangeOfTemprature",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPpointsOfPressure,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.PointsOfPressure.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPpointsOfPressure",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      dense:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      items:
                                                                                        _vm.selectYesNo,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .exPinternalSourceOfRelease,
                                                                                      "item-text":
                                                                                        "value",
                                                                                      "item-value":
                                                                                        "key",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.InternalSourceOfRelease.Label"
                                                                                        ),
                                                                                      "return-object": false,
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "exPinternalSourceOfRelease",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .equipmentDetail
                                                                        .exPinternalSourceOfRelease ===
                                                                      "yes"
                                                                        ? _c(
                                                                            "v-row",
                                                                            {
                                                                              staticClass:
                                                                                "center-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .exPmaxInletPressure,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.MaxInletPressure.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "exPmaxInletPressure",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .exPmaxFlowRate,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.MaxFlowRate.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "exPmaxFlowRate",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "MotorRotatingElectricMachine",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "background-color":
                                                                            "#1c2f53",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "SiteComponents.EquipmentDetails.MotorRotatingMachine"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      dense:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      items:
                                                                                        _vm.selectYesNo,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mOperatedConvertor,
                                                                                      "item-text":
                                                                                        "value",
                                                                                      "item-value":
                                                                                        "key",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.OperatedWithConverter.Label"
                                                                                        ),
                                                                                      "return-object": false,
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mOperatedConvertor",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .equipmentDetail
                                                                        .mOperatedConvertor ===
                                                                      "yes"
                                                                        ? _c(
                                                                            "v-row",
                                                                            {
                                                                              staticClass:
                                                                                "center-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .mSpeedRangeLimitLow,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.SpeedRangeLimitLow.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "mSpeedRangeLimitLow",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .mSpeedRangeLimitHigh,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.SpeedRangeLimitHigh.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "mSpeedRangeLimitHigh",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .equipmentDetail
                                                                        .mOperatedConvertor ===
                                                                      "yes"
                                                                        ? _c(
                                                                            "v-row",
                                                                            {
                                                                              staticClass:
                                                                                "center-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .mFrequencyRangeLimitLow,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.FrequencyRangeLimitLow.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "mFrequencyRangeLimitLow",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .mFrequencyRangeLimitHigh,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.FrequencyRangeLimitHigh.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "mFrequencyRangeLimitHigh",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mSwitchingFrequency,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.SwitchingFrequency.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mSwitchingFrequency",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      dense:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      items:
                                                                                        _vm.selectKhzMhz,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mSwitchingFrequencyUnit,
                                                                                      "item-text":
                                                                                        "value",
                                                                                      "item-value":
                                                                                        "id",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.SwitchingFrequencyUnit.Label"
                                                                                        ),
                                                                                      "return-object": false,
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mSwitchingFrequencyUnit",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      dense:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      items:
                                                                                        _vm.selectTorqueType,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mTypeOfTorque,
                                                                                      "item-text":
                                                                                        "value",
                                                                                      "item-value":
                                                                                        "id",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.TypeOfTorque.Label"
                                                                                        ),
                                                                                      "return-object": false,
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mTypeOfTorque",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mSpecificConverter,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.SpecificConverter.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mSpecificConverter",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mTypeOfConverter,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.TypeOfConverter.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mTypeOfConverter",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mExEbMotor,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ExEbMotor.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mExEbMotor",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mIaIn,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.IAIN.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mIaIn",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .mTeS,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.TeS.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "mTeS",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value:
                                                          "IntrinsicSafety",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "background-color":
                                                                            "#1c2f53",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "SiteComponents.EquipmentDetails.IntrinsicSafety"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center-column",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#1c2f53",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Field Equipment"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center-column yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#1c2f53",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Core"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center-column",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#1c2f53",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Associated Apparatus"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center-column yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldManufacturer,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.Manufacturer.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldManufacturer",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableManfacturer,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.Manufacturer.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableManfacturer",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusManufaturer,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.Manufacturer.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusManufaturer",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldModelNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.ModelNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldModelNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableModelNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.ModelNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableModelNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusModelNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.ModelNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusModelNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldSerialNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.SerialNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldSerialNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableSerialNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.SerialNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableSerialNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusSerialNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.SerialNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusSerialNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldCertNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.CertificationNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldCertNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableCertNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.CertificationNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableCertNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusCertNo,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.CertificationNo.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusCertNo",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Calculated Results"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldUiV,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.UIV.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldUiV",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusUiV,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.UIV.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusUiV",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.fieldUiV &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.apparatusUiV
                                                                                      ? Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.fieldUiV
                                                                                        ) >=
                                                                                        Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusUiV
                                                                                        )
                                                                                        ? "text-green"
                                                                                        : "text-red"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          ?.fieldUiV &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusUiV
                                                                                          ? Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.fieldUiV
                                                                                            ) >=
                                                                                            Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.apparatusUiV
                                                                                            )
                                                                                            ? "Pass"
                                                                                            : "Fail"
                                                                                          : "-"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldLiMa,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LiMa.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldLiMa",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusLiMa,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LiMa.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusLiMa",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.fieldLiMa &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.apparatusLiMa
                                                                                      ? Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.fieldLiMa
                                                                                        ) >=
                                                                                        Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusLiMa
                                                                                        )
                                                                                        ? "text-green"
                                                                                        : "text-red"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          ?.fieldLiMa &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusLiMa
                                                                                          ? Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.fieldLiMa
                                                                                            ) >=
                                                                                            Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.apparatusLiMa
                                                                                            )
                                                                                            ? "Pass"
                                                                                            : "Fail"
                                                                                          : "-"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldPiW,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.PiW.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldPiW",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusPiW,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.PiW.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusPiW",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.fieldPiW &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.apparatusPiW
                                                                                      ? Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.fieldPiW
                                                                                        ) >=
                                                                                        Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusPiW
                                                                                        )
                                                                                        ? "text-green"
                                                                                        : "text-red"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          ?.fieldPiW &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusPiW
                                                                                          ? Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.fieldPiW
                                                                                            ) >=
                                                                                            Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.apparatusPiW
                                                                                            )
                                                                                            ? "Pass"
                                                                                            : "Fail"
                                                                                          : "-"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldCiuF,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.CiuF.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldCiuF",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableCMuF,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.CmuF.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableCMuF",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableLengthM1,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.Length.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableLengthM1",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableCCuF,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.CcuF.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableCCuF",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusCiuF,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.CiuF.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusCiuF",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.fieldCiuF &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.cableCMuF &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.cableCCuF &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.apparatusCiuF
                                                                                      ? Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.fieldCiuF
                                                                                        ) +
                                                                                          Number(
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              ?.cableCMuF
                                                                                          ) <=
                                                                                        Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableCCuF
                                                                                        ) +
                                                                                          Number(
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              ?.apparatusCiuF
                                                                                          )
                                                                                        ? "text-green"
                                                                                        : "text-red"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          ?.fieldCiuF &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableCMuF &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableCCuF &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusCiuF
                                                                                          ? Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.fieldCiuF
                                                                                            ) +
                                                                                              Number(
                                                                                                _vm
                                                                                                  .siteLocationsSrv
                                                                                                  .selectedLcoation
                                                                                                  .equipmentDetail
                                                                                                  ?.cableCMuF
                                                                                              ) <=
                                                                                            Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.cableCCuF
                                                                                            ) +
                                                                                              Number(
                                                                                                _vm
                                                                                                  .siteLocationsSrv
                                                                                                  .selectedLcoation
                                                                                                  .equipmentDetail
                                                                                                  ?.apparatusCiuF
                                                                                              )
                                                                                            ? "Pass"
                                                                                            : "Fail"
                                                                                          : "-"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .fieldLiMh,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LiMh.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "fieldLiMh",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg connectHead",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesLeft",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableLmMh,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.LmMh.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableLmMh",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableLengthM2,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.Length.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableLengthM2",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              col: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "flex-grow",
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.readOnlyFlag,
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .siteLocationsSrv
                                                                                                      .selectedLcoation
                                                                                                      .equipmentDetail
                                                                                                      .cableCLMh,
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  placeholder:
                                                                                                    "",
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "Fields.EquipmentFields.ClmH.Label"
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                "update:value":
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.$set(
                                                                                                      _vm
                                                                                                        .siteLocationsSrv
                                                                                                        .selectedLcoation
                                                                                                        .equipmentDetail,
                                                                                                      "cableCLMh",
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                keypress:
                                                                                                  _vm.validateNumber,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectingLinesRight",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .apparatusLiMh,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LiMh.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "apparatusLiMh",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                        keypress:
                                                                                          _vm.validateNumber,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.fieldLiMh &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.cableLmMh &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.cableCLMh &&
                                                                                    _vm
                                                                                      .siteLocationsSrv
                                                                                      .selectedLcoation
                                                                                      .equipmentDetail
                                                                                      ?.apparatusLiMh
                                                                                      ? Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.fieldLiMh
                                                                                        ) +
                                                                                          Number(
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              ?.cableLmMh
                                                                                          ) <=
                                                                                        Number(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableCLMh
                                                                                        ) +
                                                                                          Number(
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              ?.apparatusLiMh
                                                                                          )
                                                                                        ? "text-green"
                                                                                        : "text-red"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          ?.fieldLiMh &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableLmMh &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.cableCLMh &&
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail
                                                                                            ?.apparatusLiMh
                                                                                          ? Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.fieldLiMh
                                                                                            ) +
                                                                                              Number(
                                                                                                _vm
                                                                                                  .siteLocationsSrv
                                                                                                  .selectedLcoation
                                                                                                  .equipmentDetail
                                                                                                  ?.cableLmMh
                                                                                              ) <=
                                                                                            Number(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail
                                                                                                ?.cableCLMh
                                                                                            ) +
                                                                                              Number(
                                                                                                _vm
                                                                                                  .siteLocationsSrv
                                                                                                  .selectedLcoation
                                                                                                  .equipmentDetail
                                                                                                  ?.apparatusLiMh
                                                                                              )
                                                                                            ? "Pass"
                                                                                            : "Fail"
                                                                                          : "-"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableLrcMhOHM,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LrcMhOHM.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableLrcMhOHM",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "connectMid",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex-grow",
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            _vm.readOnlyFlag,
                                                                                          value:
                                                                                            _vm
                                                                                              .siteLocationsSrv
                                                                                              .selectedLcoation
                                                                                              .equipmentDetail
                                                                                              .cableLRoMhOHM,
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "Fields.EquipmentFields.LRoMhOHM.Label"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        "update:value":
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$set(
                                                                                              _vm
                                                                                                .siteLocationsSrv
                                                                                                .selectedLcoation
                                                                                                .equipmentDetail,
                                                                                              "cableLRoMhOHM",
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center yellow-bg",
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "50px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#1c2f53",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Equipment Earth Test for IS Installation"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#1c2f53",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Equipment Room Earth Test for IS Installation"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .continuityOHM,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ContinuityOHM.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "continuityOHM",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .equipmentScreenOHM,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ContinuityOHM.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "equipmentScreenOHM",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .continuityOHM2,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ScreenToMultipleCoresScreen.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "continuityOHM2",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .multiCoreScreenOHM,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.EarthToIsBar.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "multiCoreScreenOHM",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "center-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex-grow",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.readOnlyFlag,
                                                                                      value:
                                                                                        _vm
                                                                                          .siteLocationsSrv
                                                                                          .selectedLcoation
                                                                                          .equipmentDetail
                                                                                          .equipmentScreenOHM2,
                                                                                      outlined:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "",
                                                                                      label:
                                                                                        _vm.$t(
                                                                                          "Fields.EquipmentFields.ScreenToMultipleCoresTerminal.Label"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    "update:value":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          _vm
                                                                                            .siteLocationsSrv
                                                                                            .selectedLcoation
                                                                                            .equipmentDetail,
                                                                                          "equipmentScreenOHM2",
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _vm.siteLocationsSrv.selectedLcoation
                                .equipmentDetail
                                ? _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-text", [
                                        _vm._v(" Digital Twin Goes Here "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-sheet",
                                                {
                                                  staticClass: "mx-auto",
                                                  attrs: { "max-width": "300" },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "SiteComponents.EquipmentDetails.TotalWireEntries"
                                                            )
                                                          ) +
                                                          ": " +
                                                          _vm._s(
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .wiringCables
                                                              ?.length
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-slide-group",
                                                    {
                                                      attrs: {
                                                        "slider-color":
                                                          "#1b3054",
                                                        "show-arrows": "",
                                                        centered: "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "prev",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.prev()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-left"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "next",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.next()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-right"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1796914642
                                                      ),
                                                      model: {
                                                        value: _vm.tab,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.tab = $$v
                                                        },
                                                        expression: "tab",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .wiringCables,
                                                      function (entry) {
                                                        return _c(
                                                          "v-slide-item",
                                                          {
                                                            key: entry.id,
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    active,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "tab-cable",
                                                                          attrs:
                                                                            {
                                                                              "input-value":
                                                                                active,
                                                                              "active-class":
                                                                                "tab-cable-active",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.setActive(
                                                                                  entry.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                entry?.name ??
                                                                                  "Core 1"
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.readOnlyFlag
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: {
                                                    "justify-content": "right",
                                                    position: "absolute",
                                                    right: "5px",
                                                  },
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c(
                                                    "base-btn",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "12px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setNew()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Common.AddWire"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      !_vm.showAddNew
                                        ? _c(
                                            "v-tabs-items",
                                            {
                                              model: {
                                                value: _vm.tab,
                                                callback: function ($$v) {
                                                  _vm.tab = $$v
                                                },
                                                expression: "tab",
                                              },
                                            },
                                            _vm._l(
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.wiringCables,
                                              function (entry, index) {
                                                return _c(
                                                  "v-tab-item",
                                                  { key: entry.id },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          value:
                                                                            entry.id,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableID.Label"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "id",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          value:
                                                                            entry.name,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableName.Label"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "name",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableEntryType.Label"
                                                                            ),
                                                                          clearable:
                                                                            "",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.Manufacturer.Label"
                                                                            ),
                                                                          value:
                                                                            entry.manufacturer,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "manufacturer",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.ModelNo.Label"
                                                                            ),
                                                                          value:
                                                                            entry.modelNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "modelNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.SerialNo.Label"
                                                                            ),
                                                                          value:
                                                                            entry.serialNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "serialNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CertificationNo.Label"
                                                                            ),
                                                                          value:
                                                                            entry.certificationNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "certificationNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          name: "wiringProtectionType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableProtectionType
                                                                            ],
                                                                          value:
                                                                            entry.protectionType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.ProtectionType.Label"
                                                                            ),
                                                                          clearable:
                                                                            "",
                                                                          "return-object": false,
                                                                          "error-count":
                                                                            _vm.siteLocationsSrv.checkFieldError(
                                                                              "equipmentWiringCableProtectionType",
                                                                              entry.id
                                                                            )
                                                                              .length ??
                                                                            1,
                                                                          "error-messages":
                                                                            _vm.siteLocationsSrv.checkFieldError(
                                                                              "equipmentWiringCableProtectionType",
                                                                              entry.id
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "protectionType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableModelNo.Label"
                                                                            ),
                                                                          value:
                                                                            entry.cableModelNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableModelNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          name: "equipmentWiringCableFunction",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableFunction
                                                                            ],
                                                                          value:
                                                                            entry.cableFunction,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Function",
                                                                          clearable:
                                                                            "",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableFunction",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableManufacturer.Label"
                                                                            ),
                                                                          value:
                                                                            entry.cableManufacturer,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableManufacturer",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.CableEntryType.Label"
                                                                            ),
                                                                          value:
                                                                            entry.coreInformation,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "coreInformation",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                    on: {
                                                                      dragover:
                                                                        _vm.allowDrop,
                                                                      drop: _vm.drop,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "SiteComponents.EquipmentDetails.ConnectWith"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          items:
                                                                            _vm.getEquipmentsList,
                                                                          value:
                                                                            entry.connectWith,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.SelectEquipDP.Label"
                                                                            ),
                                                                          "return-object": false,
                                                                          multiple: false,
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectWith",
                                                                                $event
                                                                              )
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeConnectWithFields(
                                                                                entry,
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          loading:
                                                                            _vm.loadingConnectLists,
                                                                          items:
                                                                            _vm
                                                                              .finalList[
                                                                              index
                                                                            ],
                                                                          value:
                                                                            entry.connectWithMid,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.SelectOutputEntries.Label"
                                                                            ),
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectWithMid",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.readOnlyFlag,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          items:
                                                                            [
                                                                              "IN",
                                                                              "OUT",
                                                                              "N/A",
                                                                            ],
                                                                          value:
                                                                            entry.connectDirection,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            _vm.$t(
                                                                              "Fields.EquipmentFields.Direction.Label"
                                                                            ),
                                                                          clearable:
                                                                            "",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectDirection",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "30px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "24px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Common.Documents"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                !_vm.addDocument
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "white--text",
                                                                        attrs: {
                                                                          color:
                                                                            "admin-primary ",
                                                                          icon: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.addDocument = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {},
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-plus-circle-outline"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.addDocument
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-autocomplete",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      _vm.readOnlyFlag,
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    clearable:
                                                                                      "",
                                                                                    rules:
                                                                                      "required",
                                                                                    items:
                                                                                      _vm.documentTypes,
                                                                                    value:
                                                                                      _vm.selectedType,
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "Fields.EquipmentFields.DocumentType.Label"
                                                                                      ),
                                                                                    "return-object": false,
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.selectedType =
                                                                                        $event
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-file-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    disabled:
                                                                                      _vm.readOnlyFlag,
                                                                                    placeholder:
                                                                                      "Upload your documents",
                                                                                    label:
                                                                                      "File input",
                                                                                    "prepend-icon":
                                                                                      "mdi-paperclip",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.filesSelected,
                                                                                },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "selection",
                                                                                        fn: function ({
                                                                                          text,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                    label:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      text
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-end",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "admin-primary ",
                                                                                    disabled:
                                                                                      !_vm.selectedType ||
                                                                                      !_vm.file,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.upload(
                                                                                        entry.id ||
                                                                                          ""
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Save "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text",
                                                                                attrs:
                                                                                  {
                                                                                    outlined:
                                                                                      "",
                                                                                    color:
                                                                                      "admin-primary",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.reset,
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Cancel "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-divider"
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._l(
                                                              _vm.documents.filter(
                                                                (docs) =>
                                                                  docs.equipmentWiringCableId ===
                                                                  entry.id
                                                              ),
                                                              function (
                                                                document
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: document.id,
                                                                    staticStyle:
                                                                      {
                                                                        "margin-top":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Documents - " +
                                                                          _vm._s(
                                                                            document.documentType
                                                                          ) +
                                                                          " - "
                                                                      ),
                                                                    ]),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          document.title
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "base-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          icon: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.view(
                                                                                document
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "admin-primary--text cursor-pointer",
                                                                            attrs:
                                                                              {
                                                                                size: "16px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-eye"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    !_vm.readOnlyFlag
                                                                      ? _c(
                                                                          "base-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "",
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteDoc(
                                                                                    document
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "red lighten-1",
                                                                                    size: "16px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-delete-empty"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        !_vm.readOnlyFlag
                                                          ? _c(
                                                              "v-card-actions",
                                                              [
                                                                _c("v-spacer"),
                                                                _c(
                                                                  "base-btn",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "background-color":
                                                                          "#fff !important",
                                                                        color:
                                                                          "#1b3054",
                                                                      },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteCable(
                                                                            entry
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Common.Delete"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "base-btn",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.updateCable(
                                                                            entry
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Common.Update"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showAddNew
                                        ? _c(
                                            "v-sheet",
                                            {
                                              staticStyle: {
                                                "margin-top": "20px",
                                              },
                                              attrs: { elevation: "0" },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    "item-text":
                                                                      "sequence",
                                                                    "item-value":
                                                                      "sequence",
                                                                    items:
                                                                      _vm
                                                                        .siteLocationsSrv
                                                                        .selectedLcoation
                                                                        .wiringCables,
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    small: "",
                                                                    clearable:
                                                                      "",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.AddBeforeWire.Label"
                                                                      ),
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "item",
                                                                          fn: function ({
                                                                            item,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          `Wire ${
                                                                                            item.sequence ??
                                                                                            1
                                                                                          }`
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                        {
                                                                          key: "selection",
                                                                          fn: function ({
                                                                            item,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-chip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Wire " +
                                                                                      _vm._s(
                                                                                        item.sequence ??
                                                                                          1
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      3939495591
                                                                    ),
                                                                  model: {
                                                                    value:
                                                                      _vm.newSeq,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.newSeq =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "newSeq",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .name,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CableName.Label"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "name",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.Manufacturer.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .manufacturer,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "manufacturer",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.ModelNo.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .modelNo,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "modelNo",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.SerialNo.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .serialNo,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "serialNo",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CertificationNo.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .certificationNo,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "certificationNo",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  staticClass:
                                                                    "flex-grow-1",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    name: "wiringProtectionTypeAdd",
                                                                    items:
                                                                      _vm
                                                                        .adminDataFieldsSrv
                                                                        .equipmentFieldOptions[
                                                                        _vm
                                                                          .equipmentFieldsList
                                                                          .equipmentWiringCableProtectionType
                                                                      ],
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .protectionType,
                                                                    "item-text":
                                                                      "value",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.ProtectionType.Label"
                                                                      ),
                                                                    clearable:
                                                                      "",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "protectionType",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CableModelNo.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .cableModelNo,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "cableModelNo",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  staticClass:
                                                                    "flex-grow-1",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    name: "equipmentWiringCableFunctionAdd",
                                                                    items:
                                                                      _vm
                                                                        .adminDataFieldsSrv
                                                                        .equipmentFieldOptions[
                                                                        _vm
                                                                          .equipmentFieldsList
                                                                          .equipmentWiringCableFunction
                                                                      ],
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .cableFunction,
                                                                    "item-text":
                                                                      "value",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CableFunction.Label"
                                                                      ),
                                                                    clearable:
                                                                      "",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "cableFunction",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fdf7e7",
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CableManufacturer.Label"
                                                                      ),
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .cableManufacturer,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "cableManufacturer",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  staticClass:
                                                                    "flex-grow-1",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    name: "wiringCableEntryTypeAdd",
                                                                    items:
                                                                      _vm
                                                                        .adminDataFieldsSrv
                                                                        .equipmentFieldOptions[
                                                                        _vm
                                                                          .equipmentFieldsList
                                                                          .equipmentWiringCableEntryType
                                                                      ],
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .coreInformation,
                                                                    "item-text":
                                                                      "value",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.CableEntryType.Label"
                                                                      ),
                                                                    clearable:
                                                                      "",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "coreInformation",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                              on: {
                                                                dragover:
                                                                  _vm.allowDrop,
                                                                drop: _vm.dropNew,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Connect With"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    items:
                                                                      _vm.getEquipmentsList,
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .connectWith,
                                                                    "item-text":
                                                                      "title",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.SelectEquipDP.Label"
                                                                      ),
                                                                    "return-object": false,
                                                                    multiple: false,
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "connectWith",
                                                                          $event
                                                                        )
                                                                      },
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeConnectWithFieldsNew(
                                                                          _vm
                                                                            .newWiringCableObj1
                                                                            .connectWith
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    loading:
                                                                      _vm.loadingConnectLists,
                                                                    items:
                                                                      _vm.finalListNew,
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .connectWithMid,
                                                                    "item-text":
                                                                      "title",
                                                                    "item-value":
                                                                      "id",
                                                                    clearable:
                                                                      "",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.SelectOutputEntries.Label"
                                                                      ),
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "connectWithMid",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    items: [
                                                                      "IN",
                                                                      "OUT",
                                                                      "N/A",
                                                                    ],
                                                                    value:
                                                                      _vm
                                                                        .newWiringCableObj1
                                                                        .connectDirection,
                                                                    "item-text":
                                                                      "value",
                                                                    "item-value":
                                                                      "id",
                                                                    label:
                                                                      _vm.$t(
                                                                        "Fields.EquipmentFields.Direction.Label"
                                                                      ),
                                                                    clearable:
                                                                      "",
                                                                    "return-object": false,
                                                                  },
                                                                  on: {
                                                                    "update:value":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.newWiringCableObj1,
                                                                          "connectDirection",
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.readOnlyFlag
                                                    ? _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "base-btn",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.saveNewCable,
                                                              },
                                                            },
                                                            [_vm._v("Save")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("PreviewDocumentDialogComponent", {
                                ref: "previewDialog",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [_c("InspectionHistoryComponent")],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "text-h5 my-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SiteComponents.EquipmentDetails.EquipmentDocuments"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-auto" },
                                                    [
                                                      !_vm.addDocument1
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "white--text",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.readOnlyFlag,
                                                                color:
                                                                  "admin-primary ",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.addDocument1 = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Common.AddDocument"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-plus-circle-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-card-text", [
                                            _vm.addDocument1
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "base-autocomplete",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.readOnlyFlag,
                                                                  dense: "",
                                                                  outlined: "",
                                                                  clearable: "",
                                                                  rules:
                                                                    "required",
                                                                  items:
                                                                    _vm.documentTypes,
                                                                  value:
                                                                    _vm.selectedType,
                                                                  label:
                                                                    "Document Type",
                                                                  "return-object": false,
                                                                },
                                                                on: {
                                                                  "update:value":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.selectedType =
                                                                        $event
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _c("v-file-input", {
                                                              attrs: {
                                                                dense: "",
                                                                outlined: "",
                                                                disabled:
                                                                  _vm.readOnlyFlag,
                                                                placeholder:
                                                                  "Upload your documents",
                                                                label:
                                                                  "File input",
                                                                "prepend-icon":
                                                                  "mdi-paperclip",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.filesSelected,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "selection",
                                                                      fn: function ({
                                                                        text,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  label:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    text
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  1464597600
                                                                ),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-end",
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "white--text mr-1",
                                                                attrs: {
                                                                  color:
                                                                    "admin-primary ",
                                                                  disabled:
                                                                    !_vm.selectedType ||
                                                                    !_vm.file,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.uploadEquip()
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" Save ")]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "white--text",
                                                                attrs: {
                                                                  outlined: "",
                                                                  color:
                                                                    "admin-primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.addDocument1 = false
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Cancel "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider"),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("div", [
                                              _c(
                                                "table",
                                                { staticClass: "foex-table" },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("Type"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("File"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Actions"),
                                                      ]),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.documents.filter(
                                                        (docs) =>
                                                          docs.equipmentWiringCableId ===
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            ?.id
                                                      ),
                                                      function (document) {
                                                        return _c(
                                                          "tr",
                                                          { key: document.id },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.documentType
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.title
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "base-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      small: "",
                                                                      icon: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.view(
                                                                            document
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "admin-primary--text cursor-pointer",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-eye"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      small: "",
                                                                      disabled:
                                                                        _vm.readOnlyFlag,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteDoc(
                                                                            document
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "red lighten-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-delete-empty"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticClass: "purple-divider",
                                        staticStyle: {
                                          "margin-top": "30px",
                                          "margin-bottom": "12px",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "bg-even" },
                                        [
                                          _c(
                                            "v-row",
                                            {},
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "-10px",
                                                  },
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "pt-0" },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "text-h5 my-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "SiteComponents.EquipmentDetails.WiringDocuments"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {},
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-sheet",
                                                    {
                                                      staticClass: "mx-auto",
                                                      attrs: {
                                                        "max-width": "300",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-slide-group",
                                                        {
                                                          attrs: {
                                                            "slider-color":
                                                              "#1b3054",
                                                            "show-arrows": "",
                                                            centered: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "prev",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.prev()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-chevron-left"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "next",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.next()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-chevron-right"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1796914642
                                                          ),
                                                          model: {
                                                            value: _vm.tab,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.tab = $$v
                                                            },
                                                            expression: "tab",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.siteLocationsSrv
                                                            .selectedLcoation
                                                            .wiringCables,
                                                          function (entry) {
                                                            return _c(
                                                              "v-slide-item",
                                                              {
                                                                key: entry.id,
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          active,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "tab-cable",
                                                                                attrs:
                                                                                  {
                                                                                    "input-value":
                                                                                      active,
                                                                                    "active-class":
                                                                                      "tab-cable-active",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.setActive(
                                                                                        entry.id
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Wire " +
                                                                                    _vm._s(
                                                                                      entry?.sequence ??
                                                                                        1
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {},
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "padding-top": "0",
                                                    "padding-bottom": "0",
                                                  },
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "pt-0" },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-auto",
                                                        },
                                                        [
                                                          !_vm.addDocument
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "white--text",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readOnlyFlag,
                                                                    outlined:
                                                                      "",
                                                                    color:
                                                                      "admin-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.addDocument = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Common.AddDocument"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-plus-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tabs-items",
                                            {
                                              model: {
                                                value: _vm.tab,
                                                callback: function ($$v) {
                                                  _vm.tab = $$v
                                                },
                                                expression: "tab",
                                              },
                                            },
                                            _vm._l(
                                              _vm.siteLocationsSrv
                                                .selectedLcoation.wiringCables,
                                              function (entry) {
                                                return _c(
                                                  "v-tab-item",
                                                  { key: entry.id },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass: "bg-even",
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          [
                                                            _vm.addDocument
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-autocomplete",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      _vm.readOnlyFlag,
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    clearable:
                                                                                      "",
                                                                                    rules:
                                                                                      "required",
                                                                                    items:
                                                                                      _vm.documentTypes,
                                                                                    value:
                                                                                      _vm.selectedType,
                                                                                    label:
                                                                                      "Document Type",
                                                                                    "return-object": false,
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.selectedType =
                                                                                        $event
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-file-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    disabled:
                                                                                      _vm.readOnlyFlag,
                                                                                    placeholder:
                                                                                      "Upload your documents",
                                                                                    label:
                                                                                      "File input",
                                                                                    "prepend-icon":
                                                                                      "mdi-paperclip",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.filesSelected,
                                                                                },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "selection",
                                                                                        fn: function ({
                                                                                          text,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                    label:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      text
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-end",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "admin-primary ",
                                                                                    disabled:
                                                                                      !_vm.selectedType ||
                                                                                      !_vm.file,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.upload(
                                                                                        entry.id ||
                                                                                          ""
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Save "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text",
                                                                                attrs:
                                                                                  {
                                                                                    outlined:
                                                                                      "",
                                                                                    color:
                                                                                      "admin-primary",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.addDocument = false
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Cancel "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-divider"
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c("v-col", [
                                                              _c(
                                                                "table",
                                                                {
                                                                  staticClass:
                                                                    "foex-table",
                                                                },
                                                                [
                                                                  _c("thead", [
                                                                    _c("tr", [
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Type"
                                                                        ),
                                                                      ]),
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "File"
                                                                        ),
                                                                      ]),
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Actions"
                                                                        ),
                                                                      ]),
                                                                    ]),
                                                                  ]),
                                                                  _c(
                                                                    "tbody",
                                                                    _vm._l(
                                                                      _vm.documents.filter(
                                                                        (
                                                                          docs
                                                                        ) =>
                                                                          docs.equipmentWiringCableId ===
                                                                          entry.id
                                                                      ),
                                                                      function (
                                                                        document
                                                                      ) {
                                                                        return _c(
                                                                          "tr",
                                                                          {
                                                                            key: document.id,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    document.documentType
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    document.title
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "base-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1",
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        icon: "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.view(
                                                                                            document
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "admin-primary--text cursor-pointer",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-eye"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                !_vm.readOnlyFlag
                                                                                  ? _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            icon: "",
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.deleteDoc(
                                                                                                document
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "red lighten-1",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-delete-empty"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mb-3 purple-divider",
                                        staticStyle: {
                                          "border-width": "1px !important",
                                        },
                                      }),
                                      _c(
                                        "h4",
                                        { staticClass: "text-h5 my-2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "SiteComponents.EquipmentDetails.InspectionDocuments"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("PreviewDocumentDialogComponent", {
                                ref: "previewDialog",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("base-textarea", {
                                                attrs: {
                                                  disabled: _vm.readOnlyFlag,
                                                  outlined: "",
                                                  rows: "5",
                                                  label: _vm.$t(
                                                    "Fields.EquipmentFields.Note.Label"
                                                  ),
                                                  "auto-grow": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .equipmentDetail.note,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .equipmentDetail,
                                                      "note",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.equipmentDetail.note",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("AreaRulesSummaryDialogComponent", {
            ref: "areaRulesSummaryDialogRef",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }