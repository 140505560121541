import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {DataFieldStatusModel, LocationModel, SiteModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class SitesApi extends BaseApi {
    public Create(data: SiteModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        return this.POST_Request<SiteModel>(`${this.ApiUrl}/sites`, data);
    }

    public UpdateSite(id: string, data: SiteModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        return this.PATCH_Request<SiteModel>(`${this.ApiUrl}/sites/${id}`, data);
    }

    // public getSites(filter: AnyObject = {}) {
    //     return this.GET_Request<Array<SiteModel>>(`${this.ApiUrl}/sites?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    // }

    public getSiteById(id: string) {
        return this.GET_Request<SiteModel>(`${this.ApiUrl}/sites/${id}`);
    }

    public getSiteLocations(siteId: string, filter: AnyObject = {}) {
        return this.GET_Request<Array<LocationModel>>(`${this.ApiUrl}/sites/${siteId}/locations?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public CreateSiteLocation(siteId: string, data: LocationModel) {
        delete data.id;
        delete data.created;
        delete data.updated;
        delete data.locationDetail;
        delete data.equipmentDetail;
        delete data.distributionPanel;
        delete data.switchBoards;
        delete data.wiringCables;
        delete data.documentations;
        delete data.parentLocation;
        delete data.coresGroups;

        data.siteId = siteId;

        if (!data.locationId) delete data.locationId;
        delete data.locations;

        return this.POST_Request<LocationModel>(`${this.ApiUrl}/sites/${siteId}/locations`, data);
    }

    public deleteSite(id: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/sites/${id}`);
    }

    public getAccountSites(accountId: string, filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<SiteModel>>(`${this.ApiUrl}/accounts/${accountId}/sites?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public getLocationRulesWarnings(siteId: string) {
        return this.GET_RequestAsync<Array<DataFieldStatusModel>>(`${this.ApiUrl}/sites/${siteId}/get-locations-rules-warnings`);
    }

    public assignOrganization(id: string, organizationId: string) {
        const data = {organizationId};
        return this.PATCH_Request<any>(`${this.ApiUrl}/sites/${id}/assign-organization`, data);
    }
}
