import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ConfirmComponent extends VueWrapper {
    $refs!: {
        confirmButton: Vue;
    };
    // Services
    // public width = 400;
    // public ConfirmService = new ConfirmService();

    public get width() {
        return this.CoreSrv.confirmCompWidth;
    }

    onEnter() {
        (this.$refs?.confirmButton?.$el as HTMLElement)?.click();
    }
}
