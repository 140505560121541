var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "1280px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._v(" Rules Warnings "), _vm._t("header")]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [_c("div")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.siteDataFieldStatuses
        ? _c("div", [
            _c("table", { staticClass: "width-100" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Field ID")]),
                  _c("th", [_vm._v("Field Title")]),
                  _c("th", [_vm._v("Message")]),
                  _c("th", [_vm._v("Error Code")]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.siteDataFieldStatuses, function (status) {
                  return _c("tr", { key: status.id }, [
                    _c("td", [
                      _vm._v(
                        _vm._s(status.dataField ? status.dataField.id : "")
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(status.dataField ? status.dataField.title : "")
                      ),
                    ]),
                    _c("td", [_vm._v(" " + _vm._s(status.message) + " ")]),
                    _c("td", [_vm._v(_vm._s(status.errorCode))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }