export class InspepctionFormModel {
    public id?: string | null = null;
    public title?: string | null = null;
    public description?: string | null = null;
    public appliedOnProtectionTechnques?: Array<string> = [];

    constructor(data?: Partial<InspepctionFormModel>) {
        Object.assign(this, data);
    }
}
