import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {LocationModel, SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';
import DistributionPanelFormComponent from '@/views/shared/distribution-panel-form/distribution-panel-form.component';
import EquipmentDetailsFormComponent from '@/views/shared/equipment-details-form/equipment-details-form.component';
import PreviewDocumentDialogComponent from '@/views/shared/preview-document-dialog/preview-document-dialog.component';
import SaveDocumentationFileDialogComponent from '@/views/shared/save-documentation-file-dialog/save-documentation-file-dialog.component';
import SaveDocumentationFolderDialogComponent from '@/views/shared/save-documentation-folder-dialog/save-documentation-folder-dialog.component';
import SaveLocationDialogComponent from '@/views/shared/save-location-dialog/save-location-dialog.component';
import {SiteLocationService} from '@/views/shared/services/site-location.service';
import {Component} from 'vue-property-decorator';
import SiteSetupAreaBrowserComponent from '../diagram-view-area-browser/diagram-view-area-browser.component';
import DiagramViewCanvasComponent from '../diagram-view-canvas/diagram-view-canvas.component';
import SiteSetupLocationFormComponent from '../diagram-view-location-form/diagram-view-location-form.component';
import SiteSetupSearchComponent from '../diagram-view-search/diagram-view-search.component';

enum TabEnum {
    HierarchalView = 'HierarchalView',
    CanvasView = 'CanvasView',
    CanvasTab = 'CanvasTab',
}

@Component({
    name: 'DiagramViewMainComponent',
    components: {
        SiteSetupAreaBrowserComponent,
        SiteSetupSearchComponent,
        SiteSetupLocationFormComponent,
        DiagramViewCanvasComponent,
        SaveLocationDialogComponent,
        SaveDocumentationFolderDialogComponent,
        SaveDocumentationFileDialogComponent,

        EquipmentDetailsFormComponent,
        DistributionPanelFormComponent,
        PreviewDocumentDialogComponent,
    },
})
export default class DiagramViewMainComponent extends VueWrapper {
    public $refs!: {
        areaBrowserElem: HTMLDivElement;
        searchSectionElem: HTMLDivElement;
        searchSectionParentElem: HTMLDivElement;
        diagramViewCanvasComponentRef: DiagramViewCanvasComponent;
        previewDialog: PreviewDocumentDialogComponent;
    };
    public siteToEdit: SiteModel = new SiteModel();

    public siteId: string | null = null;

    public diagramViewStyle: boolean | false = false;

    public diagramFullPageConfig: boolean | false = false;

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public locationPath: Array<{id: string; title: string; type: string}> = [];

    public diagramViewLayout: boolean | false = false;

    public TabEnum = TabEnum;

    public tabTop: string = TabEnum.CanvasView;
    public tabItem: string = TabEnum.CanvasTab;

    public drawer: boolean | false = false;

    // public formsSiteResults: boolean | false = false;
    public showSearchResults: boolean | false = false;

    public created() {
        this.siteId = this.$route.params['siteId'];
        // this.formsSiteResults = false;
        this.showSearchResults = false;
    }

    public mounted() {
        this.getSiteToEdit(this.siteId!);
        this.siteLocationsSrv.getSiteLocations(this.siteId!);
        this.siteLocationsSrv.selectedLcoation = null;
    }

    public startX: number | null = null;
    public startY: number | null = null;
    public startWidth: number | null = null;
    public startHeight: number | null = null;

    public wireCableArr: Array<any> = [];

    public onTabChange(t: TabEnum) {
        this.tabItem = this.TabEnum.CanvasTab;
        if (this.$refs.diagramViewCanvasComponentRef) {
            if (t === this.TabEnum.HierarchalView) {
                this.$refs.diagramViewCanvasComponentRef.showTreeView();
            } else if (t === this.TabEnum.CanvasView) {
                this.$refs.diagramViewCanvasComponentRef.showCanvasView();
            }
        }
    }

    public onNodeDoubleClick(locationId: string) {
        console.log('onNodeDoubleClick', locationId);
        if (!locationId) {
            this.siteLocationsSrv.selectedLcoation = null;
            this.diagramViewStyle = false;
            this.diagramFullPageConfig = false;
        } else {
            const found = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            found && this.locationItemClick(found);

            this.drawer = true;
        }
    }

    public onListMouseDown(e: any) {
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startWidth = parseInt(document.defaultView?.getComputedStyle(this.$refs.searchSectionElem)?.width ?? '0', 10);
        this.startHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.searchSectionElem)?.height ?? '0', 10);

        document.documentElement.addEventListener('mousemove', this.doDrag, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag, false);
    }

    doDrag(e: any) {
        this.$refs.searchSectionElem.style.width = `${this.startWidth + e.clientX - (this.startX ?? 0)}px`;
        this.$refs.searchSectionElem.style.height = `${this.startHeight + e.clientY - (this.startY ?? 0)}`;
    }

    stopDrag(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
    }

    public startX1: number | null = null;
    public startY1: number | null = null;
    public startWidth1: number | null = null;
    public startHeight1: number | null = null;

    public parentHeight: number | null = null;
    public currentHeight: number | null = null;

    public onBrowserMouseDown(e: any) {
        this.startX1 = e.clientX;
        this.startY1 = e.clientY;
        // this.startWidth1 = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.width ?? '0', 10);
        this.startHeight1 = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.height ?? '0', 10);

        this.parentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.height ?? '0', 10);

        document.documentElement.addEventListener('mousemove', this.doDrag1, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag1, false);
    }

    doDrag1(e: any) {
        // this.$refs.searchSectionElem.style.width = `${this.startWidth1 + e.clientX - (this.startX1 ?? 0)}px`;
        this.currentHeight = this.startHeight1 + e.clientY - (this.startY1 ?? 0);
        this.$refs.areaBrowserElem.style.height = `${this.currentHeight}px`;
    }

    stopDrag1(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag1, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag1, false);
    }

    public closeLocation() {
        this.diagramViewStyle = false;
    }

    public goToLocation(locationId: string) {
        console.log('object', locationId);
        if (!locationId) {
            this.siteLocationsSrv.selectedLcoation = null;
            this.diagramViewStyle = false;
            this.diagramFullPageConfig = false;
        } else {
            const found = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            found && this.locationItemClick(found);
            this.diagramViewStyle = true;
        }
    }

    public fullPageFormView(state: any) {
        // console.log('object', state);
        if (this.diagramFullPageConfig || state === 'core') {
            this.siteLocationsSrv.selectedLcoation = null;
            this.diagramViewStyle = false;
            this.diagramFullPageConfig = false;
        } else {
            this.diagramFullPageConfig = !this.diagramFullPageConfig;
        }
        // if (state) {
        //     this.diagramFullPageConfig = false;
        // } else {
        //     this.diagramFullPageConfig = true;
        // }
    }

    public formsSiteResultsEvent(state: boolean) {
        // this.formsSiteResults = state;
    }

    public showSearchResultsEvent(state: boolean) {
        this.showSearchResults = state;
    }

    public locationItemClick(location: LocationModel, fromSearch?: boolean) {
        console.log(location);
        this.locationPath = [];
        let locationId = location.id;
        let foundLocation: LocationModel | undefined = location;

        let locationId1 = location.id;
        let foundLocation1: LocationModel | undefined | null = null;

        this.showSearchResults = false;

        while (locationId1 != null) {
            if (locationId1) {
                foundLocation1 = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId1);
            }
            if (foundLocation1) {
                foundLocation1.expanded = true;
            }
            locationId1 = foundLocation1?.locationId;
        }

        while (locationId != null) {
            if (foundLocation) {
                this.locationPath.push({id: foundLocation.id!, title: foundLocation.title!, type: 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.siteLocationsSrv.site) {
            this.locationPath.push({id: this.siteLocationsSrv.site.id!, title: this.siteLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPath = this.locationPath.reverse();

        if (location.type === 'equipment') {
            this.siteLocationsSrv.getSiteLocation(location.id!);
            this.siteLocationsSrv.loadLocationDetailRules(location.id!);
            this.diagramViewStyle = true;
        } else if (location.type === 'distribution') {
            this.siteLocationsSrv.getSiteLocation(location.id!);
            this.diagramViewStyle = true;
        } else {
            this.LoaderSrv.showFullScreenLoader();

            this.siteLocationsSrv.loadLocationDetailRules(location.id!);
            this.diagramViewStyle = true;
            new LocationApi()
                .getLocationById(location.id!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'documentations',
                        },
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.approvalType ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.approvalType === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.approvalType = [];
                            // }

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.environmentalCondition ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.environmentalCondition === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.environmentalCondition = [];
                            // }
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            this.siteLocationsSrv.selectedLcoation = null;
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'Unable to load data.');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public getSiteToEdit(id: string) {
        this.LoaderSrv.showFullScreenLoader();
        new SitesApi()
            .getSiteById(id)
            .subscribe(
                res => {
                    this.siteToEdit = res.Data ?? new SiteModel();
                    this.siteLocationsSrv.site = this.siteToEdit;
                },
                err => {
                    this.AlertSrv.show('error', err.message ?? err.Message ?? 'Unable to load site.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public saveEquipmentDetail() {
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail) {
            if (!this.siteLocationsSrv.selectedLcoation.equipmentDetail.id) {
                this.siteLocationsSrv.selectedLcoation.equipmentDetail.locationId = this.siteLocationsSrv.selectedLcoation.id!;

                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .CreateLocationEquipmentDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.equipmentDetail)
                    .subscribe(
                        res => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            if (res.Data) {
                                this.AlertSrv.show('success', 'Equipment has been saved successfully!');
                                this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);

                                this.checkEquipmentRules(this.siteLocationsSrv.selectedLcoation!.id!);
                            }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save equipment. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocationEquipmentDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.equipmentDetail)
                    .subscribe(
                        res => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            // if (res.Data) {
                            this.AlertSrv.show('success', 'Equipment has been saved successfully!');

                            this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);

                            this.checkEquipmentRules(this.siteLocationsSrv.selectedLcoation!.id!);
                            // }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save equipment. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    public saveDistributionPanel() {
        if (this.siteLocationsSrv.selectedLcoation?.distributionPanel) {
            if (!this.siteLocationsSrv.selectedLcoation.distributionPanel.id) {
                this.siteLocationsSrv.selectedLcoation.distributionPanel.locationId = this.siteLocationsSrv.selectedLcoation.id!;

                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .CreateLocationDistributionPanel(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.distributionPanel)
                    .subscribe(
                        res => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            if (res.Data) {
                                this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');
                                this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocationDistributionPanel(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.distributionPanel)
                    .subscribe(
                        res => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            // if (res.Data) {
                            this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');

                            this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            // }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    // public saveSwitchBoards() {
    //     if (this.siteLocationsSrv.selectedLcoation?.switchBoards) {
    //         const switchBoardsArray = this.siteLocationsSrv.selectedLcoation?.switchBoards;
    //         if (switchBoardsArray && switchBoardsArray.length) {
    //             console.log('this.siteLocationsSrv.selectedLcoation?.switchBoards', this.siteLocationsSrv.selectedLcoation?.switchBoards);
    //             for (let i = 0; i < switchBoardsArray.length; i++) {
    //                 const board = switchBoardsArray[i];
    //                 if (board.id) {
    //                     board.locationId = this.siteLocationsSrv.selectedLcoation.id!;

    //                     this.LoaderSrv.showFullScreenLoader();
    //                     new LocationApi()
    //                         .CreateLocationSwitchBoard(this.siteLocationsSrv.selectedLcoation.id!, board)
    //                         .subscribe(
    //                             res => {
    //                                 console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                                 if (res.Data) {
    //                                     this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');
    //                                     this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
    //                                 }
    //                             },
    //                             err => {
    //                                 console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                                 this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
    //                             }
    //                         )
    //                         .add(() => {
    //                             this.LoaderSrv.hideFullScreenLoader();
    //                         });
    //                 }
    //             }
    //         }

    //         if (!this.siteLocationsSrv.selectedLcoation.switchBoards.id) {
    //             this.siteLocationsSrv.selectedLcoation.switchBoards.locationId = this.siteLocationsSrv.selectedLcoation.id!;

    //             this.LoaderSrv.showFullScreenLoader();
    //             new LocationApi()
    //                 .CreateLocationDistributionPanel(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.distributionPanel)
    //                 .subscribe(
    //                     res => {
    //                         console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                         if (res.Data) {
    //                             this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');
    //                             this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
    //                         }
    //                     },
    //                     err => {
    //                         console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                         this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
    //                     }
    //                 )
    //                 .add(() => {
    //                     this.LoaderSrv.hideFullScreenLoader();
    //                 });
    //         } else {
    //             this.LoaderSrv.showFullScreenLoader();
    //             new LocationApi()
    //                 .UpdateLocationDistributionPanel(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.distributionPanel)
    //                 .subscribe(
    //                     res => {
    //                         console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                         // if (res.Data) {
    //                         this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');

    //                         this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
    //                         // }
    //                     },
    //                     err => {
    //                         console.log(this.siteLocationsSrv.selectedLcoation?.id);
    //                         this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
    //                     }
    //                 )
    //                 .add(() => {
    //                     this.LoaderSrv.hideFullScreenLoader();
    //                 });
    //         }
    //     }
    // }

    public checkRules(locationId: string) {
        this.LoaderSrv.showFullScreenLoader('Checking Location Detail rules.');
        new LocationApi()
            .CheckLocationDetailRules(locationId)
            .subscribe(
                res => {
                    this.AlertSrv.show('success', 'Rules for this Area has been checked.');
                    this.siteLocationsSrv.loadLocationDetailRules(locationId);
                },
                err => {
                    this.AlertSrv.show('error', 'Unable to check rules for this area.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public checkEquipmentRules(locationId: string) {
        this.LoaderSrv.showFullScreenLoader('Checking Location Detail rules.');
        new LocationApi()
            .CheckEquipmentDetailRules(locationId)
            .subscribe(
                res => {
                    this.AlertSrv.show('success', 'Rules for this Equipment has been checked.');
                    this.siteLocationsSrv.loadLocationDetailRules(locationId);
                },
                err => {
                    this.AlertSrv.show('error', 'Unable to check rules for this equipment.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public saveLocationDetail() {
        console.log('saveLocationDetail');
        if (this.siteLocationsSrv.selectedLcoation?.locationDetail) {
            if (!this.siteLocationsSrv.selectedLcoation.locationDetail.id) {
                this.siteLocationsSrv.selectedLcoation.locationDetail.locationId = this.siteLocationsSrv.selectedLcoation.id!;

                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .CreateLocationLocationDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.locationDetail)
                    .subscribe(
                        res => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            if (res.Data) {
                                this.AlertSrv.show('success', 'Location details has been saved successfully!');
                                this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);

                                this.checkRules(this.siteLocationsSrv.selectedLcoation!.id!);

                                this.siteLocationsSrv.getSiteLocations();
                            }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save Location details. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocationLocationDetail(this.siteLocationsSrv.selectedLcoation.id!, this.siteLocationsSrv.selectedLcoation.locationDetail)
                    .subscribe(
                        res => {
                            // if (res.Data) {
                            console.log(this.siteLocationsSrv.selectedLcoation?.locationDetail?.dustLayerNotAppli);
                            this.AlertSrv.show('success', 'Location Details has been saved successfully!');

                            this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);

                            this.checkRules(this.siteLocationsSrv.selectedLcoation!.id!);
                            this.siteLocationsSrv.getSiteLocations();
                            // }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.locationDetail?.dustLayerNotAppli);
                            this.AlertSrv.show('error', 'Unable to save location details. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }
}
