import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop} from 'vue-property-decorator';
@Component
export default class BaseInput extends VueWrapper {
    // Props
    @Prop({default: '', type: String})
    readonly rules!: string;

    @Prop({type: String})
    readonly vid!: string;

    @Prop({default: null})
    readonly value!: string | null;

    @Prop({default: true, type: Boolean})
    readonly outlined!: boolean;

    @Prop({default: false, type: Boolean})
    readonly dense!: boolean;

    @Prop({default: true, type: Boolean})
    readonly edit!: boolean;
}
