import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AccountViewModel, SessionModel} from '@/sdk';
import {AccountSetupService, UserSetupService} from '@/views/shared/services';
import UserSetupComponent from '@/views/user-setup/user-setup.component';
import {Component} from 'vue-property-decorator';
import InviteClientAdminDialogComponent from './invite-client-admin-dialog/invite-client-admin-dialog.component';
import SaveAccountDialogComponent from './save-account-dialog/save-account-dialog.component';

@Component({
    components: {
        SaveAccountDialogComponent,
        UserSetupComponent,
        InviteClientAdminDialogComponent,
    },
})
export default class AccountSetupComponent extends VueWrapper {
    public $refs!: {
        saveAccountDialogRef: SaveAccountDialogComponent;
        userSetupRef: UserSetupComponent;
        inviteClientAdminDialogRef: InviteClientAdminDialogComponent;
    };

    public accountSetupSrv: AccountSetupService = new AccountSetupService();
    public userSetupSrv: UserSetupService = new UserSetupService();

    public selectedAccount: AccountViewModel | null = null;

    public accountId: string = '';

    public created() {
        this.AddSubscription$ = this.UserSessionSrv._session.subscribe(session => {
            this.accountId = session?.selectedAccountId ?? '';
        });
    }

    public mounted() {
        this.accountSetupSrv
            .loadAccounts()
            .then(d => {})
            .catch(e => {});
    }

    public createAccountClick() {
        this.accountSetupSrv.reset();
        this.$refs?.saveAccountDialogRef?.open();
    }

    public inviteClick() {
        this.$refs.inviteClientAdminDialogRef.open();
    }

    public async editClick(account: AccountViewModel) {
        this.accountSetupSrv.reset();
        try {
            this.LoaderSrv.showFullScreenLoader();
            await this.accountSetupSrv.getAccountById(account.id!);
            this.$refs.saveAccountDialogRef?.open();
        } catch (error: any) {
            this.AlertSrv.show('error', error?.message ?? '');
        }

        this.LoaderSrv.hideFullScreenLoader();
    }

    public async markSelectedClick(account: AccountViewModel) {
        if (this.accountId === account.id) return;
        const confirmed = await this.ConfirmSrv.open('Confirm', 'Do you want to mark this account as selected?');
        if (confirmed) {
            this.UserSessionSrv.Session = new SessionModel({...this.UserSessionSrv.Session, selectedAccountId: account.id});
            this.UserSessionSrv.save();
        }
    }

    // public accountUsersClick(account: AccountViewModel) {
    //     this.userSetupSrv.accountUsersList = [];

    //     this.selectedAccount = account;
    // }

    public selectedAccountCancelClick(account: AccountViewModel) {
        this.selectedAccount = null;
    }
}
