var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      attrs: {
        "max-width": "1200px",
        name: _vm.name,
        title: _vm.isProfile ? "My Profile" : "",
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ validate }) {
            return [
              _c(
                "base-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.save(validate)
                    },
                  },
                },
                [_vm._v(_vm._s("Save"))]
              ),
              _c(
                "base-btn",
                {
                  staticClass: "mx-1 white--text",
                  attrs: { color: "grey lighten-1", depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.clearForm()
                    },
                  },
                },
                [_vm._v(" " + _vm._s("Cancel") + " ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    { staticClass: "my-tabs", attrs: { vertical: "" } },
                    [
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            id: "category",
                            "active-class": "side-tab-active min-width-200",
                          },
                        },
                        [_vm._v(" " + _vm._s("Personal Information") + " ")]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            id: "category",
                            "active-class": "side-tab-active min-width-200",
                          },
                        },
                        [_vm._v(" " + _vm._s("Recent Activities") + " ")]
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .firstName,
                                              name: "firstName",
                                              rules: "required|alphabetic",
                                              label: "First Name",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "firstName",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .middleName,
                                              name: "middleName",
                                              rules: "alphabetic",
                                              label: "Middle Name",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "middleName",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .lastName,
                                              name: "lastName",
                                              rules: "required|alphabetic",
                                              label: "Last Name",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "lastName",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("base-select", {
                                            staticClass: "flex-grow-1",
                                            attrs: {
                                              rules: "required",
                                              label: "Account Role",
                                              name: "role",
                                              items: _vm.rolesSrv.accountRoles,
                                              "item-text": "name",
                                              "item-value": "id",
                                              clearable: "",
                                              multiple: false,
                                              "return-object": false,
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .roleId,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "roleId",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              disabled: _vm.isProfile,
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .loginId,
                                              name: "loginId",
                                              rules: "required",
                                              label: "LoginID",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "loginId",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "base-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.setPasswordRef.open()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s("Set Password") +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "ml-2" },
                                            [
                                              !!_vm.userSetupSrv.userToEdit
                                                .password
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "green" },
                                                    },
                                                    [_vm._v("mdi-lock-check")]
                                                  )
                                                : _vm._e(),
                                              !_vm.userSetupSrv.userToEdit
                                                .password
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "grey" },
                                                    },
                                                    [_vm._v("mdi-lock-remove")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !!_vm.userSetupSrv
                                                      .userToEdit.password
                                                      ? "Password Set"
                                                      : "Password is not set!"
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: { cols: "12" },
                                        },
                                        [_c("v-divider")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .email,
                                              name: "email",
                                              rules: "required|email",
                                              label: "Primary Email",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "email",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .secondaryEmail,
                                              name: "secondaryEmail",
                                              rules: "email",
                                              label: "Secondary Email",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "secondaryEmail",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .companyName,
                                              name: "companyName",
                                              label: "Company Name",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "companyName",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .companyWebsite,
                                              name: "companyWebsite",
                                              label: "Company Website",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "companyWebsite",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .workEmail,
                                              name: "workEmail",
                                              rules: "email",
                                              label: "Work Email",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "workEmail",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("PhoneNumberInputComponent", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .workPhone,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "workPhone",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("PhoneNumberInputComponent", {
                                            attrs: {
                                              value:
                                                _vm.userSetupSrv.userToEdit
                                                  .mobilePhone,
                                              name: "mobilePhone",
                                              label: "Mobile Phone",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.userSetupSrv.userToEdit,
                                                  "mobilePhone",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-tab-item", [
                        _c("div", [_vm._v("Recent Activities")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SetPasswordDialogComponent", {
        ref: "setPasswordRef",
        on: { password: _vm.onPassword },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }