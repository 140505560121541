var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    _vm._g(
      _vm._b(
        { staticClass: "round-0 overflow-hidden" },
        "v-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.$slots.header
        ? _c("div", { staticClass: "py-3 px-5 title" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }