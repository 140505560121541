var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-5 mb-5" },
        [
          _c("v-row", [_c("h3", [_vm._v("Account Creation")])]),
          _c("v-row", {
            staticStyle: {
              border: "2px solid",
              height: "100%",
              "background-color": "#dee2e6",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("base-text-field", {
                            attrs: {
                              name: "FirstName",
                              label: "First Name",
                              value: _vm.firstName,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.firstName = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("base-text-field", {
                            attrs: {
                              name: "LastName",
                              label: "Last Name",
                              value: _vm.lastName,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.lastName = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("base-text-field", {
                            attrs: {
                              name: "Email",
                              label: "Email",
                              value: _vm.email,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.email = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("base-text-field", {
                            attrs: {
                              name: "Password",
                              label: "Password",
                              value: _vm.password,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.password = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { width: "200px", color: "green" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createAccount()
                                        },
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }