import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SessionModel} from '@/sdk';
import {AutentictionApi} from '@/sdk/api-services';
import {RolesService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';

@Component
export default class SigninComponent extends VueWrapper {
    // public orgCode: String | null = null;
    public loginId: String | null = null;
    public password: String | null = null;

    public show1: boolean = false;

    public authenticationApi: AutentictionApi = new AutentictionApi();

    public localeChange() {
        localStorage.setItem('locale', this.$i18n.locale);
    }

    public goToLandingPage() {
        console.log('go to Home page clicked');

        this.LoaderSrv.showFullScreenLoader();
        this.authenticationApi
            .Login({
                loginId: this.loginId,
                password: this.password,
                RememberMe: true,
            })
            .subscribe(
                res => {
                    if (res.Data) {
                        const s = new SessionModel({...res.Data, selectedAccountId: res.Data.accountId});
                        this.UserSessionSrv._session.next(s);
                        this.UserSessionSrv.save();

                        if (this.UserSessionSrv.Session?.roleId) {
                            new RolesService().getRolePermissions(this.UserSessionSrv.Session.roleId);
                        }

                        this.$router.push({name: 'sites-list'});
                    }
                    console.log(res);
                },
                err => {
                    console.log('err', err);
                    this.AlertSrv.show('error', '$t.Common.Login.Error');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
