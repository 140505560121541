import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {LocationModel} from '@/sdk';
import {LocationApi} from '@/sdk/api-services/location.api';
import {Component, Prop} from 'vue-property-decorator';
import {LocationDcoumentationService} from '../../services';
import {SiteLocationService} from '../../services/site-location.service';

@Component({
    name: 'LocationListItemComponent',
    components: {
        // LocationListItemComponent,
    },
})
export default class LocationListItemComponent extends VueWrapper {
    @Prop({
        // type: Any,
        required: true,
    })
    public parentId!: string;

    @Prop({
        type: Number,
        default: 1,
        required: true,
    })
    public level!: number;

    @Prop({
        type: String,
        required: true,
    })
    public parentType!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readonly!: boolean;

    @Prop({
        type: Array,
    })
    public locations!: Array<LocationModel>;

    @Prop({
        type: Boolean,
        default: true,
    })
    public draggable!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public diagramViewStyle!: boolean | false;

    // public treeData: Array<any> = [];

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();
    public locationDocumentationSrv: LocationDcoumentationService = new LocationDcoumentationService();

    public mounted() {
        // this.treeData = [
        //     {
        //         id: '1',
        //         type: 'area',
        //         title: 'Area 1',
        //         siteId: '585425cd-ac7d-4d38-aa9a-296817eaf636',
        //     },
        //     {
        //         id: '2',
        //         type: 'area',
        //         title: 'Area 2',
        //         siteId: '585425cd-ac7d-4d38-aa9a-296817eaf636',
        //     },
        //     {
        //         id: '1',
        //         type: 'sub-area',
        //         title: 'Subarea 1',
        //         areaId: '1',
        //     },
        // ];
    }

    public locationIcon(location: LocationModel) {
        if (this.diagramViewStyle) {
            if (location.type === 'location') {
                return '/images/area.png';
            } else if (location.type === 'equipment') {
                return '/images/equip.png';
            } else if (location.type === 'distribution') {
                return '/images/dp.png';
            }
        } else {
            if (location.type === 'location') {
                return '/images/common/area-lg.png';
            } else if (location.type === 'equipment') {
                return '/images/common/equipment.png';
            } else if (location.type === 'distribution') {
                return '/images/common/distribution-dark.png';
            }
        }
        return '';
    }

    public get getLocations() {
        if (this.parentType === 'site') {
            return this.locations?.filter(x => x.siteId === this.parentId && !x.locationId)?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0)) ?? [];
        }
        // if (this.parentType === 'area') {
        //     return this.locations?.filter(x => x.areaId === this.parentId) ?? [];
        // }
        // console.log(
        //     this.parentType,
        //     this.parentId,
        //     this.locations,
        //     this.locations?.filter(x => x.locationId === this.parentId)
        // );
        return this.locations?.filter(x => x.locationId === this.parentId)?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0)) ?? [];
    }

    public expandClick(location: LocationModel) {
        console.log('is expanded', location.expanded);
        location.expanded = !location.expanded;
    }

    public onDragStart(event: any) {
        event.dataTransfer.setData('id', event.target.id);

        this.siteLocationsSrv.selectedItemId = event.target.id;
        this.siteLocationsSrv.selectedItemElem = event.target;

        if (this.readonly) return;

        if (this.siteLocationsSrv.selectedItemId) {
            this.siteLocationsSrv.selectedDragItem = this.siteLocationsSrv.siteLocations.find(x => x.id === this.siteLocationsSrv.selectedItemId) ?? null;
        }
    }

    public allowDrop(ev: any) {
        if (this.readonly) return;

        ev.preventDefault();
        // console.log(ev.target);
        // this.enterItemId = ev?.target?.id;
        this.siteLocationsSrv.enterItemId = ev.target.closest('.list-item-box')?.id;
    }

    public onItemDrop(event: any) {
        if (this.readonly) return;
        event.preventDefault();
        this.siteLocationsSrv.enterItemId = null;

        const droppedOverId = event.target.closest('.list-item-box')?.id;

        const found = this.siteLocationsSrv.siteLocations.find(x => x.id === droppedOverId);

        console.log(droppedOverId, found);
        if (
            !!droppedOverId &&
            !!this.siteLocationsSrv.selectedItemId &&
            !!this.siteLocationsSrv.selectedDragItem &&
            droppedOverId !== this.siteLocationsSrv.selectedItemId &&
            found?.locationId !== this.siteLocationsSrv.selectedItemId
        ) {
            if (found && found.type === 'location') {
                this.siteLocationsSrv.updateParentId(this.siteLocationsSrv.selectedItemId, droppedOverId);
            } else if (found && found?.locationId === this.siteLocationsSrv.selectedDragItem?.locationId) {
                const allChilds = this.siteLocationsSrv.siteLocations
                    .filter(x => x.locationId === found?.locationId)
                    .sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0));

                if ((this.siteLocationsSrv.selectedDragItem.sequence ?? 0) > (found?.sequence ?? 0)) {
                    this.siteLocationsSrv.selectedDragItem.sequence = found.sequence ?? 0;

                    this.LoaderSrv.showFullScreenLoader();
                    const promises = [];
                    promises.push(this.siteLocationsSrv.updateSequence(this.siteLocationsSrv.selectedItemId!, found.sequence ?? 0));

                    const droppedOverIndex = allChilds.findIndex(x => x.id === droppedOverId);

                    if (droppedOverIndex !== -1) {
                        let startInd = this.siteLocationsSrv.selectedDragItem.sequence ?? 0;
                        for (let i = droppedOverIndex; i < allChilds.length; i++) {
                            const child = allChilds[i];

                            startInd++;
                            if (this.siteLocationsSrv.selectedDragItem.id !== child.id) {
                                promises.push(this.siteLocationsSrv.updateSequence(child.id!, startInd));
                            }
                        }
                    }

                    Promise.all(promises)
                        .then(res => {
                            this.siteLocationsSrv.getSiteLocations();
                        })
                        .finally(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                } else {
                    this.siteLocationsSrv.selectedDragItem.sequence = found.sequence ?? 0;

                    this.LoaderSrv.showFullScreenLoader();
                    const promises = [];
                    promises.push(this.siteLocationsSrv.updateSequence(this.siteLocationsSrv.selectedItemId!, found.sequence ?? 0));

                    const droppedOverIndex = allChilds.findIndex(x => x.id === droppedOverId);

                    if (droppedOverIndex !== -1) {
                        let startInd = this.siteLocationsSrv.selectedDragItem.sequence ?? 0;
                        for (let i = droppedOverIndex; i >= 0; i--) {
                            const child = allChilds[i];

                            startInd--;
                            if (this.siteLocationsSrv.selectedDragItem.id !== child.id) {
                                promises.push(this.siteLocationsSrv.updateSequence(child.id!, startInd));
                            }
                        }
                    }

                    Promise.all(promises)
                        .then(res => {
                            this.siteLocationsSrv.getSiteLocations();
                        })
                        .finally(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            }
        }
    }

    public onDragEnter(event: any) {
        if (this.readonly) return;

        event.preventDefault();
        this.siteLocationsSrv.enterItemId = event?.target?.id;
        console.log('enter: ', event?.target?.id);
    }
    public onDragLeave(event: any) {
        if (this.readonly) return;
        console.log('leave:', event?.target?.id);
        // this.enterItemId = null;
    }

    public locationItemClick(location: LocationModel) {
        //
    }

    public hasSubLocations(id: any) {
        return this.locations?.some(x => x.locationId === id);
    }

    public addSubItemClick(location: LocationModel) {
        this.siteLocationsSrv.openSiteLocationDialog(location, null, {addAt: 'sub', level: this.level});
    }

    public adItemClick(location: LocationModel) {
        const loc = this.locations.find(x => x.id === location.locationId);
        this.siteLocationsSrv.openSiteLocationDialog(loc, null, {addAt: 'same', level: this.level});
    }

    public addEquipment(location: LocationModel) {
        this.siteLocationsSrv.openSiteLocationDialog(location, null, {addAt: 'eqp', level: this.level});
    }

    public addDistribution(location: LocationModel) {
        this.siteLocationsSrv.openSiteLocationDialog(location, null, {addAt: 'dist', level: this.level});
    }

    public editLocation(location: LocationModel) {
        const loc = this.locations.find(x => x.id === location.locationId);
        console.log(loc, location);
        this.siteLocationsSrv.openSiteLocationDialog(loc, location, {addAt: 'edit', level: this.level});
    }

    public addFolderClick(location: LocationModel) {
        this.locationDocumentationSrv.openAddFolderDialog(location);
    }

    public addFileClick(location: LocationModel) {
        this.locationDocumentationSrv.openAddFileDialog(location);
    }

    public deleteLocation(location: LocationModel) {
        this.ConfirmSrv.open('Delete Location', 'Are you sure you want to delete this location?').then(y => {
            if (y) {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .deleteLocation(location.id!)
                    .subscribe(
                        res => {
                            this.siteLocationsSrv.deleteLocationFromCanvas(location.id!);
                            this.AlertSrv.show('success', 'Location has been deleted successfully!');
                            this.siteLocationsSrv.getSiteLocations();
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to delete this location. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }
}
