import {AnyObject} from '@/globals';
import {JobNormalWworkPermitSignModel} from './job-normal-work-permit-sign.model';
import {IndividualProtectionEquipmentViewModel} from './job-normal-wp-to-be-filled-by-contractor-view.model';

export class JobNormalWorkPermit {
    id?: string | null = null;
    workPermitNo?: number | null = null;

    status: string | null = null;

    workOrderNumber: string | null = null;
    location?: string | null = null;
    issueDate?: Date | null = null;

    subcontractor?: string | null = null;
    areaOfWork?: string | null = null;
    startingDate?: string | null = null;
    startingTime?: string | null = null;
    endingDate?: string | null = null;
    endingTime?: string | null = null;

    // subContractorReceivingAuthoritySigned?: boolean | null = null;
    // subContractorReceivingAuthorityUserId?: string | null = null;
    // subContractorReceivingAuthoritySignDate?: string | null = null;
    // subContractorReceivingAuthoritySignTime?: string | null = null;

    // subContractorPerformingAuthoritySigned?: boolean | null = null;
    // subContractorPerformingAuthorityUserId?: string | null = null;
    // subContractorPerformingAuthoritySignDate?: string | null = null;
    // subContractorPerformingAuthoritySignTime?: string | null = null;

    description?: string | null = null;
    workEquipment?: string | null = null;

    jobId: string | null = null;
    permitCreaterUserId: string | null = null;

    workPermitKey: string | null = null;
    created?: Date | null = null;

    workPermitStatus?: number | null = null;
    safetyGuidelineStatus?: number | null = null;

    individualProtectionEquipment: IndividualProtectionEquipmentViewModel | null = null;
    commonProtectionEquipment?: string | null = null;
    otherSafetyMeasures?: string | null = null;
    electricalPTWRequired?: boolean | null = null;
    electricalPTWNumber?: string | null = null;
    confinedSpacePTWRequired?: boolean;
    confinedSpacePTWNumber?: string | null = null;
    electricalOrConfinedPTWRequired?: boolean | null = null;

    jobNormalWorkPermitSigns: Array<JobNormalWworkPermitSignModel> = [];

    constructor(data?: Partial<JobNormalWorkPermit>) {
        Object.assign(this, data);
    }
}

export class JobAssignedInspectionForm {
    id?: string | null = null;
    jobId: string | null = null;
    inspectionFormKey: string | null = null;
    created?: Date | null = null;

    constructor(data?: Partial<JobAssignedInspectionForm>) {
        Object.assign(this, data);
    }
}
