var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column height-100x overflow-hidden" },
    [
      _c("div", { staticClass: "grey lighten-3 mb-4 flex-shrink-0" }, [
        _c(
          "div",
          { staticClass: "d-flex results-header" },
          [
            _c("div", { staticClass: "px-5" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-img", {
                    staticClass: "area-search header-img-title",
                    attrs: {
                      height: "20",
                      width: "20",
                      contain: "",
                      src: require("@/assets/icons/jobs-lg.png"),
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "h2",
                      {
                        staticClass: "areaTitle",
                        staticStyle: { color: "white" },
                      },
                      [
                        _vm._v(" JOB DETAILS "),
                        _c("span", { staticClass: "subtitle-1" }, [
                          _vm._v(
                            "(Job ID: " +
                              _vm._s(
                                _vm.jobsSrv.selectedJob
                                  ? _vm.jobsSrv.selectedJob.id
                                  : ""
                              ) +
                              ")"
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "d-flex white--text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.jobsSrv.selectedJob
                            ? _vm.jobsSrv.selectedJob.eqPath
                            : ""
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex" }),
            ]),
            _c("v-spacer"),
            _c("div", { staticClass: "px-3 pt-1" }),
          ],
          1
        ),
        _c("div", {
          staticStyle: { border: "2px solid", "background-color": "#dee2e6" },
        }),
      ]),
      _c(
        "div",
        { staticClass: "flex-grow-1 flex-1 overflow-auto" },
        [
          _vm.jobsSrv.selectedJob
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "my-tabs",
                      staticStyle: { "background-color": "grey" },
                      attrs: { vertical: "" },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "min-width-180",
                          attrs: { "active-class": "side-tab-active" },
                        },
                        [_vm._v("Job Status")]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { "active-class": "side-tab-active" },
                          on: { click: _vm.jobDetailsTabClick },
                        },
                        [_vm._v("Job Details")]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [_vm._v("Job Personnel")]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { "active-class": "side-tab-active" } },
                        [_vm._v("Work Permits")]
                      ),
                      _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                        ? _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Inspection Forms")]
                          )
                        : _vm._e(),
                      _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                        ? _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Job Form")]
                          )
                        : _vm._e(),
                      _c("v-tab-item", [_c("JobStatusComponent")], 1),
                      _c(
                        "v-tab-item",
                        [
                          _c("JobDetailsFormComponent", {
                            key: _vm.jobsSrv.selectedJob.id,
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "px-3 mb-2",
                              attrs: {
                                "show-arrows": true,
                                height: "35px",
                                centered: "",
                              },
                              model: {
                                value: _vm.jobPersonnelsTab,
                                callback: function ($$v) {
                                  _vm.jobPersonnelsTab = $$v
                                },
                                expression: "jobPersonnelsTab",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  staticStyle: { border: "1px solid #d3d3d3" },
                                  attrs: { "active-class": "side-tab-active" },
                                },
                                [_vm._v("Job Managers")]
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticStyle: { border: "1px solid #d3d3d3" },
                                  attrs: { "active-class": "side-tab-active" },
                                },
                                [_vm._v("Workers")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.jobPersonnelsTab,
                                callback: function ($$v) {
                                  _vm.jobPersonnelsTab = $$v
                                },
                                expression: "jobPersonnelsTab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c("JobLinkJobManagerComponent", {
                                    key: _vm.jobsSrv.selectedJob.id,
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                [
                                  _c("JobLinkWorkerComponent", {
                                    key: _vm.jobsSrv.selectedJob.id,
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c("WorkPermitHistoryComponent", {
                            key: _vm.jobsSrv.selectedJob.id,
                          }),
                        ],
                        1
                      ),
                      _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                        ? _c("v-tab-item", [
                            _c("div", [
                              _c("div", { staticClass: "px-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "base-btn",
                                      {
                                        staticClass: "ml-auto",
                                        attrs: {
                                          disabled:
                                            _vm.jobsSrv.selectedInspectionForms
                                              .length === 0,
                                          "x-small": "",
                                        },
                                        on: {
                                          click:
                                            _vm.saveAssignedInspectionForms,
                                        },
                                      },
                                      [_vm._v(" Save Inspection Forms ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("hr", { staticClass: "mb-2" }),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: {
                                        "show-arrows": true,
                                        height: "35px",
                                        centered: "",
                                      },
                                      on: { change: _vm.inspectionTabChange },
                                      model: {
                                        value: _vm.inspectionTab,
                                        callback: function ($$v) {
                                          _vm.inspectionTab = $$v
                                        },
                                        expression: "inspectionTab",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.jobsInspectionFormSrv
                                          .jobInspectionForms,
                                        function (inspForm) {
                                          return [
                                            _c(
                                              "v-tab",
                                              {
                                                key: inspForm.id,
                                                staticStyle: {
                                                  border: "1px solid #d3d3d3",
                                                },
                                                attrs: {
                                                  "active-class":
                                                    "side-tab-active",
                                                  "tab-value": inspForm.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Inspection Form (" +
                                                    _vm._s(inspForm.title) +
                                                    ") "
                                                ),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-tabs-items",
                                    {
                                      model: {
                                        value: _vm.inspectionTab,
                                        callback: function ($$v) {
                                          _vm.inspectionTab = $$v
                                        },
                                        expression: "inspectionTab",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.jobsInspectionFormSrv
                                          .jobInspectionForms,
                                        function (inspF) {
                                          return [
                                            _c(
                                              "v-tab-item",
                                              {
                                                key: inspF.id,
                                                attrs: { value: inspF.id },
                                              },
                                              [
                                                _c(
                                                  "SingleInspectionFormComponent",
                                                  {
                                                    attrs: {
                                                      inspectionFormId:
                                                        inspF.id,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                        ? _c("v-tab-item", [
                            _c(
                              "div",
                              [
                                _c("JobCorrectiveActionStatusComponent", {
                                  key: _vm.jobsSrv.selectedJob.id,
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }