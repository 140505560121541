var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-form", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "base-dialog",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      "content-class": "foex-dialog",
                      name: _vm.name,
                      "max-width": "450px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("Dialogs.SaveFieldOption.Title")
                                  ) +
                                  " "
                              ),
                              _vm._t("header"),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "dialogFooter",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "base-btn",
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        disabled: invalid,
                                        color: "admin-primary",
                                      },
                                      on: { click: _vm.saveOption },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Common.Save")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "base-dialog",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              ),
              [
                _c("div", [
                  _c(
                    "div",
                    [
                      _c("base-text-field", {
                        attrs: {
                          label: _vm.$t("Fields.Common.Name.Label"),
                          rules: "required",
                          value: _vm.adminDataFieldsSrv.newOption.value,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv.newOption,
                              "value",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("base-text-field", {
                        attrs: {
                          label: _vm.$t("Fields.Common.RegressPriority.Label"),
                          type: "number",
                          value:
                            _vm.adminDataFieldsSrv.newOption.regressPriority,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv.newOption,
                              "regressPriority",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("base-checkbox", {
                        attrs: {
                          label: _vm.$t("Fields.Common.IsDependent.Label"),
                          inputValue: _vm.adminDataFieldsSrv.isDependent,
                        },
                        on: {
                          "update:inputValue": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv,
                              "isDependent",
                              $event
                            )
                          },
                          "update:input-value": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv,
                              "isDependent",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("base-autocomplete", {
                        attrs: {
                          value:
                            _vm.adminDataFieldsSrv.newOption.dependentOnFieldId,
                          items:
                            _vm.currentTab === "area"
                              ? _vm.adminDataFieldsSrv.areaFields
                              : _vm.adminDataFieldsSrv.equipmentFields,
                          "item-text": "title",
                          "item-value": "id",
                          label: _vm.$t("Fields.Common.DependentOnField.Label"),
                          disabled: !_vm.adminDataFieldsSrv.isDependent,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv.newOption,
                              "dependentOnFieldId",
                              $event
                            )
                          },
                          change: _vm.onFieldChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("base-autocomplete", {
                        attrs: {
                          disabled:
                            !_vm.adminDataFieldsSrv.isDependent ||
                            !_vm.adminDataFieldsSrv.newOption
                              .dependentOnFieldId,
                          items:
                            _vm.adminDataFieldsSrv.selectedFieldOptions[
                              _vm.adminDataFieldsSrv.newOption
                                .dependentOnFieldId
                            ],
                          value:
                            _vm.adminDataFieldsSrv.newOption
                              .dependentOnFieldOptionId,
                          "item-text": "value",
                          "item-value": "id",
                          label: _vm.$t("Fields.Common.FieldOption.Label"),
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.adminDataFieldsSrv.newOption,
                              "dependentOnFieldOptionId",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }