var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.isReadOnly
        ? _c("div", { staticClass: "px-3" }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "base-btn",
                  {
                    staticClass: "ml-auto",
                    attrs: { small: "" },
                    on: { click: _vm.saveJobDetails },
                  },
                  [_vm._v(" Save Job Details ")]
                ),
              ],
              1
            ),
            _c("hr", { staticClass: "mb-2" }),
          ])
        : _vm._e(),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "orderNumber",
                          label: "Order #",
                          disabled: "",
                          value: _vm.jobsSrv.selectedJob.sequence,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "sequence",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "jobId",
                          label: "Job ID",
                          disabled: "",
                          value: _vm.jobsSrv.selectedJob.id,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "id",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "equipmentId",
                          label: "Equipment ID",
                          disabled: "",
                          value:
                            _vm.jobsSrv.selectedJob.location.equipmentDetail.id,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob.location.equipmentDetail,
                              "id",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "equipmentName",
                          label: "Equipment Name",
                          disabled: "",
                          value: _vm.jobsSrv.selectedJob.location.title,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob.location,
                              "title",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "equipmentClass",
                          label: "Equipment Class",
                          disabled: "",
                          value:
                            _vm.jobsSrv.selectedJob.location.equipmentDetail
                              .equipmentClassification,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob.location.equipmentDetail,
                              "equipmentClassification",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "areaExInformation",
                          label: "Area Ex Information (Gas Area/Dust Area)",
                          disabled: "",
                          value: _vm.jobsSrv.selectedJob.areaExInformation,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "areaExInformation",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-text-field", {
                        staticStyle: { color: "#fdf7e7" },
                        attrs: {
                          name: "eqExCode",
                          label: "Eq Ex Code",
                          disabled: "",
                          value: _vm.jobsSrv.selectedJob.eqExCode,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "eqExCode",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-autocomplete", {
                        staticClass: "flex-grow-1",
                        attrs: {
                          disabled: _vm.isReadOnly,
                          name: "JobType",
                          items: _vm.$formOptions.jobTypes,
                          value: _vm.jobsSrv.selectedJob.jobType,
                          "item-text": "value",
                          "item-value": "key",
                          label: "Job Type",
                          clearable: "",
                          multiple: false,
                          "return-object": false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "jobType",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider", { staticClass: "mb-3" }),
                          !_vm.isReadOnly
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between mb-2",
                                },
                                [
                                  _c("h4", [_vm._v("Non Conformity Records")]),
                                  _c(
                                    "base-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: _vm.addNewNonConformityRecord,
                                      },
                                    },
                                    [_vm._v("Add Non Conformity Record")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "overflow-x-auto" }, [
                            _c("table", { staticClass: "foex-table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _c("div", { staticClass: "width-100px" }, [
                                      _vm._v("Type"),
                                    ]),
                                  ]),
                                  _c("th", [
                                    _c("div", { staticClass: "width-100px" }, [
                                      _vm._v("Fault Code"),
                                    ]),
                                  ]),
                                  _c("th", [
                                    _c("div", { staticClass: "width-100x" }, [
                                      _vm._v("Items"),
                                    ]),
                                  ]),
                                  _c("th", [
                                    _c("div", { staticClass: "width-100x" }, [
                                      _vm._v("Results"),
                                    ]),
                                  ]),
                                  _c("th", [
                                    _c("div", { staticClass: "width-100x" }, [
                                      _vm._v("Comment"),
                                    ]),
                                  ]),
                                  _c("th", [_c("div", [_vm._v("Actions")])]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    _vm.jobsSrv.jobNonConformityRecords,
                                    function (ncr) {
                                      return [
                                        !ncr.editable
                                          ? _c("tr", { key: ncr.id }, [
                                              _c("td", [
                                                _vm._v(_vm._s(ncr.type)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(ncr.faultCode)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(ncr.item)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(ncr.result)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(ncr.comment)),
                                              ]),
                                              _c("td", [
                                                !_vm.isReadOnly
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editJobNonConformityRecord(
                                                                  ncr
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteNonConformityRecord(
                                                                  ncr
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.uploadNonConformityRecordFile(
                                                                  ncr
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-upload"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editJobNonConformityRecord(
                                                                  ncr
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-eye"),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        ncr.editable && !_vm.isReadOnly
                                          ? _c(
                                              "tr",
                                              { key: `edit-${ncr.id}` },
                                              [
                                                _c(
                                                  "td",
                                                  [
                                                    _c("base-text-field", {
                                                      staticStyle: {
                                                        color: "#fdf7e7",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.isReadOnly,
                                                        name: "type",
                                                        label: "Type",
                                                        value: ncr.type,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              ncr,
                                                              "type",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("base-text-field", {
                                                      staticStyle: {
                                                        color: "#fdf7e7",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.isReadOnly,
                                                        name: "FaultCode",
                                                        label: "Fault Code",
                                                        value: ncr.faultCode,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              ncr,
                                                              "faultCode",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("base-text-field", {
                                                      staticStyle: {
                                                        color: "#fdf7e7",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.isReadOnly,
                                                        name: "item",
                                                        label: "Item",
                                                        value: ncr.item,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              ncr,
                                                              "item",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("base-text-field", {
                                                      staticStyle: {
                                                        color: "#fdf7e7",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.isReadOnly,
                                                        name: "result",
                                                        label: "Result",
                                                        value: ncr.result,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              ncr,
                                                              "result",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("base-text-field", {
                                                      staticStyle: {
                                                        color: "#fdf7e7",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.isReadOnly,
                                                        name: "comment",
                                                        label: "Comment",
                                                        value: ncr.comment,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              ncr,
                                                              "comment",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "base-btn",
                                                      {
                                                        staticClass: "mx-2",
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveNonConformityRecord(
                                                              ncr
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-content-save"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "base-btn",
                                                      {
                                                        staticClass: "mx-2",
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.cancelNonConformityRecordEdit(
                                                              ncr
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-cancel"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _c("v-divider", { staticClass: "my-3" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                    ? [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("base-autocomplete", {
                              staticClass: "flex-grow-1",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "typeOfInspection",
                                items: _vm.$formOptions.typesOfInspection,
                                value: _vm.jobsSrv.selectedJob.typeOfInspection,
                                "item-text": "value",
                                "item-value": "key",
                                label: "Type of Inspection",
                                clearable: "",
                                multiple: false,
                                "return-object": false,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.jobsSrv.selectedJob,
                                    "typeOfInspection",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("base-autocomplete", {
                              staticClass: "flex-grow-1",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "Grade of Inspection",
                                items: _vm.$formOptions.gradeofInspection,
                                value:
                                  _vm.jobsSrv.selectedJob.gradeOfInspection,
                                "item-text": "value",
                                "item-value": "key",
                                label: "Grade of Inspection",
                                clearable: "",
                                multiple: true,
                                "return-object": false,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.jobsSrv.selectedJob,
                                    "gradeOfInspection",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                    ? [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("base-text-field", {
                              staticStyle: { color: "#fdf7e7" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "causeOfFault",
                                label: "Cause of Fault",
                                value: _vm.jobsSrv.selectedJob.causeOfFault,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.jobsSrv.selectedJob,
                                    "causeOfFault",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("base-autocomplete", {
                              staticClass: "flex-grow-1",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "actionType",
                                items: _vm.$formOptions.actionTypes,
                                value: _vm.jobsSrv.selectedJob.actionType,
                                "item-text": "value",
                                "item-value": "key",
                                label: "Action Type",
                                clearable: "",
                                multiple: false,
                                "return-object": false,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.jobsSrv.selectedJob,
                                    "actionType",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("base-textarea", {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                label: "Action Plan Detail",
                                name: "actionPlanDetail",
                                value: _vm.jobsSrv.selectedJob.actionPlanDetail,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.jobsSrv.selectedJob,
                                    "actionPlanDetail",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          disabled: _vm.isReadOnly,
                          format: false,
                          label: "Start Date",
                          value: _vm.jobsSrv.selectedJob.startDate,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "startDate",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          disabled: _vm.isReadOnly,
                          format: false,
                          label: "End Date",
                          value: _vm.jobsSrv.selectedJob.endDate,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.jobsSrv.selectedJob,
                              "endDate",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("JobNonConformityRecordFilesDialogComponent", {
        ref: "jobNonConformityRecordFilesDialog",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }