var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-5" },
        [
          _c("v-row", [_c("h3", [_vm._v("INFORMATION DETAILS")])]),
          _c(
            "v-row",
            { staticStyle: { height: "20%", "margin-bottom": "10px" } },
            [
              _c("h3", { staticStyle: { "background-color": "#e4e7eb" } }, [
                _vm._v("POINT 2"),
              ]),
            ]
          ),
          _c("v-row", {
            staticStyle: {
              border: "2px solid",
              height: "100%",
              "background-color": "#dee2e6",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Area")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", clearable: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: { id: "colName" },
                          },
                          [_vm._v("Area Classification Drawing")]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-file-input", {
                            attrs: {
                              dense: "",
                              accept: "image/*",
                              label: "Image Upload",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "start" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { width: "200px", color: "green" },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "start" } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { width: "200px", color: "red" } },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            { attrs: { width: "200px", color: "red" } },
                            [_vm._v("Warning Information")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }