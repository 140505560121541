import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AccountViewModel, SessionModel, UserSession} from '@/sdk';
import {CoreService} from '@/services/core.service';
import {AccountSetupService, UserSetupService} from '@/views/shared/services';
import SaveUserDialogComponent from '@/views/user-setup/save-user-dialog/save-user-dialog.component';
import {Component} from 'vue-property-decorator';
@Component({
    components: {
        SaveUserDialogComponent,
        // ClientDrawerComponent,
        // LoginSignUpComponent
    },
})
export default class AdminAppBarComponent extends VueWrapper {
    public $refs!: {
        saveUserDialogRef: SaveUserDialogComponent;
    };

    public CoreSrv = new CoreService();
    public User = {};
    // public UserSrv = new UsersService();
    public userSession = new UserSession();

    public UserMenu = false;
    public UserMenuMobile = false;
    public myload = true;
    public whyMhParks = false;
    public featureMenus = false;

    public height = 70;

    public Session = new SessionModel();

    public customer: boolean = false;
    public myName = '';
    public UserName = ` ${new UserSession().Session?.FirstName}  ${new UserSession().Session?.LastName} `;

    public created() {
        this.accountSetupSrv.loadAccounts();
        this.AddSubscription$ = this.userSession._session.subscribe(session => {
            this.Session = session ?? new SessionModel();

            if (this.Session.token && !this.Session.loginId) {
                this.UserLogout();
            }
        });

        if (this.UserSessionSrv.isUserAuthenticated) {
            // this.UserSrv.getById(new UserSession().Session?.UserId!);
        }
    }

    public accountsList: Array<AccountViewModel> = [];

    public accountSetupSrv: AccountSetupService = new AccountSetupService();

    public mounted() {
        this.AddSubscription$ = this.accountSetupSrv.accountsList$.subscribe(x => {
            this.accountsList = x;
        });
    }

    public messages = 2;
    public items = [
        {
            title: 'My profile',
            icon: 'mdi-account',
            link: '',
            availableTo: 'Everyone',
            method: this.MyProfileClick,
        },
        {
            title: 'Main Site',
            icon: 'mdi-arrow-right-bold-circle-outline',
            href: '/home',
            // link: '/home',
            availableTo: 'Everyone',
            method: () => {},
        },

        {
            title: 'Logout',
            icon: 'mdi-logout',

            link: '',
            availableTo: 'Everyone',
            method: this.UserLogout,
        },
    ];

    public MyProfileClick() {
        new UserSetupService().getUserToEdit(this.userSession.Session!.userId!, true).then(() => {
            this.$refs.saveUserDialogRef.open();
        });
    }

    public UserLogout() {
        this.UserMenu = false;
        this.userSession.clear();
        this.$router.push({name: 'signin'});
    }

    public supports = [
        {
            title: 'Knowledge Base Support',
            icon: 'mdi-book-search',
            href: 'https://help.mhparks.com',
            // link: '/user/knowledge-base-support',
            method: () => {
                // this.CoreSrv.knowledgeDrawer.Mode = true;
            },
        },
        {
            title: 'Contact Support',
            icon: 'mdi-email-open-outline',
            // name: 'Profile',
            // link: '/user/contact-support',
            method: () => {
                this.CoreSrv.dialog.contactSupport = true;
            },
        },

        {
            title: 'Report Abuse',
            icon: 'mdi-ambulance',
            href: 'https://help.mhparks.com',

            // link: '/user/report-abuse'
            method: () => {
                // this.CoreSrv.dialog.reportAbuse = true;
                // this.LookupSrv.getLookups(LookupType.TICKET_CATEGORIES, LookupType.REPORT_ABUSE);
            },
        },
        {
            title: 'Submit Ticket',
            icon: 'mdi-lifebuoy',
            href: ' https://help.mhparks.com ',
            // link: '/user/add-support-ticket',
            method: () => {
                // this.CoreSrv.dialog.submitTicket = true;
                // this.LookupSrv.Filter = this.CoreSrv.DropDownFilter;
                // this.LookupSrv.getLookups(LookupType.TICKET_CATEGORIES);
            },
        },
    ];

    public tasks = [
        {title: 'Task 1', value: '40', color: 'green'},
        {title: 'Task 2', value: '20', color: 'blue'},
        {title: 'Task 3', value: '60', color: 'orange'},
        {title: 'Task 4', value: '80', color: 'red'},
    ];

    Clicked(name: string) {
        if (name === 'Logout') {
            this.$router.push({name: 'Logout'});
        }
    }

    // bugIconClick() {
    //     this.CoreSrv.OpenModal('bug-tracker-dialog');
    // }
}
