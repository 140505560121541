var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column height-100x" },
    [
      _c(
        "div",
        { staticClass: "flex-shrink-0 px-1" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "8" } },
                [
                  _c("v-text-field", {
                    staticClass: "mr-2",
                    attrs: {
                      height: "20px",
                      dense: "",
                      outlined: "",
                      clearable: "",
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-inner",
                        fn: function () {
                          return [
                            _c("v-img", {
                              attrs: {
                                height: "16",
                                width: "30",
                                contain: "",
                                src: require("@/assets/icons/Search-light.png"),
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex tabs-search", attrs: { cols: "4" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticStyle: {
                        padding: "0px 25px 0px 20px",
                        width: "70%",
                      },
                      attrs: {
                        height: "35px",
                        "hide-slider": "",
                        "fixed-tabs": "",
                      },
                      model: {
                        value: _vm.searchTab,
                        callback: function ($$v) {
                          _vm.searchTab = $$v
                        },
                        expression: "searchTab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-border",
                          staticStyle: {
                            "border-top-left-radius": "8px",
                            "border-bottom-left-radius": "8px",
                          },
                          attrs: { "active-class": "activeSearchTab" },
                          on: { click: _vm.showResult },
                        },
                        [
                          _c("v-img", {
                            staticClass: "area-search",
                            attrs: {
                              height: "18",
                              width: "18",
                              contain: "",
                              src: "/images/dp.png",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "margin-top": "4px",
                              },
                            },
                            [_vm._v(" Distribution Panel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-border",
                          staticStyle: {
                            "border-top-right-radius": "8px",
                            "border-bottom-right-radius": "8px",
                          },
                          attrs: { "active-class": "activeSearchTab" },
                          on: { click: _vm.showResult },
                        },
                        [
                          _c("v-img", {
                            staticClass: "equipment-search",
                            attrs: {
                              height: "20",
                              width: "20",
                              contain: "",
                              src: "/images/equip.png",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "margin-top": "4px",
                              },
                            },
                            [_vm._v(" Equipment ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "base-btn",
                    {
                      staticStyle: { width: "22%" },
                      attrs: { color: "primary" },
                      on: { click: _vm.searchClick },
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "16",
                          contain: "",
                          src: require("@/assets/icons/search.png"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSearchResults,
                expression: "showSearchResults",
              },
            ],
            staticClass: "overflow-hidden pt-0 pb-2 table-results",
            staticStyle: { "margin-top": "15px" },
          },
          [
            _c(
              "v-tabs-items",
              {
                staticClass: "height-100x",
                model: {
                  value: _vm.searchTab,
                  callback: function ($$v) {
                    _vm.searchTab = $$v
                  },
                  expression: "searchTab",
                },
              },
              [
                _c(
                  "v-tab-item",
                  { staticClass: "height-100x" },
                  [
                    _c(
                      "base-card",
                      {
                        staticClass:
                          "height-100x overflow-hidden flex-1 d-flex flex-column",
                        staticStyle: { border: "none" },
                        attrs: { outlined: "" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-shrink-0 px-3 font-weight-bold",
                            staticStyle: { padding: "10px 5px !important" },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "24px" } },
                              [_vm._v("Search Results")]
                            ),
                            _c(
                              "base-btn",
                              {
                                staticStyle: { float: "right" },
                                attrs: { color: "primary" },
                                on: { click: _vm.hideResult },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "icon",
                                    attrs: { size: "20", color: "white" },
                                  },
                                  [_vm._v("mdi-window-close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "flex-grow-1 white height-100x width-100x overflow-hidden pa-2 pt-0",
                            staticStyle: { padding: "10px 5px !important" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "height-100x overflow-auto" },
                              [
                                _c(
                                  "v-simple-table",
                                  { staticClass: "location-table" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Location Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Title"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Typed"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Flammable Substances"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Explosion Consequences"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("IP(x)-IP(y)"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Ambient Temperature Range"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Inspection Frequency"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Approval Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Gas Zone"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Gas Group"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Temperature Class"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Dust Zone"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Dust Group"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Dust Ignition Temp Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Gas Dust Group"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Dust Ingnition Temp Value"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Environmental Condition"),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.searchedLocations,
                                        function (location, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: `${index}-${location.id}`,
                                              staticClass:
                                                "search-row cursor-pointer",
                                              class: {},
                                              on: {
                                                click: function ($event) {
                                                  return _vm.locationItemClickFn(
                                                    location
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.locationType
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "tdcc",
                                                  attrs: { width: "400px" },
                                                },
                                                [_vm._v(_vm._s(location.title))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [_vm._v(_vm._s(location.type))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.flammableSubstances
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.explosionConsequences
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.ipx) +
                                                      " -" +
                                                      _vm._s(location.ipy)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.lowAmbientTemp
                                                    ) +
                                                      " - " +
                                                      _vm._s(
                                                        location.highAmbientTemp
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.inspectionFreq
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.approvalType
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.gasZone)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.gasGroup)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.tempClass)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.dustZone)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(location.dustgroup)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.dustIgnitionTempType
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.gasDustGroup
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.dustIngnitionTempVal
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.environmentalCondition
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { staticClass: "height-100x" },
                  [
                    _c(
                      "base-card",
                      {
                        staticClass:
                          "height-100x overflow-hidden flex-1 d-flex flex-column",
                        staticStyle: { border: "none" },
                        attrs: { outlined: "" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-shrink-0 px-3 font-weight-bold",
                            staticStyle: { padding: "10px 5px !important" },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "24px" } },
                              [_vm._v("Search Results")]
                            ),
                            _c(
                              "base-btn",
                              {
                                staticStyle: { float: "right" },
                                attrs: { color: "primary" },
                                on: { click: _vm.hideResult },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "icon",
                                    attrs: { size: "20", color: "white" },
                                  },
                                  [_vm._v("mdi-window-close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "flex-grow-1 white height-100x width-100x overflow-hidden pa-2 pt-0",
                            staticStyle: { padding: "10px 5px !important" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "height-100x overflow-auto" },
                              [
                                _c(
                                  "v-simple-table",
                                  { staticClass: "location-table" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Location Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Title"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Project Name"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Tag"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v(
                                            "Electrical Circuit Connection"
                                          ),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v(
                                            "Electrical And Process Drawing"
                                          ),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Photo"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Equipment Classification"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("IP(x)-IP(y)"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Equipment Type"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Manufacturer"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Supplier"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Model No"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Serial No"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Zone"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Flammable Substances"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Explosion Consequences"),
                                        ]),
                                        _c("th", { staticClass: "tdcc" }, [
                                          _vm._v("Gas Group"),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.searchedEquipments,
                                        function (equipment, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: `${index}-${equipment.id}`,
                                              staticClass:
                                                "search-row cursor-pointer",
                                              class: {},
                                              on: {
                                                click: function ($event) {
                                                  return _vm.locationItemClickFn(
                                                    equipment
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.locationType
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.title)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.projectName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [_vm._v(_vm._s(equipment.tag))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [_vm._v(_vm._s(equipment.type))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.electricalCircuitConnection
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.electricalAndProcessDrawing
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.photo)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.equipmentClassification
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.ipx) +
                                                      "-" +
                                                      _vm._s(equipment.ipy)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.equipmentType
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.manufacturer
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.supplier)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.modelNo)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.serialNo)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [_vm._v(_vm._s(equipment.zone))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.flammableSubstances
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equipment.explosionConsequences
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "tdcc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(equipment.gasGroup)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }