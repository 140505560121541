var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "px-3" }, [
    _c(
      "div",
      [
        _c(
          "v-tabs",
          {
            attrs: { "show-arrows": true, height: "35px", centered: "" },
            on: { change: _vm.workPermitTabChange },
            model: {
              value: _vm.workPermitTab,
              callback: function ($$v) {
                _vm.workPermitTab = $$v
              },
              expression: "workPermitTab",
            },
          },
          [
            _vm._l(_vm.workPermitsSrv.workPermitTypes, function (wp) {
              return [
                _c(
                  "v-tab",
                  {
                    key: wp.key,
                    staticStyle: { border: "1px solid #d3d3d3" },
                    attrs: {
                      "active-class": "side-tab-active",
                      "tab-value": wp.key,
                    },
                  },
                  [_vm._v(" " + _vm._s(wp.title) + " ")]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "v-tabs-items",
          {
            model: {
              value: _vm.workPermitTab,
              callback: function ($$v) {
                _vm.workPermitTab = $$v
              },
              expression: "workPermitTab",
            },
          },
          [
            _vm._l(_vm.workPermitsSrv.workPermitTypes, function (wp) {
              return [
                _c("v-tab-item", { key: wp.key, attrs: { value: wp.key } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex my-2" },
                    [
                      _c(
                        "base-btn",
                        {
                          staticClass: "ml-auto",
                          attrs: { outlined: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadWorkPermitFiel(wp)
                            },
                          },
                        },
                        [_vm._v("Download Work Permit")]
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between my-2" },
                      [
                        _c("h3", [
                          _vm._v("Submited (" + _vm._s(wp.title) + ")"),
                        ]),
                        _c(
                          "base-btn",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.submitWorkPermit(wp)
                              },
                            },
                          },
                          [_vm._v("Submit Work Permit")]
                        ),
                      ],
                      1
                    ),
                    _c("table", { staticClass: "foex-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Submitted By")]),
                          _c("th", [_vm._v("Submitted Date")]),
                          _c("th", [_vm._v("Filename")]),
                          _c("th", [_vm._v("Actions")]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.jobSubmittedWorkPermitsSrv.workPermits(wp.key),
                            function (swp) {
                              return [
                                _c("tr", { key: `${swp.id}` }, [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          swp.submittedByUser
                                            ? `${swp.submittedByUser.firstName} ${swp.submittedByUser.lastName}`
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$helpers.formatDate(
                                          swp.submitedOn,
                                          "yyyy-MM-dd HH:mm:ss a"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(swp.filename))]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "base-btn",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { small: "", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadSubmittedWorkPermit(
                                                swp
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-download"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "base-btn",
                                        {
                                          staticClass: "mx-2",
                                          attrs: {
                                            color: "red",
                                            small: "",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteSubmittedWorkPermit(
                                                swp
                                              )
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-delete")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm.jobsSrv.selectedJob
          ? _c("SubmitJobWorkPermitDialogComponent", {
              ref: "submitWorkPermitDialogRef",
              attrs: { jobId: _vm.jobsSrv.selectedJob.id },
              on: { workPermitSubmitted: _vm.workPermitSubmitted },
            })
          : _vm._e(),
        _c("DownloadWorkpermitDialogComponent", {
          ref: "downloadWorkPermitDialogRef",
          attrs: { accountId: _vm.accountId },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }