var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-checkbox",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "hide-details": "",
            value: _vm.value,
            "input-value": _vm.inputValue,
          },
          on: {
            change: function ($event) {
              return _vm.$emit("update:inputValue", $event)
            },
          },
        },
        "v-checkbox",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._l(Object.keys(_vm.$slots), function (slot) {
        return _vm._t(slot, null, { slot: slot })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }