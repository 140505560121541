var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-form", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "base-dialog",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      "content-class": "foex-dialog",
                      name: _vm.name,
                      "max-width": "650px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("Dialogs.SubmitWorkPermit.Title")
                                  ) +
                                  " "
                              ),
                              _vm._t("header"),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "dialogFooter",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "base-btn",
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        disabled: invalid,
                                        color: "admin-primary",
                                      },
                                      on: { click: _vm.submitWorkPermit },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Common.Save")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "base-dialog",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              ),
              [
                _c("div", [
                  _c(
                    "div",
                    [
                      !_vm.file
                        ? _c("base-file-picker", {
                            attrs: {
                              width: "100%",
                              height: "100px",
                              text: _vm.$t("Fields.Common.BrowseFiles.Label"),
                              accept: "application/pdf",
                            },
                            on: { filesSelected: _vm.filesSelected },
                          })
                        : _c(
                            "div",
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Dialogs.SubmitWorkPermit.SelectedFiels"
                                    )
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.file.name)),
                              ]),
                              _c(
                                "base-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.file = null
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v("mdi-delete"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }