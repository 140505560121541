import {ServiceClass} from '@/decorators';
import {ROLES, ROLE_TYPES} from '@/globals';
import {RoleModel, UserSession} from '@/sdk';
import {TranslationsApi} from '@/sdk/api-services';
import {RoleApi} from '@/sdk/api-services/role.api';

@ServiceClass()
export class RolesService {
    public rolesApi: RoleApi = new RoleApi();

    public UserSession: UserSession = new UserSession();

    public translationApi: TranslationsApi = new TranslationsApi();

    private roles: Array<RoleModel> = [];

    public accountRoles: Array<RoleModel> = [];
    public siteRoles: Array<RoleModel> = [];
    public teamRoles: Array<RoleModel> = [];
    public jobRoles: Array<RoleModel> = [];

    public constructor() {
        this.getRoles();
    }

    public getRoles() {
        this.rolesApi
            .getAll()
            .then((res: any) => {
                this.roles = res ?? [];
                // console.log('ROLES:');
                // console.log(this.roles);
                this.roles.sort((roleA, roleB) => ((roleA.name ?? '') > (roleB.name ?? '') ? 1 : -1));

                this.accountRoles = this.roles.filter(r => r.type === ROLE_TYPES.ACCOUNT);
                this.siteRoles = this.roles.filter(r => r.type === ROLE_TYPES.SITE);
                this.teamRoles = this.roles.filter(r => r.type === ROLE_TYPES.TEAM);
                this.jobRoles = this.roles.filter(r => r.type === ROLE_TYPES.JOB);

                this.accountRoles = this.accountRoles.filter(r => {
                    if (r.slug === ROLES.SYS_ADMIN) {
                        if (this.UserSession.isSystemAdmin) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                });

                if (this.UserSession.Session?.roleId) {
                    new RolesService().getRolePermissions(this.UserSession.Session.roleId);
                }
            })
            .catch(err => {
                //
            });
    }

    public getRolePermissions(roleId: string) {
        new RoleApi().getRolePermissions(roleId).subscribe(
            res => {
                this.UserSession.rolePermissions.next(res.Data?.map(x => x.permission!) ?? []);
            },
            err => {
                //
            }
        );
    }
}
