import {MediaViewModel} from '../media';
import {CorrectiveActionResultViewModel} from './corrective-action-result-view.model';

export class JobCompletionRegisterViewModel {
    id?: string | null = null;
    completionDate?: string | null = null;
    personInChargeUserId: string | null = null;
    jobId: string | null = null;
    completionReports: Array<MediaViewModel> = [];
    completionActionResults: CorrectiveActionResultViewModel[] = [];
    created: Date | null = null;

    constructor(data?: Partial<JobCompletionRegisterViewModel>) {
        Object.assign(this, data);
    }
}
