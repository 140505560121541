import {RoleModel} from '../role/role.model';

export class UserViewModel {
    public id?: string | null = null;

    public firstName?: string | null = null;
    public middleName?: string | null = null;
    public siteId?: string | null = null;
    public lastName?: string | null = null;
    public address?: string | null = null;
    public active?: boolean | null = null;
    public username?: string | null = null;
    public email: string | null = null;
    public phone?: string | null = null;
    public mobile?: string | null = null;

    public roleId?: string | null = null;
    public role?: string | null = null;

    public roles: Array<RoleModel> = [];
    public siteRoles: Array<RoleModel> = [];

    public accountId?: string | null = null;

    public siteRoleId?: string | null = null;
    public siteRole?: string | null = null;

    constructor(data?: Partial<UserViewModel>) {
        Object.assign(this, data);
    }
}
