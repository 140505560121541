export interface OrganizationModel {
    id: string;
    organizationName: string;
    organizationCode: string;
    organizationAddress: string;
    organizationWebsite: string;
    organizationContactualContactName: string;
    organizationContactualContactEmail: string;
    organizationContactualContactPhone: string;
    organizationContactualContactMobile: string;
    created?: string;
    updated?: string;
    accountId: string;
}

export class CreateUpdateOrganizationRequest implements Omit<OrganizationModel, 'id' | 'created' | 'updated' | 'accountId'> {
    id?: string;
    organizationName!: string;
    organizationCode!: string;
    organizationAddress!: string;
    organizationWebsite!: string;
    organizationContactualContactName!: string;
    organizationContactualContactEmail!: string;
    organizationContactualContactPhone!: string;
    organizationContactualContactMobile!: string;

    constructor(data: CreateUpdateOrganizationRequest) {
        Object.assign(this, data);
    }
}
