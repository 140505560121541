import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {debounceTime} from 'rxjs/operators';
import {Component} from 'vue-property-decorator';
import ConfirmComponent from './components/shared/Confirm/confirm.component';

import {LANGUAGES} from './globals';

import {TranslationsApi} from './sdk/api-services';

@Component({
    components: {
        ConfirmComponent,
    },
})
export default class AppComponent extends VueWrapper {
    public userMembership: any = '';

    public created() {
        // console.log(this.$route.query);
    }

    public mounted() {
        // console.log(this.$i18n);
        // for (const lang of LANGUAGES) {
        //     new TranslationsApi().GetLangMessages(lang.key).then(res => {
        //         this.$i18n.setLocaleMessage(lang.key, res);
        //     });
        // }
        // new TranslationsApi();
    }

    get cssProps() {
        const themeColors: any = {};

        return themeColors;
    }
}
