var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "py-0 px-0 height-100vh main-layout-container",
      staticStyle: { position: "relative" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", [_vm._v("Account Setup")]),
            _c("h4", [
              _vm._v("Selected Account: (" + _vm._s(_vm.accountTitle) + ")"),
            ]),
          ]),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }