var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "px-3" }, [
    _c("div", { staticClass: "mb-5" }, [
      _vm._m(0),
      _c("div", { staticClass: "overflow-x-auto overflow-y-hidden" }, [
        _c("table", { staticClass: "foex-table text-center" }, [
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.jobsSrv.jobWorkers, function (ins) {
              return _c("tr", { key: ins.id }, [
                _c("td", [_vm._v(_vm._s(ins.id))]),
                _c("td", [_vm._v(_vm._s(ins.firstName))]),
                _c("td", [_vm._v(_vm._s(ins.lastName))]),
                _c("td", [_vm._v(_vm._s(ins.email))]),
                _c(
                  "td",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", icon: "", color: "error" },
                        on: {
                          click: function ($event) {
                            return _vm.unlinkInspector(
                              _vm.jobsSrv.selectedJob.id,
                              ins.id
                            )
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-link-off")])],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("hr"),
    _c("div", [
      _vm._m(2),
      _c("div", { staticClass: "overflow-x-auto overflow-y-hidden" }, [
        _c("table", { staticClass: "foex-table text-center" }, [
          _vm._m(3),
          _c(
            "tbody",
            [
              _vm._l(_vm.siteTeamMembersSrv.siteWorkers, function (ins) {
                return [
                  !_vm.isInspectorLinked(ins.id)
                    ? _c("tr", { key: ins.id }, [
                        _c("td", [_vm._v(" " + _vm._s(ins.id) + " ")]),
                        _c("td", [_vm._v(_vm._s(ins.firstName))]),
                        _c("td", [_vm._v(_vm._s(ins.lastName))]),
                        _c("td", [_vm._v(_vm._s(ins.email))]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-1",
                                attrs: { small: "", icon: "", color: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkInspector(
                                      _vm.jobsSrv.selectedJob.id,
                                      ins.id
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-link-plus")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex mb-0" }, [
      _c("h4", [_vm._v("Active Workers")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_c("div", { staticClass: "width-200px" }, [_vm._v("ID")])]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("First Name")]),
        ]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("Last Name")]),
        ]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("Email")]),
        ]),
        _c("th", [_c("div", [_vm._v("Actions")])]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex" }, [
      _c("h4", [_vm._v("Available Workers")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_c("div", { staticClass: "width-200px" }, [_vm._v("ID")])]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("First Name")]),
        ]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("Last Name")]),
        ]),
        _c("th", [
          _c("div", { staticClass: "width-200px" }, [_vm._v("Email")]),
        ]),
        _c("th", [_c("div", [_vm._v("Actions")])]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }