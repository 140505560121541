import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {WorkPermitTypes} from '@/globals';
import {JobSubmitedWorkPermitModel, WorkPermitTypeViewModel} from '@/sdk';
import DownloadWorkpermitDialogComponent from '@/views/shared/dialogs/download-work-permit-dialog/download-work-permit-dialog.component';
import {JobService, JobSubmittedWorkPermitsService, WorkPermitsService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';
import SubmitJobWorkPermitDialogComponent from '../submit-job-work-permit-dialog/submit-job-work-permit.dialog';

@Component({
    components: {
        SubmitJobWorkPermitDialogComponent,
        DownloadWorkpermitDialogComponent,
    },
})
export default class WorkPermitHistoryComponent extends VueWrapper {
    public $refs!: {
        submitWorkPermitDialogRef: SubmitJobWorkPermitDialogComponent;
        downloadWorkPermitDialogRef: DownloadWorkpermitDialogComponent;
    };

    public workPermitTab: string | null = null;
    public get workPermits() {
        return WorkPermitTypes;
    }

    public jobsSrv: JobService = new JobService();
    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();
    public jobSubmittedWorkPermitsSrv: JobSubmittedWorkPermitsService = new JobSubmittedWorkPermitsService();

    accountId: string = '';

    public mounted(): void {
        this.AddSubscription$ = this.UserSessionSrv._session.subscribe(session => {
            this.accountId = session?.accountId || '';
        });
    }

    public workPermitTabChange(e: string) {
        const wpType = this.workPermitsSrv.workPermitTypes.find(x => x.key === e);
        if (wpType) {
            this.workPermitsSrv.selectedWorkPermitType = wpType;

            this.LoaderSrv.showFullScreenLoader();
            this.jobSubmittedWorkPermitsSrv
                .loadWorkPermits(this.jobsSrv.selectedJob!.id!, wpType.key)
                .then(() => {
                    //
                })
                .catch(err => {
                    this.AlertSrv.show('warning', err.message || err.error?.message || 'Unable to load work permits.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public downloadWorkPermitFiel(wp: WorkPermitTypeViewModel) {
        this.$refs.downloadWorkPermitDialogRef.open({
            type: wp.key,
        });
        // this.LoaderSrv.showFullScreenLoader();
        // new WorkPermitApi()
        //     .downloadFile(wp.id!)
        //     .then(res => {
        //         const href = URL.createObjectURL(res.data);

        //         // create "a" HTML element with href to file & click
        //         const link = document.createElement('a');
        //         link.href = href;
        //         link.setAttribute('download', wp.filename!); //or any other extension
        //         document.body.appendChild(link);
        //         link.click();

        //         // clean up "a" element & remove ObjectURL
        //         document.body.removeChild(link);
        //         URL.revokeObjectURL(href);
        //     })
        //     .catch(err => {
        //         this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download file.');
        //     })
        //     .finally(() => {
        //         this.LoaderSrv.hideFullScreenLoader();
        //     });
    }

    public submitWorkPermit(wp: WorkPermitTypeViewModel) {
        this.$refs.submitWorkPermitDialogRef.open();
    }

    public downloadSubmittedWorkPermit(submitedWorkPermit: JobSubmitedWorkPermitModel) {
        if (!this.jobsSrv.selectedJob) {
            return;
        }
        this.LoaderSrv.showFullScreenLoader();
        this.jobSubmittedWorkPermitsSrv.jobSubbmittedWorkPermitsApi
            .downloadSubmitedWorkPermit(this.jobsSrv.selectedJob.id!, submitedWorkPermit.id!)
            .then(res => {
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', submitedWorkPermit.filename!); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download file.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public deleteSubmittedWorkPermit(submitedWorkPermit: JobSubmitedWorkPermitModel) {
        if (!this.jobsSrv.selectedJob) {
            return;
        }

        this.ConfirmSrv.open('Delete Work Permit', 'Are you sure you want to delete this submitted work permit?').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.jobSubmittedWorkPermitsSrv.jobSubbmittedWorkPermitsApi
                    .deleteSubmitedWorkPermit(this.jobsSrv.selectedJob!.id!, submitedWorkPermit.id!)
                    .then(() => {
                        this.AlertSrv.show('success', 'Work permit deleted successfully.');

                        this.workPermitTabChange(this.workPermitTab!);
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to delete work permit.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public workPermitSubmitted() {
        this.workPermitTabChange(this.workPermitTab!);
    }
}
