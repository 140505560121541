var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { persistent: "", "max-width": "320px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "base-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        value: _vm.DateValue,
                        rules: _vm.rules,
                        readonly: "",
                        flat: "",
                        "append-icon": "mdi-clock-outline",
                        dense: "",
                      },
                      on: {
                        "click:append": function ($event) {
                          _vm.dialog = true
                        },
                      },
                    },
                    "base-text-field",
                    { ...attrs, ..._vm.$attrs },
                    false
                  ),
                  { ...on, ..._vm.$listeners }
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-time-picker",
                _vm._b(
                  {
                    attrs: {
                      "full-width": "",
                      color: "primary",
                      format: _vm.format,
                      value: _vm.value,
                    },
                    on: { input: _vm.update },
                  },
                  "v-time-picker",
                  _vm.$attrs,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "base-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.dialog.save(_vm.value)
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }