var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "lighten-3 d-flex flex-column height-100x overflow-auto" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between px-2 py-1 align-center" },
        [
          _c("div", [_vm._v("Inspection Area Structure")]),
          _c(
            "div",
            [
              _c(
                "base-btn",
                { attrs: { small: "", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "browse-section px-2" }, [
        _c(
          "div",
          {
            staticClass: "d-flex align-center mb-1 site-box",
            class: {
              "drag-hilight":
                _vm.siteLocationsSrv.enterItemSiteId === _vm.site.id,
            },
            attrs: { id: _vm.site.id, type: "site" },
            on: {
              dragover: _vm.allowDrop,
              drop: _vm.onItemDrop,
              dragenter: _vm.onDragEnter,
            },
          },
          [
            _c(
              "div",
              {},
              [
                _c(
                  "base-btn",
                  { attrs: { small: "", icon: "" } },
                  [
                    _c("v-img", {
                      attrs: {
                        height: "16",
                        width: "30",
                        contain: "",
                        src: "/images/plant.png",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex" }, [
              _c("span", { staticClass: "body-2" }, [
                _vm._v(_vm._s(_vm.site.siteName)),
              ]),
            ]),
            _c("div", { staticClass: "item-controls" }, [
              _c(
                "span",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-x": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "base-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { small: "", icon: "" } },
                                    "base-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-img", {
                                    attrs: {
                                      height: "16",
                                      width: "30",
                                      contain: "",
                                      src: require("@/assets/icons/add.png"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "", nav: "" } },
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: { click: _vm.addItemClick },
                            },
                            [_c("v-list-item-title", [_vm._v("Add Area")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: { click: _vm.addEquipment },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v("Add Equipment"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: { click: _vm.addDistribution },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v("Add Distribution Pabel"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "div",
          [
            _c("location-list-item-component", {
              attrs: {
                parentType: "site",
                parentId: _vm.site.id,
                locations: _vm.siteLocations,
                diagramViewStyle: true,
                level: 1,
              },
              on: {
                locationItemClick: function ($event) {
                  return _vm.$emit("locationItemClick", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }