var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    attrs: { rules: _vm.rules },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _c(
                "v-combobox",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        "error-messages": errors,
                        value: _vm.v,
                        "small-chips": "",
                        outlined: _vm.outlined,
                        dense: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("update:value", $event)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Press "),
                                          _c("kbd", [_vm._v("enter")]),
                                          _vm._v(" to add tag "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          !!_vm.$attrs.label
                            ? {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm.rules.includes("required")
                                      ? _c(
                                          "span",
                                          { staticClass: "error--text" },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.$attrs.label) + " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    "v-combobox",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm._l(Object.keys(_vm.$slots), function (slot) {
                    return _vm._t(slot, null, { slot: slot })
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }