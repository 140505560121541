export interface ShowableRoute {
    Name?: string;
    Icon: string;
    Title: string;
    Chip: string;
    Color: string;
    Position?: number;
    inactive?: boolean;
    data?: AnyObject;
}

export interface AnyObject {
    [key: string]: any;
}

export interface InfoWindowLinkType {
    title: string;
    link: string;
    key: string;
}

export interface KeyValuePair {
    value: string;
    key: string;
    [key: string]: any;
}

export interface Count {
    count: number;
}
