var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      attrs: {
        "max-width": "600px",
        name: _vm.name,
        title: _vm.$t("Dialogs.SetPassword.Title"),
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ validate }) {
            return [
              _c(
                "base-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.ok(validate)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Common.Ok")))]
              ),
              _c(
                "base-btn",
                {
                  staticClass: "mx-1 white--text",
                  attrs: { color: "grey lighten-1", depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Common.Cancel")) + " ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("base-text-field", {
                    attrs: {
                      dense: "",
                      rules: "required",
                      type: _vm.showPassword.pass ? "text" : "password",
                      "append-icon": _vm.showPassword.pass
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      vid: "Password",
                      outlined: "",
                      label: _vm.$t("Dialogs.SetPassword.Password"),
                      name: "Password",
                      value: _vm.password,
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPassword.pass = !_vm.showPassword.pass
                      },
                      "update:value": function ($event) {
                        _vm.password = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("base-text-field", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      type: _vm.showPassword.confirmPass ? "text" : "password",
                      label: _vm.$t("Dialogs.SetPassword.ConfirmPassword"),
                      name: "ConfirmPassword",
                      value: _vm.passwordAgain,
                      rules: "required|confirmed:Password",
                      "append-icon": _vm.showPassword.confirmPass
                        ? "mdi-eye"
                        : "mdi-eye-off",
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.passwordAgain = $event
                      },
                      "click:append": function ($event) {
                        _vm.showPassword.confirmPass =
                          !_vm.showPassword.confirmPass
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }