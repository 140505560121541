var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    attrs: { rules: _vm.rules, vid: _vm.vid },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: _vm.mask,
                          expression: "mask",
                        },
                      ],
                      attrs: {
                        "error-messages": _vm.getErrorMessage(errors),
                        color: _vm.$attrs.color ?? "primary",
                        outlined: "",
                        dense: "",
                        value: _vm.value,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "update:value",
                            $event ? $event : null
                          )
                        },
                        blur: _vm.onBlur,
                        keydown: _vm.onKeyDown,
                      },
                      scopedSlots: _vm._u(
                        [
                          !!_vm.$attrs.label
                            ? {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm.rules.includes("required")
                                      ? _c(
                                          "span",
                                          { staticClass: "error--text" },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.$attrs.label) + " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    "v-text-field",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm._l(Object.keys(_vm.$slots), function (slot) {
                    return _vm._t(slot, null, { slot: slot })
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }