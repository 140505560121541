var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(_vm._s(_vm.$t("DustDbPage.Heading"))),
            ]),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c("base-btn", { on: { click: _vm.importDustDb } }, [
                  _vm._v(" " + _vm._s(_vm.$t("Common.Import")) + " "),
                ]),
              ],
              1
            ),
          ]),
          _c("v-card-text", [
            _c("div", [
              _c("table", { staticClass: "sites-table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("DustDbPage.TableHeaders.No"))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("DustDbPage.TableHeaders.Name"))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("DustDbPage.TableHeaders.DustGroup"))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("DustDbPage.TableHeaders.Actions"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.dustDbSrv.dustDbData, function (r) {
                    return _c("tr", { key: `${r.id}` }, [
                      _c("td", [_vm._v(_vm._s(r.no))]),
                      _c("td", [_vm._v(_vm._s(r.name))]),
                      _c("td", [_vm._v(_vm._s(r.dustGroup))]),
                      _c(
                        "td",
                        [
                          _c(
                            "base-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.preview(r)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-eye")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("ImportDustDbDialogComponent", { ref: "importDustDbDialog" }),
      _c("PreviewDustDbRecordDialogComponent", {
        ref: "previewDustObjDialog",
        attrs: { obj: _vm.dustDbSrv.selectedObj },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }