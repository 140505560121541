import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {LocationModel, SiteModel} from '@/sdk';
// import AreaListItemComponent from '@/views/shared/area-list-item/area-list-item.component';
// import DiagramListItemComponent from '@/views/shared/diagram-list-item/diagram-list-item.component';
// import EquipmentListItemComponent from '@/views/shared/equipment-list-item/equipment-list-item.component';
import {SiteLocationService} from '@/views/shared/services/site-location.service';
import LocationListItemComponent from '@/views/shared/site-location/location-list-item/location-list-item.component';

// import PointListItemComponent from '@/views/shared/point-list-item/point-list-item.component';
// import SubAreaListItemComponent from '@/views/shared/sub-area-list-item/sub-area-list-item.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'SiteSetupAreaBrowserComponent',
    components: {
        // AreaListItemComponent,
        // SubAreaListItemComponent,
        // PointListItemComponent,
        // EquipmentListItemComponent,
        // DiagramListItemComponent,
        LocationListItemComponent,
    },
})
export default class SiteSetupAreaBrowserComponent extends VueWrapper {
    @Prop({
        type: Object,
    })
    public site!: SiteModel;

    @Prop({
        type: Array,
    })
    public siteLocations!: Array<LocationModel>;

    public search: string = '';

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public mounted() {
        //
    }

    public get getUserLoginId() {
        return this.UserSessionSrv.Session?.loginId ?? 'N/A';
    }

    public get getUserRole() {
        return this.UserSessionSrv.Session?.role ?? 'N/A';
    }

    public get filteredSiteLocations() {
        if (!this.search || this.search === '') {
            return this.siteLocations ?? [];
        }
        const searchLower = this.search.toLowerCase();
        const matchedLocations = this.siteLocations.filter(x => x.title?.toLowerCase().includes(searchLower));
        const parentIds = new Set<string | null>();

        matchedLocations.forEach(location => {
            let parentId = location.locationId;
            while (parentId) {
                parentIds.add(parentId);
                const parentLocation = this.siteLocations.find(loc => loc.id === parentId);
                parentId = parentLocation?.locationId ?? null;
            }
        });

        return this.siteLocations.filter(x => matchedLocations.includes(x) || parentIds.has(x.id!));
    }

    public searchChange(value: string) {
        console.log(value);
    }

    public addItemClick() {
        this.siteLocationsSrv.openSiteLocationDialog();
    }

    public allowDrop(ev: any) {
        ev.preventDefault();
        // console.log(ev.target);
        // this.enterItemId = ev?.target?.id;
        this.siteLocationsSrv.enterItemSiteId = ev.target.closest('.site-box')?.id;
    }

    public onDragEnter(event: any) {
        event.preventDefault();
        this.siteLocationsSrv.enterItemSiteId = event?.target?.id;
        console.log('enter: ', event?.target?.id);
    }

    public onItemDrop(event: any) {
        event.preventDefault();
        this.siteLocationsSrv.enterItemSiteId = null;

        const droppedOverId = event.target.closest('.site-box')?.id;

        if (this.siteLocationsSrv.selectedItemId) {
            this.siteLocationsSrv.setParentNull(this.siteLocationsSrv.selectedItemId);
        }
    }

    public addEquipment() {
        this.siteLocationsSrv.openSiteLocationDialog(undefined, null, {addAt: 'eqp', level: 0});
    }
    public addDistribution() {
        this.siteLocationsSrv.openSiteLocationDialog(undefined, null, {addAt: 'dist', level: 0});
    }
}
