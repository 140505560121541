var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      ref: "saveWrapperRef",
      attrs: {
        "max-width": "1200px",
        name: _vm.name,
        title: _vm.accountSetupSrv.account.id
          ? _vm.$t("Dialogs.SaveAccount.EditUser")
          : _vm.$t("Dialogs.SaveAccount.AddUser"),
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ invalid }) {
            return [
              _c(
                "base-btn",
                {
                  attrs: { disabled: invalid },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Common.Save")))]
              ),
              _c(
                "base-btn",
                {
                  staticClass: "mx-1 white--text",
                  attrs: { color: "grey lighten-1", depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.clearForm()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Common.Cancel")) + " ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    { staticClass: "my-tabs", attrs: { vertical: "" } },
                    [
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            id: "category",
                            "active-class": "side-tab-active",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Dialogs.SaveAccount.AccountAndPaymentInformation"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account.id,
                                              name: "accountId",
                                              label: "Account ID",
                                              readonly: "",
                                              disabled: "",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "id",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .accountTitle,
                                              rules: "required",
                                              name: "accountTitle",
                                              label: _vm.$t(
                                                "Fields.Common.AccountTitle.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "accountTitle",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .accountType,
                                              rules: "required",
                                              name: "accountType",
                                              label: _vm.$t(
                                                "Fields.Common.AccountType.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "accountType",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-select", {
                                            staticClass: "flex-grow-1",
                                            attrs: {
                                              rules: "required",
                                              name: "accountStatus",
                                              label: _vm.$t(
                                                "Fields.Common.AccountStatus.Label"
                                              ),
                                              items: _vm.accountStatuses,
                                              clearable: "",
                                              multiple: false,
                                              "return-object": false,
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .accountStatus,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "accountStatus",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-date-picker", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Fields.Common.AccountStartDate.Label"
                                              ),
                                              format: true,
                                              formatStr: "yyyy-MM-dd",
                                              rules: "required",
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .accountStartDate,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "accountStartDate",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-date-picker", {
                                            attrs: {
                                              label: _vm.$t(
                                                "Fields.Common.AccountEndDate.Label"
                                              ),
                                              format: true,
                                              formatStr: "yyyy-MM-dd",
                                              rules: "required",
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .accountEndDate,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "accountEndDate",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [_c("v-divider")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .licenseType,
                                              name: "licenseType",
                                              label: _vm.$t(
                                                "Fields.Common.LicenseType.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "licenseType",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-date-picker", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .licenseStartDate,
                                              format: true,
                                              formatStr: "yyyy-MM-dd",
                                              label: _vm.$t(
                                                "Fields.Common.LicenseStartDate.Label"
                                              ),
                                              name: "licenseStartDate",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "licenseStartDate",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-date-picker", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .licenseEndDate,
                                              format: true,
                                              formatStr: "yyyy-MM-dd",
                                              label: _vm.$t(
                                                "Fields.Common.LicenseExpirationDate.Label"
                                              ),
                                              name: "licenseExpirationDate",
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "licenseEndDate",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .licenseStatus,
                                              name: "licenseStatus",
                                              label: _vm.$t(
                                                "Fields.Common.LicenseStatus.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "licenseStatus",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [_c("v-divider")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("base-combobox", {
                                            staticClass: "flex-grow-1",
                                            attrs: {
                                              label: _vm.$t(
                                                "Fields.Common.PaymentMethod.Label"
                                              ),
                                              name: "paymentMethod",
                                              items: _vm.paymentMethods,
                                              "item-text": "value",
                                              "item-value": "key",
                                              clearable: "",
                                              multiple: false,
                                              "return-object": false,
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .paymentMethod,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "paymentMethod",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .paymentStatus,
                                              name: "paymentStatus",
                                              label: _vm.$t(
                                                "Fields.Common.PaymentStatus.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "paymentStatus",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .contractualContactName,
                                              name: "contractualContactName",
                                              label: _vm.$t(
                                                "Fields.Common.ContractualContactName.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "contractualContactName",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .contractualContactEmail,
                                              name: "contractualContactEmail",
                                              label: _vm.$t(
                                                "Fields.Common.ContractualContactEmail.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "contractualContactEmail",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .contractualContactPhone,
                                              name: "contractualContactPhone",
                                              label: _vm.$t(
                                                "Fields.Common.ContractualContactPhone.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "contractualContactPhone",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("base-text-field", {
                                            attrs: {
                                              value:
                                                _vm.accountSetupSrv.account
                                                  .contractualContactMobilePhone,
                                              name: "contractualContactMobilePhone",
                                              label: _vm.$t(
                                                "Fields.Common.ContractualContactMobile.Label"
                                              ),
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.accountSetupSrv.account,
                                                  "contractualContactMobilePhone",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }