import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AlertService, LoaderService} from '@/sdk';
import {CreatePointApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';

import {SiteLocationService} from '@/views/shared/services/site-location.service';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'SiteSetupSearchComponent',
})
export default class SiteSetupSearchComponent extends VueWrapper {
    search: string | null = null;

    public searchTab: number | null = 0;

    @Prop({
        type: String,
        required: true,
    })
    public siteId!: string;

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public createPointApi: CreatePointApi = new CreatePointApi();

    public siteLocations: Array<any> = [];

    @Prop({
        type: Boolean,
        required: true,
    })
    public showSearchResults!: boolean;

    // public formsSiteResults!: boolean;

    mounted() {
        // this.getSiteLocations(this.siteId);
        // this.formsSiteResults = false;
        // console.log('formsSiteResults', this.showSearchResults);
        // console.log('siteId', this.siteId);
    }

    public get searchedLocations() {
        return this.siteLocations.filter(x => x.locationType === 'distribution');
    }

    public get searchedEquipments() {
        return this.siteLocations.filter(x => x.locationType === 'equipment');
    }

    public searchClick() {
        this.getSiteLocations(this.siteId, this.search);
    }

    public hideResult() {
        // this.showResultTable = false;
        this.$emit('showSearchResultsEvent', false);

        // console.log('this.showResultTable', this.showResultTable);
    }

    public showResult() {
        if (this.search || this.siteLocations.length) {
            // console.log('Searched Data', this.search);
            this.$emit('showSearchResultsEvent', true);
        }
    }

    public locationItemClickFn(value: any) {
        this.$emit('locationItemClick', value);
        // this.showResultTable = false;
    }

    public getSiteLocations(id: string, search?: string | null) {
        if ((id || this.siteLocationsSrv.site?.id) && search) {
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .searchLocationDetails(id ?? this.siteLocationsSrv.site!.id!, search)
                .subscribe(
                    res => {
                        if (res.Data) {
                            const locations = res.Data;
                            console.log('object locations', locations);
                            this.siteLocations = [...locations.equipmentDetails];
                        } else {
                            this.siteLocations = [];
                        }

                        this.$emit('showSearchResultsEvent', true);
                        // this.formsSiteResults = false;
                        // for (const location of locations) {
                        //     const found = this.siteLocations.find(x => x.id === location.id);
                        //     location.expanded = found?.expanded || false;
                        // }
                    },
                    err => {
                        new AlertService().show('error', err.message ?? err.Message ?? 'Unable to load site.');
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }
}
