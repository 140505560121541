import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SessionModel, UserSession} from '@/sdk';
import {CoreService} from '@/services/core.service';
import AccountInfoDialogComponent from '@/views/shared/account-info/account-info-dialog/account-info-dialog.component';
import MyProfileDialogComponent from '@/views/shared/my-profile-dialog/my-profile-dialog.component';
import {SelectedSiteService, UserSetupService} from '@/views/shared/services';
import {
    mdiArrowLeftBoldOutline,
    mdiArrowRightBoldOutline,
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
    mdiNumeric5Circle,
    mdiNumeric6Circle,
} from '@mdi/js';
import {Component, Watch} from 'vue-property-decorator';
@Component({
    components: {
        MyProfileDialogComponent,
        AccountInfoDialogComponent,
        // ClientDrawerComponent,
        // LoginSignUpComponent
    },
})
export default class ClientAppBarComponent extends VueWrapper {
    public $refs!: {
        myProfileDialogRef: MyProfileDialogComponent;
        accountInfoDialog: AccountInfoDialogComponent;
    };

    public CoreSrv = new CoreService();
    public User = {};
    // public UserSrv = new UsersService();
    public userSession = new UserSession();

    public UserMenu = false;
    public UserMenuMobile = false;
    public myload = true;
    public whyMhParks = false;
    public featureMenus = false;

    public langMenu = false;

    public height = 70;

    public Session = new SessionModel();

    public icons: any = {
        mdiNumeric1Circle,
        mdiNumeric2Circle,
        mdiNumeric3Circle,
        mdiNumeric4Circle,
        mdiNumeric5Circle,
        mdiNumeric6Circle,
        mdiArrowLeftBoldOutline,
        mdiArrowRightBoldOutline,
    };

    public mounted() {
        this.AddSubscription$ = this.userSession._session.subscribe(session => {
            this.Session = session ?? new SessionModel();

            if (this.Session.token && !this.Session.loginId) {
                this.UserLogout();
            }
        });

        window.addEventListener(
            'resize',
            () => {
                // console.log('aaaaaaaaaaa', this.$vuetify.breakpoint.name);
                const breakPoint = this.$vuetify.breakpoint.name;
                if (breakPoint === 'md') {
                    this.height = 90;
                } else {
                    this.height = 70;
                }
            },
            {passive: true}
        );
    }

    public get getLocale() {
        return this.$i18n.locale.toUpperCase();
    }

    public langClick(lang: string) {
        this.$i18n.locale = lang;
        this.langMenu = false;
        localStorage.setItem('locale', lang);
    }

    @Watch('$route', {immediate: true})
    public onRouteChanged() {
        // console.log('-----mounted', this.$route.name);
        if (this.$route.name === 'sites-list') {
            this.selectedService.selectedSite = null;
            this.selectedService.stepNumber = 2;
        } else if (this.$route.params['siteId']) {
            this.selectedService.selectedSite = this.$route.params['siteId'];
        }
        if (this.$route.name === 'site-setup') {
            this.selectedService.stepNumber = 2;
        } else if (this.$route.name === 'sites-list') {
            this.selectedService.stepNumber = 2;
        } else if (this.$route.name === 'Job Main') {
            this.selectedService.stepNumber = 4;
        } else if (this.$route.name === 'Team Main') {
            this.selectedService.stepNumber = 3;
        } else if (this.$route.name === 'Job Report') {
            this.selectedService.stepNumber = 5;
        }
        // console.log('mounted----------->', this.selectedService.selectedSite, this.selectedService.stepNumber);
    }

    public get isSiteSelected() {
        return this.$route.params['siteId'] !== undefined;
    }
    public get isSiteSetupRoute() {
        return this.$route.name === 'site-setup';
    }

    public MyProfileClick() {
        new UserSetupService().getUserToEdit(this.userSession.Session!.userId!, false).then(() => {
            this.$refs.myProfileDialogRef.open();
        });
    }

    public onAccountInfoClick() {
        this.$refs.accountInfoDialog.open();
    }

    public get navHeight() {
        const breakPoint = this.$vuetify.breakpoint.name;
        if (breakPoint === 'md') {
            this.height = 90;
        } else {
            this.height = 76;
        }
        return this.height;
    }

    public get title() {
        if (this.$route.name === 'Main') {
            return 'Site Setup';
        } else if (this.$route.name === 'sites-list') {
            return 'Sites List';
        } else if (this.$route.name === 'Job Main') {
            return 'Job Setup';
        } else if (this.$route.name === 'Team Main') {
            return 'Team Setup';
        } else if (this.$route.name === 'UserSetupMain') {
            return 'User Setup';
        } else if (this.$route.name === 'diagram-view') {
            return 'Diagram View';
        }
        return '';
    }

    get num() {
        return this.$vuetify.breakpoint.width <= 1169;
    }

    public UserLogout() {
        this.UserMenu = false;
        this.userSession.clear();
        this.$router.push({name: 'signin'});
    }

    public selectedService = new SelectedSiteService();

    public stepObj: any = {
        1: {name: 'sites-list', params: {siteId: this.selectedService.selectedSite}},
        2: {name: 'site-setup', params: {siteId: this.selectedService.selectedSite}},
        3: {name: 'diagram-view', params: {siteId: this.selectedService.selectedSite}},
        4: {name: 'Team Main', params: {siteId: this.selectedService.selectedSite}},
        5: {name: 'Job Main', params: {siteId: this.selectedService.selectedSite}},
        6: {name: 'Job Report', params: {siteId: this.selectedService.selectedSite}},
    };
    public moveBack() {
        if (this.selectedService.selectedSite !== '') {
            const step = this.selectedService.stepNumber - 1;
            console.log('before if back', step);
            console.log('before if back', this.selectedService.stepNumber);
            if (this.stepObj[step]) {
                const obj = this.stepObj[step];
                obj.params.siteId = this.selectedService.selectedSite;
                this.$router.push(obj);
                // this.selectedService.stepNumber = this.selectedService.stepNumber - 1;
                console.log('after', this.selectedService.stepNumber);
            }
        }
    }
    public async moveForward() {
        console.log('before', this.selectedService.stepNumber);
        console.log('siteID', this.selectedService.selectedSite);
        if (this.selectedService.selectedSite !== '') {
            const step = this.selectedService.stepNumber + 1;
            console.log('before if for', step);
            console.log('before if for', this.selectedService.stepNumber);
            if (this.stepObj[step]) {
                const obj = this.stepObj[step];
                obj.params.siteId = this.selectedService.selectedSite;
                await this.$router.push(obj);
                // this.selectedService.stepNumber = this.selectedService.stepNumber + 1;
                console.log('after', this.selectedService.stepNumber);
            }
        }
    }
}
