var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "1280px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(" Download Work Permit Template "),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-shrink-0 min-width-200" },
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "my-tabs",
                          attrs: { vertical: "" },
                          on: { change: _vm.onTabChange },
                          model: {
                            value: _vm.selectedTab,
                            callback: function ($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              attrs: {
                                "tab-value": _vm.tabs.workPermitDb,
                                "active-class": "side-tab-active min-width-200",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "Dialogs.DownloadWorkPermits.WorkPermitDB"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: {
                                "tab-value": _vm.tabs.accountWorkPermits,
                                "active-class": "side-tab-active min-width-200",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "Dialogs.DownloadWorkPermits.AccountWorkPermits"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "flex-grow-1",
                      model: {
                        value: _vm.selectedTab,
                        callback: function ($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.tabs.workPermitDb } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-title", [
                                _c("span", [_vm._v(_vm._s(_vm.cardText))]),
                              ]),
                              _c("v-card-text", [
                                _c("table", { staticClass: "foex-table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("ID")]),
                                      _c("th", [_vm._v("Type")]),
                                      _c("th", [_vm._v("Title")]),
                                      _c("th", [_vm._v("File Name")]),
                                      _c("th", [_vm._v("Actions")]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.workPermitTemplates,
                                      function (template) {
                                        return _c(
                                          "tr",
                                          { key: `${template.id}` },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(template.id)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.type)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.title)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.filename)),
                                            ]),
                                            _c("td", [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "base-btn",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        small: "",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cloneToAccount(
                                                            template
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Dialogs.DownloadWorkPermits.CloneToAccount"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "base-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadGlobalWorkPermit(
                                                            template
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-download"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Common.Download"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.tabs.accountWorkPermits } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-title", [
                                _c("span", [_vm._v(_vm._s(_vm.cardText))]),
                              ]),
                              _c("v-card-text", [
                                _c("table", { staticClass: "foex-table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("ID")]),
                                      _c("th", [_vm._v("Type")]),
                                      _c("th", [_vm._v("Title")]),
                                      _c("th", [_vm._v("File Name")]),
                                      _c("th", [_vm._v("Download")]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.accountWorkPermitTemplates,
                                      function (template) {
                                        return _c(
                                          "tr",
                                          { key: `${template.id}` },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(template.id)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.type)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.title)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(template.filename)),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "base-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadWorkPermit(
                                                          template
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {},
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-download"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Common.Download"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }