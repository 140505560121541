export class FieldOptions {
    id?: string | null = null;
    dataFieldId: string | null = null;
    colorGrade: string | null = null;

    dependentOnFieldId?: string | null = null;
    dependentOnFieldOptionId?: string | null = null;

    regressPriority?: number | null = null;
    sortOrder?: number | null = null;

    value?: string | null = null;
    type?: string | null = null;
    active?: boolean | null = null;
    created?: string | null = null;

    extras?: Record<string, any> = {};

    constructor(data?: Partial<FieldOptions>) {
        Object.assign(this, data);
    }
}
