import {ServiceClass} from '@/decorators';
import {WorkPermitTypeViewModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class WorkPermitTypeApi extends BaseApi {
    public async getWorkPermitTypes() {
        return this.GET_RequestAsync<Array<WorkPermitTypeViewModel>>(`${this.ApiUrl}/work-permit-types`);
    }
}
