import {UserViewModel} from './user/user-view.model';

export class JobSubmitedWorkPermitModel {
    id?: string | null = null;
    submittedByUserId: string | null = null;
    submitedOn: string | null = null;
    filename: string | null = null;
    path?: string | null = null;
    workPermitType: string | null = null;
    jobId: string | null = null;
    workPermitId: string | null = null;

    public submittedByUser?: UserViewModel | null = null;

    constructor(data?: Partial<JobSubmitedWorkPermitModel>) {
        Object.assign(this, data);
    }
}
