var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column flex-1",
      class: { "d-none": _vm.diagramFullPageConfig },
      staticStyle: { "margin-top": "0px" },
    },
    [
      _c("div", { staticClass: "d-flex flex-column flex-shrink-0" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-row",
            staticStyle: { padding: "5px 0px" },
          },
          [
            _c(
              "div",
              { staticStyle: { width: "20%" } },
              [
                _vm.treeViewEnabled
                  ? _c("base-autocomplete", {
                      staticClass: "flex-grow-1",
                      staticStyle: { "margin-right": "auto", width: "20%" },
                      attrs: {
                        name: "selectDPtoRender",
                        items: _vm.nodesDPArray,
                        value: _vm.selectedDP,
                        "item-text": "text",
                        "item-value": "key",
                        label: "Select DP",
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.selectedDP = $event
                        },
                        change: _vm.changeSelectedDP,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "flex-grow-1 diagramsdiv" }, [
        _c("div", { staticClass: "d-flex flex-column height-100x" }, [
          _c("div", {
            ref: "drawingDivCanvas",
            staticClass: "drawingDivCanvas",
            attrs: { id: "drawingDivCanvas" },
          }),
          _c("div", {
            ref: "treeDrawingCanvasDiv",
            attrs: { id: "treeDrawingCanvasDiv" },
          }),
          _c(
            "div",
            {
              ref: "drawingDivCanvasCoresDiv",
              class: { hidden: _vm.diagramFullPageCoreView },
              attrs: { id: "drawingDivCanvasCoresDiv" },
            },
            [
              _c("div", {
                class: { "height-30vh": _vm.coreDetailsView },
                attrs: { id: "drawingDivCanvasCores" },
              }),
            ]
          ),
          _vm.coreDetailsView &&
          !_vm.coreGroupView &&
          !_vm.coreDetailsViewEDP &&
          !_vm.coreDetailsViewDPE &&
          _vm.coreDetailsArrayList &&
          _vm.coreDetailsArrayList?.length
            ? _c(
                "div",
                {
                  staticClass: "height-50h",
                  class: { "height-68h": _vm.diagramFullPageCoreView },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: { "max-width": "350" },
                                    },
                                    [
                                      _c(
                                        "v-slide-group",
                                        {
                                          attrs: {
                                            "slider-color": "#1b3054",
                                            "show-arrows": "",
                                            centered: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prev",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.prev()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-chevron-left"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "next",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.next()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-chevron-right"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1796914642
                                          ),
                                          model: {
                                            value: _vm.tab,
                                            callback: function ($$v) {
                                              _vm.tab = $$v
                                            },
                                            expression: "tab",
                                          },
                                        },
                                        _vm._l(
                                          _vm.coreDetailsArrayList,
                                          function (entry) {
                                            return _c("v-slide-item", {
                                              key: entry.sequence,
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ active }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "spaced-slide-btn tab-cable-core",
                                                            attrs: {
                                                              "input-value":
                                                                active,
                                                              "active-class":
                                                                "tab-cable-core-active",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.setActive(
                                                                  entry.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  entry?.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-3",
                                      staticStyle: {
                                        "margin-left": "auto",
                                        float: "right",
                                        "margin-top": "-40px",
                                      },
                                    },
                                    [
                                      _c(
                                        "base-btn",
                                        {
                                          staticStyle: {
                                            padding: "0 !important",
                                            border: "none",
                                            height: "auto",
                                            "min-width": "auto",
                                            "background-color":
                                              "white !important",
                                            "box-shadow": "none",
                                          },
                                          on: { click: _vm.fullPageCoreView },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              height: "16",
                                              width: "16",
                                              contain: "",
                                              src: "/images/enlarge.png",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "base-btn",
                                        {
                                          staticStyle: {
                                            padding: "0 !important",
                                            border: "none",
                                            height: "auto",
                                            "min-width": "auto",
                                            "background-color":
                                              "white !important",
                                            "box-shadow": "none",
                                          },
                                          on: {
                                            click: _vm.minimizePageCoreView,
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              height: "26",
                                              width: "26",
                                              contain: "",
                                              src: "/images/minus.png",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.coreDetailsArrayList, function (entry) {
                              return _c(
                                "v-tab-item",
                                { key: entry.id },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "connectivity-row" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        "line-height": "30px",
                                                        color: "#6c707a",
                                                        "margin-left": "46px",
                                                        "margin-top": "15px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Connectivity Information "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "connection-col ccol1",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        "line-height": "30px",
                                                        color: "#6c707a",
                                                        "margin-left": "12px",
                                                      },
                                                    },
                                                    [_vm._v("Connection 1")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                          staticStyle: {
                                                            border:
                                                              "solid black 1px",
                                                            "border-radius":
                                                              "10px",
                                                            padding: "5px",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.locationPathTo,
                                                          function (
                                                            path,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: path.id,
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                path.type ===
                                                                "site"
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "18",
                                                                          width:
                                                                            "18",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/plant.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : path.type ===
                                                                      "equipment" &&
                                                                    index ===
                                                                      _vm
                                                                        .locationPathTo
                                                                        .length -
                                                                        1
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "20",
                                                                          width:
                                                                            "22",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/equip.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : path.type ===
                                                                      "location" &&
                                                                    index <
                                                                      _vm
                                                                        .locationPathTo
                                                                        .length -
                                                                        1
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "18",
                                                                          width:
                                                                            "18",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/area.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                path.type ===
                                                                "site"
                                                                  ? _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            path.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h4",
                                                                          {
                                                                            staticClass:
                                                                              "header-title-text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                path.title
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                index !==
                                                                _vm
                                                                  .locationPathTo
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text next-arrow",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "black",
                                                                            padding:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " > "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        staticClass:
                                                          "flex-grow-1",
                                                        attrs: {
                                                          readonly: true,
                                                          name: "wiringCableEntryType",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .equipmentFieldOptions[
                                                              _vm
                                                                .equipmentFieldsList
                                                                .equipmentWiringCableEntryType
                                                            ],
                                                          value:
                                                            entry.cableType,
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label:
                                                            "Core Entry Type",
                                                          "return-object": false,
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                entry,
                                                                "cableType",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "connection-col ccol2",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        "line-height": "30px",
                                                        color: "#6c707a",
                                                        "margin-left": "12px",
                                                      },
                                                    },
                                                    [_vm._v("Connection 2")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                          staticStyle: {
                                                            border:
                                                              "solid black 1px",
                                                            "border-radius":
                                                              "10px",
                                                            padding: "5px",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.locationPathFrom,
                                                          function (
                                                            path,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: path.id,
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                path.type ===
                                                                "site"
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "18",
                                                                          width:
                                                                            "18",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/plant.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : path.type ===
                                                                      "equipment" &&
                                                                    index ===
                                                                      _vm
                                                                        .locationPathFrom
                                                                        .length -
                                                                        1
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "20",
                                                                          width:
                                                                            "22",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/equip.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : path.type ===
                                                                      "location" &&
                                                                    index <
                                                                      _vm
                                                                        .locationPathFrom
                                                                        .length -
                                                                        1
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        staticClass:
                                                                          "area-search header-img-title",
                                                                        attrs: {
                                                                          height:
                                                                            "18",
                                                                          width:
                                                                            "18",
                                                                          contain:
                                                                            "",
                                                                          src: "/images/area.png",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                path.type ===
                                                                "site"
                                                                  ? _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            path.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h4",
                                                                          {
                                                                            staticClass:
                                                                              "header-title-text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                path.title
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                index !==
                                                                _vm
                                                                  .locationPathFrom
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text next-arrow",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "black",
                                                                            padding:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " > "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        staticClass:
                                                          "flex-grow-1",
                                                        attrs: {
                                                          readonly: true,
                                                          name: "wiringCableEntryType",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .equipmentFieldOptions[
                                                              _vm
                                                                .equipmentFieldsList
                                                                .equipmentWiringCableEntryType
                                                            ],
                                                          value:
                                                            entry.cableType,
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label:
                                                            "Core Entry Type",
                                                          "return-object": false,
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                entry,
                                                                "cableType",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "connectivity-row",
                                              staticStyle: {
                                                "margin-top": "36px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        "line-height": "30px",
                                                        color: "#6c707a",
                                                        "margin-top": "15px",
                                                      },
                                                    },
                                                    [_vm._v("Core Information")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: true,
                                                      value: entry.id,
                                                      disabled: "",
                                                      label: "Core ID",
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "id",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      value: entry.name,
                                                      label: "Core Name",
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "name",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      readonly: false,
                                                      name: "wiringCableEntryType",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentWiringCableEntryType
                                                        ],
                                                      value: entry.cableType,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: "Core Entry Type",
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "cableType",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Manufacturer",
                                                      value: entry.manufacturer,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "manufacturer",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Model No",
                                                      value: entry.modelNo,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "modelNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Serial No",
                                                      value: entry.serialNo,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "serialNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Certification No",
                                                      value:
                                                        entry.certificationNo,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "certificationNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      readonly: false,
                                                      name: "wiringProtectionType",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentWiringCableProtectionType
                                                        ],
                                                      value:
                                                        entry.protectionType,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: "Protection Type",
                                                      "return-object": false,
                                                      "error-count":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentWiringCableProtectionType",
                                                          entry.id
                                                        ).length ?? 1,
                                                      "error-messages":
                                                        _vm.siteLocationsSrv.checkFieldError(
                                                          "equipmentWiringCableProtectionType",
                                                          entry.id
                                                        ),
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "protectionType",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Core Model No",
                                                      value: entry.cableModelNo,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "cableModelNo",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    staticClass: "flex-grow-1",
                                                    attrs: {
                                                      readonly: false,
                                                      name: "equipmentWiringCableFunction",
                                                      items:
                                                        _vm.adminDataFieldsSrv
                                                          .equipmentFieldOptions[
                                                          _vm
                                                            .equipmentFieldsList
                                                            .equipmentWiringCableFunction
                                                        ],
                                                      value:
                                                        entry.cableFunction,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: "Core Function",
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "cableFunction",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      readonly: false,
                                                      label:
                                                        "Core Manufacturer",
                                                      value:
                                                        entry.cableManufacturer,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "cableManufacturer",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("base-text-field", {
                                                    staticStyle: {
                                                      color: "#fdf7e7",
                                                    },
                                                    attrs: {
                                                      readonly: false,
                                                      label: "Core Entry Type",
                                                      value:
                                                        entry.coreInformation,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "coreInformation",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "10px",
                                                      },
                                                    },
                                                    [_vm._v("Connect With")]
                                                  ),
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      readonly: true,
                                                      dense: "",
                                                      outlined: "",
                                                      value: entry.connectWith,
                                                      "item-text": "title",
                                                      "item-value": "id",
                                                      label:
                                                        "Select Equip / DP",
                                                      "return-object": false,
                                                      multiple: false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "connectWith",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      readonly: true,
                                                      dense: "",
                                                      outlined: "",
                                                      value:
                                                        entry.connectWithMid,
                                                      "item-text": "title",
                                                      "item-value": "id",
                                                      label:
                                                        "Select Outputs / Entries",
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "connectWithMid",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("base-autocomplete", {
                                                    attrs: {
                                                      readonly: true,
                                                      dense: "",
                                                      outlined: "",
                                                      items: [
                                                        "IN",
                                                        "OUT",
                                                        "N/A",
                                                      ],
                                                      value:
                                                        entry.connectDirection,
                                                      "item-text": "value",
                                                      "item-value": "id",
                                                      label: "Direction",
                                                      "return-object": false,
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          entry,
                                                          "connectDirection",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "base-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateCable(entry)
                                            },
                                          },
                                        },
                                        [_vm._v("Update")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.coreDetailsView &&
          _vm.coreGroupView &&
          _vm.coreDetailsGroupArrayList &&
          _vm.coreDetailsGroupArrayList.length
            ? _c(
                "div",
                {
                  staticClass: "height-50h",
                  class: { "height-68h": _vm.diagramFullPageCoreView },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "px-3",
                                    staticStyle: {
                                      "margin-left": "auto",
                                      float: "right",
                                      "margin-bottom": "-20px",
                                      "margin-top": "-10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "base-btn",
                                      {
                                        staticStyle: {
                                          padding: "0 !important",
                                          border: "none",
                                          height: "auto",
                                          "min-width": "auto",
                                          "background-color":
                                            "white !important",
                                          "box-shadow": "none",
                                        },
                                        on: { click: _vm.fullPageCoreView },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            height: "16",
                                            width: "16",
                                            contain: "",
                                            src: "/images/enlarge.png",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "base-btn",
                                      {
                                        staticStyle: {
                                          padding: "0 !important",
                                          border: "none",
                                          height: "auto",
                                          "min-width": "auto",
                                          "background-color":
                                            "white !important",
                                          "box-shadow": "none",
                                        },
                                        on: { click: _vm.minimizePageCoreView },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            height: "26",
                                            width: "26",
                                            contain: "",
                                            src: "/images/minus.png",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        border: "1px solid black",
                                        "border-radius": "25px",
                                        display: "inline-block",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("Connectivity Information")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "margin-top": "-20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [_vm._v("Connection 1")]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: { padding: "0" },
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "d-flex",
                                                      staticStyle: {
                                                        border:
                                                          "solid black 1px",
                                                        "border-radius": "10px",
                                                        padding: "5px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.locationPathFrom,
                                                      function (path, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: path.id,
                                                            staticClass:
                                                              "d-flex",
                                                          },
                                                          [
                                                            path.type === "site"
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/plant.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "distribution" &&
                                                                index ===
                                                                  _vm
                                                                    .locationPathFrom
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/dp.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "equipment" &&
                                                                index ===
                                                                  _vm
                                                                    .locationPathFrom
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/equip.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "location" &&
                                                                index <
                                                                  _vm
                                                                    .locationPathFrom
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/area.png",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                            path.type === "site"
                                                              ? _c(
                                                                  "h4",
                                                                  {
                                                                    staticClass:
                                                                      "header-title-text",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          path.title
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              path.title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                            index !==
                                                            _vm.locationPathFrom
                                                              .length -
                                                              1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "header-title-text next-arrow",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "black",
                                                                        padding:
                                                                          "0px 5px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " > "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [_vm._v("Connection 2")]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: { padding: "0" },
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "d-flex",
                                                      staticStyle: {
                                                        border:
                                                          "solid black 1px",
                                                        "border-radius": "10px",
                                                        padding: "5px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.locationPathTo,
                                                      function (path, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: path.id,
                                                            staticClass:
                                                              "d-flex",
                                                          },
                                                          [
                                                            path.type === "site"
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/plant.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "distribution" &&
                                                                index ===
                                                                  _vm
                                                                    .locationPathTo
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/dp.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "equipment" &&
                                                                index ===
                                                                  _vm
                                                                    .locationPathTo
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/equip.png",
                                                                  },
                                                                })
                                                              : path.type ===
                                                                  "location" &&
                                                                index <
                                                                  _vm
                                                                    .locationPathTo
                                                                    .length -
                                                                    1
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "area-search header-img-title",
                                                                  attrs: {
                                                                    height:
                                                                      "12",
                                                                    width: "12",
                                                                    contain: "",
                                                                    src: "/images/area.png",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                            path.type === "site"
                                                              ? _c(
                                                                  "h4",
                                                                  {
                                                                    staticClass:
                                                                      "header-title-text",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          path.title
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "header-title-text",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              path.title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                            index !==
                                                            _vm.locationPathTo
                                                              .length -
                                                              1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "header-title-text next-arrow",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "black",
                                                                        padding:
                                                                          "0px 5px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " > "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        border: "1px solid black",
                                        "border-radius": "25px",
                                        display: "inline-block",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("Core Information")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "margin-top": "-20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  readonly: true,
                                                  label: "Group ID",
                                                  disabled: "",
                                                  value:
                                                    _vm.coreGroupViewObj.id,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.coreGroupViewObj,
                                                      "id",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  readonly: false,
                                                  label: "Group Name",
                                                  value:
                                                    _vm.coreGroupViewObj.name,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.coreGroupViewObj,
                                                      "name",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  readonly: true,
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.coreDetailsGroupArrayList,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  multiple: "",
                                                  label: "Cores",
                                                },
                                                model: {
                                                  value:
                                                    _vm.coreDetailsGroupArrayList,
                                                  callback: function ($$v) {
                                                    _vm.coreDetailsGroupArrayList =
                                                      $$v
                                                  },
                                                  expression:
                                                    "coreDetailsGroupArrayList",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        border: "1px solid black",
                                        "border-radius": "25px",
                                        display: "inline-block",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("Core Information")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-sheet",
                                                    {
                                                      staticClass: "mx-auto",
                                                      attrs: {
                                                        "max-width": "350",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-slide-group",
                                                        {
                                                          attrs: {
                                                            "slider-color":
                                                              "#1b3054",
                                                            "show-arrows": "",
                                                            centered: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "prev",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.prev()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-chevron-left"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "next",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.next()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-chevron-right"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1796914642
                                                          ),
                                                          model: {
                                                            value: _vm.tab,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.tab = $$v
                                                            },
                                                            expression: "tab",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.coreDetailsGroupArrayList,
                                                          function (entry) {
                                                            return _c(
                                                              "v-slide-item",
                                                              {
                                                                key: entry.sequence,
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          active,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "spaced-slide-btn tab-cable-core",
                                                                                attrs:
                                                                                  {
                                                                                    "input-value":
                                                                                      active,
                                                                                    "active-class":
                                                                                      "tab-cable-core-active",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.setActiveGroup(
                                                                                        entry.id
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      entry?.name
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tabs-items",
                                            {
                                              model: {
                                                value: _vm.tab,
                                                callback: function ($$v) {
                                                  _vm.tab = $$v
                                                },
                                                expression: "tab",
                                              },
                                            },
                                            _vm._l(
                                              _vm.coreDetailsGroupArrayList,
                                              function (entry) {
                                                return _c(
                                                  "v-tab-item",
                                                  { key: entry.id },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "connectivity-row",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                            "line-height":
                                                                              "30px",
                                                                            color:
                                                                              "#6c707a",
                                                                            "margin-left":
                                                                              "46px",
                                                                            "margin-top":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Connectivity Information "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "connection-col ccol1",
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                            "line-height":
                                                                              "30px",
                                                                            color:
                                                                              "#6c707a",
                                                                            "margin-left":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Connection 1 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                            staticStyle:
                                                                              {
                                                                                border:
                                                                                  "solid black 1px",
                                                                                "border-radius":
                                                                                  "10px",
                                                                                padding:
                                                                                  "5px",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm.locationPathTo,
                                                                            function (
                                                                              path,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: path.id,
                                                                                  staticClass:
                                                                                    "d-flex",
                                                                                },
                                                                                [
                                                                                  path.type ===
                                                                                  "site"
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "18",
                                                                                              width:
                                                                                                "18",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/plant.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : path.type ===
                                                                                        "location" &&
                                                                                      index ===
                                                                                        _vm
                                                                                          .locationPathTo
                                                                                          .length -
                                                                                          1
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "20",
                                                                                              width:
                                                                                                "22",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/equip.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : path.type ===
                                                                                        "location" &&
                                                                                      index <
                                                                                        _vm
                                                                                          .locationPathTo
                                                                                          .length -
                                                                                          1
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "18",
                                                                                              width:
                                                                                                "18",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/area.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  path.type ===
                                                                                  "site"
                                                                                    ? _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "h4",
                                                                                            {
                                                                                              staticClass:
                                                                                                "header-title-text",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  path.title
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                  index !==
                                                                                  _vm
                                                                                    .locationPathTo
                                                                                    .length -
                                                                                    1
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text next-arrow",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "black",
                                                                                              padding:
                                                                                                "0px 5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " > "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-autocomplete",
                                                                          {
                                                                            staticClass:
                                                                              "flex-grow-1",
                                                                            attrs:
                                                                              {
                                                                                readonly: true,
                                                                                name: "wiringCableEntryType",
                                                                                items:
                                                                                  _vm
                                                                                    .adminDataFieldsSrv
                                                                                    .equipmentFieldOptions[
                                                                                    _vm
                                                                                      .equipmentFieldsList
                                                                                      .equipmentWiringCableEntryType
                                                                                  ],
                                                                                value:
                                                                                  entry.cableType,
                                                                                "item-text":
                                                                                  "value",
                                                                                "item-value":
                                                                                  "id",
                                                                                label:
                                                                                  "Core Entry Type",
                                                                                "return-object": false,
                                                                              },
                                                                            on: {
                                                                              "update:value":
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    entry,
                                                                                    "cableType",
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "connection-col ccol2",
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                            "line-height":
                                                                              "30px",
                                                                            color:
                                                                              "#6c707a",
                                                                            "margin-left":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Connection 2 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                            staticStyle:
                                                                              {
                                                                                border:
                                                                                  "solid black 1px",
                                                                                "border-radius":
                                                                                  "10px",
                                                                                padding:
                                                                                  "5px",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm.locationPathFrom,
                                                                            function (
                                                                              path,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: path.id,
                                                                                  staticClass:
                                                                                    "d-flex",
                                                                                },
                                                                                [
                                                                                  path.type ===
                                                                                  "site"
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "18",
                                                                                              width:
                                                                                                "18",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/plant.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : path.type ===
                                                                                        "location" &&
                                                                                      index ===
                                                                                        _vm
                                                                                          .locationPathFrom
                                                                                          .length -
                                                                                          1
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "20",
                                                                                              width:
                                                                                                "22",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/equip.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : path.type ===
                                                                                        "location" &&
                                                                                      index <
                                                                                        _vm
                                                                                          .locationPathFrom
                                                                                          .length -
                                                                                          1
                                                                                    ? _c(
                                                                                        "v-img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "area-search header-img-title",
                                                                                          attrs:
                                                                                            {
                                                                                              height:
                                                                                                "18",
                                                                                              width:
                                                                                                "18",
                                                                                              contain:
                                                                                                "",
                                                                                              src: "/images/area.png",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  path.type ===
                                                                                  "site"
                                                                                    ? _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "h4",
                                                                                            {
                                                                                              staticClass:
                                                                                                "header-title-text",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  path.title
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                  index !==
                                                                                  _vm
                                                                                    .locationPathFrom
                                                                                    .length -
                                                                                    1
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text next-arrow",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "black",
                                                                                              padding:
                                                                                                "0px 5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " > "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-autocomplete",
                                                                          {
                                                                            staticClass:
                                                                              "flex-grow-1",
                                                                            attrs:
                                                                              {
                                                                                readonly: true,
                                                                                name: "wiringCableEntryType",
                                                                                items:
                                                                                  _vm
                                                                                    .adminDataFieldsSrv
                                                                                    .equipmentFieldOptions[
                                                                                    _vm
                                                                                      .equipmentFieldsList
                                                                                      .equipmentWiringCableEntryType
                                                                                  ],
                                                                                value:
                                                                                  entry.cableType,
                                                                                "item-text":
                                                                                  "value",
                                                                                "item-value":
                                                                                  "id",
                                                                                label:
                                                                                  "Core Entry Type",
                                                                                "return-object": false,
                                                                              },
                                                                            on: {
                                                                              "update:value":
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    entry,
                                                                                    "cableType",
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "connectivity-row",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "36px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                            "line-height":
                                                                              "30px",
                                                                            color:
                                                                              "#6c707a",
                                                                            "margin-top":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Core Information "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: true,
                                                                          value:
                                                                            entry.id,
                                                                          disabled:
                                                                            "",
                                                                          label:
                                                                            "Core ID",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "id",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          value:
                                                                            entry.name,
                                                                          label:
                                                                            "Core Name",
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "name",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: false,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Entry Type",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Manufacturer",
                                                                          value:
                                                                            entry.manufacturer,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "manufacturer",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Model No",
                                                                          value:
                                                                            entry.modelNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "modelNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Serial No",
                                                                          value:
                                                                            entry.serialNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "serialNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Certification No",
                                                                          value:
                                                                            entry.certificationNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "certificationNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: false,
                                                                          name: "wiringProtectionType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableProtectionType
                                                                            ],
                                                                          value:
                                                                            entry.protectionType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Protection Type",
                                                                          "return-object": false,
                                                                          "error-count":
                                                                            _vm.siteLocationsSrv.checkFieldError(
                                                                              "equipmentWiringCableProtectionType",
                                                                              entry.id
                                                                            )
                                                                              .length ??
                                                                            1,
                                                                          "error-messages":
                                                                            _vm.siteLocationsSrv.checkFieldError(
                                                                              "equipmentWiringCableProtectionType",
                                                                              entry.id
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "protectionType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Core Model No",
                                                                          value:
                                                                            entry.cableModelNo,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableModelNo",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: false,
                                                                          name: "equipmentWiringCableFunction",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableFunction
                                                                            ],
                                                                          value:
                                                                            entry.cableFunction,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Function",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableFunction",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Core Manufacturer",
                                                                          value:
                                                                            entry.cableManufacturer,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableManufacturer",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-text-field",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#fdf7e7",
                                                                          },
                                                                        attrs: {
                                                                          readonly: false,
                                                                          label:
                                                                            "Core Entry Type",
                                                                          value:
                                                                            entry.coreInformation,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "coreInformation",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Connect With"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly: true,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          value:
                                                                            entry.connectWith,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Select Equip / DP",
                                                                          "return-object": false,
                                                                          multiple: false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectWith",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly: true,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          value:
                                                                            entry.connectWithMid,
                                                                          "item-text":
                                                                            "title",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Select Outputs / Entries",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectWithMid",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          readonly: true,
                                                                          dense:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                          items:
                                                                            [
                                                                              "IN",
                                                                              "OUT",
                                                                              "N/A",
                                                                            ],
                                                                          value:
                                                                            entry.connectDirection,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Direction",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "connectDirection",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-actions",
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateCable(
                                                                  entry,
                                                                  true
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Update")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.coreDetailsView &&
          !_vm.coreGroupView &&
          !_vm.coreDetailsViewDPE &&
          !_vm.coreDetailsViewEDP &&
          _vm.switchBoardsFromArrayList &&
          _vm.switchBoardsFromArrayList.length
            ? _c(
                "div",
                {
                  staticClass: "height-50h",
                  class: { "height-68h": _vm.diagramFullPageCoreView },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "px-3",
                                    staticStyle: {
                                      "margin-left": "auto",
                                      float: "right",
                                      "margin-bottom": "-20px",
                                      "margin-top": "-10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "base-btn",
                                      {
                                        staticStyle: {
                                          padding: "0 !important",
                                          border: "none",
                                          height: "auto",
                                          "min-width": "auto",
                                          "background-color":
                                            "white !important",
                                          "box-shadow": "none",
                                        },
                                        on: { click: _vm.fullPageCoreView },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            height: "16",
                                            width: "16",
                                            contain: "",
                                            src: "/images/enlarge.png",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "base-btn",
                                      {
                                        staticStyle: {
                                          padding: "0 !important",
                                          border: "none",
                                          height: "auto",
                                          "min-width": "auto",
                                          "background-color":
                                            "white !important",
                                          "box-shadow": "none",
                                        },
                                        on: { click: _vm.minimizePageCoreView },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            height: "26",
                                            width: "26",
                                            contain: "",
                                            src: "/images/minus.png",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "border-right": "1px solid lightgray",
                                  },
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        border: "1px solid black",
                                        "border-radius": "25px",
                                      },
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("Connectivity Information")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "margin-top": "-20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "rounded-card",
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "card-heading-small",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Power Board "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticStyle: {
                                                            height: "314px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-spacer",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "5px",
                                                                        },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        readonly: false,
                                                                        dense:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        items:
                                                                          _vm.powerBoardType,
                                                                        value:
                                                                          _vm
                                                                            .coreFromLocationData
                                                                            .distributionPanel
                                                                            .powerBoardType,
                                                                        "item-text":
                                                                          "value",
                                                                        "item-value":
                                                                          "id",
                                                                        label:
                                                                          "Type",
                                                                        "return-object": false,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-text-field",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fdf7e7",
                                                                        },
                                                                      attrs: {
                                                                        readonly: false,
                                                                        value:
                                                                          _vm
                                                                            .coreFromLocationData
                                                                            .distributionPanel
                                                                            .powerBoardVoltage,
                                                                        name: "powerBoardVoltage",
                                                                        label:
                                                                          "Voltage",
                                                                      },
                                                                      on: {
                                                                        "update:value":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              _vm
                                                                                .coreFromLocationData
                                                                                .distributionPanel,
                                                                              "powerBoardVoltage",
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-text-field",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fdf7e7",
                                                                        },
                                                                      attrs: {
                                                                        readonly: false,
                                                                        value:
                                                                          _vm
                                                                            .coreFromLocationData
                                                                            .distributionPanel
                                                                            .powerBoardFrequency,
                                                                        name: "powerBoardFrequency",
                                                                        label:
                                                                          "Frequency",
                                                                      },
                                                                      on: {
                                                                        "update:value":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              _vm
                                                                                .coreFromLocationData
                                                                                .distributionPanel,
                                                                              "powerBoardFrequency",
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "base-btn",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateDPBoard(
                                                                _vm.coreFromLocationData
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Update")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "rounded-card",
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "card-heading-small",
                                                        },
                                                        [_vm._v("Switch Board")]
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticStyle: {
                                                            overflow: "scroll",
                                                            height: "300px",
                                                            "overflow-x":
                                                              "hidden",
                                                          },
                                                        },
                                                        [
                                                          _vm
                                                            .switchBoardsFromArrayList
                                                            ?.length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "switches",
                                                                },
                                                                _vm._l(
                                                                  _vm.switchBoardsFromArrayList,
                                                                  function (
                                                                    board,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-row",
                                                                      {
                                                                        key: board.id,
                                                                        staticClass:
                                                                          "switch-b",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center",
                                                                            staticStyle:
                                                                              {
                                                                                "padding-bottom":
                                                                                  "0",
                                                                                "padding-top":
                                                                                  "8px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h4",
                                                                              {
                                                                                staticClass:
                                                                                  "text-h6c",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Core " +
                                                                                    _vm._s(
                                                                                      index +
                                                                                        1
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      "",
                                                                                    value:
                                                                                      board.id,
                                                                                    name: "powerBoardID",
                                                                                    label:
                                                                                      "Output ID",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "id",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outpulPhase,
                                                                                    name: "powerBoardVoltage",
                                                                                    label:
                                                                                      "Phase",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outpulPhase",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outputVoltage,
                                                                                    name: "powerBoardVoltage",
                                                                                    label:
                                                                                      "Voltage",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outputVoltage",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outputFrequency,
                                                                                    name: "powerBoardFrequency",
                                                                                    label:
                                                                                      "Frequency",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outputFrequency",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    padding:
                                                                                      "15px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-spacer"
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "25px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        icon: "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.updateBoard(
                                                                                            board
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "green--text cursor-pointer",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-check"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                                "data-id":
                                                                                  index,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h3",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-bottom":
                                                                                      "10px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Connect With"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    padding:
                                                                                      "0",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex",
                                                                                    staticStyle:
                                                                                      {
                                                                                        border:
                                                                                          "solid black 1px",
                                                                                        "border-radius":
                                                                                          "10px",
                                                                                        padding:
                                                                                          "5px",
                                                                                      },
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.locationPathTo,
                                                                                    function (
                                                                                      path,
                                                                                      index
                                                                                    ) {
                                                                                      return _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: path.id,
                                                                                          staticClass:
                                                                                            "d-flex",
                                                                                        },
                                                                                        [
                                                                                          path.type ===
                                                                                          "site"
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/plant.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "location" &&
                                                                                              index ===
                                                                                                _vm
                                                                                                  .locationPathTo
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/area.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "distribution" &&
                                                                                              index ===
                                                                                                _vm
                                                                                                  .locationPathTo
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/dp.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "equipment" &&
                                                                                              index ===
                                                                                                _vm
                                                                                                  .locationPathTo
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/equip.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "location" &&
                                                                                              index <
                                                                                                _vm
                                                                                                  .locationPathTo
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/area.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          path.type ===
                                                                                          "site"
                                                                                            ? _c(
                                                                                                "h4",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "header-title-text",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        path.title
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "cursor-pointer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "12px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            path.title
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                          index !==
                                                                                          _vm
                                                                                            .locationPathTo
                                                                                            .length -
                                                                                            1
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "header-title-text next-arrow",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      color:
                                                                                                        "black",
                                                                                                      padding:
                                                                                                        "0px 5px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " > "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-autocomplete",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    readonly: true,
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    items:
                                                                                      _vm.selectConnectDirection,
                                                                                    value:
                                                                                      board.connectDirection,
                                                                                    "item-text":
                                                                                      "value",
                                                                                    "item-value":
                                                                                      "id",
                                                                                    label:
                                                                                      "Direction",
                                                                                    "return-object": false,
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "connectDirection",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        border: "1px solid black",
                                        "border-radius": "25px",
                                      },
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("Connectivity Information")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "margin-top": "-20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "rounded-card",
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "card-heading-small",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Power Board "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticStyle: {
                                                            height: "314px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-spacer",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "5px",
                                                                        },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        readonly: false,
                                                                        dense:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        items:
                                                                          _vm.powerBoardType,
                                                                        value:
                                                                          _vm
                                                                            .coreToLocationData
                                                                            .powerBoardType,
                                                                        "item-text":
                                                                          "value",
                                                                        "item-value":
                                                                          "id",
                                                                        label:
                                                                          "Type",
                                                                        "return-object": false,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-text-field",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fdf7e7",
                                                                        },
                                                                      attrs: {
                                                                        readonly: false,
                                                                        value:
                                                                          _vm
                                                                            .coreToLocationData
                                                                            .powerBoardVoltage,
                                                                        name: "powerBoardVoltage",
                                                                        label:
                                                                          "Voltage",
                                                                      },
                                                                      on: {
                                                                        "update:value":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              _vm.coreToLocationData,
                                                                              "powerBoardVoltage",
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-text-field",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fdf7e7",
                                                                        },
                                                                      attrs: {
                                                                        readonly: false,
                                                                        value:
                                                                          _vm
                                                                            .coreToLocationData
                                                                            .powerBoardFrequency,
                                                                        name: "powerBoardFrequency",
                                                                        label:
                                                                          "Frequency",
                                                                      },
                                                                      on: {
                                                                        "update:value":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              _vm.coreToLocationData,
                                                                              "powerBoardFrequency",
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "base-btn",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateDPBoard(
                                                                _vm.coreToLocationData
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Update")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "rounded-card",
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "card-heading-small",
                                                        },
                                                        [_vm._v("Switch Board")]
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticStyle: {
                                                            overflow: "scroll",
                                                            height: "300px",
                                                            "overflow-x":
                                                              "hidden",
                                                          },
                                                        },
                                                        [
                                                          _vm
                                                            .switchBoardsToArrayList
                                                            ?.length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "switches",
                                                                },
                                                                _vm._l(
                                                                  _vm.switchBoardsToArrayList,
                                                                  function (
                                                                    board,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-row",
                                                                      {
                                                                        key: board.id,
                                                                        staticClass:
                                                                          "switch-b",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center",
                                                                            staticStyle:
                                                                              {
                                                                                "padding-bottom":
                                                                                  "0",
                                                                                "padding-top":
                                                                                  "8px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h4",
                                                                              {
                                                                                staticClass:
                                                                                  "text-h6c",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Core " +
                                                                                    _vm._s(
                                                                                      index +
                                                                                        1
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      "",
                                                                                    value:
                                                                                      board.id,
                                                                                    name: "powerBoardID",
                                                                                    label:
                                                                                      "Output ID",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "id",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outpulPhase,
                                                                                    name: "powerBoardVoltage",
                                                                                    label:
                                                                                      "Phase",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outpulPhase",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outputVoltage,
                                                                                    name: "powerBoardVoltage",
                                                                                    label:
                                                                                      "Voltage",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outputVoltage",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-text-field",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#fdf7e7",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    readonly: false,
                                                                                    value:
                                                                                      board.outputFrequency,
                                                                                    name: "powerBoardFrequency",
                                                                                    label:
                                                                                      "Frequency",
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "outputFrequency",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    padding:
                                                                                      "15px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-spacer"
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "25px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        icon: "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.updateBoard(
                                                                                            board
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "green--text cursor-pointer",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-check"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                                "data-id":
                                                                                  index,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h3",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-bottom":
                                                                                      "10px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Connect With"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    padding:
                                                                                      "0",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex",
                                                                                    staticStyle:
                                                                                      {
                                                                                        border:
                                                                                          "solid black 1px",
                                                                                        "border-radius":
                                                                                          "10px",
                                                                                        padding:
                                                                                          "5px",
                                                                                      },
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.locationPathFrom,
                                                                                    function (
                                                                                      path,
                                                                                      index
                                                                                    ) {
                                                                                      return _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: path.id,
                                                                                          staticClass:
                                                                                            "d-flex",
                                                                                        },
                                                                                        [
                                                                                          path.type ===
                                                                                          "site"
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/plant.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "equipment" &&
                                                                                              index ===
                                                                                                _vm
                                                                                                  .locationPathFrom
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/equip.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "distribution" &&
                                                                                              index ===
                                                                                                _vm
                                                                                                  .locationPathFrom
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/dp.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : path.type ===
                                                                                                "location" &&
                                                                                              index <
                                                                                                _vm
                                                                                                  .locationPathFrom
                                                                                                  .length -
                                                                                                  1
                                                                                            ? _c(
                                                                                                "v-img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "area-search header-img-title",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        "12",
                                                                                                      width:
                                                                                                        "12",
                                                                                                      contain:
                                                                                                        "",
                                                                                                      src: "/images/area.png",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          path.type ===
                                                                                          "site"
                                                                                            ? _c(
                                                                                                "h4",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "header-title-text",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        path.title
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "cursor-pointer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "12px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            path.title
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                          index !==
                                                                                          _vm
                                                                                            .locationPathFrom
                                                                                            .length -
                                                                                            1
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "header-title-text next-arrow",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      color:
                                                                                                        "black",
                                                                                                      padding:
                                                                                                        "0px 5px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " > "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "base-autocomplete",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    readonly: true,
                                                                                    dense:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                    items:
                                                                                      _vm.selectConnectDirection,
                                                                                    value:
                                                                                      board.connectDirection,
                                                                                    "item-text":
                                                                                      "value",
                                                                                    "item-value":
                                                                                      "id",
                                                                                    label:
                                                                                      "Direction",
                                                                                    "return-object": false,
                                                                                  },
                                                                                on: {
                                                                                  "update:value":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        board,
                                                                                        "connectDirection",
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.coreDetailsView &&
          !_vm.coreGroupView &&
          _vm.coreDetailsViewEDP &&
          _vm.coreDetailsArrayList &&
          _vm.coreDetailsArrayList?.length &&
          _vm.coreToLocationData
            ? _c(
                "div",
                {
                  staticClass: "height-50h",
                  class: { "height-68h": _vm.diagramFullPageCoreView },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            color: "deep-purple-accent-4",
                            "align-tabs": "center",
                          },
                          model: {
                            value: _vm.tabEDP,
                            callback: function ($$v) {
                              _vm.tabEDP = $$v
                            },
                            expression: "tabEDP",
                          },
                        },
                        [
                          _c("v-tab", { key: "1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.coreFromLocationData &&
                                  _vm.coreFromLocationData.title
                                  ? _vm.coreFromLocationData.title
                                  : "Equipment"
                              )
                            ),
                          ]),
                          _c("v-tab", { key: "2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.coreToLocationData &&
                                  _vm.coreToLocationData.title
                                  ? _vm.coreToLocationData.title
                                  : "Distribution Panel"
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "px-3",
                                staticStyle: {
                                  "margin-left": "auto",
                                  float: "right",
                                  "margin-top": "-38px",
                                },
                              },
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticStyle: {
                                      padding: "0 !important",
                                      border: "none",
                                      height: "auto",
                                      "min-width": "auto",
                                      "background-color": "white !important",
                                      "box-shadow": "none",
                                    },
                                    on: { click: _vm.fullPageCoreView },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "16",
                                        width: "16",
                                        contain: "",
                                        src: "/images/enlarge.png",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "base-btn",
                                  {
                                    staticStyle: {
                                      padding: "0 !important",
                                      border: "none",
                                      height: "auto",
                                      "min-width": "auto",
                                      "background-color": "white !important",
                                      "box-shadow": "none",
                                    },
                                    on: { click: _vm.minimizePageCoreView },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "26",
                                        width: "26",
                                        contain: "",
                                        src: "/images/minus.png",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tabEDP,
                            callback: function ($$v) {
                              _vm.tabEDP = $$v
                            },
                            expression: "tabEDP",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            { key: "1" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-sheet",
                                                {
                                                  staticClass: "mx-auto",
                                                  attrs: { "max-width": "350" },
                                                },
                                                [
                                                  _c(
                                                    "v-slide-group",
                                                    {
                                                      attrs: {
                                                        "slider-color":
                                                          "#1b3054",
                                                        "show-arrows": "",
                                                        centered: "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "prev",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.prev()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-left"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "next",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.next()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-right"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1796914642
                                                      ),
                                                      model: {
                                                        value: _vm.tab,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.tab = $$v
                                                        },
                                                        expression: "tab",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.coreDetailsArrayList,
                                                      function (entry) {
                                                        return _c(
                                                          "v-slide-item",
                                                          {
                                                            key: entry.sequence,
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    active,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "spaced-slide-btn tab-cable-core",
                                                                          attrs:
                                                                            {
                                                                              "input-value":
                                                                                active,
                                                                              "active-class":
                                                                                "tab-cable-core-active",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.setActive(
                                                                                  entry.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                entry?.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.tab,
                                            callback: function ($$v) {
                                              _vm.tab = $$v
                                            },
                                            expression: "tab",
                                          },
                                        },
                                        _vm._l(
                                          _vm.coreDetailsArrayList,
                                          function (entry) {
                                            return _c(
                                              "v-tab-item",
                                              { key: entry.id },
                                              [
                                                _c(
                                                  "v-card",
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "connectivity-row",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "46px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connectivity Information "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "connection-col ccol1",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connection 1 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "solid black 1px",
                                                                            "border-radius":
                                                                              "10px",
                                                                            padding:
                                                                              "5px",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.locationPathTo,
                                                                        function (
                                                                          path,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: path.id,
                                                                              staticClass:
                                                                                "d-flex",
                                                                            },
                                                                            [
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/plant.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "equipment" &&
                                                                                  index ===
                                                                                    _vm
                                                                                      .locationPathTo
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "20",
                                                                                          width:
                                                                                            "22",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/equip.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "distribution" &&
                                                                                  index ===
                                                                                    _vm
                                                                                      .locationPathTo
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "20",
                                                                                          width:
                                                                                            "22",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/dp.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index <
                                                                                    _vm
                                                                                      .locationPathTo
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/area.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "h4",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          path.title
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              index !==
                                                                              _vm
                                                                                .locationPathTo
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text next-arrow",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "black",
                                                                                          padding:
                                                                                            "0px 5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " > "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: true,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Entry Type",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "connection-col ccol2",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connection 2 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "solid black 1px",
                                                                            "border-radius":
                                                                              "10px",
                                                                            padding:
                                                                              "5px",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.locationPathFrom,
                                                                        function (
                                                                          path,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: path.id,
                                                                              staticClass:
                                                                                "d-flex",
                                                                            },
                                                                            [
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/plant.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "equipment" &&
                                                                                  index ===
                                                                                    _vm
                                                                                      .locationPathFrom
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "20",
                                                                                          width:
                                                                                            "22",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/equip.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index <
                                                                                    _vm
                                                                                      .locationPathFrom
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/area.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "h4",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          path.title
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              index !==
                                                                              _vm
                                                                                .locationPathFrom
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text next-arrow",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "black",
                                                                                          padding:
                                                                                            "0px 5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " > "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: true,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Entry Type",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "connectivity-row",
                                                            staticStyle: {
                                                              "margin-top":
                                                                "36px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Core Information "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      value:
                                                                        entry.id,
                                                                      disabled:
                                                                        "",
                                                                      label:
                                                                        "Core ID",
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "id",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      value:
                                                                        entry.name,
                                                                      label:
                                                                        "Core Name",
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "name",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "wiringCableEntryType",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableEntryType
                                                                        ],
                                                                      value:
                                                                        entry.cableType,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Core Entry Type",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableType",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Manufacturer",
                                                                      value:
                                                                        entry.manufacturer,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "manufacturer",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Model No",
                                                                      value:
                                                                        entry.modelNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "modelNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Serial No",
                                                                      value:
                                                                        entry.serialNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "serialNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Certification No",
                                                                      value:
                                                                        entry.certificationNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "certificationNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "wiringProtectionType",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableProtectionType
                                                                        ],
                                                                      value:
                                                                        entry.protectionType,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Protection Type",
                                                                      "return-object": false,
                                                                      "error-count":
                                                                        _vm.siteLocationsSrv.checkFieldError(
                                                                          "equipmentWiringCableProtectionType",
                                                                          entry.id
                                                                        )
                                                                          .length ??
                                                                        1,
                                                                      "error-messages":
                                                                        _vm.siteLocationsSrv.checkFieldError(
                                                                          "equipmentWiringCableProtectionType",
                                                                          entry.id
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "protectionType",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Model No",
                                                                      value:
                                                                        entry.cableModelNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableModelNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "equipmentWiringCableFunction",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableFunction
                                                                        ],
                                                                      value:
                                                                        entry.cableFunction,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Core Function",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableFunction",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#fdf7e7",
                                                                      },
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Manufacturer",
                                                                      value:
                                                                        entry.cableManufacturer,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableManufacturer",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#fdf7e7",
                                                                      },
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Entry Type",
                                                                      value:
                                                                        entry.coreInformation,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "coreInformation",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h3",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Connect With"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      value:
                                                                        entry.connectWith,
                                                                      "item-text":
                                                                        "title",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Select Equip / DP",
                                                                      "return-object": false,
                                                                      multiple: false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectWith",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      value:
                                                                        entry.connectWithMid,
                                                                      "item-text":
                                                                        "title",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Select Outputs / Entries",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectWithMid",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      items: [
                                                                        "IN",
                                                                        "OUT",
                                                                        "N/A",
                                                                      ],
                                                                      value:
                                                                        entry.connectDirection,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Direction",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectDirection",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "base-btn",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateCable(
                                                              entry
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Update")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "2" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticStyle: {
                                            border: "1px solid black",
                                            "border-radius": "25px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Connectivity Information"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticStyle: {
                                                "margin-top": "-20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "rounded-card",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "card-heading-small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Power Board "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticStyle: {
                                                                height: "314px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-spacer",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "5px",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-autocomplete",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              items:
                                                                                _vm.powerBoardType,
                                                                              value:
                                                                                _vm
                                                                                  .coreToLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardType,
                                                                              "item-text":
                                                                                "value",
                                                                              "item-value":
                                                                                "id",
                                                                              label:
                                                                                "Type",
                                                                              "return-object": false,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#fdf7e7",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              value:
                                                                                _vm
                                                                                  .coreToLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardVoltage,
                                                                              name: "powerBoardVoltage",
                                                                              label:
                                                                                "Voltage",
                                                                            },
                                                                          on: {
                                                                            "update:value":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm
                                                                                    .coreToLocationData
                                                                                    .distributionPanel,
                                                                                  "powerBoardVoltage",
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#fdf7e7",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              value:
                                                                                _vm
                                                                                  .coreToLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardFrequency,
                                                                              name: "powerBoardFrequency",
                                                                              label:
                                                                                "Frequency",
                                                                            },
                                                                          on: {
                                                                            "update:value":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm
                                                                                    .coreToLocationData
                                                                                    .distributionPanel,
                                                                                  "powerBoardFrequency",
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "base-btn",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateDPBoard(
                                                                      _vm.coreToLocationData
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Update")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "rounded-card",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "card-heading-small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Switch Board"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticStyle: {
                                                                overflow:
                                                                  "scroll",
                                                                height: "300px",
                                                                "overflow-x":
                                                                  "hidden",
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .switchBoardsToArrayList
                                                                ?.length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "switches",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.switchBoardsToArrayList,
                                                                      function (
                                                                        board,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-row",
                                                                          {
                                                                            key: board.id,
                                                                            staticClass:
                                                                              "switch-b",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                                staticStyle:
                                                                                  {
                                                                                    "padding-bottom":
                                                                                      "0",
                                                                                    "padding-top":
                                                                                      "8px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h4",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-h6c",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Core " +
                                                                                        _vm._s(
                                                                                          index +
                                                                                            1
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          "",
                                                                                        value:
                                                                                          board.id,
                                                                                        name: "powerBoardID",
                                                                                        label:
                                                                                          "Output ID",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "id",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outpulPhase,
                                                                                        name: "powerBoardVoltage",
                                                                                        label:
                                                                                          "Phase",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outpulPhase",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outputVoltage,
                                                                                        name: "powerBoardVoltage",
                                                                                        label:
                                                                                          "Voltage",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outputVoltage",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outputFrequency,
                                                                                        name: "powerBoardFrequency",
                                                                                        label:
                                                                                          "Frequency",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outputFrequency",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        padding:
                                                                                          "15px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-spacer"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "25px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                            icon: "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.updateBoard(
                                                                                                board
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "green--text cursor-pointer",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-check"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                    "data-id":
                                                                                      index,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h3",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "10px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Connect With"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        padding:
                                                                                          "0",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "12",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex",
                                                                                        staticStyle:
                                                                                          {
                                                                                            border:
                                                                                              "solid black 1px",
                                                                                            "border-radius":
                                                                                              "10px",
                                                                                            padding:
                                                                                              "5px",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.locationPathTo,
                                                                                        function (
                                                                                          path,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            {
                                                                                              key: path.id,
                                                                                              staticClass:
                                                                                                "d-flex",
                                                                                            },
                                                                                            [
                                                                                              path.type ===
                                                                                              "site"
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/plant.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : path.type ===
                                                                                                    "location" &&
                                                                                                  index ===
                                                                                                    _vm
                                                                                                      .locationPathTo
                                                                                                      .length -
                                                                                                      1
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/equip.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : path.type ===
                                                                                                    "location" &&
                                                                                                  index <
                                                                                                    _vm
                                                                                                      .locationPathTo
                                                                                                      .length -
                                                                                                      1
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/area.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              path.type ===
                                                                                              "site"
                                                                                                ? _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "12px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            path.title
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "cursor-pointer",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "h4",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "header-title-text",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "font-size":
                                                                                                                "12px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                path.title
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                              index !==
                                                                                              _vm
                                                                                                .locationPathTo
                                                                                                .length -
                                                                                                1
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text next-arrow",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          color:
                                                                                                            "black",
                                                                                                          padding:
                                                                                                            "0px 5px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " > "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-autocomplete",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: true,
                                                                                        dense:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                        items:
                                                                                          _vm.selectConnectDirection,
                                                                                        value:
                                                                                          board.connectDirection,
                                                                                        "item-text":
                                                                                          "value",
                                                                                        "item-value":
                                                                                          "id",
                                                                                        label:
                                                                                          "Direction",
                                                                                        "return-object": false,
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "connectDirection",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.coreDetailsView &&
          !_vm.coreGroupView &&
          _vm.coreDetailsViewDPE &&
          !_vm.coreDetailsViewEDP &&
          _vm.coreDetailsArrayList &&
          _vm.coreDetailsArrayList?.length &&
          _vm.coreFromLocationData &&
          _vm.switchBoardsFromArrayList
            ? _c(
                "div",
                {
                  staticClass: "height-50h",
                  class: { "height-68h": _vm.diagramFullPageCoreView },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            color: "deep-purple-accent-4",
                            "align-tabs": "center",
                          },
                          model: {
                            value: _vm.tabDPE,
                            callback: function ($$v) {
                              _vm.tabDPE = $$v
                            },
                            expression: "tabDPE",
                          },
                        },
                        [
                          _c("v-tab", { key: "1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.coreFromLocationData &&
                                  _vm.coreFromLocationData.title
                                  ? _vm.coreFromLocationData.title
                                  : "Distribution Panel"
                              )
                            ),
                          ]),
                          _c("v-tab", { key: "2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.coreToLocationData &&
                                  _vm.coreToLocationData.title
                                  ? _vm.coreToLocationData.title
                                  : "Equipment"
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "px-3",
                                staticStyle: {
                                  "margin-left": "auto",
                                  float: "right",
                                  "margin-top": "-38px",
                                },
                              },
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticStyle: {
                                      padding: "0 !important",
                                      border: "none",
                                      height: "auto",
                                      "min-width": "auto",
                                      "background-color": "white !important",
                                      "box-shadow": "none",
                                    },
                                    on: { click: _vm.fullPageCoreView },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "16",
                                        width: "16",
                                        contain: "",
                                        src: "/images/enlarge.png",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "base-btn",
                                  {
                                    staticStyle: {
                                      padding: "0 !important",
                                      border: "none",
                                      height: "auto",
                                      "min-width": "auto",
                                      "background-color": "white !important",
                                      "box-shadow": "none",
                                    },
                                    on: { click: _vm.minimizePageCoreView },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "26",
                                        width: "26",
                                        contain: "",
                                        src: "/images/minus.png",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tabDPE,
                            callback: function ($$v) {
                              _vm.tabDPE = $$v
                            },
                            expression: "tabDPE",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            { key: "1" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticStyle: {
                                            border: "1px solid black",
                                            "border-radius": "25px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Connectivity Information"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticStyle: {
                                                "margin-top": "-20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "rounded-card",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "card-heading-small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Power Board "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticStyle: {
                                                                height: "314px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-spacer",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "5px",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-autocomplete",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              items:
                                                                                _vm.powerBoardType,
                                                                              value:
                                                                                _vm
                                                                                  .coreFromLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardType,
                                                                              "item-text":
                                                                                "value",
                                                                              "item-value":
                                                                                "id",
                                                                              label:
                                                                                "Type",
                                                                              "return-object": false,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#fdf7e7",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              value:
                                                                                _vm
                                                                                  .coreFromLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardVoltage,
                                                                              name: "powerBoardVoltage",
                                                                              label:
                                                                                "Voltage",
                                                                            },
                                                                          on: {
                                                                            "update:value":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm
                                                                                    .coreFromLocationData
                                                                                    .distributionPanel,
                                                                                  "powerBoardVoltage",
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#fdf7e7",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              readonly: false,
                                                                              value:
                                                                                _vm
                                                                                  .coreFromLocationData
                                                                                  .distributionPanel
                                                                                  .powerBoardFrequency,
                                                                              name: "powerBoardFrequency",
                                                                              label:
                                                                                "Frequency",
                                                                            },
                                                                          on: {
                                                                            "update:value":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm
                                                                                    .coreFromLocationData
                                                                                    .distributionPanel,
                                                                                  "powerBoardFrequency",
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "base-btn",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateDPBoard(
                                                                      _vm.coreFromLocationData
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Update")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "rounded-card",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "card-heading-small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Switch Board"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticStyle: {
                                                                overflow:
                                                                  "scroll",
                                                                height: "300px",
                                                                "overflow-x":
                                                                  "hidden",
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .switchBoardsFromArrayList
                                                                ?.length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "switches",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.switchBoardsFromArrayList,
                                                                      function (
                                                                        board,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-row",
                                                                          {
                                                                            key: board.id,
                                                                            staticClass:
                                                                              "switch-b",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                                staticStyle:
                                                                                  {
                                                                                    "padding-bottom":
                                                                                      "0",
                                                                                    "padding-top":
                                                                                      "8px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h4",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-h6c",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Core " +
                                                                                        _vm._s(
                                                                                          index +
                                                                                            1
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          "",
                                                                                        value:
                                                                                          board.id,
                                                                                        name: "powerBoardID",
                                                                                        label:
                                                                                          "Output ID",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "id",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outpulPhase,
                                                                                        name: "powerBoardVoltage",
                                                                                        label:
                                                                                          "Phase",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outpulPhase",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outputVoltage,
                                                                                        name: "powerBoardVoltage",
                                                                                        label:
                                                                                          "Voltage",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outputVoltage",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#fdf7e7",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: false,
                                                                                        value:
                                                                                          board.outputFrequency,
                                                                                        name: "powerBoardFrequency",
                                                                                        label:
                                                                                          "Frequency",
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "outputFrequency",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        padding:
                                                                                          "15px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-spacer"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "25px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                            icon: "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.updateBoard(
                                                                                                board
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "green--text cursor-pointer",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-check"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                    "data-id":
                                                                                      index,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h3",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "10px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Connect With"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        padding:
                                                                                          "0",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "12",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex",
                                                                                        staticStyle:
                                                                                          {
                                                                                            border:
                                                                                              "solid black 1px",
                                                                                            "border-radius":
                                                                                              "10px",
                                                                                            padding:
                                                                                              "5px",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.locationPathTo,
                                                                                        function (
                                                                                          path,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            {
                                                                                              key: path.id,
                                                                                              staticClass:
                                                                                                "d-flex",
                                                                                            },
                                                                                            [
                                                                                              path.type ===
                                                                                              "site"
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/plant.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : path.type ===
                                                                                                    "location" &&
                                                                                                  index ===
                                                                                                    _vm
                                                                                                      .locationPathTo
                                                                                                      .length -
                                                                                                      1
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/equip.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : path.type ===
                                                                                                    "location" &&
                                                                                                  index <
                                                                                                    _vm
                                                                                                      .locationPathTo
                                                                                                      .length -
                                                                                                      1
                                                                                                ? _c(
                                                                                                    "v-img",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "area-search header-img-title",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          height:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "12",
                                                                                                          contain:
                                                                                                            "",
                                                                                                          src: "/images/area.png",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              path.type ===
                                                                                              "site"
                                                                                                ? _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "12px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            path.title
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "cursor-pointer",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "h4",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "header-title-text",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "font-size":
                                                                                                                "12px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                path.title
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                              index !==
                                                                                              _vm
                                                                                                .locationPathTo
                                                                                                .length -
                                                                                                1
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "header-title-text next-arrow",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          color:
                                                                                                            "black",
                                                                                                          padding:
                                                                                                            "0px 5px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " > "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "base-autocomplete",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        readonly: true,
                                                                                        dense:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                        items:
                                                                                          _vm.selectConnectDirection,
                                                                                        value:
                                                                                          board.connectDirection,
                                                                                        "item-text":
                                                                                          "value",
                                                                                        "item-value":
                                                                                          "id",
                                                                                        label:
                                                                                          "Direction",
                                                                                        "return-object": false,
                                                                                      },
                                                                                    on: {
                                                                                      "update:value":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            board,
                                                                                            "connectDirection",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "2" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-sheet",
                                                {
                                                  staticClass: "mx-auto",
                                                  attrs: { "max-width": "350" },
                                                },
                                                [
                                                  _c(
                                                    "v-slide-group",
                                                    {
                                                      attrs: {
                                                        "slider-color":
                                                          "#1b3054",
                                                        "show-arrows": "",
                                                        centered: "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "prev",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.prev()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-left"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "next",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.next()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-right"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1796914642
                                                      ),
                                                      model: {
                                                        value: _vm.tab,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.tab = $$v
                                                        },
                                                        expression: "tab",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.coreDetailsArrayList,
                                                      function (entry) {
                                                        return _c(
                                                          "v-slide-item",
                                                          {
                                                            key: entry.sequence,
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    active,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "spaced-slide-btn tab-cable-core",
                                                                          attrs:
                                                                            {
                                                                              "input-value":
                                                                                active,
                                                                              "active-class":
                                                                                "tab-cable-core-active",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.setActive(
                                                                                  entry.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                entry?.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.tab,
                                            callback: function ($$v) {
                                              _vm.tab = $$v
                                            },
                                            expression: "tab",
                                          },
                                        },
                                        _vm._l(
                                          _vm.coreDetailsArrayList,
                                          function (entry) {
                                            return _c(
                                              "v-tab-item",
                                              { key: entry.id },
                                              [
                                                _c(
                                                  "v-card",
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "connectivity-row",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "46px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connectivity Information "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "connection-col ccol1",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connection 1 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "solid black 1px",
                                                                            "border-radius":
                                                                              "10px",
                                                                            padding:
                                                                              "5px",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.locationPathTo,
                                                                        function (
                                                                          path,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: path.id,
                                                                              staticClass:
                                                                                "d-flex",
                                                                            },
                                                                            [
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/plant.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index ===
                                                                                    _vm
                                                                                      .locationPathTo
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "20",
                                                                                          width:
                                                                                            "22",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/equip.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index <
                                                                                    _vm
                                                                                      .locationPathTo
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/area.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "h4",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          path.title
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              index !==
                                                                              _vm
                                                                                .locationPathTo
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text next-arrow",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "black",
                                                                                          padding:
                                                                                            "0px 5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " > "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: true,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Entry Type",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "connection-col ccol2",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-left":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Connection 2 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "solid black 1px",
                                                                            "border-radius":
                                                                              "10px",
                                                                            padding:
                                                                              "5px",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.locationPathFrom,
                                                                        function (
                                                                          path,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: path.id,
                                                                              staticClass:
                                                                                "d-flex",
                                                                            },
                                                                            [
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/plant.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index ===
                                                                                    _vm
                                                                                      .locationPathFrom
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "20",
                                                                                          width:
                                                                                            "22",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/equip.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : path.type ===
                                                                                    "location" &&
                                                                                  index <
                                                                                    _vm
                                                                                      .locationPathFrom
                                                                                      .length -
                                                                                      1
                                                                                ? _c(
                                                                                    "v-img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "area-search header-img-title",
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            "18",
                                                                                          width:
                                                                                            "18",
                                                                                          contain:
                                                                                            "",
                                                                                          src: "/images/area.png",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              path.type ===
                                                                              "site"
                                                                                ? _c(
                                                                                    "h4",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          path.title
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h4",
                                                                                        {
                                                                                          staticClass:
                                                                                            "header-title-text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              path.title
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              index !==
                                                                              _vm
                                                                                .locationPathFrom
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-title-text next-arrow",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "black",
                                                                                          padding:
                                                                                            "0px 5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " > "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-autocomplete",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1",
                                                                        attrs: {
                                                                          readonly: true,
                                                                          name: "wiringCableEntryType",
                                                                          items:
                                                                            _vm
                                                                              .adminDataFieldsSrv
                                                                              .equipmentFieldOptions[
                                                                              _vm
                                                                                .equipmentFieldsList
                                                                                .equipmentWiringCableEntryType
                                                                            ],
                                                                          value:
                                                                            entry.cableType,
                                                                          "item-text":
                                                                            "value",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Core Entry Type",
                                                                          "return-object": false,
                                                                        },
                                                                        on: {
                                                                          "update:value":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                entry,
                                                                                "cableType",
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "connectivity-row",
                                                            staticStyle: {
                                                              "margin-top":
                                                                "36px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                        "line-height":
                                                                          "30px",
                                                                        color:
                                                                          "#6c707a",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Core Information "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      value:
                                                                        entry.id,
                                                                      disabled:
                                                                        "",
                                                                      label:
                                                                        "Core ID",
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "id",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      value:
                                                                        entry.name,
                                                                      label:
                                                                        "Core Name",
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "name",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "wiringCableEntryType",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableEntryType
                                                                        ],
                                                                      value:
                                                                        entry.cableType,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Core Entry Type",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableType",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Manufacturer",
                                                                      value:
                                                                        entry.manufacturer,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "manufacturer",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Model No",
                                                                      value:
                                                                        entry.modelNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "modelNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Serial No",
                                                                      value:
                                                                        entry.serialNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "serialNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Certification No",
                                                                      value:
                                                                        entry.certificationNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "certificationNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "wiringProtectionType",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableProtectionType
                                                                        ],
                                                                      value:
                                                                        entry.protectionType,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Protection Type",
                                                                      "return-object": false,
                                                                      "error-count":
                                                                        _vm.siteLocationsSrv.checkFieldError(
                                                                          "equipmentWiringCableProtectionType",
                                                                          entry.id
                                                                        )
                                                                          .length ??
                                                                        1,
                                                                      "error-messages":
                                                                        _vm.siteLocationsSrv.checkFieldError(
                                                                          "equipmentWiringCableProtectionType",
                                                                          entry.id
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "protectionType",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Model No",
                                                                      value:
                                                                        entry.cableModelNo,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableModelNo",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    staticClass:
                                                                      "flex-grow-1",
                                                                    attrs: {
                                                                      readonly: false,
                                                                      name: "equipmentWiringCableFunction",
                                                                      items:
                                                                        _vm
                                                                          .adminDataFieldsSrv
                                                                          .equipmentFieldOptions[
                                                                          _vm
                                                                            .equipmentFieldsList
                                                                            .equipmentWiringCableFunction
                                                                        ],
                                                                      value:
                                                                        entry.cableFunction,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Core Function",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableFunction",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#fdf7e7",
                                                                      },
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Manufacturer",
                                                                      value:
                                                                        entry.cableManufacturer,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "cableManufacturer",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#fdf7e7",
                                                                      },
                                                                    attrs: {
                                                                      readonly: false,
                                                                      label:
                                                                        "Core Entry Type",
                                                                      value:
                                                                        entry.coreInformation,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "coreInformation",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h3",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Connect With"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      value:
                                                                        entry.connectWith,
                                                                      "item-text":
                                                                        "title",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Select Equip / DP",
                                                                      "return-object": false,
                                                                      multiple: false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectWith",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      value:
                                                                        entry.connectWithMid,
                                                                      "item-text":
                                                                        "title",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Select Outputs / Entries",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectWithMid",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "base-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      readonly: true,
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      items: [
                                                                        "IN",
                                                                        "OUT",
                                                                        "N/A",
                                                                      ],
                                                                      value:
                                                                        entry.connectDirection,
                                                                      "item-text":
                                                                        "value",
                                                                      "item-value":
                                                                        "id",
                                                                      label:
                                                                        "Direction",
                                                                      "return-object": false,
                                                                    },
                                                                    on: {
                                                                      "update:value":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            entry,
                                                                            "connectDirection",
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "base-btn",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateCable(
                                                              entry
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Update")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }