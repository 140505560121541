var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "px-3" }, [
    _c("div", { staticClass: "mb-5" }, [
      _c("div", { staticClass: "d-flex mb-0" }, [
        _c("h4", [
          _vm._v(_vm._s(_vm.$t("SiteComponents.JobManagers.ActiveTitle"))),
        ]),
      ]),
      _c("div", { staticClass: "overflow-x-auto overflow-y-hidden" }, [
        _c("table", { staticClass: "foex-table text-center" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(_vm.$t("SiteComponents.JobManagers.TableHeaders.ID"))
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "SiteComponents.JobManagers.TableHeaders.FirstName"
                      )
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.LastName")
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.Email")
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.Actions")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.jobsSrv.jobJobManagers, function (ins) {
              return _c("tr", { key: ins.id }, [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "white-space": "nowrap",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c("div", { staticClass: "width-50px" }, [
                      _vm._v(" " + _vm._s(ins.id) + " "),
                    ]),
                  ]
                ),
                _c("td", [_vm._v(_vm._s(ins.firstName))]),
                _c("td", [_vm._v(_vm._s(ins.lastName))]),
                _c("td", [_vm._v(_vm._s(ins.email))]),
                _c(
                  "td",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", icon: "", color: "error" },
                        on: {
                          click: function ($event) {
                            return _vm.unlinkJobManager(
                              _vm.jobsSrv.selectedJob.id,
                              ins.id
                            )
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-link-off")])],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("hr"),
    _c("div", [
      _c("div", { staticClass: "d-flex" }, [
        _c("h4", [
          _vm._v(_vm._s(_vm.$t("SiteComponents.JobManagers.AvailableTitle"))),
        ]),
      ]),
      _c("div", { staticClass: "overflow-x-auto overflow-y-hidden" }, [
        _c("table", { staticClass: "foex-table text-center" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(_vm.$t("SiteComponents.JobManagers.TableHeaders.ID"))
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "SiteComponents.JobManagers.TableHeaders.FirstName"
                      )
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.LastName")
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", { staticClass: "width-200px" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.Email")
                    )
                  ),
                ]),
              ]),
              _c("th", [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("SiteComponents.JobManagers.TableHeaders.Actions")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.siteTeamMembersSrv.siteJobManagers, function (ins) {
                return [
                  !_vm.isJobManagerLinked(ins.id)
                    ? _c("tr", { key: ins.id }, [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "white-space": "nowrap",
                              overflow: "hidden",
                            },
                          },
                          [
                            _c("div", { staticClass: "width-50px" }, [
                              _vm._v(" " + _vm._s(ins.id) + " "),
                            ]),
                          ]
                        ),
                        _c("td", [_vm._v(_vm._s(ins.firstName))]),
                        _c("td", [_vm._v(_vm._s(ins.lastName))]),
                        _c("td", [_vm._v(_vm._s(ins.email))]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-1",
                                attrs: { small: "", icon: "", color: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJobManager(
                                      _vm.jobsSrv.selectedJob.id,
                                      ins.id
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-link-plus")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }