var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.ConfirmSrv.ConfirmMode,
        "max-width": _vm.width,
        persistent: "",
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onEnter.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "border-a-2 border-primary round-10" },
        [
          _c("div", { staticClass: "pa-3 text-center" }, [
            _c("span", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.ConfirmSrv.Title)),
            ]),
          ]),
          _c("v-card-text", {
            staticClass: "text-center pb-3",
            domProps: { innerHTML: _vm._s(_vm.ConfirmSrv.Detail) },
          }),
          _c(
            "v-card-actions",
            { staticClass: "pt-0 pb-4 d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  ref: "confirmButton",
                  attrs: { color: "primary", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.ConfirmSrv.close(true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.ConfirmSrv.Options.ConfirmText) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "accent1" },
                  on: {
                    click: function ($event) {
                      return _vm.ConfirmSrv.close(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.ConfirmSrv.Options.CancelText))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }