import { render, staticRenderFns } from "./job-report-layout.component.vue?vue&type=template&id=5215a83e"
import script from "./job-report-layout.component.ts?vue&type=script&lang=ts&external"
export * from "./job-report-layout.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/foex-3r/foex-3r-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5215a83e')) {
      api.createRecord('5215a83e', component.options)
    } else {
      api.reload('5215a83e', component.options)
    }
    module.hot.accept("./job-report-layout.component.vue?vue&type=template&id=5215a83e", function () {
      api.rerender('5215a83e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/job-report/layout/job-report-layout.component.vue"
export default component.exports