var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    attrs: { rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "v-radio-group",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      "error-messages": errors,
                      "input-value": _vm.value,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("update:value", $event)
                      },
                    },
                  },
                  "v-radio-group",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              ),
              _vm._l(_vm.items, function (item, index) {
                return _c("v-radio", {
                  key: index,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }