export interface PaginationViewModel<T> {
    CurrentPage?: number;
    PageLimit?: number;
    Query?: string;
    SortBy?: keyof T;
    SortOrder?: string;
}

export interface PaginationResponseModel<T> {
    Data: Array<T>;
    Count: number;
}
