var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { "justify-lg": "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { "align-content": "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c("v-img", {
                    attrs: { src: require("../../../assets/logo.svg") },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pl-11",
                  attrs: { "offset-lg": "3", cols: "4" },
                },
                [
                  _c("v-btn", { attrs: { value: "left", color: "#1b3054" } }, [
                    _c("span", [_vm._v("Plant Setup")]),
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { value: "center", color: "#1b3054" } },
                    [_c("span", [_vm._v("Inspection & Maintenance")])]
                  ),
                  _c("v-btn", { attrs: { value: "right", color: "#1b3054" } }, [
                    _c("span", [_vm._v("Analysis & Statistics")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }