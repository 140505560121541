import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobSubmitedWorkPermitsApi} from '@/sdk/api-services';
import {Component, Prop} from 'vue-property-decorator';
import {WorkPermitsService} from '../../../shared/services';

@Component
export default class SubmitJobWorkPermitDialogComponent extends VueWrapper {
    public readonly name: string = 'submit-job-work-permit-dialog';
    public file: File | null = null;

    public jobSubmittedWorkPermitsApi: JobSubmitedWorkPermitsApi = new JobSubmitedWorkPermitsApi();

    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();

    @Prop({
        type: String,
        required: true,
    })
    public readonly jobId!: string;

    public filesSelected(files: FileList) {
        if (files.length > 0) {
            this.file = files.item(0);
        }
    }

    public submitWorkPermit() {
        if (this.file && this.workPermitsSrv.selectedWorkPermitType) {
            this.LoaderSrv.showFullScreenLoader('Uploading file and submitting work permit.');
            this.jobSubmittedWorkPermitsApi
                .submitWorkPermit(this.jobId, this.workPermitsSrv.selectedWorkPermitType.key, this.file)
                .then(() => {
                    this.AlertSrv.show('success', this.$t('Dialogs.SubmitWorkPermit.Submited') as string);
                    this.close();

                    this.$emit('workPermitSubmitted');
                })
                .catch(() => {
                    this.AlertSrv.show('error', this.$t('Dialogs.SubmitWorkPermit.Error') as string);
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public open() {
        this.file = null;
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.file = null;
        this.CoreSrv.CloseModal(this.name);
    }
}
