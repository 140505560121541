import AdminAccountSetupLayoutComponent from '@/components/layouts/admin-account-setup-layout/admin-account-setup-layout.component';
import AdminDatabaseSetupLayoutComponent from '@/components/layouts/admin-database-setup-layout/admin-database-setup-layout.component';
import AdminLayoutComponent from '@/components/layouts/admin-layout/admin-layout.component';
import {WORK_PERMITS_SCOPES} from '@/sdk';
import {RouteConfig} from 'vue-router';
import UserSetupComponent from '../user-setup/user-setup.component';
import AccountSetupComponent from './account-setup/account-setup.component';
import DustDbComponent from './dust-db/dust-db.component';
import FieldsDbComponent from './fields-db/fields-db.component';
import GasDbComponent from './gas-db/gas-db.component';

export const AdminPanelRoutes: Array<RouteConfig> = [
    {
        path: '/admin-panel',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                name: 'admin-panel',
                redirect: {name: 'fields-db'},
            },
            {
                path: 'database-setup',
                name: 'database-setup',
                component: AdminDatabaseSetupLayoutComponent,
                children: [
                    {
                        path: 'fields-db',
                        name: 'fields-db',
                        component: FieldsDbComponent,
                    },
                    {
                        path: 'gas-db',
                        name: 'gas-db',
                        component: GasDbComponent,
                    },
                    {
                        path: 'dust-db',
                        name: 'dust-db',
                        component: DustDbComponent,
                    },
                    {
                        path: 'inspection-forms-db',
                        name: 'inspection-forms-db',
                        component: () => import('./inspection-forms-db/inspection-forms-db.component.vue'),
                        children: [
                            {
                                path: 'inspection-form-setup/:id',
                                name: 'inspection-form-setup',
                                component: () => import('./inspection-forms-db/inspection-form-setup/inspection-form-setup.component.vue'),
                            },
                        ],
                    },
                    {
                        path: 'global-work-permits',
                        name: 'global-work-permits',
                        component: () => import('./work-permits-db/work-permits-db.component.vue'),
                        props: route => ({scope: WORK_PERMITS_SCOPES.GLOBAL}),
                    },
                ],
            },
            {
                path: 'account-setup',
                component: AdminAccountSetupLayoutComponent,
                children: [
                    {
                        path: '',
                        name: 'account-setup',
                        redirect: {name: 'account-list'},
                    },
                    {
                        path: 'account-list',
                        name: 'account-list',
                        component: AccountSetupComponent,
                    },
                    {
                        path: ':accountId/users',
                        name: 'account-users',
                        component: UserSetupComponent,
                    },
                    {
                        path: 'work-permits/:accountId',
                        name: 'account-work-permits',
                        component: () => import('./work-permits-db/work-permits-db.component.vue'),
                        props: route => ({scope: WORK_PERMITS_SCOPES.ACCOUNT}),
                    },
                ],
            },
        ],
    },
];
