export class DustDbModel {
    id?: string | null = null;
    no: string | null = null;
    name: string | null = null;
    dustGroup: string | null = null;
    minimumIgnitionTempDustCloud: string | null = null;
    minimumIgnitionDepthDustLayer: string | null = null;
    minimumIgnitionTempDustLayer: string | null = null;

    constructor(data?: Partial<DustDbModel>) {
        Object.assign(this, data);
    }
}
