import {AnyObject} from '@/globals';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import {Observable, Subscriber, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SdkConfig} from '../sdk.config';

import {UserSession} from '../core';
import {FilterService} from '../services/shared/filter.service';

import {AlertService, ApiResponseModel} from '..';

import router from '@/router';

export abstract class BaseApi {
    protected ApiUrl = SdkConfig.ApiPath;
    protected FilterSrv = new FilterService();

    private cancelToken: CancelTokenSource | null = null;

    constructor() {
        this.cancelToken = axios.CancelToken.source();
    }

    private requestAsync<T>(method: AxiosRequestConfig['method'], url: string, postBody: AnyObject = {}): Promise<void | AxiosResponse> {
        const headers: AxiosRequestConfig['headers'] = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: this.authorize(),
        };
        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data: postBody,
        };
        if (this.cancelToken) {
            options.cancelToken = this.cancelToken.token;
        }

        return axios<T>(options).catch((err: AxiosError) => {
            console.log(err);
            return Promise.reject(err.response?.data);
        });
    }

    private request(method: AxiosRequestConfig['method'], url: string, postBody: AnyObject = {}): Observable<AxiosResponse> {
        const headers: AxiosRequestConfig['headers'] = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: this.authorize(),
        };
        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data: postBody,
        };
        if (this.cancelToken) {
            options.cancelToken = this.cancelToken.token;
        }

        return new Observable((observer: Subscriber<any>) => {
            axios(options)
                .then(res => {
                    observer.next(res);
                    observer.complete();
                })
                .catch(err => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            map(res => res),
            catchError(e => this.handleError(e))
        );
    }

    protected fileRequest(method: AxiosRequestConfig['method'], url: string, data: AnyObject = {}): Observable<AxiosResponse> {
        // Headers to be sent
        const headers: AxiosRequestConfig['headers'] = {
            'Content-Type': 'multipart/form-data;charset=utf-8',
            Authorization: this.authorize(),
        };
        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data,
        };

        return new Observable((observer: Subscriber<any>) => {
            axios(options)
                .then(res => {
                    observer.next(res);
                    observer.complete();
                })
                .catch(err => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            map(res => res),
            catchError(e => {
                return this.handleError(e);
            })
        );
        // return from(axios(options)).pipe(
        //     delay(0),
        //     map(Res => Res),
        //     catchError(e => this.handleError(e))
        // );
    }

    protected fileRequestAsync<T>(method: AxiosRequestConfig['method'], url: string, data: AnyObject = {}) {
        // Headers to be sent
        const headers: AxiosRequestConfig['headers'] = {
            'Content-Type': 'multipart/form-data;charset=utf-8',
            Authorization: this.authorize(),
        };
        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data,
        };

        return axios<T>(options).catch(err => {
            console.log(err);
            return Promise.reject(err);
        });
        // return from(axios(options)).pipe(
        //     delay(0),
        //     map(Res => Res),
        //     catchError(e => this.handleError(e))
        // );
    }

    public downloadRequest<T>(Url: string) {
        const Options: AxiosRequestConfig = {
            method: 'GET',
            url: Url,
            responseType: 'blob',
        };
        return axios(Options)
            .then(response => response)
            .catch(err => {
                return Promise.reject(err);
            });
    }

    public downnloadFileRequest<T>(Url: string, filename: string = 'file') {
        const Options: AxiosRequestConfig = {
            method: 'GET',
            url: Url,
            responseType: 'blob',
        };
        return axios(Options)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="?(.+)"?/);
                    if (match && match[1]) {
                        filename = match[1];
                    }
                }
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    private authorize() {
        const SessionValue = new UserSession().Session;

        return `Bearer ${SessionValue ? SessionValue.token : ''}`;
    }

    private handleError(error: AxiosError) {
        if (error.response?.status == 451) {
            new UserSession().clear()!;
            new AlertService().show('error', "We can't proceed with your request. You have signed in with another device.").then(() => {
                if (router.currentRoute.name !== 'sites-list') {
                    router.push({name: 'sites-list'});
                }
            });
        }
        if (error.response?.status == 401) {
            // this.cancelToken?.cancel();
            // new AlertService().show('info', 'You session is expired. Please login again to continue.').then(() => {});
            if (router.currentRoute.name !== 'signin') {
                router.push({name: 'signin', query: {redirect_reason: 'UNAUTHORIZIED'}});
            }
        }

        return throwError(error.response?.data ?? error.response ?? 'Internal Server Error');
    }

    // Requests
    protected GET_Request<T>(url: string, filter: AnyObject = {}): Observable<ApiResponseModel<T>> {
        return this.request('GET', url).pipe(map(res => res.data));
    }
    protected POST_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('POST', url, postBody).pipe(map(res => new ApiResponseModel<T>(res.data)));
    }
    // protected Login_POST_Request<T>(url: string, postBody: AnyObject): Observable<LoginResponseModel<T>> {
    //     return this.request('POST', url, postBody).pipe(map(res => new LoginResponseModel<T>(res.data)));
    // }
    protected PATCH_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('PATCH', url, postBody).pipe(map(res => res.data));
    }
    protected PUT_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('PUT', url, postBody).pipe(map(res => res.data));
    }
    protected DELETE_Request<T>(url: string): Observable<ApiResponseModel<T>> {
        return this.request('DELETE', url).pipe(map(res => res.data));
    }
    protected POST_FileRequest<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.fileRequest('POST', url, postBody).pipe(map(res => res.data));
    }

    // Async Requests
    protected GET_RequestAsync<T>(url: string): Promise<T> {
        return this.requestAsync('GET', url).then(res => res?.data);
    }
    protected POST_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync('POST', url, postBody).then(res => res?.data);
    }
    protected PATCH_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync('PATCH', url, postBody).then(res => res?.data);
    }
    protected PUT_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync('PUT', url, postBody).then(res => res?.data);
    }
    protected DELETE_RequestAsync<T>(url: string): Promise<T> {
        return this.requestAsync('DELETE', url).then(res => res?.data);
    }
    protected POST_FileRequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.fileRequestAsync<T>('POST', url, postBody).then(res => res?.data);
    }
}
