import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component} from 'vue-property-decorator';
import {SiteTeamMembersService} from '../../../shared/services';
import {JobService} from '../../../shared/services/job.service';

@Component
export default class JobLinkWorkerComponent extends VueWrapper {
    public jobsSrv: JobService = new JobService();
    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();

    public jobsApi: JobApi = new JobApi();

    public isInspectorLinked(inspectorId: string) {
        return !!this.jobsSrv.jobWorkers.find(x => x.id === inspectorId);
    }

    public unlinkInspector(jobId: string, inspectorId: string) {
        this.ConfirmSrv.open(
            this.$t('SiteComponents.Workers.UnlinkConfirmTitle') as string,
            this.$t('SiteComponents.Workers.UnlinkConfirmMessage') as string
        ).then(yes => {
            if (yes) {
                this.jobsApi.unlinkJobWorkers(jobId, inspectorId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', this.$t('SiteComponents.Workers.UnlinkSuccess') as string);
                            this.jobsSrv.getJobWorkers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || (this.$t('SiteComponents.Workers.UnlinkError') as string));
                    }
                );
            }
        });
    }

    public linkInspector(jobId: string, inspectorId: string) {
        this.ConfirmSrv.open(this.$t('SiteComponents.Workers.LinkConfirmTitle') as string, this.$t('SiteComponents.Workers.LinkConfirmMessage') as string).then(
            yes => {
                if (yes) {
                    this.jobsApi.linkJobWorker(jobId, inspectorId).subscribe(
                        res => {
                            if (res.Status) {
                                this.AlertSrv.show('success', this.$t('SiteComponents.Workers.LinkSuccess') as string);
                                this.jobsSrv.getJobWorkers(jobId);
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', err.message || (this.$t('SiteComponents.Workers.LinkError') as string));
                        }
                    );
                }
            }
        );
    }
}
