export class EquipmentDetailModel {
    id?: string | null = null;
    client?: string | null = null;
    projectName?: string | null = null;
    tag?: string | null = null;
    // type?: string | null = null;
    electricalCircuitConnection?: string | null = null;
    electricalAndProcessDrawing?: string | null = null;
    equipmentClassification?: string | null = null;
    fieldEquipmentAssociatedApparatus?: string | null = null;
    ipx?: string | null = null;
    ipy?: Array<string> = [];
    equipmentType?: string | null = null;
    manufacturer?: string | null = null;
    supplier?: string | null = null;
    modelNo?: string | null = null;
    serialNo?: string | null = null;
    zone?: string | null = null;
    flammableSubstances?: string | null = null;
    explosionConsequences?: string | null = null;
    gasDustGroup?: string | null = null;
    dustIgnitionTempRequired?: string | null = null;
    temperatureClass?: string | null = null;
    environmentalCondition?: string | null = null;
    exMarketing?: string | null = null;
    iecex?: string | null = null;
    xur?: string | null = null;
    inspectionFrequency?: string | null = null;
    certificationNumber?: string | null = null;
    certificationScheme?: Array<string> = [];
    protectionTechnique?: Array<string> = [];
    protectionTechniqueSub?: Array<string> = [];
    eplGasAndDust?: string | null = null;
    eplGasAndDustSub?: string | null = null;
    epl?: string | null = null;
    classificationGasDustGroup?: string | null = null;
    classificationTemperatureClass?: string | null = null;
    maxSurfaceTempWithDustLayerMM?: string | null = null;
    maxSurfaceTempWithDustCloud?: string | null = null;
    ambientTemp?: string | null = null;
    equipmentAmbientTemp?: string | null = null;
    approvalType?: string | null = null;

    public wiringProtectionTechnique: Array<string> = [];

    powerCableType?: string | null = null;
    powerCableModel?: string | null = null;
    powerCableManufacturer?: string | null = null;
    powerCableOther?: string | null = null;
    powerCableElecFrom?: string | null = null;
    signalCableType?: string | null = null;
    signalCableModel?: string | null = null;
    signalCableManufacturer?: string | null = null;
    signalCableOther?: string | null = null;
    signalCableFrom?: string | null = null;
    signalCableTo?: string | null = null;

    note?: string | null = null;

    created?: Date | null = null;
    updated?: Date | null = null;

    locationId?: string | null = null;

    fieldManufacturer?: string | null = null;
    cableManfacturer?: string | null = null;
    apparatusManufaturer?: string | null = null;
    fieldModelNo?: string | null = null;
    cableModelNo?: string | null = null;
    apparatusModelNo?: string | null = null;
    fieldSerialNo?: string | null = null;
    cableSerialNo?: string | null = null;
    apparatusSerialNo?: string | null = null;
    fieldCertNo?: string | null = null;
    cableCertNo?: string | null = null;
    apparatusCertNo?: string | null = null;
    fieldUiV?: string | null = null;
    apparatusUiV?: string | null = null;
    fieldLiMa?: string | null = null;
    apparatusLiMa?: string | null = null;
    fieldPiW?: string | null = null;
    apparatusPiW?: string | null = null;
    fieldCiuF?: string | null = null;
    cableCMuF?: string | null = null;
    cableLengthM1?: string | null = null;
    cableCCuF?: string | null = null;
    apparatusCiuF?: string | null = null;
    fieldLiMh?: string | null = null;
    cableLmMh?: string | null = null;
    cableLengthM2?: string | null = null;
    cableCLMh?: string | null = null;
    apparatusLiMh?: string | null = null;
    cableLrcMhOHM?: string | null = null;
    cableLRoMhOHM?: string | null = null;
    continuityOHM?: string | null = null;
    equipmentScreenOHM?: string | null = null;
    continuityOHM2?: string | null = null;
    multiCoreScreenOHM?: string | null = null;
    equipmentScreenOHM2?: string | null = null;

    mOperatedConvertor?: string | null = null;
    mSpeedRangeLimitLow?: string | null = null;
    mSpeedRangeLimitHigh?: string | null = null;
    mFrequencyRangeLimitLow?: string | null = null;
    mFrequencyRangeLimitHigh?: string | null = null;
    mSwitchingFrequency?: string | null = null;
    mSwitchingFrequencyUnit?: string | null = null;
    mTypeOfTorque?: string | null = null;
    mSpecificConverter?: string | null = null;
    mTypeOfConverter?: string | null = null;
    mExEbMotor?: string | null = null;
    mIaIn?: string | null = null;
    mTeS?: string | null = null;

    exPminPurgeFlow?: string | null = null;
    exPminPurgeDurartion?: string | null = null;
    exPtypeOfProtectiveGas?: string | null = null;
    exPminOverpressure?: string | null = null;
    exPmaxOverpressure?: string | null = null;
    exPminSupplyPressure?: string | null = null;
    exPmaxSupplyPressure?: string | null = null;
    exPmaxLeakageRate?: string | null = null;
    exPrangeOfTemprature?: string | null = null;
    exPpointsOfPressure?: string | null = null;
    exPinternalSourceOfRelease?: string | null = null;
    exPmaxInletPressure?: string | null = null;
    exPmaxFlowRate?: string | null = null;

    exEshortCircuitCurrent?: string | null = null;

    exEGmaxRatedPower?: string | null = null;
    exEGarrangedMethod?: string | null = null;
    exEGterminalSize?: string | null = null;
    exEGpermissibleConductorNumber?: string | null = null;
    exEGpermissibleConductorSize?: string | null = null;
    exEGmaxCurrent?: string | null = null;

    rowRadioSingleToComnine?: string | null = null;

    lowAmbientTemperature?: string | null = null;
    highAmbientTemperature?: string | null = null;
    lowServiceTemperature?: string | null = null;
    highServiceTemperature?: string | null = null;
    dustLayerIgnition?: string | null = null;
    dustLayerDepth?: string | null = null;
    dustTemperatureClass?: string | null = null;
    dustGroupf?: string | null = null;
    gasTemperatureClass?: string | null = null;
    gasGroupf?: string | null = null;
    iPYrequirement?: string | null = null;
    iPXrequirement?: string | null = null;

    fieldEquipApparatus?: string | null = null;
    divisionForRiskLevel?: string | null = null;
    installedDate?: string | null = null;
    powerType?: string | null = null;
    ACDC?: string | null = null;
    divisionRiskLevel?: string | null = null;

    warningMarkingInformation?: string | null = null;
    exComponentInformationf?: string | null = null;
    powerACDC?: string | null = null;
    phasesf?: string | null = null;
    converterNamef?: string | null = null;
    voltageVf?: string | null = null;
    frequencyHzf?: string | null = null;
    minVoltageVf?: string | null = null;
    minFrequencyHzf?: string | null = null;
    maxVoltageVf?: string | null = null;
    maxFrequencyHzf?: string | null = null;
    suffixf?: string | null = null;
    ePLGas?: string | null = null;
    ePLDust?: string | null = null;
    maxSurfaceTempf?: string | null = null;
    testDustLayerf?: string | null = null;
    maxSurfaceTempWithDustLayerCf?: string | null = null;
    specialConditionsUsef?: string | null = null;

    constructor(data?: Partial<EquipmentDetailModel>) {
        Object.assign(this, data);
    }
}
