var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "pt-0 pb-2" }, [
                _c("h4", { staticClass: "text-h6 mt-2" }, [
                  _vm._v("QR Code Tags"),
                ]),
                _c(
                  "span",
                  { staticClass: "ml-auto" },
                  [
                    !_vm.addQrCode
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: { color: "admin-primary " },
                            on: {
                              click: function ($event) {
                                _vm.addQrCode = true
                              },
                            },
                          },
                          [
                            _vm._v(" Add QR Code Tag "),
                            _c("v-icon", { staticClass: "ml-1" }, [
                              _vm._v("mdi-plus-circle-outline"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-card-text", [
                _vm.addQrCode
                  ? _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              type: "info",
                              outlined: "",
                              icon: "mdi-information",
                              dense: "",
                            },
                          },
                          [
                            _c("h4", { staticClass: "text-h6" }, [
                              _vm._v("Important Info"),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Creating a new QR Code Tag will deactivate the previous one. Please create responsibly to avoid disabling the old physical tag. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  'You can generate a new QR Tag Id by clicking the "Generate" button.'
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("base-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    rules: "required",
                                    label: "QR Tag Name",
                                  },
                                  model: {
                                    value: _vm.qrCodeName,
                                    callback: function ($$v) {
                                      _vm.qrCodeName = $$v
                                    },
                                    expression: "qrCodeName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        disabled: "",
                                                        rules: "required",
                                                        label: "QR Tag ID",
                                                      },
                                                      model: {
                                                        value: _vm.qrCodeValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.qrCodeValue = $$v
                                                        },
                                                        expression:
                                                          "qrCodeValue",
                                                      },
                                                    },
                                                    "base-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2251753713
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        'Click the "' +
                                          _vm._s(
                                            _vm.qrCodeValue
                                              ? "Regenerate"
                                              : "Generate"
                                          ) +
                                          '" button to generate a new QR Tag ID'
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: { color: "admin-primary" },
                                    on: { click: _vm.autoGenerateQrCode },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("mdi-refresh")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.qrCodeValue
                                            ? "Regenerate"
                                            : "Generate"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text mr-1",
                                    attrs: {
                                      color: "admin-primary ",
                                      disabled:
                                        !_vm.qrCodeValue || !_vm.qrCodeName,
                                    },
                                    on: { click: _vm.createNewQrCode },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      outlined: "",
                                      color: "admin-primary",
                                    },
                                    on: { click: _vm.cancelQrCode },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _c("table", { staticClass: "foex-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [_vm._v("QR Tag Name")]),
                        _c("th", [_vm._v("QR Tag ID")]),
                        _c("th", [_vm._v("Status")]),
                        _c("th", [_vm._v("Last Updated")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.qrCodes, function (qrCode, index) {
                        return _c("tr", { key: qrCode.id ?? index }, [
                          _c(
                            "td",
                            {
                              class: {
                                "": qrCode.active,
                                "inactive-opacity": !qrCode.active,
                              },
                            },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": qrCode.active,
                                "inactive-opacity": !qrCode.active,
                              },
                            },
                            [_vm._v(" " + _vm._s(qrCode.name) + " ")]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": qrCode.active,
                                "inactive-opacity": !qrCode.active,
                              },
                            },
                            [_vm._v(_vm._s(qrCode.code))]
                          ),
                          _c(
                            "td",
                            [
                              qrCode.active
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "primary" } },
                                    [_vm._v(" Active ")]
                                  )
                                : _c("v-chip", { attrs: { small: "" } }, [
                                    _vm._v(" Inactive "),
                                  ]),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": qrCode.active,
                                "inactive-opacity": !qrCode.active,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      qrCode.updated ?? qrCode.created,
                                      "yyyy-MM-dd hh:mm:ss"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "base-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: { small: "", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewQrCode(qrCode)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1 cursor-pointer",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-eye")]
                                  ),
                                  _vm._v(" View "),
                                ],
                                1
                              ),
                              _c(
                                "base-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !qrCode.active,
                                      expression: "!qrCode.active",
                                    },
                                  ],
                                  staticClass: "shadown-none",
                                  attrs: {
                                    color: "success",
                                    small: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.activateQrCode(qrCode)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { size: "medium" },
                                    },
                                    [_vm._v(" mdi-check ")]
                                  ),
                                  _vm._v(" Activate "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "pt-0 pb-2" }, [
                _c("h4", { staticClass: "text-h6 mt-2" }, [
                  _vm._v("Barcode Tags"),
                ]),
                _c(
                  "span",
                  { staticClass: "ml-auto" },
                  [
                    !_vm.addBarCode
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: { color: "admin-primary" },
                            on: {
                              click: function ($event) {
                                _vm.addBarCode = true
                              },
                            },
                          },
                          [
                            _vm._v(" Add Barcode Tag "),
                            _c("v-icon", { staticClass: "ml-1" }, [
                              _vm._v("mdi-plus-circle-outline"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-card-text", [
                _vm.addBarCode
                  ? _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              type: "info",
                              outlined: "",
                              icon: "mdi-information",
                              dense: "",
                            },
                          },
                          [
                            _c("h4", { staticClass: "text-h6" }, [
                              _vm._v("Important Info"),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Creating a new Barcode Tag will deactivate the previous one. Please create responsibly to avoid disabling the old physical tag. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  'You can generate a new Barcode Tag ID by clicking the "Generate" button.'
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("base-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    rules: "required",
                                    label: "Barcode Tag Name",
                                  },
                                  model: {
                                    value: _vm.barcodeName,
                                    callback: function ($$v) {
                                      _vm.barcodeName = $$v
                                    },
                                    expression: "barcodeName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        disabled: "",
                                                        rules: "required",
                                                        label: "Barcode Tag ID",
                                                      },
                                                      model: {
                                                        value: _vm.barcodeValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.barcodeValue = $$v
                                                        },
                                                        expression:
                                                          "barcodeValue",
                                                      },
                                                    },
                                                    "base-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4257870780
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        'Click the "' +
                                          _vm._s(
                                            _vm.barcodeValue
                                              ? "Regenerate"
                                              : "Generate"
                                          ) +
                                          '" button to generate a new Barcode Tag ID'
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: { color: "admin-primary" },
                                    on: { click: _vm.autoGenerateBarCode },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("mdi-refresh")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.barcodeValue
                                            ? "Regenerate"
                                            : "Generate"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text mr-1",
                                    attrs: {
                                      color: "admin-primary",
                                      disabled:
                                        !_vm.barcodeValue || !_vm.barcodeName,
                                    },
                                    on: { click: _vm.createNewBarCode },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      outlined: "",
                                      color: "admin-primary",
                                    },
                                    on: { click: _vm.cancelBarCode },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _c("table", { staticClass: "foex-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [_vm._v("Bardcode Tag Name")]),
                        _c("th", [_vm._v("Barcode Tag ID")]),
                        _c("th", [_vm._v("Status")]),
                        _c("th", [_vm._v("Last Updated")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.barCodes, function (barCode, index) {
                        return _c("tr", { key: barCode.id ?? index }, [
                          _c(
                            "td",
                            {
                              class: {
                                "": barCode.active,
                                "inactive-opacity": !barCode.active,
                              },
                            },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": barCode.active,
                                "inactive-opacity": !barCode.active,
                              },
                            },
                            [_vm._v(" " + _vm._s(barCode.name) + " ")]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": barCode.active,
                                "inactive-opacity": !barCode.active,
                              },
                            },
                            [_vm._v(_vm._s(barCode.code))]
                          ),
                          _c(
                            "td",
                            [
                              barCode.active
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "primary" } },
                                    [_vm._v(" Active ")]
                                  )
                                : _c("v-chip", { attrs: { small: "" } }, [
                                    _vm._v(" Inactive "),
                                  ]),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": barCode.active,
                                "inactive-opacity": !barCode.active,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      barCode.updated ?? barCode.created,
                                      "yyyy-MM-dd hh:mm:ss"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "base-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: { small: "", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewBarCode(barCode)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1 cursor-pointer",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-eye")]
                                  ),
                                  _vm._v(" View "),
                                ],
                                1
                              ),
                              _c(
                                "base-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !barCode.active,
                                      expression: "!barCode.active",
                                    },
                                  ],
                                  staticClass: "shadown-none",
                                  attrs: {
                                    color: "success",
                                    small: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.activateBarCode(barCode)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { size: "medium" },
                                    },
                                    [_vm._v(" mdi-check ")]
                                  ),
                                  _vm._v(" Activate "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "pt-0 pb-2" }, [
                _c("h4", { staticClass: "text-h6 mt-2" }, [_vm._v("NFC Tags")]),
                _c(
                  "span",
                  { staticClass: "ml-auto" },
                  [
                    !_vm.addNfcTag
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: { color: "admin-primary" },
                            on: {
                              click: function ($event) {
                                _vm.addNfcTag = true
                              },
                            },
                          },
                          [
                            _vm._v(" Add NFC Tag "),
                            _c("v-icon", { staticClass: "ml-1" }, [
                              _vm._v("mdi-plus-circle-outline"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-card-text", [
                _vm.addNfcTag
                  ? _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              type: "info",
                              outlined: "",
                              icon: "mdi-information",
                              dense: "",
                            },
                          },
                          [
                            _c("h4", { staticClass: "text-h6" }, [
                              _vm._v("Important Info"),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Creating a new NFC Tag will deactivate the previous one. Please create responsibly to avoid disabling the old physical tag. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Write the exact value you see on the physical NFC Tag in the NFC Tag ID field."
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("base-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    rules: "required",
                                    label: "NFC Tag Name",
                                  },
                                  model: {
                                    value: _vm.nfcTagName,
                                    callback: function ($$v) {
                                      _vm.nfcTagName = $$v
                                    },
                                    expression: "nfcTagName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        rules: "required",
                                                        label: "NFC Tag ID",
                                                      },
                                                      model: {
                                                        value: _vm.nfcTagValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.nfcTagValue = $$v
                                                        },
                                                        expression:
                                                          "nfcTagValue",
                                                      },
                                                    },
                                                    "base-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2454569384
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Write the exact value you see on the physical NFC Tag"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text mr-1",
                                    attrs: {
                                      color: "admin-primary",
                                      disabled:
                                        !_vm.nfcTagValue || !_vm.nfcTagName,
                                    },
                                    on: { click: _vm.createNewNfcTag },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      outlined: "",
                                      color: "admin-primary",
                                    },
                                    on: { click: _vm.cancelNfcTag },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _c("table", { staticClass: "foex-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [_vm._v("NFC Tag Name")]),
                        _c("th", [_vm._v("NFC Tag ID")]),
                        _c("th", [_vm._v("Status")]),
                        _c("th", [_vm._v("Last Updated")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.nfcTags, function (nfcTag, index) {
                        return _c("tr", { key: nfcTag.id ?? index }, [
                          _c(
                            "td",
                            {
                              class: {
                                "": nfcTag.active,
                                "inactive-opacity": !nfcTag.active,
                              },
                            },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": nfcTag.active,
                                "inactive-opacity": !nfcTag.active,
                              },
                            },
                            [_vm._v(" " + _vm._s(nfcTag.name) + " ")]
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": nfcTag.active,
                                "inactive-opacity": !nfcTag.active,
                              },
                            },
                            [_vm._v(_vm._s(nfcTag.code))]
                          ),
                          _c(
                            "td",
                            [
                              nfcTag.active
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "primary" } },
                                    [_vm._v(" Active ")]
                                  )
                                : _c("v-chip", { attrs: { small: "" } }, [
                                    _vm._v(" Inactive "),
                                  ]),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                "": nfcTag.active,
                                "inactive-opacity": !nfcTag.active,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      nfcTag.updated ?? nfcTag.created,
                                      "yyyy-MM-dd hh:mm:ss"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "base-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !nfcTag.active,
                                      expression: "!nfcTag.active",
                                    },
                                  ],
                                  staticClass: "shadown-none",
                                  attrs: {
                                    color: "success",
                                    small: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.activateNfcTag(nfcTag)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { size: "medium" },
                                    },
                                    [_vm._v(" mdi-check ")]
                                  ),
                                  _vm._v(" Activate "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDetailsDialog
        ? _c("tag-dialog-component", {
            attrs: {
              show: _vm.showDetailsDialog,
              "selected-tag": _vm.selectedTag,
              type: _vm.selectedTagType,
            },
            on: {
              "update:show": function ($event) {
                _vm.showDetailsDialog = $event
              },
              closed: _vm.onDialogClosed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }