var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      attrs: { "max-width": "500px", name: _vm.name, title: "Add New File(s)" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({}) {
            return [
              _c(
                "base-btn",
                {
                  attrs: {
                    disabled: !_vm.locationDocumentationSrv.files.length,
                  },
                  on: { click: _vm.saveFile },
                },
                [_vm._v("Save")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-file-input", {
                    attrs: {
                      multiple: "",
                      label: "Select File(s)",
                      "hide-details": "",
                      outlined: "",
                      "truncate-length": "15",
                    },
                    model: {
                      value: _vm.locationDocumentationSrv.files,
                      callback: function ($$v) {
                        _vm.$set(_vm.locationDocumentationSrv, "files", $$v)
                      },
                      expression: "locationDocumentationSrv.files",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }