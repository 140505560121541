import VueWrapper from '@/components/core/Vue/vue.wrapper';
import ViewWrapperComponent from '@/components/shared/view-wrapper/view-wrapper.component';
import {TagModel} from '@/sdk';
import {TagApi} from '@/sdk/api-services';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component({
    components: {
        ViewWrapperComponent,
    },
})
export default class TagDialogComponent extends VueWrapper {
    public $refs!: {
        viewWrapper: ViewWrapperComponent;
    };

    @Prop({
        type: String,
        default: 'tag-dialog',
    })
    public readonly name!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readonly show!: boolean;

    @Prop({
        type: Object as () => TagModel | null,
        default: null,
    })
    public readonly selectedTag!: TagModel | null;

    @Prop({
        type: String,
        default: 'qr_code',
    })
    public readonly type!: string;

    public tagImageUrl: string | null = null;
    public isLoading: boolean = false;

    public tagApi: TagApi = new TagApi();

    @Watch('show')
    public onShowChange(newValue: boolean) {
        if (newValue) {
            this.open();
        } else {
            this.close();
        }
    }

    @Watch('selectedTag', {immediate: true})
    public onSelectedTagChange() {
        if (this.selectedTag) {
            this.loadTagImage();
        }
    }

    public mounted() {
        if (this.show) {
            this.open();
        }
    }

    public get isQrCode() {
        return this.type === 'qr_code';
    }

    public get dialogTitle() {
        const typeMap: {[key: string]: string} = {
            qr_code: 'QR Code',
            barcode: 'Bar Code',
            nfc_tag: 'NFC Tag',
        };
        return typeMap[this.type] || 'Tag Details';
    }

    private async loadTagImage() {
        if (this.isQrCode) {
            this.generateQrCode();
        } else {
            this.generateBarcode();
        }
    }

    private generateQrCode() {
        this.isLoading = true;
        this.tagApi
            .generateQRCode(this.selectedTag?.code || '', 200)
            .then(res => {
                this.tagImageUrl = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', `${err.error.message}`);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private generateBarcode() {
        this.isLoading = true;
        this.tagApi
            .generateBarcode(this.selectedTag?.code || '', 70)
            .then(res => {
                this.tagImageUrl = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', `${err.error.message}`);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public open() {
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }
        this.CoreSrv.OpenModal(this.name);
        this.$emit('opened');
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
        this.$emit('closed');
        this.$emit('update:show', false);
    }
}
