var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "white height-100x d-flex" },
    [
      _c(
        "div",
        {
          ref: "searchSectionElem",
          staticClass:
            "left-tree-section overflow-hidden flex-shrink-0 relative",
        },
        [
          _c(
            "div",
            {
              ref: "areaBrowserElem",
              staticClass: "overflow-hidden relative mb-1",
              staticStyle: { height: "100%", "min-height": "150px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "overflow-hidden height-100x left-tree-section-child",
                },
                [
                  _c("site-setup-area-browser-component", {
                    attrs: {
                      site: _vm.siteToEdit,
                      siteLocations: _vm.siteLocationsSrv.siteLocations,
                    },
                    on: { locationItemClick: _vm.locationItemClick },
                  }),
                ],
                1
              ),
              _c("div", {
                staticClass:
                  "resize-control-horizontal height-100x site-hozontal-resizer",
                on: { mousedown: _vm.onBrowserMouseDown },
              }),
            ]
          ),
          _c("div", {
            staticClass:
              "resize-control-vertical height-100x site-browser-resizer",
            on: { mousedown: _vm.onListMouseDown },
          }),
        ]
      ),
      _c(
        "div",
        {
          ref: "searchSectionParentElem",
          staticClass:
            "d-flex flex-column flex-grow-1 px-3 overflow-hidden relative",
          staticStyle: { "background-color": "#e9e9e9" },
        },
        [
          _c(
            "div",
            { staticClass: "d-block overflow-hidden px-2 flex-shrink-0" },
            [
              _c("SiteSetupSearchComponent", {
                attrs: {
                  siteId: _vm.siteId,
                  showSearchResults: _vm.showSearchResults,
                },
                on: {
                  locationItemClick: function ($event) {
                    return _vm.locationItemClick($event, true)
                  },
                  showSearchResultsEvent: function ($event) {
                    return _vm.showSearchResultsEvent($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex mt-1 mb-1 flex-shrink-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { centered: "" },
                  on: { change: _vm.onTabChange },
                  model: {
                    value: _vm.tabTop,
                    callback: function ($$v) {
                      _vm.tabTop = $$v
                    },
                    expression: "tabTop",
                  },
                },
                [
                  _c(
                    "v-tab",
                    { attrs: { "tab-value": _vm.TabEnum.CanvasView } },
                    [_vm._v("Canvas View")]
                  ),
                  _c(
                    "v-tab",
                    { attrs: { "tab-value": _vm.TabEnum.HierarchalView } },
                    [_vm._v("Hierarchal View")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showSearchResults,
                  expression: "!showSearchResults",
                },
              ],
              staticClass:
                "overflow-hidden relative flex-grow-1 d-flex flex-column justify-space-between pt-2",
              staticStyle: { "margin-top": "0px" },
            },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    absolute: "",
                    bottom: "",
                    right: "",
                    temporary: "",
                    permanent: _vm.drawer,
                    width: "80%",
                  },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column height-100x overflow-auto",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block" },
                        [
                          _vm.siteLocationsSrv.selectedLcoation
                            ? _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: "",
                                            fab: "",
                                            small: "",
                                            color: "red",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.drawer = false
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-close")])],
                                        1
                                      ),
                                      _c(
                                        "v-toolbar-title",
                                        { staticClass: "text-h6" },
                                        [_vm._v("Wiring Entry")]
                                      ),
                                      _c("v-spacer"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", {
                                            attrs: { cols: "12" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [_c("v-divider")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("PreviewDocumentDialogComponent", {
                    ref: "previewDialog",
                  }),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                { staticClass: "height-100x", attrs: { value: _vm.tabItem } },
                [
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "height-100x",
                      attrs: { value: _vm.TabEnum.CanvasTab },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column height-100x" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column flex-grow-1 height-100x",
                            },
                            [
                              _vm.siteLocationsSrv.site
                                ? [
                                    _c("DiagramViewCanvasComponent", {
                                      ref: "diagramViewCanvasComponentRef",
                                      attrs: {
                                        siteId: _vm.siteId,
                                        siteToEdit: _vm.siteLocationsSrv.site,
                                        showSearchResults:
                                          _vm.showSearchResults,
                                        diagramViewStyle: _vm.diagramViewStyle,
                                        diagramFullPageConfig:
                                          _vm.diagramFullPageConfig,
                                        selectedLocationId:
                                          _vm.siteLocationsSrv?.selectedLcoation
                                            ?.id,
                                        deletedLocationId:
                                          _vm.siteLocationsSrv
                                            ?.deletedLocationId,
                                      },
                                      on: {
                                        fullPageFormView: function ($event) {
                                          return _vm.fullPageFormView($event)
                                        },
                                        goToLocation: function ($event) {
                                          return _vm.goToLocation($event)
                                        },
                                        locationItemClick: function ($event) {
                                          return _vm.locationItemClick(
                                            $event,
                                            true
                                          )
                                        },
                                        showSearchResultsEvent: function (
                                          $event
                                        ) {
                                          return _vm.showSearchResultsEvent(
                                            $event
                                          )
                                        },
                                        onNodeDoubleClick:
                                          _vm.onNodeDoubleClick,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.siteLocationsSrv.selectedLcoation &&
                              _vm.siteLocationsSrv.selectedLcoation?.type ===
                                "equipment"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "diagramViewLayout flex-1",
                                        class: {
                                          "height-100x":
                                            _vm.diagramFullPageConfig,
                                          "height-50x":
                                            !_vm.diagramFullPageConfig,
                                        },
                                      },
                                      [
                                        _c("EquipmentDetailsFormComponent", {
                                          attrs: {
                                            locationPath: _vm.locationPath,
                                            readOnlyFlag: false,
                                            diagramViewStyle:
                                              _vm.diagramViewStyle,
                                            diagramFullPageConfig:
                                              _vm.diagramFullPageConfig,
                                          },
                                          on: {
                                            fullPageFormView: function (
                                              $event
                                            ) {
                                              return _vm.fullPageFormView(
                                                $event
                                              )
                                            },
                                            goToLocation: _vm.goToLocation,
                                            saveEquipmentDetail:
                                              _vm.saveEquipmentDetail,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.siteLocationsSrv.selectedLcoation &&
                              _vm.siteLocationsSrv.selectedLcoation?.type ===
                                "location"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "diagramViewLayout flex-1",
                                        class: {
                                          "height-100x":
                                            _vm.diagramFullPageConfig,
                                          "height-50x":
                                            !_vm.diagramFullPageConfig,
                                        },
                                      },
                                      [
                                        _c("SiteSetupLocationFormComponent", {
                                          attrs: {
                                            readOnlyFlag: false,
                                            locationPath: _vm.locationPath,
                                            diagramViewStyle:
                                              _vm.diagramViewStyle,
                                            diagramFullPageConfig:
                                              _vm.diagramFullPageConfig,
                                          },
                                          on: {
                                            fullPageFormView: function (
                                              $event
                                            ) {
                                              return _vm.fullPageFormView(
                                                $event
                                              )
                                            },
                                            goToLocation: _vm.goToLocation,
                                            saveLocationDetail:
                                              _vm.saveLocationDetail,
                                            saveDistributionPanel:
                                              _vm.saveDistributionPanel,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.siteLocationsSrv.selectedLcoation &&
                              _vm.siteLocationsSrv.selectedLcoation?.type ===
                                "distribution"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "diagramViewLayout flex-1",
                                        class: {
                                          "height-100x":
                                            _vm.diagramFullPageConfig,
                                          "height-50x":
                                            !_vm.diagramFullPageConfig,
                                        },
                                      },
                                      [
                                        _c("DistributionPanelFormComponent", {
                                          attrs: {
                                            readOnlyFlag: false,
                                            locationPath: _vm.locationPath,
                                            diagramViewStyle:
                                              _vm.diagramViewStyle,
                                            diagramFullPageConfig:
                                              _vm.diagramFullPageConfig,
                                          },
                                          on: {
                                            fullPageFormView: function (
                                              $event
                                            ) {
                                              return _vm.fullPageFormView(
                                                $event
                                              )
                                            },
                                            goToLocation: _vm.goToLocation,
                                            saveDistributionPanel:
                                              _vm.saveDistributionPanel,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("SaveLocationDialogComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }