import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SessionModel, UserSession, UserViewModel} from '@/sdk';
import {Component, Prop} from 'vue-property-decorator';
import {UserSetupService} from '../shared/services';
import InviteRegularUserDialogComponent from './invite-regular-user-dialog/invite-regular-user-dialog.component';
import SaveUserDialogComponent from './save-user-dialog/save-user-dialog.component';

@Component({
    components: {
        SaveUserDialogComponent,
        InviteRegularUserDialogComponent,
    },
})
export default class UserSetupComponent extends VueWrapper {
    public $refs!: {
        saveUserDialogRef: SaveUserDialogComponent;
        inviteRegularUserDialogRef: InviteRegularUserDialogComponent;
    };

    @Prop({
        type: String,
    })
    accountId!: string;

    accountIdC: string | null = null;

    public userSetupSrv: UserSetupService = new UserSetupService();

    public async editClick(user: UserViewModel) {
        this.userSetupSrv.reset();
        await this.userSetupSrv.getUserToEdit(user.id!);
        this.$refs.saveUserDialogRef?.open();
    }

    public userSession = new UserSession();
    public Session = new SessionModel();

    public addNewUserClick() {
        this.userSetupSrv.reset();
        this.$refs.saveUserDialogRef.open();
    }

    public mounted() {
        this.accountIdC = this.accountId;

        this.AddSubscription$ = this.userSession._session.subscribe(session => {
            this.Session = session ?? new SessionModel();

            if (!this.accountIdC || this.accountIdC !== this.Session.selectedAccountId) {
                this.accountIdC = this.Session.selectedAccountId!;
                this.userSetupSrv.getAccountUsers(this.accountIdC);
            }
        });

        if (!!this.accountIdC) this.userSetupSrv.getAccountUsers(this.accountIdC);
    }

    public assignSiteClick(user: UserViewModel) {
        this.$router.push({
            name: 'by-user-access-sites',
            params: {
                userId: user.id!,
            },
        });
    }

    public inviteRegularUser() {
        this.$refs.inviteRegularUserDialogRef.open();
    }

    public deleteClick(user: UserViewModel) {
        this.ConfirmSrv.open(
            'Delete Member',
            `If this member is linked to any job, all those linkings will be delete too. Are you sure you want to delete this member?`
        ).then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.userSetupSrv.usersApi
                    .deleteSiteMember(user.id!)
                    .subscribe(
                        res => {
                            this.AlertSrv.show('success', 'User has been deleted successfully!');
                            this.userSetupSrv.getAccountUsers(this.accountIdC!);
                        },
                        err => {
                            this.AlertSrv.show('error', err.message);
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }
}
