import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {LocationModel, SiteModel} from '@/sdk';
// import AreaListItemComponent from '@/views/shared/area-list-item/area-list-item.component';
// import DiagramListItemComponent from '@/views/shared/diagram-list-item/diagram-list-item.component';
// import EquipmentListItemComponent from '@/views/shared/equipment-list-item/equipment-list-item.component';
import {JobLocationService} from '@/views/shared/services';
import LocationListItemComponent from '@/views/shared/site-location/location-list-item/location-list-item.component';
// import PointListItemComponent from '@/views/shared/point-list-item/point-list-item.component';
// import SubAreaListItemComponent from '@/views/shared/sub-area-list-item/sub-area-list-item.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'JobSetupAreaBrowserComponent',
    components: {
        // AreaListItemComponent,
        // SubAreaListItemComponent,
        // PointListItemComponent,
        // EquipmentListItemComponent,
        // DiagramListItemComponent,
        LocationListItemComponent,
    },
})
export default class JobSetupAreaBrowserComponent extends VueWrapper {
    @Prop({
        type: Object,
    })
    public site!: SiteModel;

    @Prop({
        type: Array,
    })
    public siteLocations!: Array<LocationModel>;

    public jobLocationsSrv: JobLocationService = new JobLocationService();

    public mounted() {
        //
    }

    public allowDrop(ev: any) {
        ev.preventDefault();
        // console.log(ev.target);
        // this.enterItemId = ev?.target?.id;
        this.jobLocationsSrv.enterItemSiteId = ev.target.closest('.site-box')?.id;
    }

    public onDragEnter(event: any) {
        event.preventDefault();
        this.jobLocationsSrv.enterItemSiteId = event?.target?.id;
        console.log('enter: ', event?.target?.id);
    }

    public onItemDrop(event: any) {
        event.preventDefault();
        this.jobLocationsSrv.enterItemSiteId = null;

        // const droppedOverId = event.target.closest('.site-box')?.id;

        // if (this.jobLocationsSrv.selectedItemId) {
        //     this.jobLocationsSrv.setParentNull(this.jobLocationsSrv.selectedItemId);
        // }
    }
}
