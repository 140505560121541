var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "height-100x d-flex pt-2" }, [
    _c(
      "div",
      {
        ref: "areaBrowserElem",
        staticClass:
          "left-tree-section d-flex flex-column flex-shrink-0 px-3 overflow-hidden relative",
      },
      [
        _c("JobReportFilterComponent"),
        _c("div", {
          staticClass:
            "resize-control-vertical height-100x job-browser-resizer",
          on: { mousedown: _vm.onBrowserMouseDown },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "jobListParentElem",
        staticClass: "d-flex flex-column flex-grow-1 px-3 overflow-hidden",
      },
      [
        _c("div", { staticClass: "overflow-auto relative" }, [
          _c(
            "div",
            {
              ref: "jobListElem",
              staticClass: "overflow-hidden mb-1 relative pb-1",
              style: {
                height: _vm.parentHeight ? `${_vm.currentHeight}px` : "50%",
                "min-height": "150px",
              },
            },
            [
              _c("div", {
                staticClass:
                  "resize-control-horizontal height-100x job-hozontal-resizer",
                on: { mousedown: _vm.onListMouseDown },
              }),
              _c(
                "div",
                { staticClass: "d-flex height-100x overflow-hidden" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex height-100x overflow-hidden",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex height-100x overflow-hidden",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "base-card",
                            {
                              staticClass:
                                "overflow-hidden d-flex flex-column flex-1 pt-2 pb-0",
                              attrs: { outlined: "" },
                            },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "white height-100x width-100x overflow-auto py-1 pt-2 pb-0",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateAreaReport()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/area-lg.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [_vm._v("Area Report")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateEquipmentReport()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/equipment-lg.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [_vm._v("Equipment Report")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateInspectionReportExd()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/d-folder.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    "Inspection Report (Ex d)"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateInspectionReportExi()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/d-folder.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    "Inspection Report (Ex i)"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateInspectionReportExp()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/d-folder.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    "Inspection Report (Ex p)"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass:
                                            "p-relative mr-3 mb-3 text-decoration-none d-flex justify-center align-center cursor-pointer",
                                          attrs: {
                                            color: "grey lighten-3",
                                            elevation: "1",
                                            height: "150",
                                            width: "150",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateJobReport()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  height: "55",
                                                  width: "55",
                                                  src: "/images/common/d-folder.png",
                                                },
                                              }),
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [_vm._v("Jobs Report")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-block overflow-hidden",
              style: {
                height: _vm.parentHeight
                  ? `${_vm.parentHeight - _vm.currentHeight}px`
                  : "50%",
                "min-height": "150px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "overflow-hidden height-100x" },
                [_c("ReportListingComponent", { ref: "reportListingRef" })],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }