import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AccountsApi} from '@/sdk/api-services/accounts.api';
import {UserSession} from '@/sdk/core/user-session';
import {Component} from 'vue-property-decorator';

@Component
export default class AdminAccountSetupLayoutComponent extends VueWrapper {
    public accountTitle: string = '';

    private accountsApi: AccountsApi = new AccountsApi();
    private userSession: UserSession = new UserSession();

    public async mounted() {
        this.AddSubscription$ = this.UserSessionSrv._session.subscribe(session => {
            const accountId = session?.selectedAccountId;
            if (accountId) {
                this.accountsApi
                    .getAccountById(accountId)
                    .then(account => {
                        this.accountTitle = account.accountTitle ?? '';
                    })
                    .catch(error => {
                        // this.AlertSrv.show('error', error?.message ?? '');
                    });
            }
        });
    }
}
