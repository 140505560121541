var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobsSrv.selectedJob
    ? _c(
        "div",
        { staticClass: "px-3" },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "orderNumber",
                              label: "Order #",
                              disabled: "",
                              value: _vm.jobsSrv.selectedJob.sequence,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "sequence",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "jobId",
                              label: "Job ID",
                              disabled: "",
                              value: _vm.jobsSrv.selectedJob.id,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "id",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "equipmentId",
                              label: "Equipment ID",
                              disabled: "",
                              value:
                                _vm.jobsSrv.selectedJob.location.equipmentDetail
                                  .id,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob.location
                                    .equipmentDetail,
                                  "id",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "equipmentName",
                              label: "Equipment Name",
                              disabled: "",
                              value: _vm.jobsSrv.selectedJob.location.title,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob.location,
                                  "title",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "equipmentClass",
                              label: "Equipment Class",
                              disabled: "",
                              value:
                                _vm.jobsSrv.selectedJob.location.equipmentDetail
                                  .equipmentClassification,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob.location
                                    .equipmentDetail,
                                  "equipmentClassification",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "areaExInformation",
                              label: "Area Ex Information (Gas Area/Dust Area)",
                              disabled: "",
                              value: _vm.jobsSrv.selectedJob.areaExInformation,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "areaExInformation",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            staticStyle: { color: "#fdf7e7" },
                            attrs: {
                              name: "eqExCode",
                              label: "Eq Ex Code",
                              disabled: "",
                              value: _vm.jobsSrv.selectedJob.eqExCode,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "eqExCode",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-autocomplete", {
                            staticClass: "flex-grow-1",
                            attrs: {
                              disabled: "",
                              name: "JobType",
                              items: _vm.$formOptions.jobTypes,
                              value: _vm.jobsSrv.selectedJob.jobType,
                              "item-text": "value",
                              "item-value": "key",
                              label: "Job Type",
                              clearable: "",
                              multiple: false,
                              "return-object": false,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "jobType",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-divider", { staticClass: "mb-3" }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between mb-2",
                                },
                                [_c("h4", [_vm._v("Non Conformity Records")])]
                              ),
                              _c("div", { staticClass: "overflow-x-auto" }, [
                                _c("table", { staticClass: "foex-table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _c(
                                          "div",
                                          { staticClass: "width-100px" },
                                          [_vm._v("Type")]
                                        ),
                                      ]),
                                      _c("th", [
                                        _c(
                                          "div",
                                          { staticClass: "width-100px" },
                                          [_vm._v("Fault Code")]
                                        ),
                                      ]),
                                      _c("th", [
                                        _c(
                                          "div",
                                          { staticClass: "width-100x" },
                                          [_vm._v("Items")]
                                        ),
                                      ]),
                                      _c("th", [
                                        _c(
                                          "div",
                                          { staticClass: "width-100x" },
                                          [_vm._v("Results")]
                                        ),
                                      ]),
                                      _c("th", [
                                        _c(
                                          "div",
                                          { staticClass: "width-100x" },
                                          [_vm._v("Comment")]
                                        ),
                                      ]),
                                      _c("th", [
                                        _c("div", [_vm._v("Actions")]),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.jobsSrv.jobNonConformityRecords,
                                        function (ncr) {
                                          return [
                                            !ncr.editable
                                              ? _c("tr", { key: ncr.id }, [
                                                  _c("td", [
                                                    _vm._v(_vm._s(ncr.type)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(ncr.faultCode)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(ncr.item)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(ncr.result)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(ncr.comment)),
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "base-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewDocs(
                                                                  ncr
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-eye"),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                              _c("v-divider", { staticClass: "my-3" }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                        ? [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("base-autocomplete", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    disabled: "",
                                    name: "typeOfInspection",
                                    items: _vm.$formOptions.typesOfInspection,
                                    value:
                                      _vm.jobsSrv.selectedJob.typeOfInspection,
                                    "item-text": "value",
                                    "item-value": "key",
                                    label: "Type of Inspection",
                                    clearable: "",
                                    multiple: false,
                                    "return-object": false,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.jobsSrv.selectedJob,
                                        "typeOfInspection",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("base-autocomplete", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    disabled: "",
                                    name: "Grade of Inspection",
                                    items: _vm.$formOptions.gradeofInspection,
                                    value:
                                      _vm.jobsSrv.selectedJob.gradeOfInspection,
                                    "item-text": "value",
                                    "item-value": "key",
                                    label: "Grade of Inspection",
                                    clearable: "",
                                    multiple: true,
                                    "return-object": false,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.jobsSrv.selectedJob,
                                        "gradeOfInspection",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                        ? [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("base-text-field", {
                                  staticStyle: { color: "#fdf7e7" },
                                  attrs: {
                                    disabled: "",
                                    name: "causeOfFault",
                                    label: "Cause of Fault",
                                    value: _vm.jobsSrv.selectedJob.causeOfFault,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.jobsSrv.selectedJob,
                                        "causeOfFault",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("base-autocomplete", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    disabled: "",
                                    name: "actionType",
                                    items: _vm.$formOptions.actionTypes,
                                    value: _vm.jobsSrv.selectedJob.actionType,
                                    "item-text": "value",
                                    "item-value": "key",
                                    label: "Action Type",
                                    clearable: "",
                                    multiple: false,
                                    "return-object": false,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.jobsSrv.selectedJob,
                                        "actionType",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("base-textarea", {
                                  attrs: {
                                    disabled: "",
                                    label: "Action Plan Detail",
                                    name: "actionPlanDetail",
                                    value:
                                      _vm.jobsSrv.selectedJob.actionPlanDetail,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.jobsSrv.selectedJob,
                                        "actionPlanDetail",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-date-picker", {
                            attrs: {
                              disabled: "",
                              format: false,
                              label: "Start Date",
                              value: _vm.jobsSrv.selectedJob.startDate,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "startDate",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-date-picker", {
                            attrs: {
                              disabled: "",
                              format: false,
                              label: "End Date",
                              value: _vm.jobsSrv.selectedJob.endDate,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.jobsSrv.selectedJob,
                                  "endDate",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "my-3" })],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h2", [_vm._v("Corrective Action Completion Register")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-date-picker", {
                    attrs: {
                      format: false,
                      label: "Completion Date",
                      value: _vm.correctiveActionRegister.completionDate,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.correctiveActionRegister,
                          "completionDate",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-autocomplete", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      name: "personInChargeUserId",
                      items: _vm.jobsSrv.jobWorkers,
                      value: _vm.correctiveActionRegister.personInChargeUserId,
                      "item-text": (value) =>
                        value.firstName + " " + value.lastName,
                      "item-value": "id",
                      label: "Person Incharge",
                      clearable: "",
                      multiple: false,
                      "return-object": false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.correctiveActionRegister,
                          "personInChargeUserId",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.savedReportFileName
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "px-3" }, [
                      _c("span", { staticClass: "text-h6 mr-2" }, [
                        _vm._v("Saved Report:"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.savedReportFileName))]),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "px-2 py-3" }, [
                    _vm.reportFile
                      ? _c(
                          "div",
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(_vm.reportFile.name)),
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", color: "red" },
                                on: {
                                  click: function ($event) {
                                    _vm.reportFile = null
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-delete")])],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "py-1" }, [
                          _c("span", [_vm._v("No file selected")]),
                        ]),
                  ]),
                  _c("base-file-picker", {
                    attrs: {
                      text: "Upload Completion Report",
                      width: "260px",
                      multiple: false,
                      accept: "application/pdf",
                    },
                    on: {
                      filesSelected: function ($event) {
                        _vm.reportFile = $event && $event[0]
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.saveCorrectiveActionRegister },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "my-1" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-0" },
                    [
                      _c("h4", { staticClass: "text-h5 my-2" }, [
                        _vm._v("Corrective Action Results"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "span",
                        { staticClass: "ml-auto" },
                        [
                          !_vm.addResult
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text",
                                  attrs: { color: "admin-primary " },
                                  on: {
                                    click: function ($event) {
                                      _vm.addResult = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Add Result "),
                                  _c("v-icon", { staticClass: "ml-1" }, [
                                    _vm._v("mdi-plus-circle-outline"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm.addResult
                  ? _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("base-text-field", {
                                  attrs: {
                                    label: "Title",
                                    name: "title",
                                    value: _vm.title,
                                    clearable: "",
                                    rules: "required",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.title = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-file-input", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    multiple: false,
                                    placeholder: "Upload Result File",
                                    label: "Result File",
                                    "prepend-icon": "mdi-paperclip",
                                    accept: "application/pdf,image/*",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function ({ text }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  small: "",
                                                  label: "",
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(text) + " ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1464597600
                                  ),
                                  model: {
                                    value: _vm.file,
                                    callback: function ($$v) {
                                      _vm.file = $$v
                                    },
                                    expression: "file",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text mr-1",
                                    attrs: {
                                      color: "admin-primary ",
                                      disabled: !_vm.title || !_vm.file,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCorrectiveActionResult()
                                      },
                                    },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      outlined: "",
                                      color: "admin-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.addResult = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _c("table", { staticClass: "foex-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Title")]),
                        _c("th", [_vm._v("File Name")]),
                        _c("th", [_vm._v("File Type")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.correctiveActionResults, function (r) {
                        return _c("tr", { key: r.id }, [
                          _c("td", [_vm._v(_vm._s(r.title))]),
                          _c("td", [_vm._v(_vm._s(r.fileName))]),
                          _c("td", [_vm._v(_vm._s(r.fileType))]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCorrectiveActionResult(r)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-1" } },
                                    [_vm._v("mdi-delete-empty")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("JobNonConformityRecordFilesDialogComponent", {
            ref: "jobNonConformityRecordFilesDialog",
            attrs: {
              isReadonly: true,
              name: "job-non-conformity-record-readonly-files-dialog",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }