var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("table", { staticClass: "sites-table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.ProjectID"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.InspectionID"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.TypeOfInspection"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.GradeOfInspection"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.InspectorName"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.InspectorInfo"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.WorkPermit"
                            )
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "SiteComponents.InspectionHistory.TableHeader.Actions"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.team, function (member) {
                      return _c("tr", { key: member.id }, [
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Project ID"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Inspection ID"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Type of Inspection"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Grade of Inspection"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Inspector Name"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Inspector Info"),
                        ]),
                        _c("td", { staticClass: "width-100px" }, [
                          _vm._v("Work Permit"),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "width-200px text-center",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "white--text mr-1 mb-1",
                                    attrs: { color: "blue", "x-small": "" },
                                  },
                                  [_vm._v(" Edit ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      padding: "5px",
                                      "margin-left": "10px",
                                    },
                                    attrs: {
                                      color: "error",
                                      dark: "",
                                      "x-small": "",
                                    },
                                  },
                                  [_vm._v(" Delete ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }