var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: {
          fullscreen: "",
          "retain-focus": false,
          "hide-overlay": "",
          transition: "dialog-bottom-transition",
          persistent: "",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.Close.apply(null, arguments)
          },
        },
        model: {
          value: _vm.Show,
          callback: function ($$v) {
            _vm.Show = $$v
          },
          expression: "Show",
        },
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-card",
        { staticClass: "dialog-card" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary", flat: "", tile: "" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.Close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
              ]),
              _c("v-spacer"),
              _vm._t("toolbar"),
            ],
            2
          ),
          _c("div", { staticClass: "dialog-box-card" }, [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }