var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "base-pagination d-flex justify-start" },
    [
      _c(
        "v-pagination",
        _vm._b(
          {
            class: _vm.$attrs.classes,
            attrs: {
              value: _vm.filter.PageNo,
              length: _vm.filter.TotalPages,
              "total-visible": _vm.filter.TotalVisible,
            },
            on: { input: _vm.setCurrentPage },
          },
          "v-pagination",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }