var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "450px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.Title")) +
                        " "
                    ),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [_c("div")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.TableHeaders.Name")
                  )
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.TableHeaders.Value")
                  )
                ),
              ]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.CasNo"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.no))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.Name"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.name))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.NominalParticleSize")
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.nominalParticleSize))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.ElectricalResistivity")
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.electricalResistivity))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.CombustibleDust"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.combustibleDustFlying))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.DustGroup"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.dustGroup))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.MinimumIgnitionEnergy")
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.minimumIgnitionEnergy))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.LowerExplosionLimit")
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.lowerExplosionLimit))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Dialogs.PreviewDustDbRecord.MinimumIgnitionTemperatureOfDustCloud"
                    )
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.minimumIgnitionTempDustCloud))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Dialogs.PreviewDustDbRecord.MinimumIgnitionDepthOfDustLayer"
                    )
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.minimumIgnitionDepthDustLayer))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Dialogs.PreviewDustDbRecord.MinimumIgnitionTemperatureOfDustLayer"
                    )
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.minimumIgnitionTempDustLayer))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Dialogs.PreviewDustDbRecord.MaxExOverpressure")
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.maxExOverpressure))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.KstValue"))),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.kstValue))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.Explosibility"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.explosibility))]),
            ]),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("Dialogs.PreviewDustDbRecord.CombustibilityBZ"))
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.obj.combustibilityBZ))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }