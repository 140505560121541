var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    attrs: { rules: _vm.rules },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _c(
                "v-textarea",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "height-100x",
                      attrs: {
                        "error-messages": errors,
                        value: _vm.value,
                        outlined: _vm.outlined,
                        dense: "",
                        rows: "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("update:value", $event)
                        },
                        blur: function ($event) {
                          _vm.$emit(
                            "update:value",
                            !!_vm.value && _vm.$attrs["type"] !== "password"
                              ? _vm.value.trim()
                              : _vm.value
                          )
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          !!_vm.$attrs.label
                            ? {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(_vm.$attrs.label) + " "
                                    ),
                                    _vm.rules.includes("required")
                                      ? _c(
                                          "span",
                                          { staticClass: "error--text" },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    "v-textarea",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm._l(Object.keys(_vm.$slots), function (slot) {
                    return _vm._t(slot, null, { slot: slot })
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }