import {PaginationViewModel} from './../common';

export class ReportModel {
    id?: string | null = null;
    siteId: string | null = null;
    reportType: string | null = null;
    storageType: string | null = null;
    filePath: string | null = null;
    fileName: string | null = null;
    generatedOn: string | null = null;
    generatedByUserId?: string | null = null;

    constructor(data?: Partial<ReportModel>) {
        Object.assign(this, data);
    }
}

export interface ReportFilter extends PaginationViewModel<ReportModel> {}
