var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "admin-primary", clipped: "", dark: "" } },
        [
          _c(
            "v-icon",
            {
              attrs: { color: "white" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.CoreSrv.UserDrawer.Mini = !_vm.CoreSrv.UserDrawer.Mini
                },
              },
            },
            [_vm._v(" mdi-menu ")]
          ),
          _c("v-spacer"),
          !_vm.customer
            ? _c("div", { staticClass: "d-flex align-center" }, [
                _c("div", { staticClass: "mx-3" }, [
                  _c("h5", [
                    _vm._v(
                      _vm._s(_vm.Session.FirstName) +
                        " " +
                        _vm._s(_vm.Session.LastName)
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-evenly" },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          bottom: "",
                          "offset-y": "",
                          transition: "slide-y-reverse-transition",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "base-card",
                                    {
                                      staticClass:
                                        "white cursor-pointer d-flex flex-width ml-auto rounded-pill transparent",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "d-flex align-center pa-1",
                                            },
                                            "div",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "white accent-1",
                                              attrs: { size: "40" },
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: _vm.User.ImageUrl
                                                    ? _vm.User.ImageUrl
                                                    : _vm.CoreSrv.UserImage,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3839847215
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "", nav: "" } },
                          _vm._l(_vm.items, function (item, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: i,
                                attrs: {
                                  href: item.href,
                                  link: "",
                                  to: item.link,
                                  "active-class": "primary white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return item.method()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-3" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "text--lighten-1" },
                                      [_vm._v(_vm._s(item.icon))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "cursor-pointer text--lighten-1",
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "pt-1 px-1 pb-2 cursor-pointer",
                  },
                  [
                    _c("v-icon", { attrs: { size: "28", color: "white" } }, [
                      _vm._v("mdi-earth"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "pt-1 px-2 pb-2 cursor-pointer",
                  },
                  [
                    _c("v-icon", { attrs: { size: "28", color: "white" } }, [
                      _vm._v("mdi-help-circle-outline"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pt-1 pb-2" },
                  [
                    _c(
                      "base-btn",
                      {
                        staticClass:
                          "transparent rounded-circle elevation-0 py-5",
                        attrs: { "x-small": "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.CoreSrv.SettingsDrawer.Mode =
                              !_vm.CoreSrv.SettingsDrawer.Mode
                          },
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "26", color: "white" } },
                          [_vm._v("mdi-bell")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-evenly mr-n3" },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          bottom: "",
                          "offset-y": "",
                          transition: "slide-y-reverse-transition",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "base-card",
                                  {
                                    staticClass:
                                      "white cursor-pointer d-flex flex-width ml-auto rounded-pill transparent",
                                    attrs: { flat: "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "d-flex align-center pa-1",
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "30",
                                              color: "white",
                                            },
                                          },
                                          [_vm._v(" mdi-account-circle ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "", nav: "" } },
                          _vm._l(_vm.items, function (item, i) {
                            return _c(
                              "v-list-item",
                              { key: i, attrs: { link: "", to: item.link } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-3" },
                                  [
                                    _c("v-icon", { attrs: { color: "grey" } }, [
                                      _vm._v(_vm._s(item.icon)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass: "cursor-pointer primary--text",
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _c("v-progress-linear", {
        staticStyle: { "z-index": "9999" },
        attrs: {
          color: "accent",
          indeterminate: "",
          active: _vm.LoaderSrv.getProgressBarLoader,
        },
      }),
      _c("SaveUserDialogComponent", {
        ref: "saveUserDialogRef",
        attrs: { name: "app-bar-add-user-dialog", type: "profile" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }