import {ServiceClass} from '@/decorators';
import i18n from '@/plugins/i18n';

@ServiceClass()
export class ConfirmService {
    // Properties
    private Resolve!: (value: boolean) => void;
    public Title: string = '';
    public Detail: string = '';
    public MaxWidth: number = 400;
    public Options: ConfirmOptions = {
        ConfirmText: 'Yes',
        CancelText: 'Cancel',
    };

    public ConfirmMode: boolean = false;

    // Getter
    get getConfirmMode(): boolean {
        return this.ConfirmMode;
    }

    // Show loader
    public open(title: string, detail: string, options?: ConfirmOptions) {
        const isT = title.split('$t.');
        if (isT.length > 1) {
            title = title.replace('$t.', '');
            title = i18n.t(title) as string;
        }
        const isT2 = detail.split('$t.');
        if (isT2.length > 1) {
            detail = detail.replace('$t.', '');
            detail = i18n.t(detail) as string;
        }

        const instance = new ConfirmService();
        instance.ConfirmMode = true;
        instance.Title = title;
        instance.Detail = detail;

        let confirmText = '$t.Common.Yes';
        let cancelText = '$t.Common.Cancel';
        if (options) {
            confirmText = options.ConfirmText ?? '$t.Common.Yes';
            cancelText = options.CancelText ?? '$t.Common.Cancel';
        }
        const confirmTextT = confirmText.split('$t.');
        if (confirmTextT.length > 1) {
            confirmText = confirmText.replace('$t.', '');
            confirmText = i18n.t(confirmText) as string;
        }
        const cancelTextT = cancelText.split('$t.');
        if (cancelTextT.length > 1) {
            cancelText = cancelText.replace('$t.', '');
            cancelText = i18n.t(cancelText) as string;
        }

        if (options) {
            instance.Options = {...instance.Options, ...options};
        } else {
            instance.Options = {
                ConfirmText: confirmText as any,
                CancelText: cancelText as any,
            };
        }
        return new Promise(resolve => {
            instance.Resolve = resolve;
        });
    }

    public close(value: boolean) {
        this.ConfirmMode = false;
        this.Resolve(value);
    }
}
export interface ConfirmOptions {
    ConfirmText?: 'Yes' | 'Confirm' | 'Discard' | 'Continue' | string;
    CancelText?: 'Cancel' | 'No' | 'Close' | 'Dashboard' | string;
}
