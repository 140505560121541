import Vue from 'vue';
import BaseFilePickerComponent from './base/base-file-picker/base-file-picker.component';
import BaseTitleTextComponent from './shared/base-title-text/base-title-text.component';
import CustomListItemBtnComponent from './shared/custom-list-item-btn/custom-list-item-btn.component';
import PaginationComponent from './shared/pagination/pagination.component';
import SaveWrapperComponent from './shared/save-wrapper/save-wrapper.component';
import BaseAutocomplete from './vuetify/Autocomplete/base-autocomplete';
import BaseBtn from './vuetify/Btn/base-btn';
import BaseCard from './vuetify/Card/base-card.component';
import BaseCheckbox from './vuetify/Checkbox/base-checkbox';
import BaseCheckboxGroup from './vuetify/CheckboxGroup/base-checkbox-group';
import BaseCombobox from './vuetify/Combobox/base-combobox';
import BaseCurrencyField from './vuetify/CurrencyField/base-currency-field';
import BaseDatePickerComponent from './vuetify/DatePicker/base-date-picker';
import BaseDialog from './vuetify/Dialog/base-dialog';
import BaseFormComponent from './vuetify/Form/base-form';
import FullPageDialogComponent from './vuetify/FullPageDialog/full-page-dialog.component';
import BaseMenu from './vuetify/Menu/base-menu';
import PhoneNumberInputComponent from './vuetify/phone-number/phone-number-input.component';
import BaseRadioGroup from './vuetify/RadioGroup/base-radio-group';
import BaseSelect from './vuetify/Select/base-select';
import BaseSwitch from './vuetify/Switch/base-switch';
import BaseTextarea from './vuetify/Textarea/base-textarea';
import BaseTextField from './vuetify/TextField/base-text-field';
import BaseTimePickerComponent from './vuetify/TimePicker/base-time-picker';
import BaseTooltip from './vuetify/Tooltip/base-tooltip';

// Vuetify Components
Vue.component('base-text-field', BaseTextField);
Vue.component('base-textarea', BaseTextarea);
Vue.component('base-menu', BaseMenu);
Vue.component('base-btn', BaseBtn);
Vue.component('base-switch', BaseSwitch);
Vue.component('base-checkbox', BaseCheckbox);
Vue.component('base-checkbox-group', BaseCheckboxGroup);
Vue.component('base-radio-group', BaseRadioGroup);
Vue.component('base-select', BaseSelect);
Vue.component('base-autocomplete', BaseAutocomplete);
Vue.component('base-combobox', BaseCombobox);
Vue.component('base-form', BaseFormComponent);
Vue.component('base-date-picker', BaseDatePickerComponent);
Vue.component('base-time-picker', BaseTimePickerComponent);
Vue.component('base-tooltip', BaseTooltip);
Vue.component('base-card', BaseCard);
Vue.component('base-dialog', BaseDialog);
Vue.component('base-title-text', BaseTitleTextComponent);
Vue.component('base-currency-field', BaseCurrencyField);

Vue.component('base-file-picker', BaseFilePickerComponent);

// Custom Components
Vue.component('pagination-component', PaginationComponent);
Vue.component('full-page-dialog', FullPageDialogComponent);

// Shared
Vue.component('custom-list-item-btn', CustomListItemBtnComponent);

Vue.component('save-wrapper-component', SaveWrapperComponent);

Vue.component('PhoneNumberInputComponent', PhoneNumberInputComponent);
