import {WORK_PERMIT_TYPES} from '@/globals';

export enum WORK_PERMITS_SCOPES {
    GLOBAL = 'global',
    ACCOUNT = 'account',
}

export interface WorkPermitModel {
    id?: string;
    title?: string;
    description?: string;
    filename?: string;
    type: string;
    sequence?: number;
    accountId?: string;
    scope?: WORK_PERMITS_SCOPES;
    created?: string;
    updated?: string;
}

export interface WorkPermitTypeViewModel {
    key: WORK_PERMIT_TYPES;
    title: string;
}
