import {FieldOptions} from './field-options.model';

export class DataFieldModel {
    public id?: string | null = null;
    public title: string | null = null;
    public for: string | null = null;
    public required?: boolean | null = null;
    public section?: string | null = null;
    public type: string | null = null;
    public dependOnFieldId: string | null = null;
    public created?: string | null = null;

    public fieldOptions: FieldOptions[] = [];

    public loadOptionsFrom: string | null = null;

    public constructor(data?: Partial<DataFieldModel>) {
        Object.assign(this, data);
    }
}
