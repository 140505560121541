export class UploadedFilesModel {
    id?: string | null = null;
    title?: string | null = null;
    fileName?: string | null = null;
    mimeType?: string | null = null;
    principalType?: string | null = null;
    principalId?: string | null = null;
    created?: Date | null = null;

    constructor(data?: Partial<UploadedFilesModel>) {
        Object.assign(this, data);
    }
}
