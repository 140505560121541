import {ServiceClass} from '@/decorators';
import {SessionModel, UserSession, WorkPermitModel, WorkPermitTypeViewModel} from '@/sdk';
import {WorkPermitApi, WorkPermitTypeApi} from '@/sdk/api-services';

@ServiceClass()
export class WorkPermitsService {
    public workPermitApi: WorkPermitApi = new WorkPermitApi();
    public workPermitTypeApi: WorkPermitTypeApi = new WorkPermitTypeApi();

    // public workPermits: Array<WorkPermitModel> = [];

    public selectedWorkPermitType: WorkPermitTypeViewModel | null = null;

    public session: UserSession = new UserSession();

    public sessionData: SessionModel | null = null;

    public workPermitTypes: Array<WorkPermitTypeViewModel> = [];
    public workPermitTemplates: Array<WorkPermitModel> = [];

    constructor() {
        this.session._session.subscribe(session => {
            this.sessionData = session;

            this.getWorkPermitTypes();
        });
    }

    public async getWorkPermits(accountId: string) {
        this.workPermitTemplates = await this.workPermitApi.getWorkPermits(accountId, {});

        return this.workPermitTemplates;
    }

    public async getWorkPermitTypes() {
        this.workPermitTypeApi
            .getWorkPermitTypes()
            .then(types => {
                this.workPermitTypes = types;

                return types;
            })
            .catch(error => {
                console.error(error);
            });
    }
}
