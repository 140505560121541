var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between align-center" },
                  [
                    _c("h1", { staticClass: "pa-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("SiteComponents.SingleInspectionForm.Title", {
                            title: _vm.inspectionForm
                              ? _vm.inspectionForm.title
                              : "",
                          })
                        )
                      ),
                    ]),
                    _c(
                      "base-btn",
                      {
                        attrs: { small: "" },
                        on: { click: _vm.saveInspectionForm },
                      },
                      [_vm._v(_vm._s(_vm.$t("Common.Save")))]
                    ),
                  ],
                  1
                ),
                _c("hr", {
                  staticClass: "solid",
                  staticStyle: { "margin-bottom": "10px" },
                }),
                _c("table", { staticClass: "foex-table text-center" }, [
                  _c(
                    "tbody",
                    [
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            "background-color": "#eceff1 !important",
                          },
                        },
                        [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "SiteComponents.SingleInspectionForm.TableHeaders.A"
                                )
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "SiteComponents.SingleInspectionForm.TableHeaders.GeneralForm"
                                )
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "SiteComponents.SingleInspectionForm.TableHeaders.ResultOfInspection"
                                )
                              )
                            ),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "SiteComponents.SingleInspectionForm.TableHeaders.Comments"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.inspectionFormQuestions, function (q) {
                        return [
                          q && q.inspectionFormQuestionAnswers
                            ? _c("tr", { key: q.id }, [
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(_vm._s(q.code))]
                                ),
                                _c("td", { staticClass: "descriptionCell" }, [
                                  _vm._v(_vm._s(q.question)),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "centered-select width-120px mr-1",
                                        attrs: {
                                          outlined: "",
                                          items: _vm.getInspectionResultOptions(
                                            q.inspectionQuestionFieldOptions ??
                                              []
                                          ),
                                          "item-text": "option",
                                          "item-value": "id",
                                          dense: "",
                                          clearable: "",
                                          label: _vm.$t(
                                            "Fields.Common.Results.Label"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "clear",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            )
                                              return null
                                            return (() => {
                                              if (
                                                q.inspectionFormQuestionAnswers &&
                                                q.inspectionFormQuestionAnswers
                                                  .length > 0
                                              ) {
                                                q.inspectionFormQuestionAnswers[0].clear = true
                                              }
                                            }).apply(null, arguments)
                                          },
                                        },
                                        model: {
                                          value:
                                            q.inspectionFormQuestionAnswers[0]
                                              .answer,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              q
                                                .inspectionFormQuestionAnswers[0],
                                              "answer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q.inspectionFormQuestionAnswers[0].answer",
                                        },
                                      }),
                                      _c("v-select", {
                                        staticClass:
                                          "centered-select width-100px",
                                        attrs: {
                                          outlined: "",
                                          items:
                                            _vm.getInspectionSeverityOptions(
                                              q.inspectionQuestionFieldOptions ??
                                                []
                                            ),
                                          "item-text": "option",
                                          "item-value": "id",
                                          dense: "",
                                          clearable: "",
                                          label: "Severity",
                                        },
                                        on: {
                                          click: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "clear",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            )
                                              return null
                                            return (() => {
                                              if (
                                                q.inspectionFormQuestionAnswers &&
                                                q.inspectionFormQuestionAnswers
                                                  .length > 0
                                              ) {
                                                q.inspectionFormQuestionAnswers[0].clear = true
                                              }
                                            }).apply(null, arguments)
                                          },
                                        },
                                        model: {
                                          value:
                                            q.inspectionFormQuestionAnswers[0]
                                              .severity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              q
                                                .inspectionFormQuestionAnswers[0],
                                              "severity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q.inspectionFormQuestionAnswers[0].severity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("td", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          q.inspectionFormQuestionAnswers[0]
                                            .comment,
                                        expression:
                                          "q.inspectionFormQuestionAnswers[0].comment",
                                      },
                                    ],
                                    attrs: {
                                      name: "comments",
                                      id: "",
                                      cols: "30",
                                      rows: "2",
                                    },
                                    domProps: {
                                      value:
                                        q.inspectionFormQuestionAnswers[0]
                                          .comment,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          q.inspectionFormQuestionAnswers[0],
                                          "comment",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }