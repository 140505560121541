var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", { staticClass: "pt-0" }, [
        _c("h4", { staticClass: "text-h6 my-2" }, [
          _vm._v("Area Documentation"),
        ]),
        _c(
          "span",
          { staticClass: "ml-auto" },
          [
            !_vm.addDocument
              ? _c(
                  "v-btn",
                  {
                    staticClass: "white--text",
                    attrs: { color: "admin-primary ", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        _vm.addDocument = true
                      },
                    },
                  },
                  [
                    _vm._v(" Add Document "),
                    _c("v-icon", { staticClass: "ml-1" }, [
                      _vm._v("mdi-plus-circle-outline"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("v-card-text", [
        _vm.addDocument
          ? _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("base-autocomplete", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            clearable: "",
                            rules: "required",
                            items: _vm.documentTypes,
                            value: _vm.selectedType,
                            label: "Document Type",
                            "return-object": false,
                            disabled: _vm.disabled,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.selectedType = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        !_vm.file
                          ? _c("base-file-picker", {
                              attrs: {
                                width: "100%",
                                height: "39px",
                                accept: _vm.accept,
                                disabled: _vm.disabled,
                              },
                              on: { filesSelected: _vm.filesSelected },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end",
                        attrs: { cols: "4" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text mr-1",
                            attrs: {
                              color: "admin-primary ",
                              disabled:
                                !_vm.selectedType || !_vm.file || _vm.disabled,
                            },
                            on: { click: _vm.upload },
                          },
                          [_vm._v(" Save ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: { outlined: "", color: "admin-primary" },
                            on: {
                              click: function ($event) {
                                _vm.addDocument = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
              ],
              1
            )
          : _vm._e(),
        _c("div", [
          _c("table", { staticClass: "foex-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Type")]),
                _c("th", [_vm._v("File")]),
                _c("th", [_vm._v("Actions")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.documents, function (document) {
                return _c("tr", { key: document.id }, [
                  _c("td", [_vm._v(_vm._s(document.documentType))]),
                  _c("td", [_vm._v(_vm._s(document.title))]),
                  _c(
                    "td",
                    [
                      _c(
                        "base-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.view(document)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "admin-primary--text cursor-pointer",
                            },
                            [_vm._v("mdi-eye")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            small: "",
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteDoc(document)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "red lighten-1" } }, [
                            _vm._v("mdi-delete-empty"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("PreviewDocumentDialogComponent", { ref: "previewDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }