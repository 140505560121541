export interface AreaSearchViewModel {
    locationId: string;
    siteId: string;
    title: string;
    type: string;

    locationApprovalType: string | null;
    locationDustZone: string | null;
    locationDustgroup: string | null;
    locationEnvironmentalCondition: string | null;
    locationEnvironmentalRisk: string | null;
    locationExplosionConsequences: string | null;
    locationFlammableDust: string | null;
    locationFlammableSubstances: string | null;
    locationGasZone: string | null;
    locationGasGroup: string | null;
    locationGasTempClass: string | null;
    locationInspectionFreq: string | null;
    locationIpx: string | null;
    locationIpy: string | null;
    locationDustCloudIgnitionTemp: string | null;
    locationDustLayerIgnitionTemp: string | null;
    locationDustLayerDepth: string | null;
    locationDustLayerNotAppli: string | null;
    locationLayerDepthBelow5: string | null;
    locationLayerDepthBetween5to50: string | null;
    locationLayerDepthAbove50: string | null;
    locationLowAmbientTemp: string | null;
    locationHighAmbientTemp: string | null;
    locationTempClass: string | null;
    locationDustIgnitionTempType: string | null;
    locationDustIngnitionTempVal: string | null;
    locationSavedDrawing: string | null;
    locationNote: string | null;
    locationCreated: string | null;
    locationUpdated: string | null;
}
