var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "d-flex mb-2", attrs: { outlined: "" } },
    [
      _c("v-card-text", { staticClass: "d-flex justify-center py-2" }, [
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/location.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Area"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/button8.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", [_vm._v("Sub-area")]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/button9.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Point"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/button10.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", [_vm._v("Equipment")]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/button11.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Help"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "px-5 d-flex align-center flex-column cursor-pointer",
          },
          [
            _c("v-img", {
              attrs: {
                src: require("../../../../assets/button12.svg"),
                height: "40",
                width: "40",
                contain: "",
              },
            }),
            _c("p", [_vm._v("Support")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }