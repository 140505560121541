var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "py-0 px-0 height-100x main-layout-container",
      staticStyle: { position: "relative" },
      attrs: { fluid: "" },
    },
    [
      _c("client-app-bar-component"),
      _c(
        "v-main",
        {
          staticClass: "height-100x overflow-hidden",
          attrs: { id: "main-content-section" },
        },
        [
          _c("div", { staticClass: "d-flex flex-column height-100x" }, [
            _c("div", { staticClass: "height-100x" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column px-3 pt-3 pb-4 height-100x",
                  staticStyle: { "z-index": "1" },
                },
                [
                  _c(
                    "base-card",
                    {
                      staticClass: "mb-2 flex-shrink-0",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                staticStyle: { display: "none !important" },
                              },
                              [
                                _c("base-combobox", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    "hide-details": "",
                                    name: "Company",
                                    label: "Company",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { cols: "10" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        height: "20px",
                                        dense: "",
                                        outlined: "",
                                        clearable: "",
                                        "prepend-inner-icon": "mdi-magnify",
                                        label: _vm.$t("TeamPage.Search.Label"),
                                        "single-line": "",
                                        "hide-details": "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "prepend-inner",
                                          fn: function () {
                                            return [
                                              _c("v-img", {
                                                attrs: {
                                                  height: "16",
                                                  width: "30",
                                                  contain: "",
                                                  src: require("@/assets/icons/Search-light.png"),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _c(
                                      "base-btn",
                                      {
                                        staticClass: "mx-1",
                                        attrs: { width: "50%" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Common.Search")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "base-btn",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          depressed: "",
                                          width: "45%",
                                          color: "grey lighten-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Common.Clear")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "base-card",
                    { staticClass: "flex-grow-1", attrs: { outlined: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "table-header-div" },
                        [
                          _c("v-simple-table", { staticClass: "sites-table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  { staticStyle: { "min-width": "200px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("TeamPage.TableHeaders.PersonID")
                                      )
                                    ),
                                  ]
                                ),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.FirstName")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.LastName")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "TeamPage.TableHeaders.AccountRole"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.SiteRole")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.MiddleName")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.LoginID")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "TeamPage.TableHeaders.OfficeNumber"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "TeamPage.TableHeaders.MobileNumber"
                                      )
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.Address")
                                    )
                                  ),
                                ]),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("TeamPage.TableHeaders.Actions")
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.teamMembers, function (member) {
                                return _c("tr", { key: member.id }, [
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.id)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.firstName)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.lastName)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(
                                      _vm._s(
                                        member.roles && member.roles.length
                                          ? member.roles[0].name
                                          : ""
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(
                                      _vm._s(
                                        member.siteRoles &&
                                          member.siteRoles.length
                                          ? member.siteRoles[0].name
                                          : ""
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.middleName)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.loginId)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.phone)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.mobile)),
                                  ]),
                                  _c("td", { staticClass: "width-100px" }, [
                                    _vm._v(_vm._s(member.address)),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "width-200px text-center",
                                      staticStyle: { "white-space": "nowrap" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "base-btn",
                                            {
                                              staticClass:
                                                "white--text mr-1 mb-1",
                                              attrs: {
                                                color: "blue",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editUserClick(
                                                    member.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("Common.Edit")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("SaveUserDialogComponent", {
        ref: "saveUserDialogRef",
        attrs: {
          name: "team-add-user-dialog",
          accountId: _vm.userSession.Session.accountId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }