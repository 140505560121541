var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column flex-srink-0",
      staticStyle: { "min-width": "max-content" },
    },
    [
      _vm._l(_vm.getLocations, function (location) {
        return [
          _c(
            "div",
            {
              key: `${location.type}${location.id}`,
              staticClass: "d-flex align-center mb-1 ba-1 list-item-box",
              class: {
                "grey lighten-2":
                  _vm.siteLocationsSrv.selectedLcoation &&
                  _vm.siteLocationsSrv.selectedLcoation.id === location.id,
                "drag-hilight":
                  _vm.siteLocationsSrv.enterItemId === `${location.id}` &&
                  _vm.siteLocationsSrv.enterItemId !==
                    _vm.siteLocationsSrv.selectedItemId &&
                  location.type === "location" &&
                  location.locationId !== _vm.siteLocationsSrv.selectedItemId,
                "drag-hilight-swap":
                  _vm.siteLocationsSrv.enterItemId === `${location.id}` &&
                  _vm.siteLocationsSrv.enterItemId !==
                    _vm.siteLocationsSrv.selectedItemId &&
                  _vm.siteLocationsSrv.selectedDragItem &&
                  _vm.siteLocationsSrv.selectedDragItem.locationId ===
                    location.locationId,
              },
              staticStyle: { flex: "0 0 auto" },
              attrs: {
                id: `${location.id}`,
                type: `${location.type}`,
                draggable: _vm.draggable,
              },
              on: {
                dragover: _vm.allowDrop,
                drop: _vm.onItemDrop,
                dragenter: _vm.onDragEnter,
                dragleave: _vm.onDragLeave,
                dragstart: _vm.onDragStart,
              },
            },
            [
              _vm._l(_vm.level - 1, function (l) {
                return _c("div", {
                  key: `${location.id}${l}`,
                  staticClass: "d-inline-block",
                  staticStyle: { width: "25px", height: "1px" },
                })
              }),
              _c(
                "div",
                { staticStyle: { "min-width": "30px" } },
                [
                  _vm.hasSubLocations(location.id)
                    ? _c(
                        "base-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.expandClick(location)
                            },
                          },
                        },
                        [
                          location.expanded
                            ? _c("v-icon", [_vm._v("mdi-chevron-down ")])
                            : _c("v-icon", [_vm._v("mdi-chevron-right")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("locationItemClick", location)
                    },
                  },
                },
                [
                  _c("v-img", {
                    staticClass: "mr-3",
                    attrs: {
                      height: "22",
                      width: "22",
                      src: _vm.locationIcon(location),
                    },
                  }),
                  _c("span", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v(_vm._s(location.title)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item-controls d-flex" },
                [
                  !_vm.readonly
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: { width: "25px" },
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.editLocation(location)
                            },
                          },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              height: "16",
                              width: "25",
                              contain: "",
                              src: require("@/assets/icons/edit-blue.png"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  location.type === "location" && !_vm.readonly
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-x": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticStyle: { width: "25px" },
                                                attrs: { small: "", icon: "" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                height: "16",
                                                width: "25",
                                                contain: "",
                                                src: require("@/assets/icons/add.png"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "", nav: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.adItemClick(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add Area"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addSubItemClick(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add Sub Area"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addEquipment(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add Equipment"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addDistribution(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add Distribution Panel"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  location.type === "equipment" && !_vm.readonly
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-x": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticStyle: { width: "25px" },
                                                attrs: { small: "", icon: "" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                height: "16",
                                                width: "25",
                                                contain: "",
                                                src: require("@/assets/icons/add.png"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "", nav: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addFolderClick(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add Folder"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addFileClick(location)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Add File(s)"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.readonly
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { width: "25px" },
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteLocation(location)
                                },
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  height: "16",
                                  width: "25",
                                  contain: "",
                                  src: require("@/assets/icons/delete-red.png"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          location.expanded && _vm.hasSubLocations(location.id)
            ? [
                _c("LocationListItemComponent", {
                  key: `child-${location.type}${location.id}`,
                  attrs: {
                    parentType: location.type,
                    parentId: location.id,
                    locations: _vm.locations,
                    diagramViewStyle: _vm.diagramViewStyle,
                    level: _vm.level + 1,
                    draggable: _vm.draggable,
                    readonly: _vm.readonly,
                  },
                  on: {
                    locationItemClick: function ($event) {
                      return _vm.$emit("locationItemClick", $event)
                    },
                  },
                }),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }