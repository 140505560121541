import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop} from 'vue-property-decorator';
import {AdminDataFieldService, SiteProjectsService} from '../services';

@Component
export default class SaveFieldOptionDialogComponent extends VueWrapper {
    public readonly name: string = 'save-field-option-dialog';
    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    @Prop({
        type: String,
        required: true,
    })
    public currentTab!: string;

    public mounted() {
        //
    }

    public saveOption() {
        if (this.adminDataFieldsSrv.newOption?.id) {
            this.LoaderSrv.showFullScreenLoader();
            this.adminDataFieldsSrv.dataFieldApi
                .updateDataFieldOption(
                    this.adminDataFieldsSrv.selectedField!.id!,
                    this.adminDataFieldsSrv.newOption.id!,
                    this.adminDataFieldsSrv.newOption.value!,
                    this.adminDataFieldsSrv.newOption!.dependentOnFieldId!,
                    this.adminDataFieldsSrv.newOption!.dependentOnFieldOptionId!,
                    this.adminDataFieldsSrv.newOption!.regressPriority!
                )
                .subscribe(
                    res => {
                        if (res.Status) {
                            this.adminDataFieldsSrv.getFieldsOptions(this.adminDataFieldsSrv.selectedField!.id!);
                            this.AlertSrv.show('success', this.$t('Dialogs.SaveFieldOption.UpdateSuccess').toString());
                            this.close();
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', this.$t('Dialogs.SaveFieldOption.UpdateError').toString());
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        } else {
            this.LoaderSrv.showFullScreenLoader();
            this.adminDataFieldsSrv.dataFieldApi
                .createDataFieldOption(
                    this.adminDataFieldsSrv.selectedField!.id!,
                    this.adminDataFieldsSrv.newOption!.value!,
                    this.adminDataFieldsSrv.newOption!.dependentOnFieldId!,
                    this.adminDataFieldsSrv.newOption!.dependentOnFieldOptionId!,
                    this.adminDataFieldsSrv.newOption!.regressPriority!
                )
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.adminDataFieldsSrv.getFieldsOptions(this.adminDataFieldsSrv.selectedField!.id!);
                            this.AlertSrv.show('success', this.$t('Dialogs.SaveFieldOption.CreateSuccess').toString());
                            this.close();
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', this.$t('Dialogs.SaveFieldOption.CreateError').toString());
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public onFieldChange() {
        this.adminDataFieldsSrv.getFieldsOptions(this.adminDataFieldsSrv.newOption!.dependentOnFieldId!);
    }

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        console.log('close');
        this.CoreSrv.CloseModal(this.name);
    }
}
