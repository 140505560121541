var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "save-wrapper-component",
    {
      attrs: {
        "max-width": "500px",
        name: _vm.siteLocationsSrv.name,
        title: _vm.title,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ invalid }) {
            return [
              _c(
                "base-btn",
                {
                  attrs: { disabled: invalid },
                  on: { click: _vm.saveLocation },
                },
                [_vm._v(_vm._s(_vm.$t("Common.Save")))]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("Dialogs.AddLocation.Site")) + ": "),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.siteLocationsSrv.site &&
                          _vm.siteLocationsSrv.site.siteName
                      ) +
                      " "
                  ),
                ]),
                _vm.siteLocationsSrv.parentLocation
                  ? _c("div", [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("Dialogs.AddLocation.ParentArea")) +
                            ": "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.siteLocationsSrv.parentLocation &&
                              _vm.siteLocationsSrv.parentLocation.title
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("base-combobox", {
                    attrs: {
                      label: _vm.$t("Dialogs.AddLocation.LocationType"),
                      name: "LocationType",
                      value: _vm.siteLocationsSrv.location.type,
                      items: _vm.siteLocationsSrv.getTypes,
                      "item-text": "value",
                      "item-value": "key",
                      rules: "required",
                      disabled:
                        _vm.siteLocationsSrv.addAt === "eqp" ||
                        _vm.siteLocationsSrv.addAt === "dist" ||
                        _vm.siteLocationsSrv.location.type === "location" ||
                        !!(
                          _vm.siteLocationsSrv.location &&
                          _vm.siteLocationsSrv.location.id
                        ),
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.siteLocationsSrv.location,
                          "type",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("base-text-field", {
                    attrs: {
                      rules: "required",
                      value: _vm.siteLocationsSrv.location.title,
                      name: "title",
                      label:
                        _vm.siteLocationsSrv.addAt === "eqp"
                          ? _vm.$t("Dialogs.AddEquipment.LocationTitle")
                          : _vm.siteLocationsSrv.addAt === "dist"
                          ? _vm.$t("Dialogs.AddDistributionPanel.LocationTitle")
                          : _vm.$t("Dialogs.AddArea.LocationTitle"),
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.siteLocationsSrv.location,
                          "title",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }