export class SignupInvitationModel {
    id?: string | null = null;
    type: string | null = null;
    email: string | null = null;
    invitationCode: string | null = null;
    accepted: boolean | null = null;
    isActive: boolean | null = null;
    expiresAt: string | null = null;
    invitedByUserId?: string | null = null;
    accountId: string | null = null;
    created?: string | null = null;

    constructor(data?: Partial<SignupInvitationModel>) {
        Object.assign(this, data);
    }
}
