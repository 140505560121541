var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.siteLocationsSrv.selectedLcoation
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column height-100x",
          staticStyle: { overflow: "scroll !important" },
        },
        [
          _c(
            "div",
            { staticClass: "grey lighten-3 mb-4 flex-shrink-0" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center results-header" },
                [
                  _c(
                    "div",
                    { staticClass: "px-5" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "results-header-h1",
                          staticStyle: { width: "500px" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex", attrs: { cols: "6" } },
                            [
                              _c("v-img", {
                                staticClass: "area-search header-img-title",
                                attrs: {
                                  height: "20",
                                  width: "20",
                                  contain: "",
                                  src: require("@/assets/icons/area-light.png"),
                                },
                              }),
                              _c("h2", { staticClass: "areaTitle" }, [
                                _vm._v("Area Details"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        _vm._l(_vm.locationPath, function (path, index) {
                          return _c(
                            "div",
                            { key: path.id, staticClass: "d-flex" },
                            [
                              path.type === "site"
                                ? _c("v-img", {
                                    staticClass: "area-search header-img-title",
                                    attrs: {
                                      height: "18",
                                      width: "18",
                                      contain: "",
                                      src: "/images/plant.png",
                                    },
                                  })
                                : path.type === "location"
                                ? _c("v-img", {
                                    staticClass: "area-search header-img-title",
                                    attrs: {
                                      height: "18",
                                      width: "18",
                                      contain: "",
                                      src: "/images/area.png",
                                    },
                                  })
                                : _vm._e(),
                              path.type === "site"
                                ? _c(
                                    "h4",
                                    { staticClass: "header-title-text" },
                                    [_vm._v(_vm._s(path.title))]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "goToLocation",
                                            path.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "h4",
                                        { staticClass: "header-title-text" },
                                        [_vm._v(_vm._s(path.title))]
                                      ),
                                    ]
                                  ),
                              index !== _vm.locationPath.length - 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "header-title-text next-arrow",
                                      staticStyle: {
                                        color: "white",
                                        padding: "0px 5px",
                                      },
                                    },
                                    [_vm._v(" > ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _vm.diagramViewStyle
                    ? _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "base-btn",
                            {
                              staticStyle: {
                                padding: "0 !important",
                                border: "none",
                                height: "auto",
                                "min-width": "auto",
                                "background-color": "white !important",
                                "box-shadow": "none",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("fullPageFormView", true)
                                },
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  height: "16",
                                  width: "16",
                                  contain: "",
                                  src: "/images/enlarge.png",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "base-btn",
                            {
                              staticStyle: {
                                padding: "0 !important",
                                border: "none",
                                height: "auto",
                                "min-width": "auto",
                                "background-color": "white !important",
                                "box-shadow": "none",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("goToLocation", null)
                                },
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  height: "26",
                                  width: "26",
                                  contain: "",
                                  src: "/images/minus.png",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.siteLocationsSrv.locationDetailDataFieldStatuses.length > 0
                ? _c(
                    "div",
                    { staticClass: "warning-box" },
                    [
                      _c("span", [
                        _vm._v(
                          "There are total " +
                            _vm._s(
                              _vm.siteLocationsSrv
                                .locationDetailDataFieldStatuses.length
                            ) +
                            " warnings."
                        ),
                      ]),
                      _c(
                        "base-btn",
                        {
                          attrs: { color: "#e17e2c", outlined: "", small: "" },
                          on: { click: _vm.summaryClick },
                        },
                        [_vm._v(" Details ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "flex-grow-1 flex-1 overflow-auto d-flex detailed-forms info-tabs-header",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col col-12" },
                    [
                      _c(
                        "v-tabs",
                        { staticClass: "my-tabs min-height-100" },
                        [
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("General Information")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Area Classification")]
                          ),
                          _c(
                            "v-tab",
                            { attrs: { "active-class": "side-tab-active" } },
                            [_vm._v("Documentations")]
                          ),
                          _vm.diagramViewStyle
                            ? _c(
                                "div",
                                {
                                  staticClass: "px-3",
                                  staticStyle: { "margin-left": "auto" },
                                },
                                [
                                  _c(
                                    "base-btn",
                                    {
                                      staticStyle: {
                                        border: "1px solid white !important",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("saveLocationDetail")
                                        },
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  name: "areaID",
                                                  disabled: "",
                                                  label: "Area ID",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation.id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation,
                                                      "id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                staticStyle: {
                                                  color: "#fdf7e7",
                                                },
                                                attrs: {
                                                  name: "areaName",
                                                  disabled: "",
                                                  label: "Title",
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaFlammableSubstance
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  multiple: true,
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .flammableSubstances,
                                                  label: "Flammable Gas",
                                                  "return-object": false,
                                                },
                                                on: {
                                                  "update:value": [
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail,
                                                        "flammableSubstances",
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.onSelected(
                                                        $event,
                                                        "areaFlammableSubstance"
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "table",
                                                { staticClass: "width-100" },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("Title"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v(
                                                          "Relative Density"
                                                        ),
                                                      ]),
                                                      _c("th", [_vm._v("LEL")]),
                                                      _c("th", [_vm._v("UEL")]),
                                                      _c("th", [_vm._v("AIT")]),
                                                      _c("th", [
                                                        _vm._v("Gas Group"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("T-Class"),
                                                      ]),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.flameableGasInfo,
                                                      function (item) {
                                                        return _c(
                                                          "tr",
                                                          { key: item.id },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.relativeDensity
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.LFLVol
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.UFLVol
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(item.AIT)
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.equipmentGroup
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.tClass
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaFlammableDust
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  multiple: true,
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .flammableDust,
                                                  label: "Flammable Dust",
                                                  "return-object": false,
                                                },
                                                on: {
                                                  "update:value": [
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.siteLocationsSrv
                                                          .selectedLcoation
                                                          .locationDetail,
                                                        "flammableDust",
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.onSelected(
                                                        $event,
                                                        "areaFlammableDust"
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "table",
                                                { staticClass: "width-100" },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("Name"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Dust Group"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v(
                                                          "Min Ignition Temp. of Dust Cloud"
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v(
                                                          "Min Ignition Depth of Dust Layer"
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v(
                                                          "Min Ignition Temp. of Dust Layer"
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.flameableDustInfo,
                                                      function (item) {
                                                        return _c(
                                                          "tr",
                                                          { key: item.id },
                                                          [
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "150px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.name
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.dustGroup
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.minimumIgnitionTempDustCloud
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.minimumIgnitionDepthDustLayer
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.minimumIgnitionTempDustLayer
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaExplosionConsequence
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label:
                                                    "Explosion Consequences",
                                                  "return-object": false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .explosionConsequences,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "explosionConsequences",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.explosionConsequences",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  multiple: true,
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaApprovalType
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Approval Type",
                                                  "return-object": false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .approvalType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "approvalType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.approvalType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaInspectionFrequency
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label:
                                                    "Area Inspection Frequency",
                                                  "return-object": false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .inspectionFreq,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "inspectionFreq",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.inspectionFreq",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  multiple: true,
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaEnvironmentalCondition
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label:
                                                    "Environmental Condition",
                                                  "return-object": false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .environmentalCondition,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "environmentalCondition",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.environmentalCondition",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaEnvironmentalRisk
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Environmental Risk",
                                                  "return-object": false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .environmentalRisk,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "environmentalRisk",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.environmentalRisk",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .locationDetail
                                                              .lowAmbientTemp,
                                                          name: "areaLowAmbientTemp",
                                                          label:
                                                            "Low Ambient Temperature",
                                                          "error-count":
                                                            _vm.siteLocationsSrv.checkFieldError(
                                                              "areaLowAmbientTemperature"
                                                            ).length ?? 1,
                                                          "error-messages":
                                                            _vm.siteLocationsSrv.checkFieldError(
                                                              "areaLowAmbientTemperature"
                                                            ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm
                                                                  .siteLocationsSrv
                                                                  .selectedLcoation
                                                                  .locationDetail,
                                                                "lowAmbientTemp",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .locationDetail
                                                              .highAmbientTemp,
                                                          name: "areaHighAmbientTemp",
                                                          label:
                                                            "High Ambient Temperature",
                                                          "error-count":
                                                            _vm.siteLocationsSrv.checkFieldError(
                                                              "areaHighAmbientTemperature"
                                                            ).length ?? 1,
                                                          "error-messages":
                                                            _vm.siteLocationsSrv.checkFieldError(
                                                              "areaHighAmbientTemperature"
                                                            ),
                                                        },
                                                        on: {
                                                          "update:value":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm
                                                                  .siteLocationsSrv
                                                                  .selectedLcoation
                                                                  .locationDetail,
                                                                "highAmbientTemp",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        attrs: {
                                                          dense: "",
                                                          outlined: "",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .areaFieldOptions[
                                                              _vm.areaFieldsList
                                                                .areaIpx
                                                            ],
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label:
                                                            "IP (X) Requirement",
                                                          "return-object": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .locationDetail
                                                              .ipx,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .locationDetail,
                                                              "ipx",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.locationDetail.ipx",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("base-autocomplete", {
                                                        attrs: {
                                                          dense: "",
                                                          outlined: "",
                                                          items:
                                                            _vm
                                                              .adminDataFieldsSrv
                                                              .areaFieldOptions[
                                                              _vm.areaFieldsList
                                                                .areaIpy
                                                            ],
                                                          "item-text": "value",
                                                          "item-value": "id",
                                                          label:
                                                            "IP (Y) Requirement",
                                                          "return-object": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.siteLocationsSrv
                                                              .selectedLcoation
                                                              .locationDetail
                                                              .ipy,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .siteLocationsSrv
                                                                .selectedLcoation
                                                                .locationDetail,
                                                              "ipy",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "siteLocationsSrv.selectedLcoation.locationDetail.ipy",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasArea
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas Area",
                                                  "return-object": false,
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasArea"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasArea"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail.gasZone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "gasZone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.gasZone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasGroup
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas Group",
                                                  "return-object": false,
                                                  "hide-details": false,
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasGroup"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasGroup"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail.gasGroup,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "gasGroup",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.gasGroup",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaGasTemperatureClass
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Gas temp. Class",
                                                  "return-object": false,
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasTemperatureClass"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaGasTemperatureClass"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .gasTempClass,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "gasTempClass",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.gasTempClass",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaDustArea
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Dust Area",
                                                  "return-object": false,
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustArea"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustArea"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail.dustZone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "dustZone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.dustZone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.adminDataFieldsSrv
                                                      .areaFieldOptions[
                                                      _vm.areaFieldsList
                                                        .areaDustGroup
                                                    ],
                                                  "item-text": "value",
                                                  "item-value": "id",
                                                  label: "Dust Group",
                                                  "return-object": false,
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustGroup"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustGroup"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail.dustgroup,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "dustgroup",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.dustgroup",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustCloudIgnitionTemp",
                                                  label:
                                                    "Dust Cloud Ignition Temperature (oC)",
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustCloudIgnitionTemperature"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustCloudIgnitionTemperature"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .dustCloudIgnitionTemp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "dustCloudIgnitionTemp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.dustCloudIgnitionTemp",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustLayerDepth",
                                                  label: "Dust Layer Depth ",
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustLayerDepth"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustLayerDepth"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .dustLayerDepth,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "dustLayerDepth",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.dustLayerDepth",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("base-text-field", {
                                                attrs: {
                                                  name: "dustLayerIgnitionTemp",
                                                  label:
                                                    "Dust Layer Ignition Temperature (oC)",
                                                  "error-count":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustLayerIgnitionTemperature"
                                                    ).length ?? 1,
                                                  "error-messages":
                                                    _vm.siteLocationsSrv.checkFieldError(
                                                      "areaDustLayerIgnitionTemperature"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.siteLocationsSrv
                                                      .selectedLcoation
                                                      .locationDetail
                                                      .dustLayerIgnitionTemp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.siteLocationsSrv
                                                        .selectedLcoation
                                                        .locationDetail,
                                                      "dustLayerIgnitionTemp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "siteLocationsSrv.selectedLcoation.locationDetail.dustLayerIgnitionTemp",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c("AreaDocumentationComponent", {
                                attrs: {
                                  siteLocationId:
                                    _vm.siteLocationsSrv.selectedLcoation.id,
                                  documents:
                                    _vm.siteLocationsSrv.selectedLcoation
                                      .documentations ?? [],
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("AreaRulesSummaryDialogComponent", {
                ref: "areaRulesSummaryDialogRef",
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }