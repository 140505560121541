var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex-grow-1 flex-1 overflow-auto" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "my-tabs",
              staticStyle: { "background-color": "grey" },
              attrs: { vertical: "" },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: { name: "client", label: "Client" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.projectName,
                                      name: "projectName",
                                      label: "Project Name/Number",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "projectName",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .title,
                                      name: "areaName",
                                      disabled: "",
                                      label: "Equipment Name",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation,
                                          "title",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.tag,
                                      name: "tagInfo",
                                      label: "Tag Information",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "tag",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-combobox", {
                                    staticClass: "flex-grow-1",
                                    attrs: {
                                      name: "Type",
                                      items: _vm.$formOptions.equipType,
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.equipType,
                                      "item-text": "value",
                                      "item-value": "key",
                                      rules: "required",
                                      label: "Type",
                                      clearable: "",
                                      multiple: false,
                                      "return-object": false,
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "equipType",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail
                                          .electricalCircuitConnection,
                                      name: "electCircuitConnection",
                                      label: "Electrical Circuit Connection",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "electricalCircuitConnection",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-combobox", {
                                    staticClass: "flex-grow-1",
                                    attrs: {
                                      name: "Classification",
                                      items:
                                        _vm.$formOptions.classificationsList,
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail
                                          .equipmentClassification,
                                      "item-text": "value",
                                      "item-value": "key",
                                      rules: "required",
                                      label: "Equipment Classification",
                                      clearable: "",
                                      multiple: false,
                                      "return-object": false,
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "equipmentClassification",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-combobox", {
                                    staticClass: "flex-grow-1",
                                    attrs: {
                                      name: "EqpType",
                                      items: _vm.equipmentTypes,
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.equipmentType,
                                      "item-text": "value",
                                      "item-value": "key",
                                      rules: "required",
                                      label: "Equipment Type",
                                      clearable: "",
                                      disabled:
                                        !_vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail
                                          .equipmentClassification,
                                      multiple: false,
                                      "return-object": false,
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "equipmentType",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.manufacturer,
                                      name: "equipManufacturer",
                                      label: "Manufacturer",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "manufacturer",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.supplier,
                                      name: "equipSupplier",
                                      label: "Supplier",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "supplier",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.modelNo,
                                      name: "equipModelNo",
                                      label: "Model No.",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "modelNo",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("base-text-field", {
                                    staticStyle: { color: "#fdf7e7" },
                                    attrs: {
                                      value:
                                        _vm.siteLocationsSrv.selectedLcoation
                                          .equipmentDetail.serialNo,
                                      name: "equipSerialNo",
                                      label: "Serial No.",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.siteLocationsSrv.selectedLcoation
                                            .equipmentDetail,
                                          "serialNo",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }