var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "view-wrapper-component",
    {
      attrs: {
        "max-width": _vm.isQrCode ? 700 : 700,
        name: _vm.name,
        title: _vm.dialogTitle,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "admin-primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column align-center" },
            [
              _c(
                "div",
                {
                  ref: "tagImageContainer",
                  staticClass:
                    "tag-image-container pa-2 rounded-lg elevation-1",
                  staticStyle: { background: "white" },
                  style: {
                    width: _vm.isQrCode ? "220px" : "320px",
                    height: _vm.isQrCode ? "220px" : "170px",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("img", {
                        staticClass: "tag-image",
                        attrs: {
                          src: _vm.tagImageUrl ?? "",
                          alt: _vm.dialogTitle,
                        },
                      })
                    : _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "admin-primary" },
                      }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mt-4",
                  attrs: { color: "admin-primary", loading: false },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-download"),
                  ]),
                  _vm._v(" Download " + _vm._s(_vm.dialogTitle) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "v-list",
            { staticClass: "d-flex flex-row" },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-subtitle-1" },
                        [_vm._v("Tag Name")]
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(_vm.selectedTag?.name ?? "N/A")),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-subtitle-1" },
                        [_vm._v("Tag Code")]
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(_vm.selectedTag?.code ?? "N/A")),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-subtitle-1" },
                        [_vm._v("Status")]
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          _vm._s(
                            _vm.selectedTag?.active ? "Active" : "Inactive"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-subtitle-1" },
                        [_vm._v("Last Updated")]
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.selectedTag?.updated ??
                                _vm.selectedTag?.created,
                              "yyyy-MM-dd hh:mm:ss"
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }