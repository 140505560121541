var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "white height-100x d-flex" },
    [
      _c(
        "div",
        {
          ref: "searchSectionElem",
          staticClass:
            "left-tree-section overflow-hidden flex-shrink-0 relative",
        },
        [
          _c(
            "div",
            {
              ref: "areaBrowserElem",
              staticClass: "overflow-hidden relative mb-1",
              staticStyle: { height: "100%", "min-height": "150px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "overflow-hidden height-100x left-tree-section-child",
                },
                [
                  _c("SiteSetupAreaBrowserComponent", {
                    attrs: {
                      site: _vm.siteToEdit,
                      siteLocations: _vm.siteLocationsSrv.siteLocations,
                    },
                    on: {
                      locationItemClick: _vm.locationItemClick,
                      siteClick: _vm.onSiteClick,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", {
            staticClass:
              "resize-control-vertical height-100x site-browser-resizer",
            on: { mousedown: _vm.onListMouseDown },
          }),
        ]
      ),
      _c(
        "div",
        {
          ref: "searchSectionParentElem",
          staticClass: "d-flex flex-column flex-grow-1 pl-2 overflow-hidden",
          staticStyle: { "background-color": "#e9e9e9" },
        },
        [
          _vm.siteLocationsSrv.selectedLcoation
            ? [
                _c(
                  "div",
                  { staticClass: "d-block overflow-hidden px-2 topSearchBar" },
                  [
                    _c("SiteSetupSearchComponent", {
                      key: _vm.siteId,
                      attrs: {
                        siteId: _vm.siteId,
                        showSearchResults: _vm.showSearchResults,
                      },
                      on: {
                        locationItemClick: function ($event) {
                          return _vm.locationItemClick($event, true)
                        },
                        showSearchResultsEvent: function ($event) {
                          return _vm.showSearchResultsEvent($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showSearchResults,
                        expression: "!showSearchResults",
                      },
                    ],
                    staticClass: "overflow-hidden",
                    staticStyle: { "margin-top": "15px" },
                  },
                  [
                    _vm.siteLocationsSrv.selectedLcoation &&
                    _vm.siteLocationsSrv.selectedLcoation?.type === "location"
                      ? [
                          _c("SiteSetupLocationFormComponent", {
                            key: _vm.siteLocationsSrv.selectedLcoation.id,
                            attrs: { locationPath: _vm.locationPath },
                            on: {
                              goToLocation: _vm.goToLocation,
                              saveLocationDetail: _vm.saveLocationDetail,
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm.siteLocationsSrv.selectedLcoation &&
                    _vm.siteLocationsSrv.selectedLcoation?.type === "equipment"
                      ? [
                          _c("EquipmentDetailsFormComponent", {
                            key: _vm.siteLocationsSrv.selectedLcoation.id,
                            attrs: {
                              locationPath: _vm.locationPath,
                              readOnlyFlag: false,
                            },
                            on: {
                              goToLocation: _vm.goToLocation,
                              saveEquipmentDetail: _vm.saveEquipmentDetail,
                              checkEquipmentRules: _vm.checkEquipmentRules,
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm.siteLocationsSrv.selectedLcoation &&
                    _vm.siteLocationsSrv.selectedLcoation?.type ===
                      "distribution"
                      ? [
                          _c("DistributionPanelFormComponent", {
                            key: _vm.siteLocationsSrv.selectedLcoation.id,
                            attrs: {
                              readOnlyFlag: false,
                              locationPath: _vm.locationPath,
                            },
                            on: {
                              goToLocation: _vm.goToLocation,
                              saveDistributionPanel: _vm.saveDistributionPanel,
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : [_vm._v(" Digital Twin Goes Here ")],
        ],
        2
      ),
      _c("SaveLocationDialogComponent"),
      _c("SaveDocumentationFolderDialogComponent"),
      _c("SaveDocumentationFileDialogComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }