var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "content-class": "foex-dialog",
            name: _vm.name,
            "max-width": "750px",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(" " + _vm._s("Invite Regular User") + " "),
                    _vm._t("header"),
                  ]
                },
                proxy: true,
              },
              {
                key: "dialogFooter",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "base-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              disabled: !_vm.isvalidForm(),
                              color: "admin-primary",
                            },
                            on: { click: _vm.invite },
                          },
                          [_vm._v(_vm._s("Invite"))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "min-height-100 d-flex justify-center flex-column" },
        [
          _c(
            "base-form",
            { ref: "form" },
            [
              _c("base-text-field", {
                attrs: {
                  value: _vm.email,
                  label: "Email",
                  rules: "required|email",
                  name: "email",
                },
                on: {
                  "update:value": function ($event) {
                    _vm.email = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }