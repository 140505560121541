var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "height-100x d-flex pt-2" },
    [
      _c(
        "div",
        {
          ref: "areaBrowserElem",
          staticClass:
            "left-tree-section d-flex flex-column flex-shrink-0 px-3 overflow-hidden relative",
        },
        [
          _c(
            "div",
            {
              ref: "jobAreaBrowserElem",
              staticClass: "overflow-hidden mb-1 relative pb-1",
              staticStyle: { height: "100%", "min-height": "150px" },
            },
            [
              _c(
                "div",
                { staticClass: "height-100x overflow-hidden" },
                [
                  _c("JobSetupAreaBrowserComponent", {
                    attrs: {
                      site: _vm.siteToEdit,
                      siteLocations: _vm.jobLocationsSrv.siteLocations,
                    },
                    on: { locationItemClick: _vm.locationItemClick },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", {
            staticClass:
              "resize-control-vertical height-100x job-browser-resizer",
            on: { mousedown: _vm.onBrowserMouseDown },
          }),
        ]
      ),
      _c(
        "div",
        {
          ref: "jobListParentElem",
          staticClass:
            "d-flex flex-column flex-grow-1 px-3 overflow-hidden relative",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-block overflow-hidden",
              style: { "min-height": "45px" },
            },
            [
              _c(
                "div",
                { staticClass: "overflow-hidden height-100x" },
                [
                  _c("SiteSetupSearchComponent", {
                    attrs: {
                      siteId: _vm.siteId,
                      showSearchResults: _vm.showSearchResults,
                    },
                    on: {
                      locationItemClick: function ($event) {
                        return _vm.locationItemClick($event, true)
                      },
                      showSearchResultsEvent: function ($event) {
                        return _vm.showSearchResultsEvent($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showSearchResults,
                  expression: "!showSearchResults",
                },
              ],
              staticClass: "overflow-auto relative flex-grow-1",
            },
            [
              _c(
                "div",
                {
                  ref: "jobListElem",
                  staticClass: "overflow-hidden mb-1 relative pb-1",
                  style: {
                    height: _vm.parentHeight ? `${_vm.currentHeight}px` : "50%",
                    "min-height": "150px",
                    "margin-top": "15px",
                  },
                },
                [
                  _c("div", {
                    staticClass:
                      "resize-control-horizontal height-100x job-hozontal-resizer",
                    on: { mousedown: _vm.onListMouseDown },
                  }),
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex height-100x overflow-hidden",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex height-100x overflow-hidden",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "base-card",
                            {
                              staticClass:
                                "overflow-hidden d-flex flex-column flex-1 pt-2 pb-0",
                              attrs: { outlined: "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-3 font-weight-bold flex-shrink-0 d-flex",
                                },
                                [
                                  _vm.siteProjectsSrv.selectedProject
                                    ? _c("div", { staticClass: "d-flex" }, [
                                        _c("h4", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$t(
                                                  "JobSetupPage.JobListLabel"
                                                )} `
                                              ) +
                                              " "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.siteProjectsSrv
                                                    .selectedProject.title
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "JobSetupPage.SelectProjectLabel"
                                            )
                                          )
                                        ),
                                      ]),
                                  _c(
                                    "span",
                                    { staticClass: "px-1" },
                                    [
                                      _c(
                                        "base-btn",
                                        {
                                          staticStyle: {
                                            padding: "14px 8px !important",
                                            height: "20px",
                                          },
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: _vm.onSelectProjectClick,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "JobSetupPage.SelectProjectBtn"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _vm.jobService.loadingJobs
                                    ? _c("v-progress-linear", {
                                        attrs: { indeterminate: "" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "white height-100x width-100x overflow-auto py-1 pt-2 pb-0",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "table-outer",
                                      on: {
                                        dragover: _vm.allowDrop,
                                        dragenter: _vm.onDragEnter,
                                        dragleave: _vm.onDragLeave,
                                        mouseenter: _vm.onMouseEnter,
                                        drop: _vm.drop,
                                      },
                                    },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "jobs-table" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [
                                                _c(
                                                  "div",
                                                  { staticClass: "width-50px" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "JobSetupPage.TableHeaders.Actions"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.OrderNum"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.JobID"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.EqID"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "width-100px",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "JobSetupPage.TableHeaders.EqName"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.EqClass"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "width-200px",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "JobSetupPage.TableHeaders.EqPath"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("th", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "width-110px",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "JobSetupPage.TableHeaders.JobType"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.JobProgress"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.TypeOfInspection"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.GradeOfInspection"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.FaultLevel"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.ActionType"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.ActionPriority"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.StartDate"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "JobSetupPage.TableHeaders.EndDate"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.jobService.jobs,
                                              function (job, index) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: index,
                                                    staticClass: "job-row",
                                                    class: {
                                                      "job--selected":
                                                        _vm.jobService
                                                          .selectedJob &&
                                                        _vm.jobService
                                                          .selectedJob.id ===
                                                          job.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.jobClick(job)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "text-align":
                                                            "center !important",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mb-1",
                                                          },
                                                          [
                                                            job.edit
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      icon: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.jobEdit(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-checkbox-marked-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      icon: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.jobEdit(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-pencil"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  "x-small": "",
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteJob(
                                                                        `${job.id}`
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    height:
                                                                      "20",
                                                                    width: "25",
                                                                    contain: "",
                                                                    src: require("@/assets/icons/delete-red.png"),
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  "x-small": "",
                                                                  icon: "",
                                                                  outlined: "",
                                                                  disabled:
                                                                    index ===
                                                                    _vm
                                                                      .jobService
                                                                      .jobs
                                                                      .length -
                                                                      1,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.down(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "green--text cursor-pointer",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-chevron-down"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  "x-small": "",
                                                                  icon: "",
                                                                  outlined: "",
                                                                  disabled:
                                                                    index === 0,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.up(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "green--text cursor-pointer",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-chevron-up"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(job.sequence)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(job.id) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            job?.location?.id ??
                                                              ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          overflow: "hidden",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              job?.location
                                                                ?.title ?? ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          job?.location
                                                            ?.equipmentDetail
                                                            ?.equipmentClassification ??
                                                            ""
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(job?.eqPath)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(job?.jobType)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.jobService.getJobProgress(
                                                            job
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          job?.typeOfInspection
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          job?.gradeOfInspection
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(job?.faultLevel)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(job?.actionType)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          job?.actionPriority
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-decoration-none",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "width-140px",
                                                          },
                                                          [
                                                            _c(
                                                              "base-date-picker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    job.edit ==
                                                                    false,
                                                                  format: false,
                                                                  value:
                                                                    _vm
                                                                      .jobService
                                                                      .jobs[
                                                                      index
                                                                    ].startDate,
                                                                },
                                                                on: {
                                                                  "update:value":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm
                                                                          .jobService
                                                                          .jobs[
                                                                          index
                                                                        ],
                                                                        "startDate",
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "width-140px",
                                                        },
                                                        [
                                                          _c(
                                                            "base-date-picker",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  job.edit ==
                                                                  false,
                                                                format: false,
                                                                value:
                                                                  _vm.jobService
                                                                    .jobs[index]
                                                                    .endDate,
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .jobService
                                                                        .jobs[
                                                                        index
                                                                      ],
                                                                      "endDate",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-grow-1 px-3 overflow-hidden relative",
                  style: {
                    height: _vm.parentHeight
                      ? `${_vm.parentHeight - _vm.currentHeight}px`
                      : "auto",
                    "min-height": "150px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "overflow-hidden" },
                    [
                      _vm.jobService.selectedJob &&
                      !_vm.siteLocationsSrv.selectedLcoation
                        ? [
                            _c("JobDetailsComponent", {
                              ref: "jobDetailsComponentRef",
                            }),
                          ]
                        : _vm._e(),
                      !_vm.jobService.selectedJob &&
                      _vm.siteLocationsSrv.selectedLcoation &&
                      _vm.siteLocationsSrv.selectedLcoation?.type ===
                        "equipment"
                        ? [
                            _c("EquipmentDetailsFormComponent", {
                              attrs: {
                                locationPath: _vm.locationPath,
                                readOnlyFlag: true,
                              },
                              on: {
                                goToLocation: _vm.goToLocation,
                                saveEquipmentDetail: _vm.saveEquipmentDetail,
                              },
                            }),
                          ]
                        : _vm._e(),
                      !_vm.jobService.selectedJob &&
                      _vm.siteLocationsSrv.selectedLcoation &&
                      _vm.siteLocationsSrv.selectedLcoation?.type === "location"
                        ? [
                            _c("SiteSetupLocationFormComponent", {
                              attrs: {
                                locationPath: _vm.locationPath,
                                readOnlyFlag: true,
                              },
                              on: {
                                goToLocation: _vm.goToLocation,
                                saveLocationDetail: _vm.saveLocationDetail,
                              },
                            }),
                          ]
                        : _vm._e(),
                      !_vm.jobService.selectedJob &&
                      _vm.siteLocationsSrv.selectedLcoation &&
                      _vm.siteLocationsSrv.selectedLcoation?.type ===
                        "distribution"
                        ? [
                            _c("DistributionPanelFormComponent", {
                              attrs: {
                                locationPath: _vm.locationPath,
                                readOnlyFlag: true,
                              },
                              on: {
                                goToLocation: _vm.goToLocation,
                                saveLocationDetail: _vm.saveLocationDetail,
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("ProjectSelectionDialogComponent", {
        ref: "projectSelectionDialogRef",
        attrs: { siteId: _vm.siteId },
        on: { selected: _vm.projectSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }