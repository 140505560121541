import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {makeShortId, TAG_TYPE} from '@/globals';
import {TagModel} from '@/sdk';
import {TagApi} from '@/sdk/api-services';
import TagDialogComponent from '@/views/shared/tag-dialog/tag-dialog.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    components: {
        TagDialogComponent,
    },
})
export default class TagManagementComponent extends VueWrapper {
    public $refs!: {
        tagDialog: TagDialogComponent;
    };

    @Prop({
        type: String,
        required: true,
    })
    public siteLocationId!: string;

    public showDetailsDialog = false;
    public selectedTag: TagModel | null = null;
    public selectedTagType = TAG_TYPE.QR_CODE;

    public addQrCode: boolean = false;
    public addBarCode: boolean = false;
    public addNfcTag: boolean = false;
    public qrCodeName: string | null = null;
    public qrCodeValue: string | null = null;
    public barcodeName: string | null = null;
    public barcodeValue: string | null = null;
    public nfcTagName: string | null = null;
    public nfcTagValue: string | null = null;
    public qrCodes: Array<TagModel> = [];
    public barCodes: Array<TagModel> = [];
    public nfcTags: Array<TagModel> = [];

    public tagApi: TagApi = new TagApi();

    public mounted(): void {
        this.fetchTagsByLocation();
    }

    public fetchTagsByLocation(): void {
        this.LoaderSrv.showFullScreenLoader();
        this.tagApi
            .getTagsByLocation(this.siteLocationId)
            .then(res => {
                this.qrCodes = res.qrCodeTags;
                this.barCodes = res.barCodeTags;
                this.nfcTags = res.nfcTags;
            })
            .catch(err => {
                this.AlertSrv.show('error', `${err.error.message}`);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public viewQrCode(qrCode: TagModel): void {
        this.selectedTag = qrCode;
        this.selectedTagType = TAG_TYPE.QR_CODE;
        this.showDetailsDialog = true;
    }

    public viewBarCode(barCode: TagModel): void {
        this.selectedTag = barCode;
        this.selectedTagType = TAG_TYPE.BARCODE;
        this.showDetailsDialog = true;
    }

    public onDialogClosed(): void {
        this.selectedTag = null;
        this.showDetailsDialog = false;
    }

    autoGenerateQrCode() {
        this.qrCodeValue = makeShortId(8, this.siteLocationId);
    }

    autoGenerateBarCode() {
        this.barcodeValue = makeShortId(8, this.siteLocationId);
    }

    public createNewQrCode(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.QrCodeConfirmTitle', '$t.Common.TagManagement.QrCodeConfirmMessage').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.tagApi
                    .Create({
                        name: this.qrCodeName!,
                        code: this.qrCodeValue!,
                        locationId: this.siteLocationId,
                        type: 'qr_code',
                    })
                    .then(() => {
                        this.fetchTagsByLocation();
                        this.AlertSrv.show('success', '$t.Common.TagManagement.QrCodeCreatedSuccess');
                        this.resetQrCode();
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', `${err.error.message}`);
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public createNewBarCode(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.BarCodeConfirmTitle', '$t.Common.TagManagement.BarCodeConfirmMessage').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.tagApi
                    .Create({
                        name: this.barcodeName!,
                        code: this.barcodeValue!,
                        locationId: this.siteLocationId,
                        type: 'barcode',
                    })
                    .then(() => {
                        this.fetchTagsByLocation();
                        this.AlertSrv.show('success', '$t.Common.TagManagement.BarCodeCreatedSuccess');
                        this.resetBarCode();
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', `${err.error.message}`);
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public createNewNfcTag(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.NfcTagConfirmTitle', '$t.Common.TagManagement.NfcTagConfirmMessage').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.tagApi
                    .Create({
                        name: this.nfcTagName!,
                        code: this.nfcTagValue!,
                        locationId: this.siteLocationId,
                        type: 'nfc_tag',
                    })
                    .then(() => {
                        this.fetchTagsByLocation();
                        this.AlertSrv.show('success', '$t.Common.TagManagement.NfcTagCreatedSuccess');
                        this.resetNfcTag();
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', `${err.error.message}`);
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public activateTag(tag: TagModel, type: string): void {
        this.LoaderSrv.showFullScreenLoader();
        this.tagApi
            .activateTag(tag.id, this.siteLocationId, type)
            .then(() => {
                this.fetchTagsByLocation();
                this.AlertSrv.show('success', '$t.Common.TagManagement.TagActivatedSuccess');
            })
            .catch(err => {
                this.AlertSrv.show('error', `${err.error.message}`);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public activateQrCode(qrCode: TagModel): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.ActivateQrCodeConfirmTitle', '$t.Common.TagManagement.ActivateQrCodeConfirmMessage').then(yes => {
            if (yes) {
                this.activateTag(qrCode, 'qr_code');
            }
        });
    }

    public activateBarCode(barCode: TagModel): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.ActivateBarCodeConfirmTitle', '$t.Common.TagManagement.ActivateBarCodeConfirmMessage').then(yes => {
            if (yes) {
                this.activateTag(barCode, 'barcode');
            }
        });
    }

    public activateNfcTag(nfcTag: TagModel): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.ActivateNfcTagConfirmTitle', '$t.Common.TagManagement.ActivateNfcTagConfirmMessage').then(yes => {
            if (yes) {
                this.activateTag(nfcTag, 'nfc_tag');
            }
        });
    }

    public resetQrCode(): void {
        this.qrCodeName = null;
        this.qrCodeValue = null;
        this.addQrCode = false;
    }

    public resetBarCode(): void {
        this.barcodeName = null;
        this.barcodeValue = null;
        this.addBarCode = false;
    }

    public resetNfcTag(): void {
        this.nfcTagName = null;
        this.nfcTagValue = null;
        this.addNfcTag = false;
    }

    public cancelQrCode(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.CancelQrCodeConfirmTitle', '$t.Common.TagManagement.CancelQrCodeConfirmMessage').then(yes => {
            if (yes) {
                this.resetQrCode();
            }
        });
    }

    public cancelBarCode(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.CancelBarCodeConfirmTitle', '$t.Common.TagManagement.CancelBarCodeConfirmMessage').then(yes => {
            if (yes) {
                this.resetBarCode();
            }
        });
    }

    public cancelNfcTag(): void {
        this.ConfirmSrv.open('$t.Common.TagManagement.CancelNfcTagConfirmTitle', '$t.Common.TagManagement.CancelNfcTagConfirmMessage').then(yes => {
            if (yes) {
                this.resetNfcTag();
            }
        });
    }
}
