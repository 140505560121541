var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    ref: "validationRef",
    attrs: { rules: _vm.rules, "custom-messages": _vm.customErrors },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _c(
                "div",
                {
                  staticClass: "base-file-picker",
                  class: { "picker-has-errors": errors.length > 0 },
                  style: { height: _vm.height, width: _vm.width },
                  on: { click: _vm.pickerClick },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column height-100x" }, [
                    _c(
                      "div",
                      { staticClass: "base-file-picker-btn height-100x" },
                      [
                        _c("div", { staticClass: "base-file-picker-overlay" }),
                        _c("input", {
                          ref: "inputField",
                          staticClass: "base-file-picker-input",
                          attrs: {
                            type: "file",
                            multiple:
                              _vm.$attrs["multiple"] === true ? true : false,
                            accept: _vm.accept,
                          },
                          on: { change: _vm.filesSelected },
                        }),
                        _c("span", { staticClass: "base-file-picker-title" }, [
                          _vm._v(" " + _vm._s(_vm.text) + " "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "base-file-picker-errors" },
                      [_vm._t("default", null, { errors: errors })],
                      2
                    ),
                  ]),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }