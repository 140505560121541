var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobsSrv.selectedJob
    ? _c(
        "div",
        { staticClass: "px-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Job ID",
                      text: _vm.jobsSrv.selectedJob.id,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Equipment ID",
                      text: _vm.jobsSrv.selectedJob.location.id,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Equipment Name",
                      text: _vm.jobsSrv.selectedJob.location.title,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Equipment Class",
                      text: _vm.adminDataFieldSrv.getEquipmentFieldOptionValue(
                        "equipmentEquipmentClassification",
                        _vm.jobsSrv.selectedJob.location.equipmentDetail
                          .equipmentClassification
                      ),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Area Ex Information (Gas Area/Dust Area)",
                      text: _vm.jobsSrv.selectedJob.areaExInformation,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Eq Ex Code",
                      text: _vm.jobsSrv.selectedJob.eqExCode,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Job Type",
                      text: _vm.jobsSrv.selectedJob.jobType,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("base-title-text", {
                    attrs: {
                      title: "Start Date",
                      text: _vm.jobsSrv.getJobProgress(_vm.jobsSrv.selectedJob),
                    },
                  }),
                ],
                1
              ),
              _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                ? _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-title-text", {
                        attrs: {
                          title: "Type of Inspection",
                          text: _vm.jobsSrv.selectedJob.typeOfInspection,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.jobsSrv.selectedJob.jobType === "Ex Inspection"
                ? _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-title-text", {
                        attrs: {
                          title: "Grade of Inspection",
                          text: _vm.jobsSrv.selectedJob.gradeOfInspection,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.jobsSrv.selectedJob.jobType === "Corrective Action"
                ? _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("base-title-text", {
                        attrs: {
                          title: "Action Type",
                          text: _vm.jobsSrv.selectedJob.actionType,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-title-text", {
                            attrs: {
                              title: "Start Date",
                              text: _vm.$helpers.formatDate(
                                _vm.jobsSrv.selectedJob.startDate,
                                "yyyy-MM-dd"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("base-title-text", {
                            attrs: {
                              title: "End Date",
                              text: _vm.$helpers.formatDate(
                                _vm.jobsSrv.selectedJob.endDate,
                                "yyyy-MM-dd"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }