import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {DocumentationModel} from '@/sdk';
import {DocumentationApi} from '@/sdk/api-services';
import PreviewDocumentDialogComponent from '@/views/shared/preview-document-dialog/preview-document-dialog.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    components: {
        PreviewDocumentDialogComponent,
    },
})
export default class AreaDocumentationComponent extends VueWrapper {
    public $refs!: {
        previewDialog: PreviewDocumentDialogComponent;
    };

    @Prop({
        type: String,
        required: true,
    })
    public siteLocationId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public disabled!: boolean;

    documents: Array<DocumentationModel> = [];

    public addDocument: boolean = false;
    public documentTypes: Array<string> = ['Construction Drawing', 'Electrical Drawing', 'Process Drawing', 'Zone Map', 'ETC', 'Area Photo'];
    public selectedType: string | null = null;

    public file: File | null = null;

    public documentationApi: DocumentationApi = new DocumentationApi();

    public accept: string =
        `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/png,image/jpeg,application/pdf,` +
        `application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`;

    public mounted() {
        this.getDocuments();
    }

    public filesSelected(event: any) {
        if (event.length > 0) {
            this.file = event.item(0);
        }
    }

    public getDocuments() {
        this.LoaderSrv.showFullScreenLoader();
        this.documentationApi
            .GetDocuments(this.siteLocationId)
            .subscribe(
                res => {
                    this.documents = res.Data ?? [];
                },
                err => {
                    //
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public upload() {
        this.LoaderSrv.showFullScreenLoader();
        this.documentationApi
            .AddDocument(this.siteLocationId, this.file!, this.selectedType!)
            .subscribe(
                res => {
                    this.getDocuments();
                    this.AlertSrv.show('success', '$t.Common.UploadDocuments.UploadSuccess');
                    this.reset();
                },
                error => {
                    this.AlertSrv.show('error', '$t.Common.UploadDocuments.UploadError');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public reset() {
        this.file = null;
        this.selectedType = null;
        this.addDocument = false;
    }

    public view(document: DocumentationModel) {
        this.$refs.previewDialog.open(document);
    }

    public deleteDoc(documentation: DocumentationModel) {
        this.ConfirmSrv.open(
            '$t.Common.UploadDocuments.DeleteConfirmTitle',
            this.$t('UploadDocuments.DeleteConfirmMessage', {type: documentation.type}) as string
        ).then(y => {
            if (y) {
                this.LoaderSrv.showFullScreenLoader();
                new DocumentationApi()
                    .delete(documentation.id!)
                    .subscribe(
                        res => {
                            this.AlertSrv.show('success', `$t.Common.UploadDocuments.DeleteSuccess`);
                            this.getDocuments();
                        },
                        err => {
                            this.AlertSrv.show('error', `$t.Common.UploadDocuments.DeleteError`);
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }
}
