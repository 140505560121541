import { render, staticRenderFns } from "./job-report-area-browser.component.vue?vue&type=template&id=0a25621e"
import script from "./job-report-area-browser.component.ts?vue&type=script&lang=ts&external"
export * from "./job-report-area-browser.component.ts?vue&type=script&lang=ts&external"
import style0 from "./job-report-area-browser.component.vue?vue&type=style&index=0&id=0a25621e&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/foex-3r/foex-3r-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a25621e')) {
      api.createRecord('0a25621e', component.options)
    } else {
      api.reload('0a25621e', component.options)
    }
    module.hot.accept("./job-report-area-browser.component.vue?vue&type=template&id=0a25621e", function () {
      api.rerender('0a25621e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/job-report/job-report-area-browser/job-report-area-browser.component.vue"
export default component.exports