import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class PhoneNumberInputComponent extends VueWrapper {
    @Prop({default: ''}) value!: string;
    public countryCode = '';
    public search = '';
    public numberTyped = '';

    get filteredCountries() {
        return this.countries.filter(country => country.name.toLowerCase().includes(this.search.toLowerCase()));
    }

    get selectedMask() {
        const selectedCountry = this.countries.find(country => country.dialCode === this.countryCode);
        return selectedCountry ? selectedCountry.mask : '';
    }

    @Watch('value', {immediate: true})
    onValueChange(newValue: string) {
        const country = this.countries.find(c => newValue.startsWith(c.dialCode));
        if (country) {
            this.countryCode = country.dialCode;
            this.numberTyped = newValue.replace(country.dialCode, '').trim();
        } else {
            this.countryCode = '';
            this.numberTyped = newValue;
        }
    }

    onCountryChange(countryCode: string) {
        this.countryCode = countryCode;
        this.emitPhoneNumber();
    }

    emitPhoneNumber() {
        this.$emit('update:value', `${this.countryCode} ${this.numberTyped}`.trim());
    }

    public countries: {name: string; code: string; dialCode: string; icon: string; mask: string}[] = [
        {name: 'Afghanistan', code: 'AF', dialCode: '+93', icon: 'https://flagcdn.com/af.svg', mask: '### ### ####'},
        {name: 'Albania', code: 'AL', dialCode: '+355', icon: 'https://flagcdn.com/al.svg', mask: '## ### ####'},
        {name: 'Algeria', code: 'DZ', dialCode: '+213', icon: 'https://flagcdn.com/dz.svg', mask: '### ## ## ##'},
        {name: 'Andorra', code: 'AD', dialCode: '+376', icon: 'https://flagcdn.com/ad.svg', mask: '### ###'},
        {name: 'Angola', code: 'AO', dialCode: '+244', icon: 'https://flagcdn.com/ao.svg', mask: '### ### ###'},
        {name: 'Antigua and Barbuda', code: 'AG', dialCode: '+1-268', icon: 'https://flagcdn.com/ag.svg', mask: '### ####'},
        {name: 'Argentina', code: 'AR', dialCode: '+54', icon: 'https://flagcdn.com/ar.svg', mask: '## #### ####'},
        {name: 'Armenia', code: 'AM', dialCode: '+374', icon: 'https://flagcdn.com/am.svg', mask: '## ### ###'},
        {name: 'Australia', code: 'AU', dialCode: '+61', icon: 'https://flagcdn.com/au.svg', mask: '### ### ###'},
        {name: 'Austria', code: 'AT', dialCode: '+43', icon: 'https://flagcdn.com/at.svg', mask: '### ### ####'},
        {name: 'Azerbaijan', code: 'AZ', dialCode: '+994', icon: 'https://flagcdn.com/az.svg', mask: '## ### ## ##'},
        {name: 'Bahamas', code: 'BS', dialCode: '+1-242', icon: 'https://flagcdn.com/bs.svg', mask: '### ####'},
        {name: 'Bahrain', code: 'BH', dialCode: '+973', icon: 'https://flagcdn.com/bh.svg', mask: '#### ####'},
        {name: 'Bangladesh', code: 'BD', dialCode: '+880', icon: 'https://flagcdn.com/bd.svg', mask: '#### ######'},
        {name: 'Barbados', code: 'BB', dialCode: '+1-246', icon: 'https://flagcdn.com/bb.svg', mask: '### ####'},
        {name: 'Belarus', code: 'BY', dialCode: '+375', icon: 'https://flagcdn.com/by.svg', mask: '## ### ## ##'},
        {name: 'Belgium', code: 'BE', dialCode: '+32', icon: 'https://flagcdn.com/be.svg', mask: '### ## ## ##'},
        {name: 'Belize', code: 'BZ', dialCode: '+501', icon: 'https://flagcdn.com/bz.svg', mask: '### ####'},
        {name: 'Benin', code: 'BJ', dialCode: '+229', icon: 'https://flagcdn.com/bj.svg', mask: '## ## ## ##'},
        {name: 'Bhutan', code: 'BT', dialCode: '+975', icon: 'https://flagcdn.com/bt.svg', mask: '## ### ###'},
        {name: 'Bolivia', code: 'BO', dialCode: '+591', icon: 'https://flagcdn.com/bo.svg', mask: '#### ####'},
        {name: 'Bosnia and Herzegovina', code: 'BA', dialCode: '+387', icon: 'https://flagcdn.com/ba.svg', mask: '## ### ###'},
        {name: 'Botswana', code: 'BW', dialCode: '+267', icon: 'https://flagcdn.com/bw.svg', mask: '## ### ###'},
        {name: 'Brazil', code: 'BR', dialCode: '+55', icon: 'https://flagcdn.com/br.svg', mask: '## ##### ####'},
        {name: 'Brunei', code: 'BN', dialCode: '+673', icon: 'https://flagcdn.com/bn.svg', mask: '### ####'},
        {name: 'Bulgaria', code: 'BG', dialCode: '+359', icon: 'https://flagcdn.com/bg.svg', mask: '### ### ###'},
        {name: 'Burkina Faso', code: 'BF', dialCode: '+226', icon: 'https://flagcdn.com/bf.svg', mask: '## ## ## ##'},
        {name: 'Burundi', code: 'BI', dialCode: '+257', icon: 'https://flagcdn.com/bi.svg', mask: '## ## ####'},
        {name: 'Cambodia', code: 'KH', dialCode: '+855', icon: 'https://flagcdn.com/kh.svg', mask: '## ### ###'},
        {name: 'Cameroon', code: 'CM', dialCode: '+237', icon: 'https://flagcdn.com/cm.svg', mask: '#### ####'},
        {name: 'Canada', code: 'CA', dialCode: '+1', icon: 'https://flagcdn.com/ca.svg', mask: '### ### ####'},
        {name: 'Cape Verde', code: 'CV', dialCode: '+238', icon: 'https://flagcdn.com/cv.svg', mask: '### ## ##'},
        {name: 'Central African Republic', code: 'CF', dialCode: '+236', icon: 'https://flagcdn.com/cf.svg', mask: '## ## ## ##'},
        {name: 'Chad', code: 'TD', dialCode: '+235', icon: 'https://flagcdn.com/td.svg', mask: '## ## ## ##'},
        {name: 'Chile', code: 'CL', dialCode: '+56', icon: 'https://flagcdn.com/cl.svg', mask: '## #### ####'},
        {name: 'China', code: 'CN', dialCode: '+86', icon: 'https://flagcdn.com/cn.svg', mask: '### #### ####'},
        {name: 'Colombia', code: 'CO', dialCode: '+57', icon: 'https://flagcdn.com/co.svg', mask: '### ### ####'},
        {name: 'Comoros', code: 'KM', dialCode: '+269', icon: 'https://flagcdn.com/km.svg', mask: '## ## ####'},
        {name: 'Congo', code: 'CG', dialCode: '+242', icon: 'https://flagcdn.com/cg.svg', mask: '## ### ####'},
        {name: 'Congo (DRC)', code: 'CD', dialCode: '+243', icon: 'https://flagcdn.com/cd.svg', mask: '### ### ####'},
        {name: 'Costa Rica', code: 'CR', dialCode: '+506', icon: 'https://flagcdn.com/cr.svg', mask: '#### ####'},
        {name: 'Croatia', code: 'HR', dialCode: '+385', icon: 'https://flagcdn.com/hr.svg', mask: '## ### ###'},
        {name: 'Cuba', code: 'CU', dialCode: '+53', icon: 'https://flagcdn.com/cu.svg', mask: '### ### ####'},
        {name: 'Cyprus', code: 'CY', dialCode: '+357', icon: 'https://flagcdn.com/cy.svg', mask: '## ### ###'},
        {name: 'Czech Republic', code: 'CZ', dialCode: '+420', icon: 'https://flagcdn.com/cz.svg', mask: '### ### ###'},
        {name: 'Denmark', code: 'DK', dialCode: '+45', icon: 'https://flagcdn.com/dk.svg', mask: '## ## ## ##'},
        {name: 'Djibouti', code: 'DJ', dialCode: '+253', icon: 'https://flagcdn.com/dj.svg', mask: '## ## ## ##'},
        {name: 'Dominica', code: 'DM', dialCode: '+1-767', icon: 'https://flagcdn.com/dm.svg', mask: '### ####'},
        {name: 'Dominican Republic', code: 'DO', dialCode: '+1-809', icon: 'https://flagcdn.com/do.svg', mask: '### ### ####'},
        {name: 'Ecuador', code: 'EC', dialCode: '+593', icon: 'https://flagcdn.com/ec.svg', mask: '## ### ####'},
        {name: 'Egypt', code: 'EG', dialCode: '+20', icon: 'https://flagcdn.com/eg.svg', mask: '### ### ####'},
        {name: 'El Salvador', code: 'SV', dialCode: '+503', icon: 'https://flagcdn.com/sv.svg', mask: '#### ####'},
        {name: 'Equatorial Guinea', code: 'GQ', dialCode: '+240', icon: 'https://flagcdn.com/gq.svg', mask: '## ## ####'},
        {name: 'Eritrea', code: 'ER', dialCode: '+291', icon: 'https://flagcdn.com/er.svg', mask: '## ### ###'},
        {name: 'Estonia', code: 'EE', dialCode: '+372', icon: 'https://flagcdn.com/ee.svg', mask: '### ####'},
        {name: 'Eswatini', code: 'SZ', dialCode: '+268', icon: 'https://flagcdn.com/sz.svg', mask: '## ## ####'},
        {name: 'Ethiopia', code: 'ET', dialCode: '+251', icon: 'https://flagcdn.com/et.svg', mask: '## ### ####'},
        {name: 'Fiji', code: 'FJ', dialCode: '+679', icon: 'https://flagcdn.com/fj.svg', mask: '## #####'},
        {name: 'Finland', code: 'FI', dialCode: '+358', icon: 'https://flagcdn.com/fi.svg', mask: '## ### ####'},
        {name: 'France', code: 'FR', dialCode: '+33', icon: 'https://flagcdn.com/fr.svg', mask: '## ## ## ## ##'},
        {name: 'Gabon', code: 'GA', dialCode: '+241', icon: 'https://flagcdn.com/ga.svg', mask: '## ## ## ##'},
        {name: 'Gambia', code: 'GM', dialCode: '+220', icon: 'https://flagcdn.com/gm.svg', mask: '## ## ####'},
        {name: 'Georgia', code: 'GE', dialCode: '+995', icon: 'https://flagcdn.com/ge.svg', mask: '### ### ###'},
        {name: 'Germany', code: 'DE', dialCode: '+49', icon: 'https://flagcdn.com/de.svg', mask: '### ### ####'},
        {name: 'Ghana', code: 'GH', dialCode: '+233', icon: 'https://flagcdn.com/w40/gh.png', mask: '### ### ####'},
        {name: 'Greece', code: 'GR', dialCode: '+30', icon: 'https://flagcdn.com/w40/gr.png', mask: '### ### ####'},
        {name: 'Grenada', code: 'GD', dialCode: '+1473', icon: 'https://flagcdn.com/w40/gd.png', mask: '### ### ####'},
        {name: 'Guatemala', code: 'GT', dialCode: '+502', icon: 'https://flagcdn.com/w40/gt.png', mask: '#### ####'},
        {name: 'Guinea', code: 'GN', dialCode: '+224', icon: 'https://flagcdn.com/w40/gn.png', mask: '### ### ####'},
        {name: 'Guinea-Bissau', code: 'GW', dialCode: '+245', icon: 'https://flagcdn.com/w40/gw.png', mask: '### ### ####'},
        {name: 'Guyana', code: 'GY', dialCode: '+592', icon: 'https://flagcdn.com/w40/gy.png', mask: '### ### ####'},
        {name: 'Haiti', code: 'HT', dialCode: '+509', icon: 'https://flagcdn.com/w40/ht.png', mask: '### ### ####'},
        {name: 'Honduras', code: 'HN', dialCode: '+504', icon: 'https://flagcdn.com/w40/hn.png', mask: '#### ####'},
        {name: 'Hungary', code: 'HU', dialCode: '+36', icon: 'https://flagcdn.com/w40/hu.png', mask: '### ### ####'},
        {name: 'Iceland', code: 'IS', dialCode: '+354', icon: 'https://flagcdn.com/w40/is.png', mask: '### ### ####'},
        {name: 'India', code: 'IN', dialCode: '+91', icon: 'https://flagcdn.com/w40/in.png', mask: '##### #####'},
        {name: 'Indonesia', code: 'ID', dialCode: '+62', icon: 'https://flagcdn.com/w40/id.png', mask: '### ### ####'},
        {name: 'Iran', code: 'IR', dialCode: '+98', icon: 'https://flagcdn.com/w40/ir.png', mask: '### ### ####'},
        {name: 'Iraq', code: 'IQ', dialCode: '+964', icon: 'https://flagcdn.com/w40/iq.png', mask: '### ### ####'},
        {name: 'Ireland', code: 'IE', dialCode: '+353', icon: 'https://flagcdn.com/w40/ie.png', mask: '### ### ####'},
        {name: 'Israel', code: 'IL', dialCode: '+972', icon: 'https://flagcdn.com/w40/il.png', mask: '### ### ####'},
        {name: 'Italy', code: 'IT', dialCode: '+39', icon: 'https://flagcdn.com/w40/it.png', mask: '### ### ####'},
        {name: 'Jamaica', code: 'JM', dialCode: '+1876', icon: 'https://flagcdn.com/w40/jm.png', mask: '### ### ####'},
        {name: 'Japan', code: 'JP', dialCode: '+81', icon: 'https://flagcdn.com/w40/jp.png', mask: '### ### ####'},
        {name: 'Jordan', code: 'JO', dialCode: '+962', icon: 'https://flagcdn.com/w40/jo.png', mask: '### ### ####'},
        {name: 'Kazakhstan', code: 'KZ', dialCode: '+7', icon: 'https://flagcdn.com/w40/kz.png', mask: '### ### ####'},
        {name: 'Kenya', code: 'KE', dialCode: '+254', icon: 'https://flagcdn.com/w40/ke.png', mask: '### ### ####'},
        {name: 'Kiribati', code: 'KI', dialCode: '+686', icon: 'https://flagcdn.com/w40/ki.png', mask: '### ### ####'},
        {name: 'Kuwait', code: 'KW', dialCode: '+965', icon: 'https://flagcdn.com/w40/kw.png', mask: '### ### ####'},
        {name: 'Kyrgyzstan', code: 'KG', dialCode: '+996', icon: 'https://flagcdn.com/w40/kg.png', mask: '### ### ####'},
        {name: 'Laos', code: 'LA', dialCode: '+856', icon: 'https://flagcdn.com/w40/la.png', mask: '### ### ####'},
        {name: 'Latvia', code: 'LV', dialCode: '+371', icon: 'https://flagcdn.com/w40/lv.png', mask: '### ### ####'},
        {name: 'Lebanon', code: 'LB', dialCode: '+961', icon: 'https://flagcdn.com/w40/lb.png', mask: '### ### ####'},
        {name: 'Lesotho', code: 'LS', dialCode: '+266', icon: 'https://flagcdn.com/w40/ls.png', mask: '### ### ####'},
        {name: 'Liberia', code: 'LR', dialCode: '+231', icon: 'https://flagcdn.com/w40/lr.png', mask: '### ### ####'},
        {name: 'Libya', code: 'LY', dialCode: '+218', icon: 'https://flagcdn.com/w40/ly.png', mask: '### ### ####'},
        {name: 'Liechtenstein', code: 'LI', dialCode: '+423', icon: 'https://flagcdn.com/w40/li.png', mask: '### ### ####'},
        {name: 'Lithuania', code: 'LT', dialCode: '+370', icon: 'https://flagcdn.com/w40/lt.png', mask: '### ### ####'},
        {name: 'Luxembourg', code: 'LU', dialCode: '+352', icon: 'https://flagcdn.com/w40/lu.png', mask: '### ### ####'},
        {name: 'Madagascar', code: 'MG', dialCode: '+261', icon: 'https://flagcdn.com/w40/mg.png', mask: '### ### ####'},
        {name: 'Malawi', code: 'MW', dialCode: '+265', icon: 'https://flagcdn.com/w40/mw.png', mask: '### ### ####'},
        {name: 'Malaysia', code: 'MY', dialCode: '+60', icon: 'https://flagcdn.com/w40/my.png', mask: '### ### ####'},
        {name: 'Maldives', code: 'MV', dialCode: '+960', icon: 'https://flagcdn.com/w40/mv.png', mask: '### ### ####'},
        {name: 'Mali', code: 'ML', dialCode: '+223', icon: 'https://flagcdn.com/w40/ml.png', mask: '### ### ####'},
        {name: 'Malta', code: 'MT', dialCode: '+356', icon: 'https://flagcdn.com/w40/mt.png', mask: '### ### ####'},
        {name: 'Marshall Islands', code: 'MH', dialCode: '+692', icon: 'https://flagcdn.com/w40/mh.png', mask: '### ### ####'},
        {name: 'Mauritania', code: 'MR', dialCode: '+222', icon: 'https://flagcdn.com/w40/mr.png', mask: '### ### ####'},
        {name: 'Mauritius', code: 'MU', dialCode: '+230', icon: 'https://flagcdn.com/w40/mu.png', mask: '### ### ####'},
        {name: 'Mexico', code: 'MX', dialCode: '+52', icon: 'https://flagcdn.com/w40/mx.png', mask: '### ### ####'},
        {name: 'Micronesia', code: 'FM', dialCode: '+691', icon: 'https://flagcdn.com/w40/fm.png', mask: '### ### ####'},
        {name: 'Moldova', code: 'MD', dialCode: '+373', icon: 'https://flagcdn.com/w40/md.png', mask: '### ### ####'},
        {name: 'Monaco', code: 'MC', dialCode: '+377', icon: 'https://flagcdn.com/w40/mc.png', mask: '### ### ####'},
        {name: 'Mongolia', code: 'MN', dialCode: '+976', icon: 'https://flagcdn.com/w40/mn.png', mask: '### ### ####'},
        {name: 'Montenegro', code: 'ME', dialCode: '+382', icon: 'https://flagcdn.com/w40/me.png', mask: '## ### ####'},
        {name: 'Morocco', code: 'MA', dialCode: '+212', icon: 'https://flagcdn.com/w40/ma.png', mask: '## #### ####'},
        {name: 'Mozambique', code: 'MZ', dialCode: '+258', icon: 'https://flagcdn.com/w40/mz.png', mask: '## ### ####'},
        {name: 'Myanmar', code: 'MM', dialCode: '+95', icon: 'https://flagcdn.com/w40/mm.png', mask: '## ### ###'},
        {name: 'Namibia', code: 'NA', dialCode: '+264', icon: 'https://flagcdn.com/w40/na.png', mask: '## ### ####'},
        {name: 'Nauru', code: 'NR', dialCode: '+674', icon: 'https://flagcdn.com/w40/nr.png', mask: '### ###'},
        {name: 'Nepal', code: 'NP', dialCode: '+977', icon: 'https://flagcdn.com/w40/np.png', mask: '## ### ####'},
        {name: 'Netherlands', code: 'NL', dialCode: '+31', icon: 'https://flagcdn.com/w40/nl.png', mask: '## ### ####'},
        {name: 'New Zealand', code: 'NZ', dialCode: '+64', icon: 'https://flagcdn.com/w40/nz.png', mask: '## ### ####'},
        {name: 'Nicaragua', code: 'NI', dialCode: '+505', icon: 'https://flagcdn.com/w40/ni.png', mask: '#### ####'},
        {name: 'Niger', code: 'NE', dialCode: '+227', icon: 'https://flagcdn.com/w40/ne.png', mask: '## ## ####'},
        {name: 'Nigeria', code: 'NG', dialCode: '+234', icon: 'https://flagcdn.com/w40/ng.png', mask: '## ### ####'},
        {name: 'North Korea', code: 'KP', dialCode: '+850', icon: 'https://flagcdn.com/w40/kp.png', mask: '### ### ####'},
        {name: 'North Macedonia', code: 'MK', dialCode: '+389', icon: 'https://flagcdn.com/w40/mk.png', mask: '## ### ####'},
        {name: 'Norway', code: 'NO', dialCode: '+47', icon: 'https://flagcdn.com/w40/no.png', mask: '### ## ###'},
        {name: 'Oman', code: 'OM', dialCode: '+968', icon: 'https://flagcdn.com/w40/om.png', mask: '### ### ###'},
        {name: 'Pakistan', code: 'PK', dialCode: '+92', icon: 'https://flagcdn.com/w40/pk.png', mask: '### ### ####'},
        {name: 'Palau', code: 'PW', dialCode: '+680', icon: 'https://flagcdn.com/w40/pw.png', mask: '### ####'},
        {name: 'Palestine', code: 'PS', dialCode: '+970', icon: 'https://flagcdn.com/w40/ps.png', mask: '### ### ####'},
        {name: 'Panama', code: 'PA', dialCode: '+507', icon: 'https://flagcdn.com/w40/pa.png', mask: '### ### ####'},
        {name: 'Papua New Guinea', code: 'PG', dialCode: '+675', icon: 'https://flagcdn.com/w40/pg.png', mask: '### ### ####'},
        {name: 'Paraguay', code: 'PY', dialCode: '+595', icon: 'https://flagcdn.com/w40/py.png', mask: '### ### ###'},
        {name: 'Peru', code: 'PE', dialCode: '+51', icon: 'https://flagcdn.com/w40/pe.png', mask: '### ### ###'},
        {name: 'Philippines', code: 'PH', dialCode: '+63', icon: 'https://flagcdn.com/w40/ph.png', mask: '### ### ####'},
        {name: 'Poland', code: 'PL', dialCode: '+48', icon: 'https://flagcdn.com/w40/pl.png', mask: '### ### ###'},
        {name: 'Portugal', code: 'PT', dialCode: '+351', icon: 'https://flagcdn.com/w40/pt.png', mask: '### ### ###'},
        {name: 'Qatar', code: 'QA', dialCode: '+974', icon: 'https://flagcdn.com/w40/qa.png', mask: '### ### ###'},
        {name: 'Romania', code: 'RO', dialCode: '+40', icon: 'https://flagcdn.com/w40/ro.png', mask: '### ### ###'},
        {name: 'Russia', code: 'RU', dialCode: '+7', icon: 'https://flagcdn.com/w40/ru.png', mask: '### ### ####'},
        {name: 'Rwanda', code: 'RW', dialCode: '+250', icon: 'https://flagcdn.com/w40/rw.png', mask: '### ### ###'},
        {name: 'Saint Kitts and Nevis', code: 'KN', dialCode: '+1869', icon: 'https://flagcdn.com/w40/kn.png', mask: '### ####'},
        {name: 'Saint Lucia', code: 'LC', dialCode: '+1758', icon: 'https://flagcdn.com/w40/lc.png', mask: '### ####'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC', dialCode: '+1784', icon: 'https://flagcdn.com/w40/vc.png', mask: '### ####'},
        {name: 'Samoa', code: 'WS', dialCode: '+685', icon: 'https://flagcdn.com/w40/ws.png', mask: '## ####'},
        {name: 'San Marino', code: 'SM', dialCode: '+378', icon: 'https://flagcdn.com/w40/sm.png', mask: '### ### ###'},
        {name: 'Sao Tome and Principe', code: 'ST', dialCode: '+239', icon: 'https://flagcdn.com/w40/st.png', mask: '## ####'},
        {name: 'Saudi Arabia', code: 'SA', dialCode: '+966', icon: 'https://flagcdn.com/w40/sa.png', mask: '### ### ####'},
        {name: 'Senegal', code: 'SN', dialCode: '+221', icon: 'https://flagcdn.com/w40/sn.png', mask: '## ### ####'},
        {name: 'Serbia', code: 'RS', dialCode: '+381', icon: 'https://flagcdn.com/w40/rs.png', mask: '### ### ####'},
        {name: 'Seychelles', code: 'SC', dialCode: '+248', icon: 'https://flagcdn.com/w40/sc.png', mask: '### ###'},
        {name: 'Sierra Leone', code: 'SL', dialCode: '+232', icon: 'https://flagcdn.com/w40/sl.png', mask: '## ######'},
        {name: 'Singapore', code: 'SG', dialCode: '+65', icon: 'https://flagcdn.com/w40/sg.png', mask: '#### ####'},
        {name: 'Slovakia', code: 'SK', dialCode: '+421', icon: 'https://flagcdn.com/w40/sk.png', mask: '### ### ###'},
        {name: 'Slovenia', code: 'SI', dialCode: '+386', icon: 'https://flagcdn.com/w40/si.png', mask: '### ### ###'},
        {name: 'Solomon Islands', code: 'SB', dialCode: '+677', icon: 'https://flagcdn.com/w40/sb.png', mask: '### ###'},
        {name: 'Somalia', code: 'SO', dialCode: '+252', icon: 'https://flagcdn.com/w40/so.png', mask: '### ### ###'},
        {name: 'South Africa', code: 'ZA', dialCode: '+27', icon: 'https://flagcdn.com/w40/za.png', mask: '## ### ####'},
        {name: 'South Korea', code: 'KR', dialCode: '+82', icon: 'https://flagcdn.com/w40/kr.png', mask: '## ### ####'},
        {name: 'South Sudan', code: 'SS', dialCode: '+211', icon: 'https://flagcdn.com/w40/ss.png', mask: '### ### ####'},
        {name: 'Spain', code: 'ES', dialCode: '+34', icon: 'https://flagcdn.com/w40/es.png', mask: '### ### ###'},
        {name: 'Sri Lanka', code: 'LK', dialCode: '+94', icon: 'https://flagcdn.com/w40/lk.png', mask: '### ### ####'},
        {name: 'Sudan', code: 'SD', dialCode: '+249', icon: 'https://flagcdn.com/sd.svg', mask: '### ### ####'},
        {name: 'Suriname', code: 'SR', dialCode: '+597', icon: 'https://flagcdn.com/sr.svg', mask: '### ### ####'},
        {name: 'Sweden', code: 'SE', dialCode: '+46', icon: 'https://flagcdn.com/se.svg', mask: '### ### ####'},
        {name: 'Switzerland', code: 'CH', dialCode: '+41', icon: 'https://flagcdn.com/ch.svg', mask: '### ### ####'},
        {name: 'Syria', code: 'SY', dialCode: '+963', icon: 'https://flagcdn.com/sy.svg', mask: '### ### ####'},
        {name: 'Taiwan', code: 'TW', dialCode: '+886', icon: 'https://flagcdn.com/tw.svg', mask: '### ### ####'},
        {name: 'Tajikistan', code: 'TJ', dialCode: '+992', icon: 'https://flagcdn.com/tj.svg', mask: '### ### ####'},
        {name: 'Tanzania', code: 'TZ', dialCode: '+255', icon: 'https://flagcdn.com/tz.svg', mask: '### ### ####'},
        {name: 'Thailand', code: 'TH', dialCode: '+66', icon: 'https://flagcdn.com/th.svg', mask: '### ### ####'},
        {name: 'Timor-Leste', code: 'TL', dialCode: '+670', icon: 'https://flagcdn.com/tl.svg', mask: '### ### ####'},
        {name: 'Togo', code: 'TG', dialCode: '+228', icon: 'https://flagcdn.com/tg.svg', mask: '### ### ####'},
        {name: 'Tonga', code: 'TO', dialCode: '+676', icon: 'https://flagcdn.com/to.svg', mask: '### ### ####'},
        {name: 'Trinidad and Tobago', code: 'TT', dialCode: '+1-868', icon: 'https://flagcdn.com/tt.svg', mask: '### ### ####'},
        {name: 'Tunisia', code: 'TN', dialCode: '+216', icon: 'https://flagcdn.com/tn.svg', mask: '### ### ####'},
        {name: 'Turkey', code: 'TR', dialCode: '+90', icon: 'https://flagcdn.com/tr.svg', mask: '### ### ####'},
        {name: 'Turkmenistan', code: 'TM', dialCode: '+993', icon: 'https://flagcdn.com/tm.svg', mask: '### ### ####'},
        {name: 'Tuvalu', code: 'TV', dialCode: '+688', icon: 'https://flagcdn.com/tv.svg', mask: '### ### ####'},
        {name: 'Uganda', code: 'UG', dialCode: '+256', icon: 'https://flagcdn.com/ug.svg', mask: '### ### ####'},
        {name: 'Ukraine', code: 'UA', dialCode: '+380', icon: 'https://flagcdn.com/ua.svg', mask: '### ### ####'},
        {name: 'United Arab Emirates', code: 'AE', dialCode: '+971', icon: 'https://flagcdn.com/ae.svg', mask: '### ### ####'},
        {name: 'United Kingdom', code: 'GB', dialCode: '+44', icon: 'https://flagcdn.com/gb.svg', mask: '### ### ####'},
        {name: 'United States', code: 'US', dialCode: '+1', icon: 'https://flagcdn.com/us.svg', mask: '### ### ####'},
        {name: 'Uruguay', code: 'UY', dialCode: '+598', icon: 'https://flagcdn.com/uy.svg', mask: '### ### ####'},
        {name: 'Uzbekistan', code: 'UZ', dialCode: '+998', icon: 'https://flagcdn.com/uz.svg', mask: '### ### ####'},
        {name: 'Vanuatu', code: 'VU', dialCode: '+678', icon: 'https://flagcdn.com/vu.svg', mask: '### ### ####'},
        {name: 'Vatican City', code: 'VA', dialCode: '+379', icon: 'https://flagcdn.com/va.svg', mask: '### ### ####'},
        {name: 'Venezuela', code: 'VE', dialCode: '+58', icon: 'https://flagcdn.com/ve.svg', mask: '### ### ####'},
        {name: 'Vietnam', code: 'VN', dialCode: '+84', icon: 'https://flagcdn.com/vn.svg', mask: '### ### ####'},
        {name: 'Yemen', code: 'YE', dialCode: '+967', icon: 'https://flagcdn.com/ye.svg', mask: '### ### ####'},
        {name: 'Zambia', code: 'ZM', dialCode: '+260', icon: 'https://flagcdn.com/zm.svg', mask: '### ### ####'},
        {name: 'Zimbabwe', code: 'ZW', dialCode: '+263', icon: 'https://flagcdn.com/zw.svg', mask: '### ### ####'},
    ];
}
