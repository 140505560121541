export class LocationDetailModel {
    id?: string | null = null;

    // @property({
    //     type: 'string | null = null',
    // })
    // area: string | null = null;

    // @property({
    //     type: 'string | null = null',
    // })
    // subarea: string | null = null;
    type: string | null = null;

    explosionConsequences: string | null = null;
    ipx: string | null = null;
    ipy: string | null = null;
    gasTempClass: string | null = null;
    lowAmbientTemp: string | null = null;
    highAmbientTemp: string | null = null;
    inspectionFreq: string | null = null;
    approvalType: Array<string> = [];
    flammableSubstances: Array<string> = [];
    flammableDust: Array<string> = [];
    gasGroup: string | null = null;
    gasZone: string | null = null;
    tempClass: string | null = null;
    dustZone: string | null = null;
    dustgroup: string | null = null;
    dustIgnitionTempType: string | null = null;
    dustIngnitionTempVal: string | null = null;
    environmentalCondition: Array<string> = [];
    environmentalRisk: string | null = null;

    dustCloudIgnitionTemp: string | null = null;
    dustLayerIgnitionTemp: string | null = null;
    dustLayerDepth: string | null = null;

    dustLayerNotAppli: boolean = false;
    layerDepthBelow5: string | null = null;
    layerDepthBetween5to50: string | null = null;
    layerDepthAbove50: string | null = null;

    note?: string | null = null;

    created?: Date | null = null;
    updated?: Date | null = null;

    locationId?: string | null = null;

    constructor(data?: Partial<LocationDetailModel>) {
        Object.assign(this, data);
    }
}
